import { Backdrop, CircularProgress } from '@suid/material';

import { JSX, ParentProps, Match, Switch, Resource, createResource, createSignal } from 'solid-js';

import { BoundSource } from './BoundSource';
import { EntityCore, Identifier } from '@libs/db';
import { BoundDataSource, DataPageContext, PageContext } from '../types.ts';
import { CircularIndeterminate } from '@app/components/CircularIndeterminate.tsx';

import SaveButton from './SaveButton.tsx';
import { Trackable } from '../trackable.ts';
import { SignalProxy } from '@app/signalProxy/mod.ts';
import { logger } from '@libs/logger/mod.ts';
import { logEmitAndReturnError } from '@libs/logger/log.util.ts';
import { SxProps } from '@suid/system';

export type OwningArrayInfo<P extends EntityCore = EntityCore, K extends keyof P = any> = {
    parent: P, propName: K, id: Identifier
}

export type DataPageProps<T extends EntityCore = EntityCore> = ParentProps<{
    source: Resource<T | OwningArrayInfo>,
    pageType: string,
    onSaveSuccess?: () => void,
    fallback?: JSX.Element | null
    saveAction?: {
        style?: SxProps,
        label?: string,
        target?: () => HTMLElement,
    }
}>;

const DataPage = <T extends EntityCore = EntityCore,>(props: DataPageProps<T>) => {
    const ctx = PageContext.create(props.pageType);
    const resource = props.source;

    const [isSaving, setIsSaving] = createSignal(false);

    const onSave = {
        onBeforeSave: () => {
            setIsSaving(true);
        },
        onAfterSave: (e: any) => {
            try {
                setIsSaving(false);

                if (e) {
                    logger.error(e);
                } else {
                    props?.onSaveSuccess?.();
                }
            } catch (inner) {
                throw logEmitAndReturnError('error during save.', inner);
            }
        }
    };

    const trackable = createResource(resource, r => {
        return Trackable.from(r, onSave);
    })[0];

    const bindableSource = createResource(trackable, t => {
        const owner = props.source();
        if (!owner || !('parent' in owner)) {
            return t.source as BoundDataSource;
        }
        return SignalProxy.of(t.source) as BoundDataSource
    })[0];

    const fallback = props.fallback === null ? undefined : (props.fallback ?? <CircularIndeterminate />);

    return (
        <Switch fallback={fallback}>
            <Match when={bindableSource()}>
                <DataPageContext.Provider value={ctx}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 9999 }}
                        open={isSaving()}
                    >
                        <CircularProgress color="secondary" />
                    </Backdrop>
                    <BoundSource source={bindableSource} fallback={fallback}>
                        <SaveButton
                            ctx={ctx}
                            trackable={trackable()!}
                            options={props.saveAction}
                        />
                        {props.children}
                    </BoundSource>
                </DataPageContext.Provider>
            </Match>
            <Match when={resource.state === "errored"}>
                <div>Error: {resource.error?.message ?? 'unknown error'}</div>
            </Match>
        </Switch>
    );
};

export default DataPage;