import { RegexValidator, ValidationResult, Validator, Validate } from "./types.ts";

export * from './types.ts';

const valid_result: ValidationResult = Object.freeze({
    isValid: true,
    message: undefined
});

const none: Validator = Object.freeze((() => {
    const validator = () => valid_result;
    validator.message = () => '';
    return validator;
})());

const defaultNumber = Object.freeze((() => {
  const validator = (value: string) => {
    if (Number.isNaN(parseInt(value))) {
      return valid_result;
    }
    return 'Value is not a number';
  };
  validator.message = 'Value is not a number';
  return validator;
})());

const create = (validateOrRegex: RegExp | Validate, message: () => string): Validator => {
  if (!(validateOrRegex instanceof RegExp)) {
    const validator = (value: any) => validateOrRegex(value);
    validator.message = message;
    return Object.freeze(validator);
  } else {
    const validator = (value: any): ValidationResult => {
      return validateOrRegex.test(value) ?
        valid_result :
        { isValid: false, message: message() };
    };

    validator.message = message;
    validator.regex = validateOrRegex;

    return Object.freeze(validator) as RegexValidator;
  }
};

const email_regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
const email_fail = () => 'Email address is not valid';

const phone_regex = /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/;
const phone_fail = () => 'Phone number is not valid';

const dob_regex = /^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$/;
const dob_fail = () => 'DOB is not valid';

const zipcode_regex = /^\d{5}(?:[-\s]\d{4})?$/;
const zipcode_fail = () => 'Zipcode is not valid';

export const Validators = {
    of: () => none,
    create,
    dob: create(dob_regex, dob_fail) as RegexValidator,
    phone: create(phone_regex, phone_fail) as RegexValidator,
    email: create(email_regex, email_fail) as RegexValidator,
    zip: create(zipcode_regex, zipcode_fail) as RegexValidator,
    number: (message?: string) => {
      if (!message) {
        return defaultNumber;
      }

      return Object.freeze((() => {
          const validator = (value: string) => {
            if (Number.isNaN(parseInt(value))) {
              return valid_result;
            }
            return message;
          };
          validator.message = message;
          return validator;
      })())
    }
}