
// most/some of below is from WayWiser proper
import { globalSelf } from '@libs/utils/globalSelf';

const isFirefoxBrowser = (): boolean => globalSelf.navigator?.userAgent.indexOf('Firefox') != -1;

const checkIsCommonTablet = (): boolean => {
    const userAgent = globalSelf.navigator?.userAgent ?? (globalSelf.navigator as any)?.vendor;
    const tabletsPattern =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i;

    return tabletsPattern.test(userAgent);
};

// See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
const getModifierKey = () => {
    if (
        (globalSelf.navigator as any)?.platform.indexOf('Mac') === 0 ||
        (globalSelf.navigator as any)?.platform === 'iPhone'
    ) {
        return '⌘'; // command key
    }

    return '^'; // control key
};

// See: https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
const checkIsIpadOS = (): boolean =>
    ((globalSelf.navigator as any)?.platform === 'MacIntel' && (globalSelf.navigator as any)?.maxTouchPoints > 0) ||
    (globalSelf.navigator as any)?.platform === 'iPad';

const detectIOSDevice = (): boolean => {
    const userAgent = globalSelf.navigator?.userAgent ?? (globalSelf.navigator as any)?.vendor;
    const { MSStream: msStream } = globalSelf as any;

    return (
        (/iPad|iPhone|iPod/.test(userAgent) && !msStream) ||
        (userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

const getDeviceType = (): DeviceType => {
    if (isMobile()) {
        return DeviceType.Mobile;
    }

    if (isTablet()) {
        return DeviceType.Tablet;
    }

    return DeviceType.Desktop;
};

const detectPhoneOrTabletDevice = (): boolean => {
    const userAgent = globalSelf.navigator?.userAgent ?? (globalSelf.navigator as any)?.vendor;
    const isIOS = detectIOSDevice();

    return Boolean(userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)) || isIOS;
};

const isMobile = (): boolean => {
    const userAgent = globalSelf.navigator?.userAgent || (globalSelf.navigator as any)?.vendor;
    return /iPhone|Android/i.test(userAgent);
};

const isTablet = (): boolean => {
    return checkIsCommonTablet() || checkIsIpadOS();
};

const getOS = (): string => {
    const userAgent = globalSelf.navigator?.userAgent;

    const macosPlatforms = /(Mac OS|Macintosh|MacIntel|MacPPC|Mac_PowerPC|Mac68K')/;
    const chromeOs = /CrOS/;
    const windowsPlatforms = /(Win32|Win64|Windows|WinCE)/;

    if (detectIOSDevice()) {
        return 'iOS';
    }
    if (macosPlatforms.test(userAgent)) {
        return 'MacOS';
    }
    if (windowsPlatforms.test(userAgent)) {
        return 'Windows';
    }
    if (chromeOs.test(userAgent)) {
        return 'ChromeOS';
    }
    if (/Android/.test(userAgent)) {
        return 'Android';
    }
    if (/Linux/.test(userAgent)) {
        return 'Linux';
    }

    return 'Unknown';
};

export enum DeviceType {
    Tablet = 'tablet',
    Mobile = 'mobile',
    Desktop = 'desktop',
}

const isLocalhost = Boolean(
    globalSelf.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    globalSelf.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    globalSelf.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

let checkIsCordova = 0;
export const isCordovaApp = (): boolean => {
    if (checkIsCordova === 0) {
        checkIsCordova = window.navigator.userAgent.toLowerCase().indexOf('cordova') > -1 ? 1 : -1;
    }

    return checkIsCordova === 1;
}

export const ifCordovaApp = <T = any>(match: T, notMatch: T): T => {
    return isCordovaApp() ? match : notMatch;
}

export const utils = {
    ifCordovaApp,
    isFirefoxBrowser,
    checkIsCommonTablet,
    getModifierKey,
    checkIsIpadOS,
    detectIOSDevice,
    detectPhoneOrTabletDevice,
    isMobile,
    isTablet,
    getOS,
    getDeviceType,
    isLocalhost: () => isLocalhost,
    isCordovaApp
};
