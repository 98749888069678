//profile forms use this common component, still need to migrate medinfo, doc & med forms  - will consolidate 9 components
import { For, JSX } from 'solid-js';
import {
    Grid,
    Box
} from '@suid/material';

import { TextFieldCommon } from '@app/components/TextFieldCommon';

import { layoutBodyStyles } from '@app/assets/theme';

interface Field {
    name: string;
    label: string;
    fullWidth?: boolean;
    autoComplete?: string;
    gxs: number;
    mode?: string;
    endAdornmentValue?: string;
    multiline?: boolean;
    placeholder?: string;
    shrink?: boolean;
    component?: (props: any) => JSX.Element;
}

interface FormCommonProps {
    fields: Field[];
}

const FormCommon = (props: FormCommonProps) => {
    return (
        <Box sx={layoutBodyStyles.formBox}>
            <Grid container spacing={{ xs: 1, md: 1 }} sx={{ marginBottom: '30px' }}>
                <For each={props.fields}>{(field: Field) => {
                    const FieldComponent = field.component || TextFieldCommon;
                    return (
                        <FieldComponent
                            name={field.name}
                            label={field.label}
                            fullWidth={field.fullWidth}
                            autoComplete={field.autoComplete}
                            gxs={field.gxs}
                            mode={field.mode}
                            endAdornmentValue={field.endAdornmentValue}
                            multiline={field.multiline}
                            shrink={field.shrink}
                            placeholder={field.placeholder}
                        />
                    );
                }}</For>
            </Grid>
        </Box>
    );
};

export default FormCommon;
