import { zIndexDefaults } from "@suid/material/styles/createZIndex";

export const zIndexes = Object.freeze({
    pageModal: zIndexDefaults.tooltip + 500,
    appBar: zIndexDefaults.appBar, // 1100
    tooltip: zIndexDefaults.tooltip, // 1500

    speedDial: zIndexDefaults.speedDial, // 1050
    speedDialModal: zIndexDefaults.speedDial + 1, // 1051

    snackbar: zIndexDefaults.snackbar, // 1400
    modal: zIndexDefaults.modal, // 1300

    drawer: zIndexDefaults.drawer, // 1200
    drawerModal: zIndexDefaults.drawer + 1 // 1201
});