import { createEvent } from "@libs/utils/event.ts";
import { Config } from "@libs/config/mod.ts";
import { Env } from "@libs/environment/mod.ts";

export type DebugLogItem = { context: string, value: string };

export type DebugConsole = {
    emit(value: string): void
}

export const debugConsole = (() => {
    if (!Env.isDev && !Config.DEBUG_CONSOLE_ENABLED.else(false)) {
        return Object.freeze({
            isEnabled: false,
            emit: () => { },
            isShowing: () => false,
            fireShowEvent: () => { },
            fireHideEvent: () => { },
            clear: () => { },
            onLog: () => () => { },
            onShowHideRequested: () => () => { },
        });
    }

    let bufferSize = 0;
    let buffer: DebugLogItem[] | undefined = new Array<DebugLogItem>();
    const debugConsoleEvent = createEvent<DebugLogItem>();
    let isShowing = false;
    const showDebugConsoleEvent = createEvent<boolean>();

    return Object.freeze({
        isEnabled: true,
        emit: (value: any, context?: string) => {
            if (!buffer) {
                debugConsoleEvent.emit({ context: context ?? 'GLOBAL', value });
                return;
            }

            bufferSize += value.length;
            while (bufferSize > 10000) {
                const item = buffer.shift();
                if (!item) {
                    break;
                }
                bufferSize -= item.value.length;
            }

            buffer.push({ context: context ?? 'GLOBAL', value });
        },
        isShowing: () => isShowing,
        fireShowEvent: () => {
            showDebugConsoleEvent.emit(isShowing = true);
            if (buffer && buffer.length > 0) {
                const b = buffer;
                buffer = undefined;
                bufferSize = 0;
                b.forEach(item => debugConsoleEvent.emit(item));
            }
        },
        fireHideEvent: () => {
            showDebugConsoleEvent.emit(isShowing = false);
        },
        clear: () => {
            buffer = new Array<DebugLogItem>();
            bufferSize = 0;
        },
        onLog: (listener: (item: DebugLogItem) => void, once = false) => {
            return once ?
                debugConsoleEvent.source.listenOnce(listener) :
                debugConsoleEvent.source.listen(listener);
        },
        onShowHideRequested: (listener: (show: boolean) => void, once = false) => {
            return once ?
                showDebugConsoleEvent.source.listenOnce(listener) :
                showDebugConsoleEvent.source.listen(listener);
        }
    });
})();
