import { layoutBodyStyles } from "@app/assets/theme";
import AlertDialog from "@app/components/AlertDialog";
import { EntityCore } from "@libs/db";
import { Fab } from "@suid/material";
import { PageContext } from "../types";
import { saveButtonHandler } from "./save.button.handler";
import { ParentProps } from "solid-js";
import { Trackable } from "../trackable";
import { SxProps } from "@suid/system";
import { Portal } from "solid-js/web";

export type SaveBottonProps<T extends EntityCore = EntityCore> = ParentProps<{
    ctx: PageContext;
    trackable: Trackable<T>;
    disableSavePrompt?: boolean;
    options?: {
        style?: SxProps,
        label?: string,
        target?: () => HTMLElement,
    }
}>

export type ColorType = "inherit" | "primary" | "secondary" | "default" | "success" | "error" | "info" | "warning";

export default function SaveButton({
    ctx, trackable, disableSavePrompt: disableSavePrompt,
    children, options
}: SaveBottonProps) {
    const button = saveButtonHandler(
        ctx,
        trackable,
        disableSavePrompt === true
    );

    const isDisabled = () => !trackable.hasChanges() || (ctx.displayValidationErrors() && !trackable.isValid());
    const color = (ifChanged: ColorType = 'secondary', ifNotChanged: ColorType = 'inherit'): ColorType => trackable.hasChanges() ? ifChanged : ifNotChanged;
    const buttonStyle = options?.style ?? layoutBodyStyles.saveAction;
    const buttonText = options?.label ?? 'Save';

    const getButton = () => {
        return <Fab
            size='small'
            id='fab_save_button_id'
            onClick={button.onClick}
            variant='extended'
            color={color()}
            disabled={isDisabled()}
            sx={buttonStyle}
            type='submit'
        >
            {buttonText}
        </Fab>;
    };

    return (
        <>
            {
                options?.target
                    ? <Portal mount={options!.target()}>
                        {getButton()}
                    </Portal>
                    : getButton()
            }
            {children}
            <AlertDialog
                id='save_confirmation_dialog'
                {...button.confirmationDialogProps()}
            />
        </>
    );
}
