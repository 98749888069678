import { createArray } from './signals/signaledArray.ts';
import { createObject } from './signals/signaledObject.ts';

// Borrowed and refactored to minimal needed surface area
// ref: https://github.com/Exelord/solid-proxies

export const SignalProxy = {
    of<T extends Record<PropertyKey, unknown>>(object: T): T {
        return createObject(object);
    },
    ofArray<T = unknown>(arr: T[]): T[] {
        return createArray(arr);
    },
};
