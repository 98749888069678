import {
    Executor,
    objects,
    Deadline,
    DeadlineExceededError,
    Cancellable
} from '@rdt-utils';
import { Task, TaskCancelError } from './task.ts';

export const __task_symbol: unique symbol = Symbol('Task');

const isTask = <T = void>(value: any): value is Task<T> => {
    return objects.isObject(value) && __task_symbol in value;
}

const invoke = <T>(callable: () => T | PromiseLike<T>, task?: Task<T>): Task<T> => {
    return fulfillTask(callable(), task);
};

const invokeWithArgs = <T, U, V, R>(t: T, u: U, v: V, fn: (t: T, u: U, v: V) => R | PromiseLike<R>, task?: Task<R>): Task<R> => {
    return fulfillTask(fn(t, u, v), task);
};

const fulfillTask = <T>(value: T | PromiseLike<T>, task?: Task<T>): Task<T> => {
    if (!task) {
        return convertToTask(value);
    }

    if (!objects.isThenable(value)) {
        task.resolve(value);
        return task;
    }

    return bindPromiseLike(task, value);
};

const convertToTask = <T>(value: T | PromiseLike<T>): Task<T> => {
    if (!objects.isThenable(value)) {
        return fulfilled(value);
    }

    if (isTask<T>(value)) {
        return value;
    }

    return bindPromiseLike(Task.create(), value);
};

const bindPromiseLike = <T>(task: Task<T>, promise: PromiseLike<T>): Task<T> => {
    try {
        promise.then((v) => {
            task.resolve(v);
        }, (e) => {
            task.reject(e);
        });
    } catch (error) {
        task.reject(error);
    }

    return task;
};

const rejected = <T>(err: any): Task<T> => {
    return makeTask({
        status: () => 'rejected',
        isRejected: () => true,
        isFulfilled: () => false,
        isCancelled: () => false,
        getResult: () => ({ success: false, error: err }),
    }, Promise.reject(err), 'RejectedTask');
};

const fulfilled = (...args: any[]): Task<any> => {
    return makeTask({
        status: () => 'fulfilled',
        isRejected: () => false,
        isFulfilled: () => true,
        isCancelled: () => false,
        getResult: () => ({ success: true, value: args.length === 0 ? undefined : args[0] }),
    }, Promise.resolve(), 'FulfilledTask');
};

const cancelled = <T>(): Task<T> => {
    const err = new TaskCancelError();
    return makeTask({
        status: () => 'rejected',
        isRejected: () => true,
        isFulfilled: () => false,
        isCancelled: () => false,
        getResult: () => ({ success: false, error: err })
    }, Promise.reject(err), 'CancelledTask');
};

const makeTask = <T>(task: any, p: Promise<T>, type: string): Task<T> => {
    task[__task_symbol] = true;
    task.withTimeout = () => task;
    task.then = p.then.bind(p);
    task.catch = p.catch.bind(p);
    task.finally = p.finally.bind(p);
    task.isDone = () => true;
    task.cancel = () => false;
    task.cancelAfter = () => Promise.resolve(false);
    task.resolve = () => false;
    task.reject = () => false;
    task.bind = () => { };
    task[Symbol.toStringTag] = type;

    return task;
};

const exec = <T = void>(callable: () => T | PromiseLike<T>): Task<T> => {
    if (objects.isNil(callable)) {
        throw new Error('callable must not be null or undefined');
    }

    return invoke(callable);
};

const run = <T = void>(callable: () => T | PromiseLike<T>, executor?: Executor, deadline?: Deadline): Task<T> => {
    if (objects.isNil(callable)) {
        throw new Error('callable must not be null or undefined');
    }

    if (objects.isNil(executor) || executor === Executor.immediate) {
        if (deadline?.isExpired) {
            return rejected(new DeadlineExceededError());
        }
        return invoke(callable);
    }

    return invokeWithArgs(
        executor,
        callable,
        deadline,
        (e, c, d) => e.execute(c, d ? Cancellable.timeout(d) : undefined)
    );
};

const schedule = <T = void>(callable: () => T | PromiseLike<T>, ms = 0): Task<T> => {
    if (objects.isNil(callable)) {
        throw new Error('callable must not be null or undefined');
    }

    ms = ms ?? 0;
    if (ms < 0) {
        throw new Error('ms must be greater than or equal to 0');
    }

    const task = Task.create<T>();
    const timerId = setTimeout(() => {
        invoke(callable, task);
    }, ms);

    task.finally(() => {
        clearTimeout(timerId);
    });

    return task;
};

const _ = {
    isTask,
    invoke,
    invokeWithArgs,
    fulfillTask,
    rejected,
    fulfilled,
    cancelled,
    schedule,
    run,
    exec
};

export default _;