type ArrayIndexNavigatorType<T> = ReturnType<typeof createIndexNavigator<T>>;
export interface ArrayIndexNavigator<T> extends ArrayIndexNavigatorType<T> {
    add(item: T): number;
}

interface ArrayUtils {
    indexNavigator<T>(items: T[]): ArrayIndexNavigator<T>;
    indexNavigator<T>(items: T[], startIndex: number): ArrayIndexNavigator<T>;
    indexNavigator<T>(items: T[], activeIndexStorage: [getter: () => number, setter: (update: (current: number) => void) => void]): ArrayIndexNavigator<T>;
    indexNavigator<T>(items: T[], startIndex: number, activeIndexStorage: [getter: () => number, setter: (update: (current: number) => void) => void]): ArrayIndexNavigator<T>;
}

export const arrayUtils: ArrayUtils = Object.freeze({
    indexNavigator<T>(...args: any[]): ArrayIndexNavigator<T> {
        if (args.length === 0) {
            throw new Error('items is required');
        }

        if (args.length === 1) {
            return createIndexNavigator(args[0]);
        }

        if (args.length !== 2) {
            throw new Error('Invalid arguments');
        }

        if (typeof args[1] === 'number') {
            return createIndexNavigator(args[0], args[1]);
        }

        return createIndexNavigator(args[0], 0, args[1]);
    }
});

export function createIndexNavigator<T>(
    items: T[],
    startIndex: number = 0,
    activeIndexStorage?: [getter: () => number, setter: (update: (current: number) => void) => void]) {

    startIndex = items.length === 0 ? -1 : startIndex < 0 ? 0 : startIndex >= items.length ? items.length - 1 : startIndex;
    const [activeIndex, setActiveIndex] = activeIndexStorage ??
        (() => {
            let currentIndex = startIndex;
            return [() => currentIndex, (setter: (current: number) => number) => {
                currentIndex = setter(currentIndex);
            }]
        })();

    return Object.freeze({
        add: (item: T): number => {
            items.push(item);
            return items.length - 1;
        },
        previous: () => {
            const current = activeIndex();
            if (current === -1) {
                return null;
            }

            const previousIndex = current <= 0 ? items.length - 1 : current - 1;
            return items[previousIndex]
        },
        currentIndex: () => activeIndex(),
        current: () => {
            const current = activeIndex();
            if (current === -1) {
                return null;
            }

            return current === -1 ? null : items[current]
        },
        next: () => {
            const current = activeIndex();
            if (current === -1) {
                return null;
            }

            const nextIndex = current >= items.length - 1 ? 0 : current + 1;
            return items[nextIndex]
        },
        moveNext: () => {
            if (items.length === 0) {
                return;
            }

            setActiveIndex(current => {
                return current >= items.length - 1 ? 0 : current + 1;
            });
        },
        movePrevious: () => {
            if (items.length === 0) {
                return;
            }

            setActiveIndex(current => {
                return current <= 0 ? items.length - 1 : current - 1;
            });
        },
        map: <R,>(transform: (isCurrent: boolean, item: T) => R) => {
            const current = activeIndex();
            return items.map((_, index) => transform(index === current, items[index]));
        }
    });
}
