export type Subscriber<T> = {
  next?: (value: T) => void;
  error?: (error: any) => void;
  complete?: () => void;
}

export type Subscription = {
  unsubscribe: () => void;
}

export type Observable<T> = {
  subscribe: (subscriber: Subscriber<T>) => Subscription;
}

export class ObservableEmitter<T> {
  #subscribers: Array<Partial<Subscriber<T>>> | undefined = [];

  public subscribe(subscriber: Subscriber<T>): void {
    if (this.#subscribers === undefined) {
        if (subscriber.complete) {
          subscriber.complete();
        }
    } else {
        this.#subscribers.push(subscriber);
    }
  }

  public unsubscribe(subscriber: Partial<Subscriber<T>>): void {
    if (this.#subscribers === undefined) {
      return;
    }
    const idx = this.#subscribers.indexOf(subscriber);
    if (idx !== -1) {
      this.#subscribers.splice(idx, 1);
    }
  }

  public next(value: any): void {
    if (this.#subscribers === undefined) {
      return;
    }

    const observers = this.#subscribers;
    for (const observer of observers) {
      if (observer.next) {
        observer.next(value);
      }
    }
  }

  public error(error: any): void {
    if (this.#subscribers === undefined) {
      return;
    }

    const observers = this.#subscribers;
    this.#subscribers = undefined;
    for (const observer of observers) {
      if (observer.error) {
        observer.error(error);
      }
    }
  }

  public complete(): void {
    if (this.#subscribers === undefined) {
      return;
    }

    const observers = this.#subscribers;
    this.#subscribers = undefined;
    for (const observer of observers) {
      if (observer.complete) {
        observer.complete();
      }
    }
  }

  public toObservable(): Observable<T> {
    return {
      subscribe: (observer: Subscriber<T>) => {
        this.subscribe(observer);
        return {
          unsubscribe: () => {
            this.unsubscribe(observer);
          }
        };
      }
    };
  }
}