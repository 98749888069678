import { xs } from "@libs/db/mod.ts";
import { TypeInfo } from "./entity.types.ts";

const insurance = xs.define("Insurance", TypeInfo.INSURANCE.type, {
    insurerName: xs.string(150),
    insuranceType: xs.string(150), //Type of insurance card - Medical, Vision, Dental, Prescription Plan, Other
}, {
    responsibleParty: xs.string(150), //Member Name, Name, Member, Subscriber Name, Subscriber
    memberID: xs.string(150), //Subscriber ID, Member ID, Medicare Number, ID, Identification Number, Health Plan
    groupNumber: xs.string(50), //Group ID, Group, Group No., Group #
    copay: xs.string(1500), //Copayment amount, generally a list of different amounts for Primary Care/Office Visit, Specialist, Urgent Care, Emergency Room/ER, Vision Exam / Glasses
    deductible: xs.string(1500), //Individual, Family, In Network, Out of Network, Out-of-Pocket Max
    contactInformation: xs.string(1500), //Contact info on back of card, most of the time these will be a list of phone numbers with labels
    additionalNotes: xs.string(1500), //Any additional notes the user thinks will be helpful
    //Secondary Attributes
    coIns: xs.string(1500), //% covered for in network and out of network
    planNumber: xs.string(50), //Plan Code, Plan Codes, Plan ID, Policy ID
    planType: xs.string(150), //Plan Type, Plan, Coverage Type, Coverage, Membership Type (Family, PPO, HMO)
    payerID: xs.string(50), //Issurer ID, "Insurer Name" ID (UHC ID etc)
    network: xs.string(150), //Generally a code or name for the covered network of doctors
    effectiveDate: xs.string(), //Policy or Program Start Date
    dependents: xs.string(1500), //List of dependents, other members, the people listed here will probalby be subjects of other care cards in their vault
    //Rx Attributes
    rxName: xs.string(150), //Rx Name - usually appears as a logo, company names like OptumRx, GoodRx Choice POS II
    rxID: xs.string(50),
    rxBIN: xs.string(50), //Common
    rxGRP: xs.string(50), //Common
    rxPCN: xs.string(50), //Common
    rxNetwork: xs.string(50),
    rxFormulary: xs.string(50),
    rxCopay: xs.string(150) //Copayment amount, generally a list of different amounts for Generic, Preferred Brand Name, Non-preferred Brand Name
});

const def = xs.define("InsuranceDetail", TypeInfo.INSURANCE_DETAILS.type, {
    insuranceCards: xs.array(insurance.schemaDef).default([])
});

export const Insurance = xs.EntityFactory<Insurance>(
    insurance, TypeInfo.INSURANCE.version
);

export const InsuranceDetail = xs.EntityFactory<InsuranceDetail>(
    def,
    TypeInfo.INSURANCE_DETAILS.version,
    migrateInsurance
);

export interface Insurance extends xs.inferType<typeof insurance.schemaDef> {
    __type: typeof TypeInfo.INSURANCE.type,
}

export interface InsuranceDetail extends xs.inferType<typeof def.schemaDef> {
    __type: typeof TypeInfo.INSURANCE_DETAILS.type,
    insuranceCards: Insurance[]
}

function migrateInsurance(entity: InsuranceDetail, isNew: boolean) {
    migrateInsurancev1(entity, isNew);
}

function migrateInsurancev1(entity: InsuranceDetail, isNew: boolean) {
    if (isNew || entity.__scid === TypeInfo.INSURANCE.version.value) {
        return;
    }

    if (entity.__scid !== 0) {
        // we return since the below code is only relevant to versions
        // prior to the addition to insurance detail array migration
        return;
    }

    const propNames = Insurance.getSchemaPropertyNames();
    if (propNames.every(p => !(entity as any)[p])) {
        return; // nothing to migrate
    }

    // create a new one based on the old data
    const copy = Insurance.copyFrom(entity);
    entity.insuranceCards = [...(entity.insuranceCards ?? []), copy];

    // delete the old data off of the parent
    propNames.forEach(p => delete (entity as any)[p]);
}