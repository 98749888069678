export const FrequencyList = [
    { value: 'OD', label: 'Once Daily' },
    { value: 'BID', label: 'Twice Daily' },
    { value: 'TID', label: 'Three Times Daily' },
    { value: 'QID', label: 'Four Times Daily' },
    { value: 'EOD', label: 'Every Other Day' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Hourly', label: 'Hourly' },
    { value: 'ac', label: 'Before Meals' },
    { value: 'pc', label: 'After Meals' },
    { value: 'PRN', label: 'As Needed' },
    { value: 'Other', label: 'Other' },
];

const frequencyMap = new Map<string, string>(FrequencyList.map((item) => [item.value, item.label]));

export const findFrequency = (value: string) => {
    return frequencyMap.get(value);
};

export function formatDate(dateString: string | null | undefined) {
    if (!dateString) {
        return '';
    }

    const date = new Date(dateString + 'T00:00');

    return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
    }).format(date);
}

export const getFrequencyLabel = (frequencyValue: string): string => {
    const freq = FrequencyList.find(freq => freq.value === frequencyValue);
    return freq ? freq.label : '';
};