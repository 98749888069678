import { JSX } from 'solid-js';
import * as h from '@app/pages/helpers/commonHelpers.tsx';

import OtherInsuranceIcon from '@app/assets/icons/OtherInsuranceIcon';
import RxInsuranceIcon from '@app/assets/icons/RxInsuranceIcon';
import DentalInsuranceIcon from '@app/assets/icons/DentalInsuranceIcon';
import VisionInsuranceIcon from '@app/assets/icons/VisionInsuranceIcon';
import MedicalInsuranceIcon from '@app/assets/icons/MedicalInsuranceIcon';

import { Insurance } from '@app/entities/insurance.detail';
import ListEditDetailsDrawer from '@app/components/ListEditDetailsDrawer';

interface CardListProps {
    insuranceCards: () => Insurance[];
    onInsuranceCardEditSelected: (insurance: Insurance) => void;
}

const CardList = ({ insuranceCards, onInsuranceCardEditSelected }: CardListProps) => {
    return (
        <>
            <ListEditDetailsDrawer
                title={(item) => item.insurerName || 'Insurance Details'}
                onEditSelected={onInsuranceCardEditSelected}
                listItems={() => listItems(insuranceCards())}
                avatar={false}

            >{c =>
            (
                <>
                    <h.AttributeWithLabel value={c.memberID} label={"Member ID"} />
                    <h.AttributeWithLabel value={c.groupNumber} label={"Group Number"} />
                    <h.AttributeWithLabel value={c.responsibleParty} label={"Member Name"} />
                    <h.AttributeWithLabel value={c.planNumber} label={"Plan Number"} />
                    <h.AttributeWithLabel value={c.payerID} label={"Payer ID"} />
                    <h.AttributeWithLabel value={c.planType} label={"Plan Type"} />
                    <h.AttributeWithLabel value={c.network} label={"Network"} />
                    <h.AttributeWithLabel value={c.effectiveDate} label={"Effective"} />
                    <h.AttributeWithLabel value={c.dependents} label={"Dependents"} divider={true} />
                    <h.AttributeWithLabel value={c.copay} label={"Copay"} divider={true} />
                    <h.AttributeWithLabel value={c.deductible} label={"Deductible"} divider={true} />
                    <h.AttributeWithLabel value={c.coIns} label={"Coinsurance"} divider={true} />
                    <h.AttributeWithLabel value={c.contactInformation} label={"Contact Information"} divider={true} />
                    <h.AttributeWithLabel value={c.rxName} label={"Rx Info"} divider={true} />
                    <h.AttributeWithLabel value={c.rxID} label={"Rx ID"} />
                    <h.AttributeWithLabel value={c.rxBIN} label={"Rx BIN"} />
                    <h.AttributeWithLabel value={c.rxGRP} label={"Rx GRP"} />
                    <h.AttributeWithLabel value={c.rxPCN} label={"Rx PCN"} />
                    <h.AttributeWithLabel value={c.rxFormulary} label={"Rx Formulary"} />
                    <h.AttributeWithLabel value={c.rxNetwork} label={"Rx Network"} />
                    <h.AttributeWithLabel value={c.rxCopay} label={"Rx Copay"} />
                    <h.AttributeWithLabel value={c.additionalNotes} label={"Additional Notes"} divider={true} />
                </>
            )}
            </ListEditDetailsDrawer >
        </>
    );
};

export default CardList;

const listItems = (insuranceCards: Insurance[]) => {
    const iconMapping: { [key: string]: () => JSX.Element } = {
        Medical: MedicalInsuranceIcon,
        Dental: DentalInsuranceIcon,
        Vision: VisionInsuranceIcon,
        RxPlan: RxInsuranceIcon,
        Other: OtherInsuranceIcon
    };

    return insuranceCards.map(card => {
        const Icon = iconMapping[card.insuranceType] || MedicalInsuranceIcon;

        return {
            id: card._id,
            primaryText: card.insurerName,
            secondaryText: card.memberID ? card.memberID : card.insuranceType,
            icon: Icon,
            data: card,
        };
    });
};