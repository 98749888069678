import { Validator, Validators } from "@libs/validators";
import { SignalRef } from "./common";
import { SchemaInfo } from "./schema.info";

export type PropertyValidationHandler = (path: string) => { isRequired: () => boolean, validator: Validator }

export const getPropertyValidationHandler = (type: number, overallIsValidSignal: SignalRef<boolean>): PropertyValidationHandler => {
    let validators: Map<string, { isRequired: () => boolean, validator: Validator }> | undefined;
    let currentErrorCount = 0;

    return (path: string) => {
        const propInfo = SchemaInfo.from(type).getPropInfo(path);

        let prevError = false;
        const incrementErrorCount = () => {
            if (!prevError) {
                prevError = true;
                currentErrorCount++;
                if (currentErrorCount > 0) {
                    overallIsValidSignal.set(false);
                }
            }
        };

        const decrementErrorCount = () => {
            if (prevError) {
                prevError = false;
                currentErrorCount--;
                if (currentErrorCount === 0) {
                    overallIsValidSignal.set(true);
                }
            }
        };

        const validator: Validator = Validators.create(value => {
            const result = propInfo.validate(value);
            if (result.isValid) {
                decrementErrorCount();
            } else {
                incrementErrorCount();
            }

            return result;
        }, () => {
            return '';
        });

        const v = { isRequired: propInfo.isRequired, validator };
        (validators ?? (validators = new Map())).set(path, v);
        return v;
    }
}