import CheckIcon from '@suid/icons-material/Check';
import RotateRightIcon from '@suid/icons-material/RotateRight';
import RotateLeftIcon from '@suid/icons-material/RotateLeft';
import { IconButton } from '@suid/material';

export function CropButtons(
    props: {
        ready: () => boolean,
        rotate: (degree: number) => void,
        crop: () => void
    }
) {
    const { ready, rotate, crop } = props;

    const Icon = (
        props: { icon: any, label: string, onClick: () => void }
    ) => {
        return <IconButton
            edge="end"
            color="inherit"
            disabled={!ready()}
            onClick={props.onClick}
            aria-label={props.label}
        >
            {props.icon}
        </IconButton>
    }

    return <>
        <Icon label="rotate"
            icon={<RotateLeftIcon />} onClick={() => rotate(-90)}
        />

        <Icon label="rotate"
            icon={<RotateRightIcon />} onClick={() => rotate(90)}
        />

        <Icon label="crop"
            icon={<CheckIcon />} onClick={() => crop()}
        />
    </ >
}