import {
    Box,
    Typography
} from '@suid/material';

import { svc } from '@app/utils/svc.ts';

import SectionCommon from '@app/components/SectionCommon.tsx';
import ActionBar from '@app/components/ActionBar';
import { useNavigateToEdit } from '@app/routes/hooks.ts';
import { MultiSelectEmptyState } from '@app/pages/helpers/commonHelpers';
import MedicalInfoList from '@app/pages/medical-info/components/MedicalInfoList';

const MedicalInfoView = () => {
    const editSection = useNavigateToEdit();
    const medicalInfoResource = svc.card.medicalInfo;

    const isEmptySection = (medicalInfo: any, sectionKey: string) => {
        return !medicalInfo[sectionKey] || medicalInfo[sectionKey].length === 0;
    };

    return (
        <>
            <Box sx={{ background: '#EBEBEB', paddingTop: '15px', paddingBottom: '10px' }}>
                <SectionCommon
                    onClick={() => editSection('MedicalConditions')}
                    resource={medicalInfoResource}
                    displayData={m => [
                        <Typography style={{ flex: 1, "padding-top": '3px' }}>
                            <MedicalInfoList medicalInformationItems={() => m.medicalConditions} />
                        </Typography>
                    ]}
                    sectionTitle="Medical Conditions"
                    emptyText={<MultiSelectEmptyState emptyStateText="Tap to add any current medical conditions. If no medical conditions, indicate &quot;None&quot;." />}
                    isEmpty={m => isEmptySection(m, 'medicalConditions')}
                />

                <SectionCommon
                    onClick={() => editSection('DrugAllergies')}
                    resource={medicalInfoResource}
                    displayData={m => [
                        <Typography style={{ flex: 1, "padding-top": '3px' }}>
                            <MedicalInfoList medicalInformationItems={() => m.drugAllergies} />
                        </Typography>
                    ]}
                    sectionTitle="Drug Allergies"
                    emptyText={<MultiSelectEmptyState emptyStateText="Tap to add drug allergies, it may be helpful to indicate severity. If no known drug allergies, indicate &quot;None&quot;." />}
                    isEmpty={m => isEmptySection(m, 'drugAllergies')}
                />

                <SectionCommon
                    onClick={() => editSection('OtherAllergies')}
                    resource={medicalInfoResource}
                    displayData={m => [
                        <Typography style={{ flex: 1, "padding-top": '3px' }}>
                            <MedicalInfoList medicalInformationItems={() => m.otherAllergies} />
                        </Typography>
                    ]}
                    sectionTitle="Other Allergies"
                    emptyText={<MultiSelectEmptyState emptyStateText="Tap to add any other allergies beyond drug allergies. If no known allergies, indicate &quot;None&quot;." />}
                    isEmpty={m => isEmptySection(m, 'otherAllergies')}
                />

                <SectionCommon
                    onClick={() => editSection('Surgeries')}
                    resource={medicalInfoResource}
                    displayData={m => [
                        <Typography style={{ flex: 1, "padding-top": '3px' }}>
                            <MedicalInfoList medicalInformationItems={() => m.surgeries} />
                        </Typography>
                    ]}
                    sectionTitle="Surgeries, Procedures & Tests"
                    emptyText={<MultiSelectEmptyState emptyStateText="Tap to add surgeries, procedures and tests. It is helpful to include surgeries but also procedures and tests like CT Scans and MRIs as well. If no past surgeries, indicate &quot;None&quot;." />}
                    isEmpty={m => isEmptySection(m, 'surgeries')}
                />

                <SectionCommon
                    onClick={() => editSection('Immunizations')}
                    resource={medicalInfoResource}
                    displayData={m => [
                        <Typography style={{ flex: 1, "padding-top": '3px' }}>
                            <MedicalInfoList medicalInformationItems={() => m.immunizations} />
                        </Typography>
                    ]}
                    sectionTitle="Immunizations"
                    emptyText={<MultiSelectEmptyState emptyStateText="Tap to add immunizations. If known, it may also be helpful to include the date immunized &quot;COVID - Oct 2023&quot;. If no immunizations, indicate &quot;None&quot;." />}
                    isEmpty={m => isEmptySection(m, 'immunizations')}
                />
            </Box>
            <ActionBar showAction={false} />
        </>
    );
};

export default MedicalInfoView;
