
import { svc } from '@app/utils/svc.ts';
import { pageTypeStrings } from '@app/defs/page.strings.ts';

import DrawerCommon from '@app/components/DrawerCommon.tsx';

import MedicalInfoForm from '@app/pages/medical-info/components/MedicalInfoForm';
import DataPage from '@app/data/components/DataPage.tsx';
import { useNavigateToView } from '@app/routes/hooks';
import { Once } from '@rdt-utils';


const MedicalInfoEdit = () => {
    const [navToView, section] = useNavigateToView()
    const title = (() => {
        switch (section) {
            case 'MedicalConditions': return 'Medical Conditions';
            case 'DrugAllergies': return 'Drug Allergies';
            case 'OtherAllergies': return 'Other Allergies';
            case 'Surgeries': return 'Surgeries';
            case 'Immunizations': return 'Immunizations';
            default: return 'Default Title'; // Default title if none selected
        }
    })();

    const dataPage = Once.of(() => {
        return <>
            <DataPage source={svc.card.medicalInfo} pageType={pageTypeStrings.medicalInfo} onSaveSuccess={navToView}>
                <MedicalInfoForm
                    type={section as 'MedicalConditions' | 'DrugAllergies' | 'OtherAllergies' | 'Surgeries' | 'Immunizations'}
                />
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title={title}
        >
            {dataPage()}
        </DrawerCommon>
    );
};

export default MedicalInfoEdit;
