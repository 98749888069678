import { Box } from '@suid/material';

import { svc } from '@app/utils/svc.ts';
import * as h from '@app/pages/helpers/commonHelpers.tsx';

import { SectionEmptyState } from '@app/pages/helpers/commonHelpers';
import SectionCommon from '@app/components/SectionCommon.tsx';
import ActionBar from '@app/components/ActionBar';
import { useNavigateToEdit } from '@app/routes/hooks.ts';

import CalendarIcon from '@app/assets/icons/CalendarIcon';
import BloodTypeIcon from '@app/assets/icons/BloodTypeIcon.tsx';
import HeightIcon from '@app/assets/icons/HeightIcon.tsx';
import WeightIcon from '@app/assets/icons/WeightIcon.tsx';
import EmailIcon from '@app/assets/icons/EmailIcon.tsx';
import NotesIcon from '@app/assets/icons/NotesIcon';

export default function ProfileView() {
    const editSection = useNavigateToEdit();
    const subject = svc.card.cardInfo;
    const profile = svc.card.profile;
    return (
        <>
            <Box sx={{ background: '#EBEBEB', paddingTop: '15px', paddingBottom: '10px' }}>

                <SectionCommon
                    onClick={() => editSection('PersonalDetails')}
                    resource={profile}
                    displayData={p => [
                        subject()?.age && h.Attribute({
                            name: (
                                <span>
                                    {h.formatDate(p.dob)}
                                    <span style={{ "font-size": '14px', "margin-left": '10px', "color": '#979797' }}>({subject()?.age})</span>
                                </span>
                            ),
                            contentIcon: <CalendarIcon />
                        }),
                        p.heightFt && h.Attribute({ name: `${p.heightFt}' ${p.heightIn}"`, contentIcon: <HeightIcon /> }),
                        p.weight && h.Attribute({ name: `${p.weight} lbs`, contentIcon: <WeightIcon /> }),
                        p.blood_type && h.Attribute({ name: `${p.blood_type} blood type`, contentIcon: <BloodTypeIcon /> }),
                    ]}
                    isEmpty={p => !p.dob && !p.blood_type && !p.heightFt && !p.weight}
                    emptyText={<SectionEmptyState emptyStateHeader="No personal details yet" emptyStateText="Tap to add personal details like birthdate.  Birthdate is required for many interactions, including picking up meds. " />}
                />

                <SectionCommon
                    onClick={() => editSection('ContactInfo')}
                    resource={profile}
                    displayData={p => [
                        h.attributePhoneNumber(p.phone1, p.phone1_type),
                        h.attributePhoneNumber(p.phone2, p.phone2_type),
                        h.attributePhoneNumber(p.phone3, p.phone3_type),
                        h.attributePhoneNumber(p.phone4, p.phone4_type),
                        h.Attribute({ name: p.email, contentIcon: <EmailIcon /> }),
                        h.attributeAddress(p.street1, p.street2, p.city, p.state, p.zipcode, p.county)
                    ]}
                    isEmpty={p => !p.phone1 && !p.email && !(p.street1 || p.street2 || p.city || p.state || p.zipcode || p.county)}
                    emptyText={<SectionEmptyState emptyStateHeader="No contact information yet" emptyStateText="Tap to add contact details. An accurate address is crucial for emergency assistance and helpful during financial interactions." />}
                />

                <SectionCommon
                    onClick={() => editSection('ProfileNotes')}
                    resource={profile}
                    displayData={p => [
                        h.Attribute({ name: p.notes, contentIcon: <NotesIcon /> }),
                    ]}
                    isEmpty={p => !p.notes}
                    emptyText={<SectionEmptyState emptyStateHeader="Additional notes" emptyStateText="Want to include more personal details on your Care Card? Tap to add extra notes." />}
                />
            </Box>
            <ActionBar showAction={false}/>
        </>
    );
}
