import { MutableEntity, OwningArrayInfo, SaveLifecyleHandler } from "@app/data/mutable";
import { EntityCore } from "@libs/db/schema/entity.core";
import { createSignal } from "solid-js";

export type Trackable<T extends EntityCore = EntityCore> = {
    source: T;
    hasChanges: () => boolean;
    isValid: () => boolean;
    save: () => Promise<T>;
    untrack: () => void;
    reset: () => void;
};

export const Trackable = {
    from: <T extends EntityCore, P extends EntityCore = EntityCore, K extends keyof P = any>(
        value: T | OwningArrayInfo<P, K>,
        onSave?: (() => void) | SaveLifecyleHandler
    ): Trackable<T> => {
        const mutable = MutableEntity.as<T>('parent' in value ? value.parent : value);
        if (mutable !== undefined) {
            return mutable;
        }

        const changeSignal = createSignal(false);
        const validSignal = createSignal(true);
        const deferred = MutableEntity.track(value, changeSignal, validSignal, onSave);

        return {
            untrack: () => {
                MutableEntity.untrack(deferred.source);
            },
            reset: () => {
                deferred.reset();
            },
            source: deferred.source,
            hasChanges: changeSignal[0],
            isValid: validSignal[0],
            save: () => deferred.save(),
        };
    }
}