

export default function MobileIcon() {
    return (
<svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2843 0H2.71606C1.61938 0 0.727173 0.892207 0.727173 1.98865V22.0112C0.727173 23.1078 1.6195 24 2.71606 24H13.2843C14.3808 24 15.2729 23.1077 15.2729 22.0112V1.98865C15.2729 0.892207 14.3808 0 13.2843 0ZM13.794 22.0113C13.794 22.2925 13.5653 22.5214 13.2843 22.5214H2.71606C2.43478 22.5214 2.20606 22.2925 2.20606 22.0113V1.98865C2.20606 1.70749 2.4349 1.47877 2.71606 1.47877H4.53575C4.59755 1.98853 5.03159 2.38369 5.55803 2.38369H10.4417C10.9687 2.38369 11.4023 1.98853 11.4641 1.47877H13.2844C13.5654 1.47877 13.7942 1.70749 13.7942 1.98865V22.0113H13.794Z" fill="#333333"/>
</svg>



  );
}
