import { createSignal } from 'solid-js';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Typography
} from '@suid/material';

import { debounce } from '@std/async/debounce';
import { valueLookupApiUrlBase } from '@app/svc/constants';

export interface ListSelectItem<T = unknown> {
    id: string;
    primaryText: string;
    secondaryText: string;
    data: T;
}

const SelectSearchCommon = <T = any>({ onSelected, placeholder, instructions, type, formatter, selector }: SelectSearchCommonProps<T>) => {
    const [items, setItems] = createSignal<ListSelectItem<T>[]>([]);
    selector = getSelector(selector, type, formatter);
    const search = debounce((value: string) => {
        if (value && value.length >= 3) {
            selector!(value).then(setItems);
        }
    }, 150);

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: '8px',
            }}
        >
            <TextField
                onChange={(ev) => search(ev.currentTarget.value)}
                type="text"
                variant='filled'
                fullWidth={true}
                placeholder={placeholder}
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '10px',
                        border: '1px solid #E0E0E0',
                    }
                }}
            />
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true}>
                {items().map((item) => (
                    <ListItem
                        onClick={() => onSelected(item.data)}
                        divider
                        sx={{ width: '100%', display: 'flex', marginLeft: '0px', paddingLeft: '0px' }}
                    >
                        <ListItemButton alignItems='flex-start' sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                            <ListItemText
                                primary={item.primaryText}
                                secondary={item.secondaryText}
                                sx={{ '& .MuiListItemText-primary': { fontWeight: '500', fontSize: '16px' } }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Typography
                variant='body1'
                visibility={items().length > 0 ? 'hidden' : 'visible'}
                sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    marginTop: '50px',
                    color: 'text.secondary',
                    fontSize: '20px',
                }}
            >
                {instructions}
            </Typography>
        </Box>
    );
};

export default SelectSearchCommon;

type SelectSearchCommonProps<T = unknown> = {
    type: string;
    formatter: (item: T) => ListSelectItem<T>;
    onSelected: (item: T) => void;
    placeholder?: string;
    instructions?: string;
    selector?: undefined;
} | {
    type?: undefined
    formatter?: undefined;
    onSelected: (item: T) => void;
    placeholder?: string;
    instructions?: string;
    selector: (value: string) => Promise<ListSelectItem<T>[]>;
}

function getSelector<T>(
    selector?: (value: string) => Promise<ListSelectItem<T>[]>,
    type?: string,
    formatter?: (item: T) => ListSelectItem<T>) {
    if (selector) {
        return selector;
    }

    if (!formatter) {
        throw new Error('formatter is required when selector is not provided');
    }
    if (!type) {
        throw new Error('type is required when selector is not provided');
    }

    const url = valueLookupApiUrlBase(type);
    return async (value: string) => {
        const response = await fetch(`${url}${value}`);
        const data = await response.json();
        return data.map(formatter);
    }
}