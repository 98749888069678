const isProxied = Symbol('proxied');
const unwrapProxied = Symbol('proxied');

export const Proxied = {
    of<T extends object>(source: T): T {
        if (Proxied.is(source)) {
            return source;
        }

        return new Proxy(source, {
            get: (target, p) => {
                if (p === isProxied) {
                    return true;
                }

                if (p === unwrapProxied) {
                    return source;
                }

                return Reflect.get(target, p);
            },
            set(_, key) {
                throw new Error(`Cannot set property '${key.toString()}' on readonly object`);
            },
            deleteProperty(_, key) {
                throw new Error(`Cannot delete property '${key.toString()}' on readonly object`);
            },
            defineProperty(_, key) {
                throw new Error(`Cannot define property '${key.toString()}' on readonly object`);
            },
        });
    },
    is: (source: any): boolean => {
        if (!source) {
            return false;
        }

        return source[isProxied] === true;
    },
    unwrap: <T extends object>(source: T): T | undefined => {
        return (source as any)[unwrapProxied] as T;
    }
};