import { Accessor, createEffect, createSignal } from 'solid-js';
import { AUTH_SERVICE, AuthService } from '../auth';
import { Config } from '@libs/config';
import { logger } from './logger';
import { DevUtil } from './dev.utils';
import { host } from '@libs/services/host';
import { svc } from './svc';
import { CARD_SERVICE } from '@app/card-service/types.ts';

export const useIsOnline = (() => {
    const isOnlineSignal = createSignal(navigator.onLine);

    window.addEventListener('online', () => isOnlineSignal[1](true));
    window.addEventListener('offline', () => isOnlineSignal[1](false));

    return () => isOnlineSignal[0];
})();

export const useIsAuthenticated = (() => {
    const signal = createSignal(false);

    let auth!: AuthService;
    const register = () => {
        signal[1](auth.isAuthenticated());
        auth.onLoginLogout(value => {
            signal[1](value);
        });
    }

    host.onStart()
        .then(
            (host) => {
                auth = host.get(AUTH_SERVICE);
                register();
            }
        ).catch(
            console.error
        );

    const checkAuth = () => {
        const isAuth = auth?.isAuthenticated() === true;
        if (signal[0]() !== isAuth) {
            signal[1](isAuth);
        }
        return isAuth;
    };

    return () => checkAuth;
})();

export const useIsInPwa = (() => {
    const iOS = {
        // works on iOS
        insideInstalledApp: 'standalone' in window.navigator && window.navigator.standalone === true,
        canInstall: 'standalone' in window.navigator
    }

    const signal = createSignal(iOS.insideInstalledApp);
    if (signal[0]()) {
        logger.emit(`[useIsInPwa.iOS.insideInstalledApp], Running in PWA`);
        signal[1](true);
        return () => signal[0];
    }

    if (svc.isCordovaApp()) {
        logger.emit(`[useIsInPwa.isCordovaApp], Running in PWA`);
        signal[1](true);
        return () => signal[0];
    }

    const allowInBrowserUsage = Config.APP_CFG_ALLOW_USE_IN_BROWSER.else(false);
    if (allowInBrowserUsage) {
        logger.emit(`[useIsInPwa.Config.APP_CFG_ALLOW_USE_IN_BROWSER], Allowing run in browser or PWA`);
        signal[1](true);
        return () => signal[0];
    }

    document.addEventListener('deviceready', () => {
        logger.emit(`[useIsInPwa.deviceready], Running in PWA`);
        signal[1](true);
    }, false);

    // yet another way to detect if on PWA
    window.addEventListener('DOMContentLoaded', () => {
        if (signal[0]()) {
            return;
        }

        const mq = window.matchMedia('(display-mode: standalone)');
        const displayMode = mq.matches ? 'standalone' : 'browser tab';
        if (displayMode === 'standalone') {
            logger.emit(`[useIsInPwa.DOMContentLoaded.standalone], Running in 'PWA'}`);
            signal[1](true);
        } else {
            mq.onchange = () => {
                if (mq.matches) {
                    logger.emit(`[useIsInPwa.DOMContentLoaded.mq.onchange.standalone], Running in 'PWA'}`);
                    signal[1](true);
                    mq.onchange = null;
                }
            };
        }
    }, {
        once: true
    });

    // works on chrome & firefox
    window.addEventListener('appinstalled', () => {
        signal[1](true);
        logger.emit(`[useIsInPwa.appinstalled], Running in ${signal[0]() ? 'PWA' : 'browser tab'}`);
    }, {
        once: true
    });

    return () => signal[0];
})();

export const useIsReadOnlyMode = (() => {
    const usePWA = Config.ALLOW_EDIT_MODE_OUTSIDE_PWA.else(false) ? () => true : useIsInPwa();
    const signal = createSignal(false);
    createEffect(() => {
        signal[1](!useIsOnline()() || !useIsAuthenticated()() || !usePWA());
    });

    DevUtil.set('setIsReadOnlyMode', (value: boolean) => signal[1](value));

    return () => signal[0];
})();

export const useIsCordovaApp = () => svc.isCordovaApp()

let onReadySignal: Accessor<boolean> | undefined;
export function useOnCardServiceReady() {
    if (!onReadySignal) {
        const signal = createSignal(false);
        onReadySignal = signal[0];
        host
            .onStart()
            .then(h => {
                h.get(CARD_SERVICE).ready().then(() => {
                    signal![1](true);
                });
            });
    }

    return onReadySignal;
}