

export default function WeightIcon() {
    return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.401 7.27824C20.8956 7.82371 20.744 8.66204 20.1412 9.08491L16.893 11.3632C16.2901 11.7861 15.4664 11.6154 14.8708 11.1826C14.7183 11.0717 14.5578 10.9714 14.3903 10.8826C13.8282 10.5842 13.2035 10.4232 12.5672 10.4126C11.931 10.402 11.3012 10.5422 10.7295 10.8216C10.5591 10.9049 10.3954 10.9998 10.2393 11.1055C9.62957 11.5184 8.8007 11.6615 8.21224 11.2188L5.04164 8.83364C4.45317 8.39097 4.3295 7.54804 4.8421 7.01937C5.69884 6.13577 6.7045 5.40457 7.81637 4.86117C9.3287 4.12204 10.9945 3.75131 12.6776 3.77931C14.3606 3.80731 16.0132 4.23324 17.5001 5.02231C18.5932 5.60237 19.574 6.36664 20.401 7.27824ZM19.2972 8.04831C18.5985 7.30591 17.7809 6.68077 16.8751 6.20011C15.5741 5.50964 14.1281 5.13697 12.6554 5.11244C11.1827 5.08797 9.7251 5.41237 8.40184 6.05911C7.48057 6.50937 6.64257 7.10697 5.91964 7.82571L9.01117 10.1514C9.02117 10.1546 9.0471 10.1596 9.0985 10.155C9.20304 10.1456 9.3497 10.0976 9.49177 10.0014C9.69944 9.86077 9.91737 9.73451 10.144 9.62371C10.9048 9.25191 11.7428 9.06544 12.5894 9.07951C12.6234 9.08004 12.6574 9.08097 12.6914 9.08217C12.7168 9.02877 12.7498 8.97784 12.7906 8.93104L14.6215 6.82577C14.8632 6.54797 15.2842 6.51864 15.562 6.76024C15.8399 7.00191 15.8692 7.42297 15.6276 7.70077L14.1913 9.35224C14.4744 9.44624 14.7501 9.56404 15.0153 9.70477C15.2382 9.82304 15.4518 9.95651 15.6546 10.104C15.7934 10.2048 15.9384 10.2577 16.0426 10.2705C16.0938 10.2768 16.1199 10.2727 16.13 10.2698L19.2972 8.04831ZM16.1351 10.2676C16.1353 10.2678 16.1334 10.2688 16.13 10.2698C16.1329 10.2681 16.135 10.2674 16.1351 10.2676ZM9.0061 10.149C9.00624 10.1488 9.0083 10.1496 9.01117 10.1514C9.00777 10.1502 9.0059 10.1492 9.0061 10.149Z" fill="#333333"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.6441 24.4443C22.0594 24.4443 23.2281 23.3387 23.3066 21.9256L24.3437 3.25894C24.4286 1.73039 23.212 0.444336 21.6811 0.444336H3.31897C1.78808 0.444336 0.571492 1.73038 0.656412 3.25894L1.69345 21.9256C1.77195 23.3387 2.94072 24.4443 4.35597 24.4443H20.6441ZM4.35597 23.111H20.6441C21.3517 23.111 21.9361 22.5582 21.9754 21.8516L23.0124 3.18494C23.0548 2.4207 22.4466 1.77767 21.6811 1.77767H3.31897C2.55353 1.77767 1.94523 2.4207 1.98769 3.18494L3.02473 21.8516C3.06398 22.5582 3.64837 23.111 4.35597 23.111Z" fill="#333333"/>
</svg>

  );
}
