import { Box, Button } from '@suid/material';
import { svc } from '@app/utils/svc';
import Brand from '@app/assets/Brand.tsx';
import { layoutHeaderStyles } from '@app/assets/theme.ts';
import { useIsInPwa } from '@app/utils/hooks';
import { AUTH0_ALLOWED_AUTH_TYPES } from '@app/defs/constants';

export const Loggedout = () => {
    const isInPwa = useIsInPwa();
    return (
        <Box sx={layoutHeaderStyles.layoutHeaderContainer}>
            <Box
                sx={{
                    width: '100dvw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ marginBottom: 5, marginTop: 10 }}>
                    <Brand />
                </Box>
                {isInPwa() && (<Box sx={{ width: '330px' }}>
                    {AUTH0_ALLOWED_AUTH_TYPES.includes('sms') && <Button
                        onClick={() => {
                            svc.auth.navigateUserExternalLogin('sms');
                        }}
                        variant='contained'
                        fullWidth={true}
                        sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: '#0A3E44',
                            height: '55px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            '&:hover, &:active, &:focus': {
                                backgroundColor: '#0A3E44',
                            }
                        }}
                        disableElevation
                    >
                        Continue with phone
                    </Button>}
                    {AUTH0_ALLOWED_AUTH_TYPES.includes('email') && <Button
                        onClick={() => {
                            svc.auth.navigateUserExternalLogin('email');
                        }}
                        variant='contained'
                        fullWidth={true}
                        sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: '#0A3E44',
                            height: '55px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            '&:hover, &:active, &:focus': {
                                backgroundColor: '#0A3E44',
                            }
                        }}
                        disableElevation
                    >
                        Continue with email
                    </Button>}
                    {AUTH0_ALLOWED_AUTH_TYPES.includes('user_password') && <Button
                        onClick={() => {
                            svc.auth.navigateUserExternalLogin('user_password');
                        }}
                        variant='contained'
                        fullWidth={true}
                        sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: '#0A3E44',
                            height: '55px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            '&:hover, &:active, &:focus': {
                                backgroundColor: '#0A3E44',
                            }
                        }}
                        disableElevation
                    >
                        Continue with email and password
                    </Button>}
                </Box>)}
            </Box>
        </Box >

    )
}

export default Loggedout;