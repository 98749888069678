//Icons
import EmailIcon from '@suid/icons-material/Email';
import LogoDev from '@suid/icons-material/LogoDev';
import NotesIcon from '@app/assets/icons/NotesIcon';
import SignOutIcon from '@suid/icons-material/Logout';
import {
    Box,
    Container,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@suid/material';
import { For, createSignal } from 'solid-js';

import ActionMenuIcon from '@app/assets/icons/actionMenuIcon';
import { svc } from '@app/utils/svc';
import { useIsOnline } from '@app/utils/hooks';
import { layoutHeaderStyles } from '@app/assets/theme';
import AlertDialog from './AlertDialog';
import { appStorage } from '@app/utils/app.storage';
import { ROLES } from '@app/defs/constants';
import { utils } from '@app/auth/utils';
import { Runtime } from '@app/host/runtime';
import { useLogger } from '@libs/logger/mod.ts';
import { debugConsole } from '@libs/logger/debug.console.ts';

const logger = useLogger('ActionMenu');

const ActionMenu = () => {
    type ActionMenuType = {
        caption: () => string,
        icon: any,
        disabled: () => boolean,
        isVisible: () => boolean,
        onClick: () => void
    }

    const [drawerState, setDrawerState] = createSignal({ bottom: false });

    const toggleDrawer = (open: any) => (event: any) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawerState((prevState) => ({ ...prevState, bottom: open }));
    };

    const isOnline = useIsOnline();

    function createActionMenuItem<T = undefined>(
        actionLine: string | ((arg: T) => string),
        icon: any,
        onClick: (item: ActionMenuType, arg: T) => void,
        allowOffline = true,
        isVisibleDefault = true,
        arg: T | undefined = undefined) {

        const captionFn: (arg: T) => string = typeof actionLine === 'string' ? () => actionLine : actionLine;
        const item = {
            caption: () => captionFn(arg as any),
            icon,
            disabled: () => !allowOffline && !isOnline(),
            isVisible: () => isVisibleDefault,
            onClick: () => onClick(item, arg as any)
        };

        return item;
    }

    const [openAppInfo, setOpenAppInfo] = createSignal(false);
    const appInfoDetailsConfirmationProps = {
        type: 'Ok' as const,
        open: openAppInfo,
        title: 'App Info',
        description: () => openAppInfo() ? getAppInfoDetails() : <></>,
        onClick: () => {
            setOpenAppInfo(false);
        }
    };

    const getVars = () => {
        return Object
            .keys((window as any).__ENV_VARS_APP_CFG ?? {})
            .map(key => {
                return { key, value: (window as any).__ENV_VARS_APP_CFG[key] };
            });
    }

    const getAppInfoDetails = () => {
        const { versionCheckDisabled, appStartedDt, currentVersionDt, appOrigin, apiOrigin, envType, auth0, dataSyncInterval, appRefreshCheckInterval } = Runtime;
        const user = Runtime.userInfo();
        const showDevInfo = utils.userInRole(ROLES.DEBUG_USERS) || utils.userInRole(ROLES.APP_DEVELOPERS);
        const dt = appStartedDt.toLocaleString(undefined, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
        });

        const currentVersionLocaleDt = versionCheckDisabled ? currentVersionDt : new Date(currentVersionDt).toLocaleString(undefined, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
        });

        const varsInjected = Object.keys((window as any).__ENV_VARS_APP_CFG && {}).length > 0;
        return <div>
            <div><strong>Current Version DateTime:</strong> <br /> <i>{currentVersionLocaleDt}</i></div><br />
            <div><strong>Page Loaded DateTime:</strong> <br /><i> {dt}</i></div><br />
            <div><strong>Current User:</strong> <br /><i> {user.email ?? user.mobile}</i></div><br />
            {showDevInfo &&
                <>
                    <div><strong>APP Origin:</strong> <br /><i>{appOrigin}</i></div><br />
                    <div><strong>API Origin: </strong><br /> <i>{apiOrigin}</i></div><br />
                    <div><strong>Env Type: </strong><br /> <i>{envType}</i></div><br />
                    <div><strong>Auth Domain: </strong><br /> <i>{auth0.domain}</i></div><br />
                    <div><strong>Auth Types: </strong><br /><i> {auth0.allowedAuthTypes.join(',')}</i></div><br />
                    <div><strong>Data Sync Interval (seconds): </strong><br /><i>{dataSyncInterval}</i></div><br />
                    <div><strong>App Refresh Interval (seconds): </strong><br /><i>{appRefreshCheckInterval}</i></div><br />
                    <div><strong> ##### ALL INJECTED VARS #####: </strong><br />
                        {varsInjected ?
                            (
                                <For each={getVars()}>{(kv: { key: string, value: string }) => {
                                    return <>
                                        <strong>{kv.key}: </strong><br /><i>{kv.value}</i><br /><br />
                                    </>;
                                }}</For>
                            ) :
                            <i>None injected</i>
                        }
                    </div>
                </>
            }
        </div >;
    };

    const [openConfirmation, setOpenConfirmation] = createSignal(false);
    const logoffConfirmationProps = {
        type: 'OkayCancel' as const,
        button1: 'Yes',
        open: openConfirmation,
        title: 'Sign Out',
        description: 'Are you sure you want to sign out?',
        onClick: (e: any, index: number) => {
            if (index === 0) {
                svc.db.deleteLocalData().catch(logger.error);
                appStorage.clear();
                svc.auth.invokeExternalLogout();
            }
            setOpenConfirmation(false);
        }
    };

    const actionMenuItems = [
        createActionMenuItem('Show Debug Output', LogoDev, () => debugConsole.fireShowEvent(), true, debugConsole.isEnabled && utils.userInRole(ROLES.DEBUG_USERS)),
        createActionMenuItem('Contact Us', EmailIcon, () => svc.gleap.openWidget(), false),
        createActionMenuItem('App Info', NotesIcon, () => {
            setOpenAppInfo(true);
        }),
        createActionMenuItem('Sign Out', SignOutIcon, () => {
            setOpenConfirmation(true);
        })
    ];

    return (<>
        <IconButton
            sx={layoutHeaderStyles.actionMenu}
            onClick={toggleDrawer(true)}
        >
            <ActionMenuIcon themeColorSecondary='#08373E' />
        </IconButton>

        <AlertDialog
            id='logout_confirmation_dialog'
            {...logoffConfirmationProps}
        />

        <AlertDialog
            id='appinfo_popup_dialog'
            {...appInfoDetailsConfirmationProps}
        />

        <Container id='drawerMenu'>
            <Drawer
                anchor={'bottom'}
                open={drawerState().bottom}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                    borderTopLeftRadius: 25,
                    borderTopRightRadius: 25,
                    height: 'inherit'}
                }}
            >
                <Box
                    sx={{ width: '100%', marginBottom: '30px' }}
                    role='presentation'
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {actionMenuItems.map((actionMenuItem) => {
                            const Icon = actionMenuItem.icon;
                            const onClick = actionMenuItem.onClick;
                            const disabled = actionMenuItem.disabled;
                            const caption = actionMenuItem.caption;
                            return actionMenuItem.isVisible() && (
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemButton
                                        disabled={disabled()}
                                        onClick={onClick}
                                    >
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <Icon sx={{ color: '#182325' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={caption()} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
        </Container>
    </>);
};

export default ActionMenu;