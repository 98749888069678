import { Resource, createResource, useContext } from "solid-js";
import { BoundResource, BoundDataSource, BoundSourceContext, DataPageContext } from "./types";
import { MutableEntity } from "@app/db/mod";
import { Validators } from "@libs/validators";
import { LogLevel, logger } from "@libs/logger/mod.ts";
import { Proxied } from "@libs/utils/proxy";
import { logEmitAndReturnError } from "@libs/logger/log.util";

export const useBoundSource = <T extends BoundDataSource>() => {
    const source = useContext(BoundSourceContext) as BoundResource<T>;
    let m: MutableEntity<any> | undefined;
    const bound = {
        get: <R = any>(path: keyof T) => {
            return (source()?.value as any)?.[path] as R;
        },
        set: (path: keyof T, value: unknown) => {
            const obj = source()?.value;
            if (obj) {
                (obj as any)[path] = value;
            }
        },
        markAsDeleted: () => {
            const obj = source()?.value;
            if (obj) {
                (obj as any)['__deleted'] = true;
            }
        },
        save: (options?: {
            deleteEntity?: boolean;
        }) => {
            if (!m) {
                m = MutableEntity.as(source()?.value);
                if (!m) {
                    return Promise.reject(new Error('Source is not a mutable entity'));
                }
            }

            if (options?.deleteEntity) {
                bound.markAsDeleted();
            }

            return m.save() as Promise<void>;
        },
        isRequired: (path: string) => {
            if (!m) {
                m = MutableEntity.as(source()?.value);
                if (!m) {
                    return false;
                }
            }

            return m.isRequired(path) ?? false;
        },
        validator: (path: string) => {
            if (!m) {
                m = MutableEntity.as(source()?.value);
                if (!m) {
                    return Validators.of();
                }
            }

            return m.validator(path);
        }
    };

    return bound;
};

export const usePageContext = () => {
    return useContext(DataPageContext);
};

export const useBoundSourceReadonly = <T extends object>(defaultResource?: () => Resource<T>): Resource<T> => {
    const r = useContext(BoundSourceContext) as Resource<T>;
    if (r) {
        return createResource(r, inner => {
            const s = (inner as any)?.value;
            if (!s) {
                throw logEmitAndReturnError('No value property in the current bound source context');
            }
            return Proxied.of(s);
        })[0];
    }

    if (defaultResource) {
        return defaultResource();
    }

    logger.emit(LogLevel.ERROR, 'No resource in the current bound source context');
    return createResource<T>(() => Promise.reject(new Error('No resource in the current bound source context')))[0];
};

export const useBoundSourceUnsafe = <T extends object>() => {
    return useContext(BoundSourceContext)?.()?.value as T;
};