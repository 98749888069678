import { svc } from '@app/utils/svc.ts';
import DrawerCommon from '@app/components/DrawerCommon.tsx';
import FormCommon from '@app/components/FormCommon.tsx';

import DataPage from '@app/data/components/DataPage.tsx';
import * as h from './profileHelper.tsx';

import { useNavigateToView } from '@app/routes/hooks.ts';
import { Once } from '@rdt-utils';

export default function ProfileEdit() {
    const [navToView, section] = useNavigateToView()
    const title = (() => {
        switch (section) {
            case 'PersonalDetails': return 'Personal Details';
            case 'ContactInfo': return 'Contact Information';
            case 'ProfileNotes': return 'Notes';
            default: return 'Default Title'; // Default title if none selected
        }
    })();

    const profile = svc.card.profile;
    const dataPage = Once.of(() => {
        return <>
            <DataPage pageType="personal" source={profile} onSaveSuccess={navToView}>
                {section === 'PersonalDetails' && <FormCommon fields={[...h.basicInfoFields, ...h.personalDetailsFields]} />}
                {section === 'ContactInfo' && <FormCommon fields={h.contactInfoFields} />}
                {section === 'ProfileNotes' && <FormCommon fields={h.profileNotesFields} />}
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title={title}
        >
            {dataPage()}
        </DrawerCommon>
    );
}
