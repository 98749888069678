import DrawerCommon from '@app/components/DrawerCommon.tsx';
import DataPage from '@app/data/components/DataPage';
import { pageTypeStrings } from '@app/defs/page.strings';
import { useNavigateToView } from '@app/routes/hooks';
import { svc } from '@app/utils/svc';
import { Once } from '@rdt-utils';

export default function DocumentsEdit() {
    const [navToView] = useNavigateToView()
    const dataPage = Once.of(() => {
        return <>
            <DataPage source={svc.card.insuranceDetails} pageType={pageTypeStrings.documents} onSaveSuccess={navToView}>
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title='Upload Documents'
        >
            {dataPage()}
        </DrawerCommon>
    );
}
