
import { pageTypeStrings } from '@app/defs/page.strings.ts';
import { svc } from '@app/utils/svc.ts';

import DrawerCommon from '@app/components/DrawerCommon.tsx';
import MedicationForm from '@app/pages/medications/components/MedicationForm.tsx';

import DataPage, { OwningArrayInfo } from '@app/data/components/DataPage.tsx';
import { useNavigateToView } from '@app/routes/hooks.ts';
import { createResource } from 'solid-js';
import { Medication, MedicationDetail } from '@app/entities/medication.detail';
import { Once } from '@rdt-utils';

const MedsEdit = () => {
    const [navToView, section] = useNavigateToView()
    const isAddMedication = section === 'Add Medication';
    const title = isAddMedication ? 'Add Medication' : 'Edit Medication';

    const ownerInfo = createResource(svc.card.medications, m => {
        return {
            parent: m,
            propName: 'medications',
            id: { type: Medication.TYPE, key: isAddMedication ? undefined : section }
        } as OwningArrayInfo<MedicationDetail, 'medications'>
    })[0];

    const dataPage = Once.of(() => {
        return <>
            <DataPage source={ownerInfo} onSaveSuccess={navToView} pageType={pageTypeStrings.meds}>
                <MedicationForm isAddMedication={isAddMedication} />
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title={title}
            closeOnSwipe={false}
        >
            {dataPage()}
        </DrawerCommon>
    );
};

export default MedsEdit;
