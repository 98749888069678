import { IconProps } from ".";

export default function VitalDocumentsIcon({themeColorPrimary, themeColorSecondary}: IconProps) {
  return (
    <svg width="42" height="42" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.75 24.5C0.75 13.3041 0.75 7.70621 4.22811 4.22811C7.70621 0.75 13.3041 0.75 24.5 0.75C35.6959 0.75 41.2938 0.75 44.7719 4.22811C48.25 7.70621 48.25 13.3041 48.25 24.5C48.25 35.6959 48.25 41.2938 44.7719 44.7719C41.2938 48.25 35.6959 48.25 24.5 48.25C13.3041 48.25 7.70621 48.25 4.22811 44.7719C0.75 41.2938 0.75 35.6959 0.75 24.5Z" fill={themeColorPrimary} />
          <path d="M21.0399 13.8535C21.7183 13.1411 21.6908 12.0136 20.9785 11.3351C20.2661 10.6567 19.1386 10.6842 18.4601 11.3966L12.9643 17.1672L11.5399 15.6716C10.8614 14.9592 9.73393 14.9317 9.02155 15.6101C8.30918 16.2886 8.28168 17.4161 8.96013 18.1285L11.6744 20.9785C12.0106 21.3315 12.4768 21.5313 12.9643 21.5313C13.4518 21.5313 13.918 21.3315 14.2542 20.9785L21.0399 13.8535Z" fill={themeColorSecondary} />
          <path d="M26.875 15.5938C25.8912 15.5938 25.0938 16.3912 25.0938 17.375C25.0938 18.3588 25.8912 19.1563 26.875 19.1563H38.75C39.7338 19.1563 40.5312 18.3588 40.5312 17.375C40.5312 16.3912 39.7338 15.5938 38.75 15.5938H26.875Z" fill={themeColorSecondary} />
          <path d="M21.0399 30.4784C21.7183 29.7661 21.6908 28.6386 20.9785 27.9601C20.2661 27.2817 19.1386 27.3092 18.4601 28.0216L12.9643 33.7922L11.5399 32.2966C10.8614 31.5842 9.73393 31.5567 9.02155 32.2351C8.30918 32.9136 8.28168 34.0411 8.96013 34.7534L11.6744 37.6034C12.0106 37.9565 12.4768 38.1562 12.9643 38.1562C13.4518 38.1562 13.918 37.9565 14.2542 37.6034L21.0399 30.4784Z" fill={themeColorSecondary} />
          <path d="M26.875 32.2188C25.8912 32.2188 25.0938 33.0162 25.0938 34C25.0938 34.9838 25.8912 35.7812 26.875 35.7812H38.75C39.7338 35.7812 40.5312 34.9838 40.5312 34C40.5312 33.0162 39.7338 32.2188 38.75 32.2188H26.875Z" fill={themeColorSecondary} />
      </svg>
  );
}
