import RelationshipSelect from "@app/components/RelationshipSelect";
import PhoneNumbersByIndex from "@app/pages/profile/components/ProfilePhoneNumbers";
import { Field } from "@app/pages/profile/profileHelper";
import CategorySelect from "./CategorySelect";
import { EmergencyCareTeamSwitch } from "./EmergencyCareTeamSwitch";
import { getCategoryTypeLabel } from "./contact.funcs";

export function getCategoryFormFields(category: string, onCategorySelect: (category: string) => void): Field[] {
    const categoryType = getCategoryTypeLabel(category);
    return [
        { name: 'name', label: 'Name', fullWidth: true, gxs: 12 },
        { name: 'category', label: 'Category', fullWidth: true, gxs: 12, component: (props) => <CategorySelect {...props} onCategorySelected={onCategorySelect} /> },
        ...(category === 'Family+Friend' ? [{ name: 'relationShip', label: 'Relationship', fullWidth: true, gxs: 12, component: (props: any) => <RelationshipSelect {...props} inContacts={true} /> }] : []),
        ...(category !== 'Family+Friend' ? [{ name: 'categoryType', label: categoryType, fullWidth: true, gxs: 12 }] : []),
        ...(category === 'Family+Friend' ? [{ name: 'isEmergencyContact', label: 'Emergency Care Team', fullWidth: true, gxs: 12, component: EmergencyCareTeamSwitch }] : []),
        { name: 'email', label: 'Email Address', fullWidth: true, gxs: 12 },
        { name: 'phone1', label: 'Phone Numbers', fullWidth: true, gxs: 12, component: PhoneNumbersByIndex },
        { name: 'street1', label: 'Street Address', fullWidth: true, gxs: 12 },
        { name: 'street2', label: 'Street Address Line 2', fullWidth: true, gxs: 12 },
        { name: 'city', label: 'City', fullWidth: true, gxs: 5 },
        { name: 'state', label: 'State', fullWidth: true, gxs: 3 },
        { name: 'zipcode', label: 'Zip', fullWidth: true, autoComplete: 'postal-code', gxs: 4 },
        { name: 'county', label: 'County', fullWidth: true, gxs: 12 },
        { name: 'notes', label: 'Notes', multiline: true, fullWidth: true, gxs: 12 }
    ] as Field[];
}