export function extractInitialsFromName(name: string) {
    const parts = (name ?? '').split(' ').map(n => n.trim());
    if (parts.length === 1 && parts[0]) {
        return parts[0][0].toUpperCase();
    }

    let count = 0;
    while (count < parts.length - 1) {
        const initial = normalizeInitial(parts[count] ?? '');
        if (excludedPrefixes.has(initial)) {
            count++;
            continue;
        }
        return initial[0] + lastInitial(count + 1, parts);
    }

    return '';
}

const excludedPrefixes = new Set([
    'DR', 'MR', 'MS', 'MRS', 'MX', 'REV', 'FR', 'RAV'
]);

const excludedSuffixes = new Set([
    'SR', 'JR', 'I', 'II', 'III', 'IV', 'V', 'VI',
    'ESQUIRE', 'DDS', 'DMD', 'OD', 'DO', 'MD', 'RN', 'NP', 'PA', 'LPN', 'LVN',
    'DNP', 'PHD', 'PsyD', 'RPh', 'PharmD', 'DC', 'LCSW', 'LPC', 'LMFT'
]);

function lastInitial(lowerBound: number, parts: string[]) {
    let count = parts.length - 1;
    while (count >= lowerBound) {
        const initial = normalizeInitial(parts[count] ?? '');
        if (excludedSuffixes.has(initial)) {
            count--;
            continue;
        }
        return initial[0];
    }
    return '';
}

function normalizeInitial(initial: string) {
    return initial.replace(/\./g, '').toUpperCase();
}
