import { IDENTITY_SERVICE } from '@app/identity/identity.ts';
import { Service, ServiceName } from "@libs/services/service.ts"
import { type Host, host } from "@libs/services/host.ts"
import { LOCAL_DB } from "@app/localdb/local_db.ts"

let local: Host = {
    get<T extends Service>(name: ServiceName<T>): T {
        return host.getUnsafe(name)!;
    }
}

host.onStart().then((host) => local = host);

class SvcUtil {
    get auth() {
        return local.get(IDENTITY_SERVICE)
    }

    get db() {
        return local.get(LOCAL_DB)
    }
}

export const svc_util = Object.seal(new SvcUtil());