import { Config } from "@libs/config";
import { Env } from "@libs/environment";
import { Nullable } from "@libs/types";
import { logger } from "./logger";
import { Task } from "@libs/async/task";
import { CUSTOMER_IO_SERVICE, CustomerIOService, CustomerIOUser } from "./types";
import { ServiceFactory } from "@libs/services/service.ts";
import { APP_ORIGIN } from "@app/defs/constants";

const CUSTOMER_IO_TRACK_JS_URL = 'https://assets.customer.io/assets/track.js';
const CUSTOMER_IO_SCRIPT_ERROR = 'Customer IO load script error';
const SITE_ID_NOT_SET_MESSAGE = 'Customer IO site_id not set';

/*
  Per the documentation, this should be invoked after DOM render.
  The current method will be invoked on the DOM load event to ensure
  that the DOM is ready.

  For customer.io details: https://customer.io/docs/sdk/web
*/
const cioLoadTask = Task.create<void>();
cioLoadTask.catch(handleActionError);
const init = (function () {
    function isInitialized(): boolean {
        return (window as any)._cio !== undefined;
    }

    function getSiteId(): Nullable<string> {
        const siteId = Config.CUSTOMER_IO_SITE_ID.else('');
        if (siteId !== '') {
            return siteId
        }

        if (Env.envType !== 'dev') {
            return null;
        }

        return '5180cd440f02cbc5d4f9';
    }

    function execCustomerIoSnippet(siteId: string, autoTrackPage: string, url: string): void {
        if (isInitialized()) {
            return;
        }

        /* ########## BEGIN: customer.io snippet ########## */
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.id = 'cio-tracker';
        script.setAttribute('data-site-id', siteId);
        script.setAttribute('data-use-array-params', 'true');
        script.setAttribute('data-use-in-app', 'true');
        script.setAttribute('data-auto-track-page', autoTrackPage);
        script.src = url;
        document.body.appendChild(script);

        const w = window as any;
        w._cio = w._cio || [];
        const a = (f: string) =>
            function (...args: any[]) {
                w._cio.push([f].concat(args));
            };

        const b = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'];
        for (const element of b) {
            w._cio[element] = a(element);
        }
        /* ########## END: customer.io snippet ########## */
    }

    window.addEventListener('DOMContentLoaded', () => {
        try {
            const siteId = getSiteId();
            if (!siteId) {
                logger.warn(SITE_ID_NOT_SET_MESSAGE);
                cioLoadTask.reject('Site ID not set');
                return;
            }

            const autoTrackPage = Config.CUSTOMER_IO_AUTO_TRACK_PAGE.else('false');
            const url = Config.CUSTOMER_IO_URL.else(CUSTOMER_IO_TRACK_JS_URL);
            execCustomerIoSnippet(siteId, autoTrackPage, url);
            cioLoadTask.resolve();
        } catch (e: any) {
            logger.error(CUSTOMER_IO_SCRIPT_ERROR, e);
            cioLoadTask.reject(CUSTOMER_IO_SCRIPT_ERROR);
        }
    }, {
        once: true
    });
});

function handleActionError(e: any) {
    // if the load task is rejected, then the error is already logged and can be ignored
    if (!cioLoadTask.isRejected()) {
        logger.error('Customer.io error', e);
    }
}

/* See: https://customer.io/docs/sdk/web */
class CioImpl implements CustomerIOService {
    private user: CustomerIOUser | null = null;
    private nextAction: Promise<void> = cioLoadTask;

    public isIdentified(): boolean {
        return this.user !== null;
    }

    public track(event: string, data?: any): void {
        this.nextAction = this.nextAction.then(() => {
            if (this.isIdentified()) {
                // only track if user has been identified
                this.safeInvoke(event, data, (c, e, d) => c.track(e, d));
            }
        }).catch(handleActionError);
    }

    public identifyUser(u: CustomerIOUser): void {
        const copy = {
            userId: u.userId,
            name: u.name ?? '',
            email: u.email ?? '',
            phone: u.phone ?? ''
        };

        this.nextAction = this.nextAction.then(() => {
            // no need to identify if already identified
            if (this.user?.userId === copy?.userId) {
                return;
            }

            if (!copy?.userId || copy.userId === '') {
                // clear identity if id is empty
                this.clearIdentity();
                return;
            }

            this.safeInvoke(copy, null, (c, u) => {
                const envName = 'VAULT_' + Env.envType.toUpperCase();
                const identifiable: any = {
                    id: u.userId,
                    email: u.email,
                    name: u.name,
                    phone: u.phone,
                    vaultUser: true,
                    cio_relationships: {
                        action: "add_relationships",
                        relationships: [
                            {
                                identifiers: {
                                    object_type_id: "1",
                                    object_id: "Vault"
                                }
                            }
                        ]
                    }
                };

                identifiable[envName] = APP_ORIGIN;

                c.identify(identifiable);

                // if we are here, then no error occurred, set the user
                this.user = copy;
            });
        }).catch(handleActionError);
    }

    public clearIdentity(): void {
        if (!this.isIdentified()) {
            return;
        }

        this.user = null;
        this.nextAction = cioLoadTask;
        this.safeInvoke(null, null, c => c.reset());
    }

    // Args added due to being pedantic about avoiding closures
    private safeInvoke<T, U>(arg1: T, arg2: U, fn: (c: any, t: T, u: U) => void): void {
        try {
            const c = (window as any)?._cio;
            if (c) {
                return fn(c, arg1, arg2);
            }
        } catch (e) {
            console.error('Customer.io error', e);
        }
    }
}

const cio: CustomerIOService = new CioImpl();
export const customerIOServiceFactory: ServiceFactory<CustomerIOService> = Object.seal({
    name: CUSTOMER_IO_SERVICE,
    init: () => init(),
    create: () => cio
});

