import { z } from 'zod';

import { EntityCoreType } from './entity.core.ts';
import { EntityTypeId } from './entity.type.id.ts';
import { ulid } from '@std/ulid/mod.ts';

/**
 * A type alias and factory for the entity schema type in order to make the overall semantic less
 * confusing when using z.ZodObject. This is the type that will be used as the entity schema and
 * for validation.
 */
export type Schema<
    TYPE extends number = number,
    T extends Exclude<z.ZodRawShape, keyof EntityCoreType> = Exclude<
        z.ZodRawShape,
        keyof EntityCoreType
    >,
> = z.ZodObject<EntityCoreType<TYPE, T>>;

export type EntitySchema<
    NAME extends string = string,
    TYPE extends number = number,
    T extends Exclude<z.ZodRawShape, keyof EntityCoreType> = Exclude<
        z.ZodRawShape,
        keyof EntityCoreType
    >,
> = {
    typeId: EntityTypeId<NAME, TYPE>;
    schemaDef: Schema<TYPE, T>;
};

const schemaRegistry = new Map<number | string, EntitySchema>();
export const EntitySchema = {
    /**
     * @param defType The newly defined entity type schema, rules, etc.
     * @param params The raw create params to be passed to z.ZodObject
     * for more advanced schema creation.
     * @returns The newly defined entity schema.
     */
    define: <
        NAME extends string,
        TYPE extends number,
        T extends Exclude<z.ZodRawShape, keyof EntityCoreType>,
    >(
        name: NAME,
        type: TYPE,
        defType: T,
        params?: z.RawCreateParams,
    ): EntitySchema<NAME, TYPE, T> => {
        if (typeof type !== 'number' || type < 1000 || type > 9999) {
            throw new Error('type must be a number between 1000 and 9999');
        }

        if (typeof name !== 'string' || name.length < 3 || name.length > 25) {
            throw new Error('schema name must be a string between 3 and 25 characters');
        }

        if (schemaRegistry.has(type) || schemaRegistry.has(name)) {
            throw new Error(
                `Entity schema for type ${type}, name ${name} already defined`,
            );
        }

        const coreType = {
            _id: z.string().default(() => ulid()).readonly(),
            createdAt: z.number().default(() => Date.now()).readonly(),
            updatedAt: z.number().default(() => Date.now()).readonly(),
            __type: z.literal<TYPE>(type), //.or(z.number().default(type)),
        };

        const schema = {
            typeId: EntityTypeId.of<NAME, TYPE>(type, name),
            schemaDef: z.object(coreType, params).extend(defType).passthrough() as any as Schema<TYPE, T>,
        };

        schemaRegistry.set(type, schema);
        schemaRegistry.set(name, schema);

        return schema
    },
    getByType: <TYPE extends number = number>(type: TYPE): EntitySchema | undefined => {
        return schemaRegistry.get(type);
    },
    getByName: (name: string): EntitySchema | undefined => {
        return schemaRegistry.get(name);
    }
};
