
import { pageTypeStrings } from '@app/defs/page.strings.ts';
import { svc } from '@app/utils/svc.ts';

import DrawerCommon from '@app/components/DrawerCommon.tsx';
import InsuranceForm from '@app/pages/wallet/components/InsuranceForm';

import DataPage, { OwningArrayInfo } from '@app/data/components/DataPage.tsx';
import { useNavigateToView } from '@app/routes/hooks.ts';
import { createResource } from 'solid-js';
import { Insurance, InsuranceDetail } from '@app/entities/insurance.detail';
import { Once } from '@rdt-utils';

const WalletEdit = () => {
    const [navToView, section] = useNavigateToView()
    const isAddCard = section === 'Add Insurance Card';
    const title = isAddCard ? 'Add Insurance Card' : 'Edit Insurance Card';

    const ownerInfo = createResource(svc.card.insuranceDetails, m => {
        return {
            parent: m,
            propName: 'insuranceCards',
            id: { type: Insurance.TYPE, key: isAddCard ? undefined : section }
        } as OwningArrayInfo<InsuranceDetail, 'insuranceCards'>
    })[0];

    const dataPage = Once.of(() => {
        return <>
            <DataPage source={ownerInfo} onSaveSuccess={navToView} pageType={pageTypeStrings.meds}>
                <InsuranceForm isAddCard={isAddCard} />
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title={title}
            closeOnSwipe={false}
        >
            {dataPage}
        </DrawerCommon>
    );
};

export default WalletEdit;
