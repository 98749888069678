import { hashIntegration, Router, Routes } from '@solidjs/router';
import { ThemeProvider } from '@suid/material';

import { theme } from './assets/theme.ts';
import { AuthnRoutes } from './routes/AuthnRoutes';
import { FallbackRoute } from './routes/FallbackRoute';
import { CareCardRoutes } from './routes/CareCardRoutes.tsx';
import { app } from './app.ts';
import ReloadPrompt from './installupdate/ReloadPrompt.tsx';
import { SharedRoute } from './routes/SharedRoutes.tsx';
import { DebugConsole } from './components/DebugConsole.tsx';
import ErrorBoundaryX from './components/ErrorBoundary.tsx';

app.start(() =>
    <ErrorBoundaryX>
        <ThemeProvider theme={theme}>
            <Router source={hashIntegration()}>
                <Routes>
                    <SharedRoute />
                    <AuthnRoutes />
                    <CareCardRoutes />
                    <FallbackRoute />
                </Routes>
            </Router>
            <DebugConsole />
            <ReloadPrompt />
        </ThemeProvider>
    </ErrorBoundaryX>
);
