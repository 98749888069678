import { useLogger } from '@libs/logger/mod.ts';
import { authClient } from './auth.client.ts';
import { AuthToken } from './auth.token.ts';
import { createEvent, DisconnectListener } from '@libs/utils/event.ts';
import { AuthUser } from './auth.user.ts';
import { AuthService } from '../auth.service.ts';
import { Executor } from '@rdt-utils';
import { AUTH_LOGGED_OUT_PATH, AUTH_LOGOUT_CALLBACK_PATH } from '../constants.ts';

const logger = useLogger('AUTH');
const isLoggedOffCallback = window.location.hash === `#${AUTH_LOGGED_OUT_PATH}` ||
    window.location.hash === `#${AUTH_LOGOUT_CALLBACK_PATH}` ||
    window.location.pathname.startsWith(AUTH_LOGGED_OUT_PATH) ||
    window.location.pathname.startsWith(AUTH_LOGOUT_CALLBACK_PATH);

export class AuthenticationService implements AuthService {
    readonly #executor: Executor;
    readonly #onAuthenticated = createEvent<boolean>();
    readonly #currentUser: AuthUser;

    #refreshTimerId: any = null;
    #isAuthenticated: boolean;
    #unsubscribeToTokenChanges: DisconnectListener;

    private constructor() {
        this.#executor = Executor.sequential();
        this.#onAuthenticated = createEvent<boolean>();
        this.#currentUser = new AuthUser(this.#authSource);
        this.#unsubscribeToTokenChanges = this.#subscribeToTokenChanges();
        this.#isAuthenticated = this.#authSource.isValid();
    }

    public isAuthenticated() {
        return this.#authSource.isValid();
    }

    public currentUser() {
        return this.#currentUser;
    }

    public async navigateUserExternalLogin(type: 'sms' | 'email' | 'user_password' = 'sms') {
        authClient.loginWithRedirect(type).catch(logger.error);
    }

    public async handleExternalLoginCallback(onComplete: (e?: any) => void) {
        authClient.handleRedirectCallback(() => {
            this.#setAuthenticated(true);
            onComplete();
        }).catch(e => {
            logger.error('Error handling external login callback', e);
            onComplete(e);
        });
    }

    public async invokeExternalLogout() {
        try {
            this.#unsubscribeToTokenChanges();

            AuthToken.reset();

            await authClient.logout();

            this.#setAuthenticated(false);
        } catch (err: any) {
            if (err.error === 'app_offline_error') {
                logger.error('Cannot fully logout in offline mode');
            } else {
                logger.error('Error logging out', err);
            }
            this.#setAuthenticated(false);
        }
    }

    public logoutSilently() {
        this.#unsubscribeToTokenChanges();

        AuthToken.reset();

        this.#setAuthenticated(false); // just in case it does not emit, set to false
    }

    public onLoginLogout(listener: (isAuthenticated: boolean) => void, once?: boolean): DisconnectListener {
        if (once) {
            return this.#onAuthenticated.source.listenOnce(listener);
        }
        return this.#onAuthenticated.source.listen(listener);
    }

    public refreshToken() {
        return this.#executor.execute(this.#refreshToken)
    }

    public getToken() {
        return this.#executor.execute(this.#getToken)
    }

    readonly #setAuthenticated = (newIsAuthenticated: boolean) => {
        if (this.#isAuthenticated !== newIsAuthenticated) {
            this.#onAuthenticated.emit(this.#isAuthenticated = newIsAuthenticated);
        }

        return this.#isAuthenticated;
    }

    readonly #subscribeToTokenChanges = () => {
        return AuthToken.onChange((token) => {
            this.#setAuthenticated(token.isValid());
        });
    }

    private handleFailedRefreshTokenAttempts(message: string, isErrorMessage = false) {
        this.#failedRefreshTokenAttempts++;
        if (isErrorMessage) {
            logger.warn(message);
        } else {
            logger.info(message);
        }

        if (this.#failedRefreshTokenAttempts > 2) {
            logger.error('Too many failed refresh token attempts.');
            throw new Error(message);
        }

        this.logoutSilently();

        return '';
    }

    #failedRefreshTokenAttempts = 0;
    // TODO: make this more readable, and add comments
    readonly #refreshToken = async (skipLogout = false) => {
        if (this.#refreshTimerId !== null) {
            clearTimeout(this.#refreshTimerId);
            this.#refreshTimerId = null;
        }

        if (!AuthToken.current().canRefresh()) {
            return this.handleFailedRefreshTokenAttempts('Refresh token is not present. Unable to refresh token.');
        }

        if (isLoggedOffCallback) {
            return this.handleFailedRefreshTokenAttempts('Logged off callback detected. Should not be attempting to refresh token. Forcing logout and purge of found refresh tokens.', true);
        }

        logger.debug('Refreshing token started');

        const currentToken = AuthToken.current();
        let updatedToken = currentToken;
        try {
            this.#unsubscribeToTokenChanges();
            await authClient.getTokenSilently();
            updatedToken = AuthToken.current();
            if (currentToken === updatedToken || !updatedToken.isValid()) {
                logger.debug('Unable to refresh token');
                if (!skipLogout) {
                    this.invokeExternalLogout();
                }
            } else {
                logger.debug('Successfully refreshed token');
            }
        } catch (err: any) {
            if (err.error === 'login_required' || err.error === 'consent_required' || err.error === 'missing_refresh_token') {
                logger.debug(`Unable to refresh token: ${err.error}`);
                if (!skipLogout) {
                    this.invokeExternalLogout();
                }
            } else {
                logger.warn('Unable to refresh token', err);
                this.#setAuthenticated(false);
                throw err;
            }
        } finally {
            this.#unsubscribeToTokenChanges = this.#subscribeToTokenChanges();
        }

        this.#setAuthenticated(updatedToken.isValid());

        return updatedToken.id_token;
    };

    readonly #getToken = async () => {
        const token = AuthToken.current();
        const timeRemaining = token.ttl();
        if (!this.#isAuthenticated || timeRemaining < 10) {
            return this.refreshToken();
        }

        if (this.#refreshTimerId === null) {
            const timeRemaining = token.ttl();
            if (timeRemaining < 20 * 60) {
                const timeout = Math.max(0, timeRemaining < 60 ? timeRemaining - 5 : timeRemaining - 60);
                this.#refreshTimerId = setTimeout(() => {
                    void this.refreshToken();
                }, timeout * 1000);
            }
        }

        return token.id_token;
    };

    readonly #authSource = Object.seal({
        isAuthenticated: () => this.#isAuthenticated && AuthToken.current().isValid(),
        getAuthToken: () => AuthToken.current(),
        isValid: () => AuthToken.current().isValid(),
    });

    public static readonly instance = Object.seal(new AuthenticationService());

    static #__initialized: Promise<void> | null = null;
    static async initAuth() {
        if (AuthenticationService.#__initialized) {
            return AuthenticationService.#__initialized;
        }

        return AuthenticationService.#__initialized = (async () => {
            if (!window.navigator.onLine) {
                return;
            }

            const auth = AuthenticationService.instance;
            try {
                if (auth.#authSource.isValid()) {
                    auth.#setAuthenticated(true);
                } else if (!isLoggedOffCallback) {
                    await auth.#refreshToken(true);
                }
            } catch (err) {
                logger.error('Error initializing auth. Subsequent calls will retry up to 3 times.', err);
            }
        })()
            .finally(() => {
                logger.debug('Auth client initialized');
            });
    }
}
