import { Observable, ObservableEmitter, Subscriber } from "./observables.ts";

export type DisconnectListener = () => void;

export type EventsListener<T> = (value: T) => void;

export type Events<T> = {
  source: EventsSource<T>;
  emit: (value: T) => void;
}

export type EventsSource<T> = {
  listen: (listener: EventsListener<T>) => DisconnectListener;
  listenOnce: (listener: EventsListener<T>) => DisconnectListener;
  asObservable: () => Observable<T>;
}

export const createEvent = <T>(): Events<T> => {
  const emitter = new ObservableEmitter<T>();
  const source = Object.seal({
    listen: (listener: EventsListener<T>) => {
      const subscriber = { next: listener };
      emitter.subscribe(subscriber);
      return () => emitter.unsubscribe(subscriber);
    },
    listenOnce: (listener: EventsListener<T>) => {
      const subscriber: Subscriber<T> = { next: (value) => {
        emitter.unsubscribe(subscriber);
        listener(value);
      }};
      emitter.subscribe(subscriber);
      return () => emitter.unsubscribe(subscriber);
    },
    asObservable: () => {
      return {
        subscribe: (subscriber: Subscriber<T>) => {
          const listener: EventsListener<T> = value => {
            if (subscriber.next) {
              subscriber.next(value);
            }
          };

          return { unsubscribe: source.listen(listener) };
        }
      };
    }
  });

  return Object.seal({
    source,
    emit: (value: T) => emitter.next(value),
  });
}
