import { Box, Grid, InputAdornment, TextField } from '@suid/material';

import { useIsReadOnlyMode } from '@app/utils/hooks.ts';
import { useBoundProperty } from '../data/bound.property';
import { usePageContext } from '../data/hooks';

//Styles
export type Props = {
    name: string;
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    alias?: string;
    autoComplete?: string;
    type?: string;
    mode?: string;
    endAdornmentValue?: string;
    shrink?: boolean;
    gxs?: number;
};

export const TextFieldCommon = (
    { name, mode, label, shrink, placeholder, alias, fullWidth = false, multiline = false, autoComplete, endAdornmentValue, gxs }: Props,
) => {
    mode = mode ?? 'text';
    const pageContext = usePageContext();
    const isReadonly = useIsReadOnlyMode();
    const source = useBoundProperty(alias ?? name);
    const textField = (
        <Box
            sx={{
                width: '100%',
                marginTop: '8px',
            }}
        >
            <TextField
                required={source.isRequired()}
                type={mode}
                error={pageContext.displayValidationErrors() && source.hasError()}
                helperText={pageContext.displayValidationErrors() && source.errorMessage}
                name={name}
                autoComplete={autoComplete}
                onChange={(ev) => {
                    const e = ev as unknown as Event & { inputType: string };
                    if (mode !== 'tel' || e.inputType !== 'insertFromPaste' || !ev.target.value || ev.target.value.length === 0) {
                        source.value = ev.target.value;
                    } else {
                        source.value = ev.target.value.replace('(', '').replace(')', '-').replace('.', '-').replace(' ', '');
                    }
                }}
                value={source.value ?? ''}
                multiline={multiline}
                variant='filled'
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '10px',
                        border: '1px solid #E0E0E0',
                    }
                }}
                InputProps={{
                    endAdornment: endAdornmentValue ? <InputAdornment position="end">{endAdornmentValue}</InputAdornment> : null,
                    disableUnderline: true,
                    readOnly: isReadonly()
                }}
                InputLabelProps={{
                    shrink: shrink,
                }}
            />
        </Box>
    );

    if (!gxs) {
        return textField;
    }

    return (
        <Grid item xs={gxs}>
            {textField}
        </Grid>
    );
};

export default TextFieldCommon;
