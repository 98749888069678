import {
    Dialog,
    Button,
    Box
} from '@suid/material';

import CareCardIcon from '@app/assets/icons/CareCardIcon.tsx';
import { getCurrentTheme } from '@app/assets/theme';
import { ShareActionView } from './types';
import { shareActions } from './ShareActionView';

interface ShareDialogProps {
    open: () => boolean;
    onClose: (value: ShareActionView | null) => void;
}

// Define ShareDialog component
export function ShareChoiceDialog(props: ShareDialogProps) {
    const { onClose, open } = props;
    const theme = getCurrentTheme();

    return (
        <>
            <Dialog onClose={() => onClose(null)} open={open()}>
                <div style={{ "margin-top": '40px' }}></div>
                {shareActions.map((shareAction) => (
                    <Button variant="contained" color="secondary"
                        sx={{
                            textTransform: 'none',
                            margin: 1,
                            mr: 3,
                            ml: 3,
                            padding: 2,
                            pr: 3,
                            borderRadius: '10px'
                        }}
                        onClick={() => onClose(shareAction)}>
                        {shareAction.name}
                    </Button>
                ))}

                <Box
                    sx={{
                        position: 'fixed',
                        top: '200',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 0,
                        textTransform: 'none',
                        zIndex: 'modal'
                    }}
                >
                    <CareCardIcon
                        themeColorPrimary={theme.iconPrimary}
                        themeColorSecondary={theme.iconSecondary}
                    />
                </Box>
                <div style={{ "padding-bottom": '20px' }}></div>

            </Dialog >
        </>
    );
}
