const names = new Map<string, symbol>();
export const ServiceName = {
    create: <T extends Service>(name: string): ServiceName<T> => {
        if (names.has(name)) {
            throw new Error(`Service '${name}' is already registered`);
        }

        const symbol = Symbol(name);
        names.set(name, symbol);

        return Object.freeze({
            name: symbol,
            toString: () => name
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ServiceName<T extends Service = Service> = {
    readonly name: symbol;
    toString(): string;
};

export interface Service { }

export type ServiceManager = {
    get<T extends Service>(name: ServiceName<T>): T;
}

export type ServiceManagerAsync = {
    get<T extends Service>(name: ServiceName<T>): Promise<T>;
}

export type ServiceFactory<T extends Service = Service> = {
    readonly name: ServiceName<T>;
    init?: () => void | Promise<void>;
    create: (manager: ServiceManager) => Service;
    postInit?: (manager: ServiceManager) => void | Promise<void>;
}