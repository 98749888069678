import { Route } from '@solidjs/router';
import { routeStrings } from './route.strings';
import AuthnCallback from '@app/pages/Login/AuthnCallback';
import LogoutCallback from '@app/pages/Login/LogoutCallback';
import Loggedout from '@app/pages/Login/Loggedout';

export const AuthnRoutes = () => {
    return (<>
        <Route path={routeStrings.authnCallback} component={AuthnCallback} />
        <Route path={routeStrings.logoutPathCallback} component={LogoutCallback} />
        <Route path={routeStrings.loggedoutPath} component={Loggedout} />
    </>);
}