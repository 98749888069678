import { Route, useNavigate } from '@solidjs/router';
import { routeStrings } from './route.strings';
import { lazy } from "solid-js";
import { shareService } from '@app/services/share-service/share.service';

const SharedShow = lazy(() => import("@app/pages/share/SharedShow"));

export const SharedRoute = () => {
    return (<>
        <Route path={routeStrings.shareResolvePath} component={RemoveUrlAndNavigateToSharedShow} />
        <Route path={routeStrings.sharedShowPath} component={SharedShow} />
    </>);
}

const RemoveUrlAndNavigateToSharedShow = () => {
    return <>
        {useNavigate()(routeStrings.sharedShowPath, { replace: true, state: { keyInfo: shareService.getUrlInfo() } })}
    </>;
};
