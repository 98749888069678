import { IconProps } from ".";

export default function MedicalIcon({themeColorPrimary, themeColorSecondary}: IconProps) {
  return (
    <svg width="42" height="42" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 24.5C0.75 13.3041 0.75 7.70621 4.22811 4.22811C7.70621 0.75 13.3041 0.75 24.5 0.75C35.6959 0.75 41.2938 0.75 44.7719 4.22811C48.25 7.70621 48.25 13.3041 48.25 24.5C48.25 35.6959 48.25 41.2938 44.7719 44.7719C41.2938 48.25 35.6959 48.25 24.5 48.25C13.3041 48.25 7.70621 48.25 4.22811 44.7719C0.75 41.2938 0.75 35.6959 0.75 24.5Z"
      fill={themeColorPrimary} />
      <path d="M31.861 24.7223C31.422 24.0052 30.994 23.306 30.5752 22.8055C30.1307 22.2743 29.3515 21.5436 28.1598 21.5915C26.9682 21.6393 26.25 22.43 25.8496 22.9951C25.4722 23.5275 25.1016 24.2588 24.7215 25.0086L20.0004 34.3181C19.8221 34.6696 19.6735 34.9622 19.54 35.2138C19.3775 34.9798 19.1954 34.7068 18.9768 34.3789L18.5567 33.7487C18.0737 33.0242 17.6459 32.3824 17.2358 31.8763C16.7911 31.3274 16.2901 30.8373 15.6149 30.4759C14.9397 30.1146 14.254 29.9696 13.5506 29.904C12.902 29.8435 12.1307 29.8436 11.26 29.8436L7.875 29.8436C6.89124 29.8436 6.09375 30.6411 6.09375 31.6249C6.09375 32.6086 6.89124 33.4061 7.875 33.4061H11.1868C12.1527 33.4061 12.7563 33.4079 13.2199 33.4511C13.6467 33.4909 13.8215 33.5567 13.9339 33.6169C14.0463 33.6771 14.1981 33.786 14.4679 34.1191C14.761 34.4808 15.0973 34.982 15.6331 35.7857L16.0749 36.4485C16.5221 37.1196 16.962 37.7798 17.3882 38.2509C17.8462 38.7571 18.6294 39.4319 19.7864 39.3632C20.9434 39.2945 21.6413 38.5317 22.0362 37.9748C22.4036 37.4566 22.7622 36.7489 23.1268 36.0297L27.847 26.7222C28.033 26.3553 28.1884 26.0492 28.3282 25.786C28.4886 26.0371 28.668 26.3298 28.8828 26.6806L30.4361 29.2166C30.9114 29.9927 31.3319 30.6793 31.7401 31.2211C32.1827 31.8085 32.6882 32.3347 33.384 32.7245C34.0799 33.1143 34.7926 33.2705 35.5247 33.3411C36.2 33.4062 37.005 33.4062 37.9151 33.4061H41.125C42.1088 33.4061 42.9062 32.6086 42.9062 31.6249C42.9062 30.6411 42.1088 29.8436 41.125 29.8436H37.9912C36.9823 29.8436 36.3506 29.8417 35.8667 29.7951C35.4209 29.7521 35.2405 29.6811 35.1251 29.6164C35.0097 29.5518 34.8549 29.435 34.5854 29.0773C34.2928 28.689 33.9613 28.1514 33.4343 27.291L31.861 24.7223Z"
      fill={themeColorSecondary} />
    </svg>
  );
}
