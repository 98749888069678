import { createSignal, createEffect } from 'solid-js';
import {
    Switch,
    IconButton,
    FormControlLabel
} from '@suid/material';

import { theme } from '@app/assets/theme';
import { styled } from '@suid/material/styles';
import { useBoundSource } from '@app/data/hooks';
import AlertDialog from '@app/components/AlertDialog';

import InfoIcon from '@suid/icons-material/InfoOutlined';


const EmergencyContactSwitch = styled(Switch)(() => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#FAE5E1' : '#F08B8B',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const EmergencyContactInfoLabel = () => {
    const [showDialog, setShowDialog] = createSignal(false);

    const handleInfoClick = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <div>

            <div style={{ display: 'flex', "align-items": 'center' }}>
                <span>Emergency Care Team</span>
                <IconButton onClick={handleInfoClick} size="small">
                    <InfoIcon />
                </IconButton>
            </div>

            <AlertDialog
                open={showDialog}
                onClose={handleClose}
                title="Emergency Care Team"
                description="The Emergency Care Team are the people that will be immediately contacted in case of an emergency. They will appear on the Care Card you share with others."
                type="Ok"
                onClick={() => {
                    handleClose();
                }}
            />
        </div>
    );
};

export function EmergencyCareTeamSwitch() {
    const [isEmergencyContact, setIsEmergencyContact] = createSignal(false);
    const source = useBoundSource();

    const handleEmergencyContactToggle = () => {
        const newValue = !isEmergencyContact();
        setIsEmergencyContact(newValue);
        source.set('isEmergencyContact', newValue);
    };

    createEffect(() => {
        setIsEmergencyContact(source.get('isEmergencyContact'));
    });

    return (
        <FormControlLabel
            control={<EmergencyContactSwitch sx={{ m: 1 }} checked={isEmergencyContact()} onChange={handleEmergencyContactToggle} />}
            label={<EmergencyContactInfoLabel />}
            labelPlacement='start'
            sx={{ marginTop: '15px' }}
        />
    );
}
