//import { NOTIFY_SW_EVENTS } from "@app/defs/constants";
import { ServiceName } from "@libs/services/service.ts";

export const GLEAP_SERVICE = ServiceName.create<GleapService>('GLEAP_SERVICE');
export const CUSTOMER_IO_SERVICE = ServiceName.create<CustomerIOService>('CUSTOMER_IO_SERVICE');
export const SW_SERVICE = ServiceName.create<SW>('SW_SERVICE');

export type SW = {
    init(): void;
    // notifySW(message: NOTIFY_SW_EVENTS): void;
    // messageSW(message: any): Promise<any>;
    clearCaches(): Promise<void>;
}

export type GleapUser = { userId: string, name?: string, email?: string, phone?: string };

export type GleapService = {
    init: () => void;
    clearUser: () => void;
    identifyUser: (user: GleapUser) => void;
    openWidget: () => void;
    showSurvey: (surveyId: string) => void;
    startChecklist: (id: string) => void;
    trackEvent: (name: string, data?: any) => void;
    isEnabled: () => boolean;
};

export type CustomerIOUser = { userId: string, name?: string, email?: string, phone?: string };

// For customer.io details: https://customer.io/docs/sdk/web
export interface CustomerIOService {
    track(event: string, data?: any): void;
    identifyUser(user: CustomerIOUser): void;
    isIdentified(): boolean;
    clearIdentity(): void;
}
