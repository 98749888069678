import Cropper from "cropperjs";

export async function getCropper(
    image: HTMLImageElement
): Promise<Cropper> {
    return new Promise((resolve, reject) => {
        try {
            const cropper = new Cropper(image, {
                aspectRatio: 1,
                viewMode: 1,
                minCropBoxHeight: 80,
                minCropBoxWidth: 80,
                dragMode: "move",
                guides: true,
                ready: () => {
                    resolve(cropper!);
                }
            });
        } catch (e) {
            reject(e);
        }
    });
}

// export function getRoundedCanvas(sourceCanvas: HTMLCanvasElement) {
//     const targetWidth = sourceCanvas.width;
//     const targetHeight = sourceCanvas.height;

//     const canvas = new OffscreenCanvas(targetWidth, targetHeight);
//     const context = canvas.getContext('2d');

//     if (!context) {
//         throw new Error('Could not get 2d context');
//     }

//     context.imageSmoothingEnabled = true;
//     context.imageSmoothingQuality = 'high';
//     context.drawImage(
//         sourceCanvas,
//         0,
//         0,
//         targetWidth,
//         targetHeight
//     );

//     context.globalCompositeOperation = 'destination-in';
//     context.beginPath();
//     context.arc(
//         targetWidth / 2, targetHeight / 2,
//         Math.min(targetWidth, targetHeight) / 2,
//         0,
//         2 * Math.PI,
//         true
//     );

//     context.fill();

//     return canvas;
// }
