import { LocalStorageCache, Cacheable, MaybePromise } from "@auth0/auth0-spa-js";
import { createEvent } from "@libs/utils/event";
import { AUTH0_CLIENT_CONFIG, AUTH0_DEFAULT_SCOPES } from "./constants.ts";
import { Md5 } from "@libs/utils/md5";
import { AuthToken as authToken } from "./types.ts";
import { appStorage } from "@app/utils/app.storage.ts";
import { TOKEN_CLAIM_ROLES } from "../constants.ts";
import { objects } from "@rdt-utils";

const AUTH0_KEY_PREFIX = '@@auth0spajs@@';
const AUTH0_KEY_ID_TOKEN_SUFFIX = '@@user@@';
const AUTH0_TOKEN_KEY = `${AUTH0_KEY_PREFIX}::${AUTH0_CLIENT_CONFIG.clientId}::${AUTH0_KEY_ID_TOKEN_SUFFIX}`;
const AUTH0_REFRESH_TOKEN_KEY = `${AUTH0_KEY_PREFIX}::${AUTH0_CLIENT_CONFIG.clientId}::default::${AUTH0_DEFAULT_SCOPES}`;
const AUTH0_TOKEN_ORIGIN = 'AUTH0_TOKEN_ORIGIN';
const localStorageCache = new LocalStorageCache();
const changes = createEvent<AuthToken>();

export type AuthToken = authToken;

export const TokenTime = Object.seal({
    MINUTE: 60,     // based on the token time units versus Date.now() units,
    HOUR: 60 * 60,   // based on the token time units versus Date.now() units
    toMs: (seconds: number) => seconds * 1000,
    toSeconds: (ms: number) => Math.max(Math.floor(ms / 1000), 0),
    ttl: (seconds: number) => TokenTime.toSeconds(seconds * 1000 - Date.now()),
    ttlMs: (seconds: number) => Math.max(seconds * 1000 - Date.now(), 0),
    toIsoString: (seconds: number) => new Date(seconds * 1000).toISOString(),
    now: () => TokenTime.toSeconds(Date.now())
});

const HOUR_MS = TokenTime.HOUR * 1000;

const canRefresh = () => {
    return !objects.isNil(appStorage.getItem(AUTH0_REFRESH_TOKEN_KEY));
};

const no_token: AuthToken = Object.seal({
    id: '',
    id_token: '',
    exp: TokenTime.now() - TokenTime.HOUR,
    sub: '',
    name: '',
    isValid: () => false,
    ttl: () => 0,
    canRefresh,
    hasRole: () => false
});

let resetting = false
export const authTokenCache = Object.seal({
    set<T = Cacheable>(key: string, entry: T) {
        localStorageCache.set(key, entry);
        if (key.endsWith(AUTH0_KEY_ID_TOKEN_SUFFIX)) {
            if (resetting) {
                // event listener may react to a storage remove and then call set,
                // thus we should not reset the authtoken once reset has finished
                resetting = false;
            }
            changes.emit(__authToken = toAuthToken(entry));
        }
    },
    get<T = Cacheable>(key: string): MaybePromise<T | undefined> {
        return localStorageCache.get<T>(key);
    },
    remove(key: string) {
        localStorageCache.remove(key);
        if (!resetting && key.endsWith(AUTH0_KEY_ID_TOKEN_SUFFIX)) {
            changes.emit(__authToken = toAuthToken(null));
        }
    },
    allKeys() {
        return localStorageCache.allKeys();
    },
    reset() {
        if (resetting) {
            return;
        }

        resetting = true;
        try {
            authTokenCache.allKeys().forEach(k => authTokenCache.remove(k));
            if (resetting) {
                changes.emit(__authToken = toAuthToken(null));
            }
        } finally {
            resetting = false;
        }
    }
});

export const AuthToken = {
    current: (): AuthToken => __authToken,
    onChange: (cb: (token: AuthToken) => void) => {
        return changes.source.listen(cb);
    },
    reset: () => {
        authTokenCache.reset();
    }
};

const idMap = new Map<string, string>();
const getIdForSub = (sub: string) => {
    let id = idMap.get(sub);
    if (!id) {
        idMap.set(sub, id = new Md5().update(sub).toString());
    }

    return id;
}

const toAuthToken = (item: any): AuthToken => {
    if (!item?.id_token || !item?.decodedToken?.claims) {
        return no_token;
    }

    const { exp, sub, name } = item.decodedToken.claims;
    if (!exp || !sub) {
        return no_token;
    }

    const roles = new Set<string>(item.decodedToken.claims[TOKEN_CLAIM_ROLES] ?? []);
    return Object.seal({
        id: getIdForSub(sub),
        id_token: item.id_token,
        exp: exp,
        sub: sub,
        name: name ?? no_token.name,
        isValid: () => !window.navigator.onLine || TokenTime.toMs(exp) > Date.now(),
        ttl: () => {
            if (!window.navigator.onLine) {
                return TokenTime.HOUR;
            }
            return TokenTime.ttl(exp);
        },
        ttlMS: () => {
            if (!window.navigator.onLine) {
                return HOUR_MS;
            }
            return TokenTime.ttlMs(exp);
        },
        hasRole: name => roles.has(name),
        canRefresh
    });
}

if (appStorage.getItem(AUTH0_TOKEN_ORIGIN) !== window.location.origin) {
    localStorageCache.allKeys().forEach(k => localStorageCache.remove(k));
    appStorage.setItem(AUTH0_TOKEN_ORIGIN, window.location.origin);
}

let __authToken: AuthToken = toAuthToken(authTokenCache.get<AuthToken>(AUTH0_TOKEN_KEY));