import { ServiceFactory } from '@libs/services/service.ts';
import { VAULT_DB, VaultDb } from './types.ts';

let vaultDb: VaultDb & { postInit: () => void } | null = null;
export const dbServiceFactory: ServiceFactory<VaultDb> = Object.seal({
    name: VAULT_DB,
    init: async () => {
        return import('./db.idb.ts').then((module) => {
            vaultDb = module.vaultIdb;
        });
    },
    postInit: () => vaultDb!.postInit(),
    create: () => vaultDb!
});
