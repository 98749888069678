import { Config } from '@libs/config/index.ts';
import { Env } from '@libs/environment/index.ts';
import { AUTH_CALLBACK, AUTH_LOGOUT_CALLBACK } from '../constants';

export const AUTH0_LOGOUT_ARGS = {
    logoutParams: {
        returnTo: Env.global.location.origin + '/#/' + AUTH_LOGOUT_CALLBACK
    }
};

export const ID_TOKEN = 'id_token';
export const AUTH0_DEFAULT_SCOPES = 'openid profile email offline_access'
export const AUTH0_UPDATE_USER_IDENTITIES_SCOPE = 'update:current_user_identities'

export const AUTH0_CLIENT_CONFIG = {
    domain: Config.AUTH0_DOMAIN.else('AUTH0_DOMAIN_UNSPECIFIED'),
    clientId: Config.AUTH0_CLIENT_ID.else('AUTH0_CLIENT_ID_UNSPECIFIED'),
    useRefreshTokens: true,
    authorizationParams: {
        redirect_uri: Env.global.location.origin + '/#/' + AUTH_CALLBACK,
        display: 'touch' as const,
        scope: AUTH0_DEFAULT_SCOPES
    }
};