import { EntityFactory as EF } from './schema/entity.factory.ts';
import { Version as VS } from '../utils/version.ts';
import { EntitySchema as ES } from './schema/entity.schema.ts';
import { EntityCore, EntityShape, MergedShape } from './schema/entity.core.ts';
import { StorageRule } from './schema/entity.storage.ts';

export * from './schema/entity.schema.ts';
export * from './schema/schema.ts';

export const Version = VS;

export type WithSchemaVersion<T extends EntityCore> = {
    __scid: number;
} & T;

export function define<
    NAME extends string,
    TYPE extends number,
    T extends EntityShape = EntityShape
>(
    name: NAME,
    type: TYPE,
    required: T
): ES<NAME, TYPE, T>;

export function define<
    NAME extends string,
    TYPE extends number,
    T extends EntityShape = EntityShape,
    O extends EntityShape = EntityShape
>(
    name: NAME,
    type: TYPE,
    required: T,
    optional: O,
): ES<NAME, TYPE, MergedShape<T, O>>

export function define(...args: any[]): any {
    return ES.define(args[0], args[1], merge(args[2], args[3]));
}

export function EntityFactory<T extends EntityCore>(schema: ES): EF<T>

export function EntityFactory<T extends EntityCore>(schema: ES, schemaVersion: VS): EF<T>
export function EntityFactory<T extends EntityCore>(schema: ES, storage: StorageRule<T>): EF<T>
export function EntityFactory<T extends EntityCore>(
    schema: ES, onEntityInit: (entity: T, isNew: boolean) => void
): EF<T>

export function EntityFactory<T extends EntityCore>(schema: ES, schemaVersion: VS, storage: StorageRule<T>): EF<T>
export function EntityFactory<T extends EntityCore>(
    schema: ES, schemaVersion: VS, onEntityInit: (entity: T, isNew: boolean) => void
): EF<T>
export function EntityFactory<T extends EntityCore>(
    schema: ES, onEntityInit: (entity: T, isNew: boolean) => void, storage: StorageRule<T>
): EF<T>

export function EntityFactory<T extends EntityCore>(
    schema: ES, schemaVersion: VS, onEntityInit: (entity: T, isNew: boolean) => void, storage: StorageRule<T>
): EF<T>

export function EntityFactory<T extends EntityCore>(...args: any[]): any {
    if (args.length === 0 || args.length > 4) {
        throw new Error("Invalid number of arguments");
    }

    if (args.length === 1) {
        return EF.create<T>(args[0]);
    }

    if (args.length === 2) {
        if (typeof args[1] === 'function') {
            return EF.create<T>(args[0], undefined, args[1]);
        }

        if (args[1] instanceof VS) {
            return EF.create<T>(args[0], args[1]);
        }

        return EF.create<T>(args[0], undefined, undefined, args[1]);
    }

    if (args.length === 4) {
        return EF.create<T>(args[0], args[1], args[2], args[3]);
    }

    if (!(args[1] instanceof VS)) {
        return EF.create<T>(args[0], undefined, args[1], args[2]);
    }

    if (typeof args[2] === 'function') {
        return EF.create<T>(args[0], args[1], args[2]);
    }

    return EF.create<T>(args[0], args[1], undefined, args[2]);
}

const merge = <
    T extends EntityShape,
    O extends EntityShape
>(required: T, optional: O): MergedShape<T, O> => {
    const o: any = {};
    if (optional !== undefined) {
        for (const key in optional) {
            const v = optional[key];
            o[key] = !v.isOptional() ? v.optional() : v;
        }
    }

    if (!required) {
        return o;
    }

    for (const key in required) {
        if (key in o) {
            throw new Error(`Cannot merge required key ${key} with optional key`);
        }

        const v = required[key];
        o[key] = v;
    }

    return o;
};