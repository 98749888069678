import { JSX, Resource } from 'solid-js';
import {
    Card,
    Avatar,
    IconButton,
    Typography
} from '@suid/material';
import EditIcon from '@app/assets/icons/EditIcon';

interface SectionCommonProps<T = any> {
    onClick: () => void;
    displayData: (source: T) => JSX.Element[];
    isEmpty?: (source: T) => boolean;
    resource: Resource<T>;
    emptyText: JSX.Element;
    sectionTitle?: JSX.Element; //optional
}

function SectionCommon<T>({ onClick, displayData, isEmpty, resource, emptyText, sectionTitle }: SectionCommonProps<T>) {
    const empty = isEmpty ?? ((source: T) => {
        return !source
    });

    return (
        <>
            {resource() && (<Card
                sx={{
                    margin: '15px',
                    padding: 1,
                    borderRadius: 3,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
                onClick={onClick}
            >
                <Typography style={{ flex: 1, "padding-top": '3px' }}>
                    {sectionTitle && (
                        <Typography style={{ "font-size": '18px', "font-weight": '500', "padding-bottom": '5px', "margin-left": '5px', "padding-top": '3px' }}>
                            {sectionTitle}
                        </Typography>
                    )}
                    {empty(resource()!) ? emptyText : displayData(resource()!)}
                </Typography>
                <Avatar sx={{ bgcolor: '#EBEBEB' }}>
                    <IconButton style={{ "align-self": 'flex-start' }}>
                        <EditIcon />
                    </IconButton>
                </Avatar>
            </Card>)}
        </>
    );
}

export default SectionCommon;
