import { gleapServiceFactory } from '@app/utils/gleap.ts';
import { dbServiceFactory } from '@app/db/mod.ts';
import { cardServiceFactory, cardSelectorFactory } from '@app/card-service/service.factory.ts';
import { authServiceFactory } from './auth/auth.service.factory.ts';
import { AUTH_SERVICE, AuthService } from './auth/auth.service.ts';
import { CUSTOMER_IO_SERVICE, CustomerIOService, GLEAP_SERVICE, GleapService } from './utils/types.ts';
import { eventTrackerFactory } from './services/event-tracker/event.tracker.ts';
import { medServiceFactory } from './services/meds-lookups/med.service.ts';
import { customerIOServiceFactory } from './utils/customerio.ts';
import { cordovaServiceFactory } from './utils/cordova.ts';
import { builder } from '@libs/services/host.ts';

let initialized = false;
export const loadServices = async () => {
    if (initialized) {
        return;
    }

    initialized = true;
    return new Promise<void>((resolve, reject) => {
        try {
            builder
                .register(cordovaServiceFactory)
                .register(authServiceFactory)
                .register(eventTrackerFactory)
                .register(gleapServiceFactory)
                .register(customerIOServiceFactory)
                .register(dbServiceFactory)
                .register(cardServiceFactory)
                .register(cardSelectorFactory)
                .register(medServiceFactory)
                .start().then(host => {
                    const authService = host.get(AUTH_SERVICE);
                    const gleapService = host.get(GLEAP_SERVICE);
                    const cioService = host.get(CUSTOMER_IO_SERVICE);

                    toggleGleapCioUser(authService, gleapService, cioService);

                    authService.onLoginLogout(() => {
                        toggleGleapCioUser(authService, gleapService, cioService);
                    });

                    globalThis.addEventListener('online', () => {
                        toggleGleapCioUser(authService, gleapService, cioService);
                    });

                    globalThis.addEventListener('offline', () => {
                        toggleGleapCioUser(authService, gleapService, cioService);
                    });
                })
                .catch(reject)
                .finally(resolve);
        } catch (e) {
            reject(e);
        }
    });
};

const toggleGleapCioUser = (authService: AuthService, gleapService: GleapService, cioService: CustomerIOService) => {
    if (!window.navigator.onLine || !authService.isAuthenticated()) {
        gleapService.clearUser();
        cioService.clearIdentity();
        return;
    }

    const user = authService.currentUser();
    const copy = {
        userId: user.id,
        email: user.email,
        phone: user.phone,
        name: user.email ?? user.phone
    };

    gleapService.identifyUser(copy);
    cioService.identifyUser(copy);
};