import { DebugLogItem, debugConsole } from "@app/utils/debug.console";
import { For, createSignal } from "solid-js";
import AlertDialog from "./AlertDialog";

let loaded = false;
const buffer: DebugLogItem[] = [];
export const DebugConsole = () => {
    if (loaded) {
        console.error('DebugConsole already loaded');
        return <></>;
    }

    loaded = true;

    if (!debugConsole.isEnabled) {
        return <></>;
    }

    const CAUGHT_ERROR = 'CAUGHT_ERROR';
    const errorHandler = (ev: ErrorEvent) => {
        debugConsole.emit(ev.message, CAUGHT_ERROR);
    };

    const UNHANDLED_REJECTION_ERROR = 'UNHANDLED_REJECTION_ERROR';
    const unhandledRejectionHandler = (ev: PromiseRejectionEvent) => {
        debugConsole.emit(ev.reason, UNHANDLED_REJECTION_ERROR);
    };

    // Hook into global error/log store to add logs
    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    const [openDebugInfo, setOpenDebugInfo] = createSignal(false);
    const [debugInfo, setDebugInfo] = createSignal(<></>);
    let lastDebugInfoLineCount = -1;
    let lineCount = 0;

    debugConsole.onShowHideRequested(show => {
        setTimeout(() => setOpenDebugInfo(show), 0);
    });

    debugConsole.onLog(item => {
        lineCount++;
        buffer.push(item);
        if (buffer.length > 150) {
            buffer.shift();
        }
    });

    const getDebugInfo = () => {
        if (lastDebugInfoLineCount === lineCount) {
            return debugInfo();
        }

        lastDebugInfoLineCount = lineCount;
        setDebugInfo((
            <div>
                <For each={buffer}>{(item: DebugLogItem) => {
                    return <>
                        <strong>{`[${item.context}]`}: </strong><br /><i>{`${item.value}`}</i><br /><br />
                    </>;
                }}</For>
            </div>)
        );
    };

    return (
        <AlertDialog
            id='debug_console_confirmation_dialog_id'
            type={'Ok' as const}
            open={openDebugInfo}
            title={'Debug Info'}
            description={getDebugInfo}
            onClick={() => setOpenDebugInfo(false)}
            onClose={() => setDebugInfo(<></>)}
        />
    );
};