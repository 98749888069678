import { Env } from '../environment/index.ts';
import { objects } from "@rdt-utils";
import { Behavior } from "../utils/behavior.ts";
import { configHelper } from './configHelper.ts';
import { InferredType, MappedValue, Value } from './value.ts';

export type Config = {
    readonly [key: string]: Value;
};

export const Config: Config = new Proxy<Config>({}, {
    get: (_, key: string): Value => wrap(key, Env[key]),
});

const wrap = (key: string, value: any): Value => {
    const wrappedValue: Value = {
        key: key,
        exists: !objects.isNil(value),
        value: value,

        get: () => {
            if (!wrappedValue.exists) {
                throw new Error(`Config value '${key}' does not exist`);
            }

            return wrappedValue.value as string;
        },

        else<T extends number | string | string[] | Date | boolean>(elseValue: T): InferredType<T> {
            return configHelper.else(key, value, elseValue) as any as InferredType<T>;
        },

        num: (dv) => mapped(key, wrappedValue.asNum(dv)),
        dt: (dv) => mapped(key, wrappedValue.asDt(dv)),
        bool: (dv) => mapped(key, wrappedValue.asBool(dv)),
        str: (dv) => mapped(key, wrappedValue.asStr(dv)),
        array: (dv, delimiter) => mapped(key, wrappedValue.asArray(dv, delimiter)),
        map<T extends object, R extends object = T>(map?: (value: T) => R): MappedValue<R> {
            if (objects.isNil(wrappedValue.value)) {
                return mapped(key) as any;
            }

            const value = wrappedValue.requireObject() as T;
            return mapped(key, objects.isNil(map) ? value as any as R : map(value));
        },
        requireNum: () => configHelper.asNum(key, value, Behavior.FailIfEither),
        requireDt: () => configHelper.asDt(key, value, Behavior.FailIfEither),
        requireBool: () => configHelper.asBool(key, value, Behavior.FailIfEither),
        require: () => configHelper.asStr(key, value, Behavior.FailIfEither),
        requireArray: (d) => configHelper.asArray(key, value, Behavior.FailIfEither, d ?? ','),
        requireObject: <T extends object>() => {
            return JSON.parse(configHelper.asStr(key, value, Behavior.FailIfEither)) as T;
        },
        asNum: (dv) => configHelper.asNum(key, value, dv),
        asDt: (dv) => configHelper.asDt(key, value, dv),
        asBool: (dv) => configHelper.asBool(key, value, dv),
        asStr: (dv) => configHelper.asStr(key, value, dv),
        asArray: (dv, delimiter) => configHelper.asArray(key, value, dv, delimiter),
    };

    return wrappedValue;
};

function mapped<T>(key: string, value?: T): MappedValue<T> {
    const wrappedValue: MappedValue<T> = {
        else(elseValue: T): T {
            return objects.isNil(wrappedValue.value) ? elseValue : wrappedValue.value;
        },
        transform<R>(fn: (value: T | undefined) => R): R {
            const value = wrappedValue.value ?? undefined;
            return fn(value as any);
        },
        elseThrow(handle?: (key: string) => Error): T {
            if (!objects.isNil(wrappedValue.value)) {
                return wrappedValue.value;
            }

            throw handle?.(key) ?? new Error(`Config value '${key}' does not exist`);
        },
        get(): T {
            if (objects.isNil(wrappedValue.value)) {
                throw new Error(`Config value '${key}' does not exist`);
            }

            return wrappedValue.value;
        },
        map<R>(map: (source: T) => R): MappedValue<R> {
            if (objects.isNil(wrappedValue.value)) {
                return wrappedValue as MappedValue<any>;
            }

            return mapped(key, map(wrappedValue.value as any));
        },
        key: key,
        exists: !objects.isNil(value),
        value: value,
    };

    return wrappedValue;
}
