export default function TextureWhite() {
    return (
        <svg width="446" height="371" viewBox="0 0 446 371" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.05" d="M303.777 300.37C251.191 189.174 188.122 -14.0495 356.534 62.6265C524.946 139.302 391.535 253.07 303.777 300.37Z" fill="white" />
            <path opacity="0.05" d="M14.1659 15.6122C146.145 57.3524 362.444 161.981 171.803 246.573C-18.8385 331.165 -12.7224 127.846 14.1659 15.6122Z" fill="white" />
            <path opacity="0.05" d="M61.4334 136.211C222.199 48.1516 520.705 -62.8416 428.6 197.662C336.495 458.165 145.445 265.238 61.4334 136.211Z" fill="white" />
            <path opacity="0.05" d="M162.834 48.1899C238.771 6.32735 379.658 -46.6381 335.714 76.4009C291.769 199.44 202.15 108.86 162.834 48.1899Z" fill="white" />
            <path opacity="0.05" d="M35.3466 193.807C147.985 190.71 342.247 213.491 218.185 329.397C94.1235 445.303 44.6004 287.298 35.3466 193.807Z" fill="white" />
        </svg>
    );
}
