import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@suid/material';

import { useBoundSource } from '@app/data/hooks';
import { useIsReadOnlyMode } from '@app/utils/hooks';

export type Props = {
    name: string;
    label?: string;
    gxs?: number;
    onCategoryChange?: (category: string) => void;
    onCategorySelected?: (category: string) => void;
};

export default function CategorySelect(props: Props & { onCategorySelected?: (category: string) => void }) {
    const source = useBoundSource();
    const isReadonly = useIsReadOnlyMode();

    const handleCategoryChange = (event: any) => {
        const newCategory = event.target.value;
        source.set(props.name, newCategory);

        if (props.onCategoryChange) {
            props.onCategoryChange(newCategory);
        }

        if (props.onCategorySelected) {
            props.onCategorySelected(newCategory);
        }
    };

    const select = (
        <Box sx={{ background: '#FFFFFF' }}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>Category</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={props.name}
                    value={source.get(props.name) ?? ''}
                    label='Category'
                    variant='filled'
                    onChange={handleCategoryChange}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    <MenuItem value={'Family+Friend'}>Family & Friends</MenuItem>
                    <MenuItem value={'Doctor+Specialist'}>Doctor or Specialist</MenuItem>
                    <MenuItem value={'Facility'}>Facility</MenuItem>
                    <MenuItem value={'Service'}>Service</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    if (!props.gxs) {
        return select;
    }

    return (
        <Grid item xs={props.gxs}>
            {select}
        </Grid>
    );
}
