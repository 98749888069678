import { For } from 'solid-js';

import ContactsIcon from '@app/assets/icons/Contacts.tsx';
import MedicalIcon from '@app/assets/icons/Medical.tsx';
import MedsIcon from '@app/assets/icons/Meds.tsx';
import PersonProfileIcon from '@app/assets/icons/PersonProfile.tsx';
import VitalDocumentsIcon from '@app/assets/icons/VitalDocs.tsx';
import WalletIcon from '@app/assets/icons/Wallet.tsx';
import { BoundSource } from '@app/data/components/BoundSource';
import { FeatureCard } from '@app/components/FeatureCard';
import { pageStrings } from '@app/defs/page.strings.ts';
import { routeStrings } from '@app/routes/route.strings';
import { HomeLayout } from './page-layouts/HomeLayout.tsx';
import { svc } from '@app/utils/svc.ts';

const CardHome = () => {
    const currentCard = svc.card;
    return (
        <BoundSource source={currentCard.cardInfo}>
            <HomeLayout>
                <For each={cards}>
                    {(card) => (
                        <FeatureCard
                            caption={card.caption}
                            icon={card.icon}
                            path={card.path}
                            status={card.status}
                        />
                    )}
                </For>
            </HomeLayout>
        </BoundSource>
    );
};

export const cards = [{
    caption: pageStrings.profileCaption,
    icon: PersonProfileIcon,
    path: routeStrings.profilePath,
    status: undefined,
}, {
    caption: pageStrings.medicalInfoCaption,
    icon: MedicalIcon,
    path: routeStrings.medicalInfoPath,
    status: undefined,
}, {
    caption: pageStrings.medsCaption,
    icon: MedsIcon,
    path: routeStrings.medsPath,
    status: undefined,
}, {
    caption: pageStrings.contactsCaption,
    icon: ContactsIcon,
    path: routeStrings.contactsPath,
    status: undefined,
}, {
    caption: pageStrings.walletCaption,
    icon: WalletIcon,
    path: routeStrings.walletPath,
    status: undefined,
}, {
    caption: pageStrings.documentsCaption,
    icon: VitalDocumentsIcon,
    path: routeStrings.documentsPath,
    status: undefined,
},
];

export default CardHome;
