export class Version {
    public readonly value: number;

    private constructor(value: number) {
        this.value = value;
    }

    public static readonly v1 = new Version(1);
    public static readonly v2 = new Version(2);
    public static readonly v3 = new Version(3);
    public static readonly v4 = new Version(4);
    public static readonly v5 = new Version(5);
    public static readonly v6 = new Version(6);
    public static readonly v7 = new Version(7);
    public static readonly v8 = new Version(8);
    public static readonly v9 = new Version(9);
    public static readonly v10 = new Version(10);
    public static readonly v11 = new Version(11);
    public static readonly v12 = new Version(12);
    public static readonly v13 = new Version(13);
    public static readonly v14 = new Version(14);
    public static readonly v15 = new Version(15);
    public static readonly v16 = new Version(16);
    public static readonly v17 = new Version(17);
    public static readonly v18 = new Version(18);
    public static readonly v19 = new Version(19);
    public static readonly v20 = new Version(20);
    public static readonly v21 = new Version(21);
    public static readonly v22 = new Version(22);
    public static readonly v23 = new Version(23);
    public static readonly v24 = new Version(24);
    public static readonly v25 = new Version(25);
}
