import { Resource, Accessor } from 'solid-js';
import { subjectViewService } from './subjectViewService';
import { ActionViewType, ShareActionView, SubjectView } from './types';
import { BasicInfo, PersonalInfo, Medications, MedicalInfo, MedicalInfoExtra, EmergencyContacts, ContactInfo, ProfileNotes, InsuranceInfo } from './ShareSectionView';
import { Typography } from '@suid/material';
import { documentStyles } from '@app/assets/theme.ts';

export const shareActions: ShareActionView[] = [
    {
        type: ActionViewType.EMERGENCY_PERSONNEL,
        name: 'Emergency Personnel',
        component: (sub?: Resource<SubjectView> | Accessor<SubjectView>) => {
            const subject = sub ?? subjectViewService.getSubjectView(ActionViewType.EMERGENCY_PERSONNEL);
            return <>
                {subject() &&
                    <>
                        <BasicInfo subject={subject()!} />
                        <PersonalInfo subject={subject()!} />
                        <MedicalInfo subject={subject()!} />
                        <Medications subject={subject()!} />
                        <EmergencyContacts subject={subject()!} />
                    </>
                }
            </>
        },
        ttlMinutes: 60 * 3
    }, {
        type: ActionViewType.FAMILY_MEMBER_CAREGIVER,
        name: 'Family Member or Trusted Caregiver',
        component: (sub?: Resource<SubjectView> | Accessor<SubjectView>) => {
            const subject = sub ?? subjectViewService.getSubjectView(ActionViewType.FAMILY_MEMBER_CAREGIVER);
            return <>
                {subject() &&
                    <>
                        <Typography sx={documentStyles.documentForm}>
                            <BasicInfo subject={subject()!} />
                            <PersonalInfo subject={subject()!} />
                            <ProfileNotes subject={subject()!} />
                            <ContactInfo subject={subject()!} />
                            <MedicalInfo subject={subject()!} />
                            <MedicalInfoExtra subject={subject()!} />
                            <Medications subject={subject()!} />
                            <EmergencyContacts subject={subject()!} />
                            <InsuranceInfo subject={subject()!} />
                        </Typography>
                    </>
                }
            </>
        },
        ttlMinutes: 60 * 24 * 2
    }, {
        type: ActionViewType.DOCTOR_SPECIALIST,
        name: 'Doctor or Specialist',
        component: (sub?: Resource<SubjectView> | Accessor<SubjectView>) => {
            const subject = sub ?? subjectViewService.getSubjectView(ActionViewType.DOCTOR_SPECIALIST);
            return <>
                {subject() &&
                    <>
                        <BasicInfo subject={subject()!} />
                        <PersonalInfo subject={subject()!} />
                        <ProfileNotes subject={subject()!} />
                        <MedicalInfo subject={subject()!} />
                        <MedicalInfoExtra subject={subject()!} />
                        <Medications subject={subject()!} />
                    </>
                }
            </>
        },
        ttlMinutes: 60 * 24
    }, {
        type: ActionViewType.FINANCIAL_REPRESENTATIVE,
        name: 'Financial Representative',
        component: (sub?: Resource<SubjectView> | Accessor<SubjectView>) => {
            const subject = sub ?? subjectViewService.getSubjectView(ActionViewType.FINANCIAL_REPRESENTATIVE);
            return <>
                {subject() &&
                    <>
                        <BasicInfo subject={subject()!} />
                        <ContactInfo subject={subject()!} />
                        <InsuranceInfo subject={subject()!} />
                    </>
                }
            </>
        },
        ttlMinutes: 60
    }
];
