import { Route } from '@solidjs/router';

import CardHome from '@app/pages/CardHome';
import { routeStrings } from './route.strings';
import CardInteriorLayoutOutlet from '@app/pages/page-layouts/CardInteriorLayoutOutlet';
import { ContactsView, ContactsEdit } from '@app/pages/contacts';
import { ProfileView, ProfileEdit } from '@app/pages/profile';
import { WalletView, WalletEdit } from '@app/pages/wallet';
import { DocumentsEdit, DocumentsView } from '@app/pages/documents';
import { MedicalInfoEdit, MedicalInfoView } from '@app/pages/medical-info';
import { MedsView, MedsEdit } from '@app/pages/medications';
import CardSelection from '@app/pages/card/CardSelection';
import CardCreate from '@app/pages/card/CardCreate';
import { Component, JSX } from 'solid-js';
import { useOnCardServiceReady } from '@app/utils/hooks';

export function CareCardRoutes() {
    return (
        <CardServiceReadyGuard>
            <Route path={['/', routeStrings.cardSelectionPath]} component={CardSelection} />
            <Route path={routeStrings.cardCreatePath} component={CardCreate} />
            <Route path={routeStrings.cardHomePath} component={CardHome} />
            <Route path='' component={CardInteriorLayoutOutlet} >

                <CardViewEditRoute
                    path={routeStrings.medicalInfoPath}
                    view={MedicalInfoView}
                    edit={MedicalInfoEdit} />

                <CardViewEditRoute
                    path={routeStrings.profilePath}
                    view={ProfileView}
                    edit={ProfileEdit} />

                <CardViewEditRoute
                    path={routeStrings.contactsPath}
                    view={ContactsView}
                    edit={ContactsEdit} />

                <CardViewEditRoute
                    path={routeStrings.walletPath}
                    view={WalletView}
                    edit={WalletEdit} />

                <CardViewEditRoute
                    path={routeStrings.documentsPath}
                    view={DocumentsView}
                    edit={DocumentsEdit} />

                <CardViewEditRoute
                    path={routeStrings.medsPath}
                    view={MedsView}
                    edit={MedsEdit} />
            </Route>
        </CardServiceReadyGuard>
    );
}

function CardServiceReadyGuard(props: { children: JSX.Element }) {
    const onCardServiceReady = useOnCardServiceReady();
    return (<>
        {onCardServiceReady() && props.children}
    </>)
}

function CardViewEditRoute(props: { path: string, view: Component; edit: Component; }) {
    return (
        <Route path={props.path}>
            <Route path={'/'} component={props.view} />
            <Route path={'/:section'} component={props.edit} />
        </Route>
    );
}