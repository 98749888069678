export const TypeIdSymbol = Symbol();

export class EntityTypeId<NAME extends string = string, TYPE extends number = number> {
  readonly #type: TYPE;
  readonly #name: NAME;

  private constructor(type: TYPE, name: NAME) {
    this.#type = type;
    this.#name = name;
    (this as any)[TypeIdSymbol] = Symbol.for(`EntityTypeId:${type}:${name}`);
    Object.freeze(this);
  }

  public static of<NAME extends string, TYPE extends number>(
    type: TYPE,
    name: NAME,
  ): EntityTypeId<NAME, TYPE> {
    if (entityIdRegistry.has(type)) {
      throw new Error(`Entity type ${type} already defined`);
    }

    if (entityIdRegistry.hasByName(name)) {
      throw new Error(`Entity name ${name} already defined`);
    }

    return entityIdRegistry.setAndGet(new EntityTypeId(type, name)) as EntityTypeId<NAME, TYPE>;
  }

  public static lookupByType<TYPE extends number = number, NAME extends string = string>(
    type: TYPE,
  ): EntityTypeId<NAME, TYPE> | undefined {
    return entityIdRegistry.get(type) as EntityTypeId<NAME, TYPE>;
  }

  public static lookupByName<NAME extends string = string, TYPE extends number = number>(
    name: NAME,
  ): EntityTypeId<NAME, TYPE> | undefined {
    return entityIdRegistry.getByName(name) as EntityTypeId<NAME, TYPE>;
  }

  public get type(): TYPE {
    return this.#type;
  }

  public get name(): NAME {
    return this.#name;
  }

  public toString(): string {
    return Symbol.keyFor((this as any)[TypeIdSymbol]) ?? 'EntityTypeId';
  }

  public static all(): EntityTypeId[] {
    return entityIdRegistry.all();
  }
}

const entityIdRegistry = (() => {
  const registryByName = new Map<string, EntityTypeId>();
  const registry = new Array<EntityTypeId>(9000);
  return {
    has: (type: number): boolean => {
      return registry[type - 1000] !== undefined;
    },
    hasByName: (name: string): boolean => {
      return registryByName.has(name);
    },
    get: (type: number): EntityTypeId | undefined => {
      return registry[type - 1000];
    },
    getByName: (name: string): EntityTypeId | undefined => {
      const typeId = registryByName.get(name);
      return typeId === undefined ? undefined : registry[typeId.type - 1000];
    },
    setAndGet: (type: EntityTypeId): EntityTypeId => {
      if (registryByName.has(type.name)) {
        throw new Error(`Entity name ${type.name} already defined`);
      }

      if (registry[type.type - 1000] !== undefined) {
        throw new Error(`Entity type ${type.type} already defined`);
      }

      registryByName.set(type.name, type);
      return registry[type.type - 1000] = type;
    },
    all: (): EntityTypeId[] => {
      return Array.from(registryByName.values());
    },
  };
})();
