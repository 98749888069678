import { createResource, createSignal, For } from 'solid-js';
import {
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Chip,
    Stack,
    TextField,
    InputAdornment,
    Typography,
    Divider
} from '@suid/material';
import MoreInfo from '@suid/icons-material/InfoOutlined';
import InteractionIcon from '@suid/icons-material/SyncAlt';
import DrawerCommon from '@app/components/DrawerCommon.tsx';
import SearchIcon from '@suid/icons-material/Search';

import { svc } from '@app/utils/svc.ts';
// import { getLogger, LogLevel } from "@libs/logger/mod.ts";
import { HttpClient } from "@app/identity/http.client";
import { APP_API_ORIGIN } from '@app/defs/constants';
import { API_V2 } from '@app/dto/constants';
import { BoundSource } from '@app/data/components/BoundSource';


// const logger = getLogger('MED_INTR');
const shareApiUrl = APP_API_ORIGIN + API_V2.medsUrlPath;
// logger.info(shareApiUrl);
const client = new HttpClient();

interface InteractionMessage {
    level: "Critical" | "Moderate" | "Mild";
    message: string;
}

interface InteractionItem {
    id: number;
    primaryMed: string;
    interactorMeds: string;
    description: string;
    interactionMessages: InteractionMessage[];
    level: "Critical" | "Moderate" | "Mild";
}

type ApiInteraction = {
    a: string;
    b: string;
    severity: "mild" | "moderate" | "severe";
    symptoms: string;
};

const MedicationInteractions = () => {
    const [selectedFilter, setSelectedFilter] = createSignal('All');
    const [selectedInteraction, setSelectedInteraction] = createSignal<InteractionItem | null>(null);
    const [isDetailDrawerOpen, setIsDetailDrawerOpen] = createSignal(false);
    const [searchQuery, setSearchQuery] = createSignal('');

    const fetchDataAsync = async (req: any) => {
        // logger.emit(LogLevel.INFO, "fetching med check for:\n" + JSON.stringify(req, null, 2));
        const url: URL = new URL(shareApiUrl);
        const response = await client.post(url, {
            body: JSON.stringify(req)
        });

        if (!response.ok) {
            // logger.emit(LogLevel.DEBUG, `Failed to get data from ${url.toString()}`);
            throw new Error(response.statusText);
        }
        const res: ApiInteraction[] = await response.json();
        // logger.emit(LogLevel.INFO, "recieved med check\n" + JSON.stringify(res, null, 2));

        return preprocessMedCheck(res);
    }

    const fetchedData = createResource(() => [svc.card.medications(), svc.card.medicalInfo()] as const, ([meds, info]) => {
        if (!meds || !info) {
            return null;
        }

        return fetchDataAsync({
            "medications": meds.medications.map(x => x.name),
            "conditions": info.medicalConditions.map(x => x.name)
        });
    })[0];

    const openInteractionDialog = (interaction: InteractionItem) => {
        setSelectedInteraction(interaction);
        setIsDetailDrawerOpen(true);
    };

    const levels = ["Critical", "Moderate", "Mild"];

    const getChipColor = (level: string) => {
        switch (level) {
            case 'Critical': return 'error';
            case 'Moderate': return 'primary';
            case 'Mild': return 'success';
            default: return 'default';
        }
    };

    const countInteractions = (interactionData: InteractionItem[], level: string) => {
        return interactionData.filter(item => item.level === level).length;
    };

    const handleSearchChange = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const filteredInteractions = (interactionData: InteractionItem[]) => {
        const query = searchQuery().toLowerCase();
        return interactionData.filter(item =>
            item.level === selectedFilter() || selectedFilter() === 'All')
            .filter(item =>
                item.primaryMed.toLowerCase().includes(query) ||
                item.interactorMeds.toLowerCase().includes(query)
            );
    };

    return (
        <BoundSource<InteractionItem[]> source={fetchedData as any}>{interactionData => {
            return (<>
                <TextField
                    variant="outlined"
                    placeholder="Filter by medication name"
                    fullWidth
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        marginBottom: '10px',
                        '& .MuiInputBase-root': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        }
                    }}
                />
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        marginBottom: '10px',
                        flexWrap: 'wrap',
                        rowGap: '10px',
                        marginLeft: '-10px',

                    }}
                >
                    <Chip
                        label={`All (${interactionData.length})`}
                        variant={selectedFilter() === 'All' ? 'filled' : 'outlined'}
                        onClick={() => setSelectedFilter('All')}
                        sx={{ marginLeft: '10px' }}
                    />
                    {levels.map(level => (
                        <Chip
                            label={`${level} (${countInteractions(interactionData, level)})`}
                            variant={selectedFilter() === level ? 'filled' : 'outlined'}
                            onClick={() => setSelectedFilter(level)}
                        />
                    ))}
                </Stack>

                {(
                    levels.map(level => (
                        <For each={filteredInteractions(interactionData).filter(item => item.level === level)}>{(item) => (
                            <ListItem
                                onClick={() => openInteractionDialog(item)}
                                divider
                                sx={{ width: '100%', display: 'flex', marginLeft: '0px', paddingLeft: '0px' }}
                            >
                                <ListItemButton alignItems='flex-start' sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                                    <Chip label={item.level} color={getChipColor(item.level)} sx={{ marginLeft: '-15px', marginRight: '10px', marginTop: '8px', width: '90px' }} />
                                    <ListItemText
                                        primary={
                                            <span style={{ display: 'flex', "align-items": 'center', "flex-wrap": 'wrap' }}>
                                                {item.primaryMed}
                                                <InteractionIcon style={{ "margin-left": '4px', "margin-right": '4px' }} />
                                                {item.interactorMeds}
                                            </span>
                                        }
                                        secondary={item.description}
                                        sx={{ '& .MuiListItemText-primary': { fontWeight: '500', fontSize: '16px' } }}
                                    />
                                    <IconButton edge="end" sx={{ flexGrow: 0, "&:hover": { backgroundColor: "transparent" } }}>
                                        <MoreInfo />
                                    </IconButton>
                                </ListItemButton>
                            </ListItem>
                        )}</For>
                    )))}
                <Divider sx={{ paddingTop: '30px' }}><Chip label="Disclaimer Statement" size='small'></Chip></Divider>
                <Typography variant='caption'>Vital Vault is designed to provide general information about potential medication interactions and should not be considered a substitute for professional medical advice, diagnosis, or treatment. The information provided by the App is based on available data and AI algorithms but may not be comprehensive or up-to-date.</Typography>

                {selectedInteraction() && isDetailDrawerOpen() && (
                    <DrawerCommon
                        onClose={() => setIsDetailDrawerOpen(false)}
                        title={`${selectedInteraction()?.primaryMed} and ${selectedInteraction()?.interactorMeds}`}
                        height='auto'
                        closeOnSwipe={false}
                    >
                        {selectedInteraction()?.interactionMessages.map(msg => (
                            <div>
                                <Chip label={msg.level} color={getChipColor(msg.level)} sx={{ marginLeft: '0px', marginRight: '10px', marginTop: '8px', width: '90px' }} />
                                <p>{msg.message}</p>
                            </div>
                        ))}
                        <Divider sx={{ paddingTop: '25px' }}><Chip label="Disclaimer Statement" size='small'></Chip></Divider>
                        <Typography variant='caption'>Vital Vault is designed to provide general information about potential medication interactions and should not be considered a substitute for professional medical advice, diagnosis, or treatment. The information provided by the App is based on available data and AI algorithms but may not be comprehensive or up-to-date.</Typography>

                    </DrawerCommon>
                )}
            </>)
        }}
        </BoundSource>
    );
};

export default MedicationInteractions;

function preprocessMedCheck(x: ApiInteraction[]): InteractionItem[] {
    const groups: Record<string, any[]> = {};
    for (const inter of x) {
        const key: string = inter.a > inter.b ? `${inter.a}|${inter.b}` : `${inter.b}|${inter.a}`
        if (!groups[key])
            groups[key] = [];
        groups[key].push({ level: inter.severity, message: inter.symptoms });
    }
    const out: InteractionItem[] = [];
    let id = 0;
    for (const group of Object.keys(groups)) {
        groups[group].forEach((item, index, array) => array[index].level = item.level.charAt(0).toUpperCase() + item.level.slice(1))
        const [a, b] = group.split('|');
        const levels = groups[group].map(x => x.level);
        const max_level = levels.includes("Critical") ? "Critical" : levels.includes("Moderate") ? "Moderate" : "Mild";
        const next: InteractionItem = {
            id: id++,
            primaryMed: a,
            interactorMeds: b,
            description: `${groups[group].length} interaction${groups[group].length == 1 ? '' : 's'} found`,
            level: max_level,
            interactionMessages: groups[group]
        }
        out.push(next);
    }
    return out;
}