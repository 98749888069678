import { shareCareCardStyles } from "@app/assets/theme";
import 'cropperjs/dist/cropper.css';
import './imageCropDialog.css';

import CloseIcon from '@suid/icons-material/Cancel';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@suid/material";

import { createEffect, createResource, createSignal, onCleanup } from "solid-js";
import { CropButtons } from "./ImageCropButtons";
import { getCropper } from "./_utils";
import { CircularIndeterminate } from "../CircularIndeterminate";
import { LogLevel, logger } from "@libs/logger";
import { ErrorLike } from "@rdt-utils";

export type Props = {
    imageFile: File;
    onChange: (imageUrl?: Blob, error?: ErrorLike) => Promise<void>;
    onClose?: () => void;
}

let cropImageIdCounter = 0;
export default function ImageCropDialog(props: Props) {
    const [
        imageRef,
        setImageRef
    ] = createSignal<HTMLImageElement | null>(null);
    const imageId = 'image-crop-image-id-' + cropImageIdCounter++;
    const [busy, setBusy] = createSignal(true);
    const cropper = createResource(() => imageRef(), getCropper)[0];

    const rotate = (degree: number) => {
        const c = cropper();
        c?.rotate(degree);
    }

    const crop = () => {
        const c = cropper();
        if (!c) {
            return;
        }

        setBusy(true);
        try {
            const roundedCanvas = c.getCroppedCanvas({
                width: 1024,
                height: 1024,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
                maxWidth: 1024,
                maxHeight: 1024
            });

            logger.emit(LogLevel.DEBUG, `original size ${props.imageFile.size}`);
            roundedCanvas.toBlob(blob => {
                if (blob) {
                    logger.emit(LogLevel.DEBUG, `Rounded and cropped blob size ${blob.size}`);
                    props.onChange(blob).finally(() => {
                        setBusy(false);
                    });
                } else {
                    setBusy(false);
                    props.onChange(undefined, new Error('Failed to convert cropped image to blob'));
                }
            }, 'image/jpeg', 0.5)

        } catch (err) {
            setBusy(false);
            props.onChange(undefined, err);
        }
    }

    const cancel = () => {
        props?.onClose?.();
    }

    const imageUrl = URL.createObjectURL(props.imageFile)
    logger.info('ImageCropDialog', 'imageUrl to file', imageUrl);
    createEffect(() => {
        onCleanup(() => {
            URL.revokeObjectURL(imageUrl);
            cropper()?.destroy();
        });
    })

    createEffect(() => {
        if (cropper()) {
            setBusy(false);
        }
    });

    return (
        <div>
            <Dialog
                onClose={cancel}
                title="Crop Image"
                open={true}
                sx={{
                    '& .MuiDialog-paper': { width: '100%', height: '50%' }
                }}
            >
                <DialogTitle sx={shareCareCardStyles.dialogTitle}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            cancel();
                        }}
                        aria-label="cancel"
                    >
                        <CloseIcon />
                    </IconButton>
                    <CropButtons
                        ready={() => cropper() !== null}
                        rotate={rotate}
                        crop={crop}
                    />
                </DialogTitle>
                <DialogContent>
                    {busy() && <CircularIndeterminate />}
                    <div id="image-crop-div" class="cropper-container">
                        <img
                            class="cropper-image"
                            alt="Crop"
                            src={imageUrl}
                            id={imageId}
                            onLoad={() => {
                                const image = document.getElementById(imageId) as HTMLImageElement
                                setImageRef(image);
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
