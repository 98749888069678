import { IconProps } from ".";

export default function MedsIcon({themeColorPrimary, themeColorSecondary}: IconProps) {
  return (
    <svg width="42" height="42" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2744 27.1332L18.0984 27.3598L13.5321 22.7936C8.48931 17.7508 8.48931 9.57486 13.5321 4.53208C18.5749 -0.510694 26.7508 -0.510694 31.7936 4.53208L36.3595 9.09803L36.1327 10.2748L36.1283 10.2951C36.123 10.3192 36.113 10.3626 36.0975 10.4242C36.0664 10.5474 36.013 10.7435 35.93 11.0034C35.764 11.5231 35.4796 12.2976 35.018 13.2554C34.0957 15.169 32.4628 17.821 29.6412 20.6427C26.8196 23.4643 24.1678 25.0969 22.2545 26.0189C21.2968 26.4804 20.5225 26.7647 20.0028 26.9306C19.743 27.0136 19.547 27.0669 19.4238 27.098C19.3622 27.1136 19.3188 27.1235 19.2948 27.1289L19.2744 27.1332ZM27.6345 6.61547C26.9389 5.91984 25.8111 5.91984 25.1155 6.61547C24.4198 7.31109 24.4198 8.43891 25.1155 9.13453L28.678 12.697C29.3736 13.3927 30.5014 13.3927 31.197 12.697C31.8927 12.0014 31.8927 10.8736 31.197 10.178L27.6345 6.61547Z" fill={themeColorSecondary} />
          <path d="M43.9679 16.7064L39.3297 12.0682L39.3236 12.0873C39.1109 12.753 38.7664 13.6833 38.2272 14.8021C37.1477 17.042 35.2907 20.0313 32.1603 23.1617C29.0298 26.2922 26.0407 28.1489 23.801 29.2282C22.6823 29.7674 21.7521 30.1118 21.0864 30.3243L21.0687 30.33L25.7064 34.9679C30.7492 40.0107 38.9251 40.0107 43.9679 34.9679C49.0107 29.9251 49.0107 21.7492 43.9679 16.7064Z" fill={themeColorSecondary} />
          <path d="M11.4518 20.0768C12.0086 21.0486 12.702 21.9635 13.5321 22.7936L25.7064 34.9679C26.5365 35.798 27.4514 36.4914 28.4232 37.0482C27.8323 39.7598 26.4694 42.1831 24.5763 44.0763C21.9975 46.655 18.435 48.25 14.5 48.25C6.62994 48.25 0.25 41.8701 0.25 34C0.25 30.065 1.84499 26.5025 4.42373 23.9237C6.31686 22.0306 8.74017 20.6677 11.4518 20.0768Z" fill={themeColorPrimary} />
      </svg>
  );
}
