import { IconProps } from ".";

export default function actionMenuIcon({themeColorSecondary}: Pick<IconProps, "themeColorSecondary">) {
  return (
    <svg width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.1528 9.375H1.84722C0.82703 9.375 0 10.1725 0 11.1562V11.3437C0 12.3275 0.82703 13.125 1.84722 13.125H33.1528C34.173 13.125 35 12.3275 35 11.3437V11.1562C35 10.1725 34.173 9.375 33.1528 9.375Z" 
    fill={themeColorSecondary} />
    <path d="M33.1528 18.75H1.84722C0.82703 18.75 0 19.5475 0 20.5312V20.7187C0 21.7025 0.82703 22.5 1.84722 22.5H33.1528C34.173 22.5 35 21.7025 35 20.7187V20.5312C35 19.5475 34.173 18.75 33.1528 18.75Z" 
    fill={themeColorSecondary} />
    <path d="M33.1528 0H1.84722C0.82703 0 0 0.797492 0 1.78125V1.96875C0 2.95251 0.82703 3.75 1.84722 3.75H33.1528C34.173 3.75 35 2.95251 35 1.96875V1.78125C35 0.797492 34.173 0 33.1528 0Z" 
    fill={themeColorSecondary} />
  </svg>
  );
}
