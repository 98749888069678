import { z } from 'zod';

export type EntityType<T extends EntityCore> = T extends EntityCore<infer TYPE> ?
    TYPE :
    never;

/**
 * The base entity type. It is a combination of the base props and the entity definition type
 */
export type EntityCore<TYPE extends number = number, T = object> = T & {
    get __type(): TYPE;
    get _id(): string;
    get createdAt(): number;
    get updatedAt(): number;
};

/**
 * Convenience method to check for a core property name.
 * @param name Name of the property to check.
 * @returns true if the property name is a core property, false otherwise.
 */
export const isCoreProperty = (name: string): boolean => {
    return name === '__type' || name === '_id' || name === 'createdAt' || name === 'updatedAt' || name === '__scid' || name === '__rels';
};

export const CoreProperties = Object.freeze(['__type', '_id', 'createdAt', 'updatedAt', '__scid', '__rels']);

/**
 * The type definition for the base entity type. It is a combination of the base props
 * and the entity definition type (.e.g, '__type', '_id', 'createdAt', 'updatedAt').
 * @param TYPE The literal type to be used as a parameter during type def creation.
 */
export type EntityCoreType<
    TYPE extends number = number,
    T extends z.ZodRawShape = z.ZodRawShape,
> = T & {
    __type: z.ZodLiteral<TYPE>;
    _id: z.ZodReadonly<z.ZodDefault<z.ZodString>>;
    __scid: z.ZodReadonly<z.ZodDefault<z.ZodNumber>>;
    createdAt: z.ZodReadonly<z.ZodDefault<z.ZodNumber>>;
    updatedAt: z.ZodReadonly<z.ZodDefault<z.ZodNumber>>;
};

export type EntityShape = Exclude<z.ZodRawShape, keyof EntityCoreType>;

export type MergedShape<T, OPTIONAL> =
    T extends EntityShape ?
    OPTIONAL extends EntityShape ?
    T & { [P in keyof OPTIONAL]: OPTIONAL[P] extends z.ZodOptional<any> ? OPTIONAL[P] : z.ZodOptional<OPTIONAL[P]> } :
    OPTIONAL :
    never;