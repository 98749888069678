import { strings } from "@rdt-utils";
import { ValidationResult } from "@libs/validators";
import { z } from "zod";
import { EntitySchema } from "@libs/db/schema/entity.schema";
import { Config } from "@libs/config";
import { LogLevel, useLogger } from "@libs/logger/mod.ts";

export type PropertySchema = {
    path: string,
    isRequired: () => boolean,
    validate: (value: any) => ValidationResult
}

export type SchemaInfo = {
    getPropInfo(path: string): PropertySchema;
    getSchema: () => EntitySchema;
}

const isValidationDisabled = Config.ENTITY_VALIDATION_DISABLED.else(false);
useLogger('SCHEMA_INFO').emit(LogLevel.INFO, `Entity validation is ${isValidationDisabled ? 'disabled' : 'enabled'}`);

export const SchemaInfo = {
    from: (type: number): SchemaInfo => {
        const schema = EntitySchema.getByType(type);
        const schemaShape = schema?.schemaDef?.shape;
        if (!schemaShape) {
            throw new Error(`No valid schema found for type ${type}`);
        }

        let propSchemas: Map<string, PropertySchema> | undefined;

        const setSchemaProp = (path: string, isRequired: () => boolean, validate: (value: any) => ValidationResult) => {
            const v = { path, isRequired, validate };
            propSchemas?.set(path, v);
            return v;
        }

        return {
            getSchema: () => schema,
            getPropInfo: (path: string) => {
                if (!propSchemas) {
                    propSchemas = new Map();
                } else {
                    const v = propSchemas.get(path);
                    if (v) {
                        return v;
                    }
                }

                const propSchema = schemaShape[path] as z.ZodTypeAny;
                if (!propSchema || !propSchema._def) {
                    return setSchemaProp(path, () => false, () => ValidationResult.valid());
                }

                const isRequired = () => {
                    return !propSchema.isOptional();
                }

                const validate: (value: any) => ValidationResult = !isValidationDisabled ?
                    (value: any) => {
                        try {
                            if (!(propSchema.isOptional() && strings.isNilOrEmpty(value as any))) {
                                propSchema.parse(value);
                            }

                            return ValidationResult.valid();
                        } catch (e: any) {
                            if (e instanceof z.ZodError) {
                                // TODO: map errors to appropriate field names
                                const message = e.issues.find(i => i.code === 'custom')?.message;
                                if (message) {
                                    return ValidationResult.invalid(message);
                                }
                            }

                            return ValidationResult.invalid('Invalid value');
                        }
                    } :
                    () => {
                        return ValidationResult.valid();
                    }

                return setSchemaProp(path, isRequired, validate);
            }
        };
    }
}
