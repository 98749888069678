import { Avatar, Box, Typography } from '@suid/material';
import ActionMenu from '@app/components/ActionMenu';
import BackArrowIcon from '@suid/icons-material/ArrowBackIosRounded';
import { layoutBodyStyles, layoutHeaderStyles, careCardInteriorIdentityStyles } from '@app/assets/theme.ts';
import CareCardInteriorPhoto from '@app/components/CareCardInteriorPhoto';
import { svc } from '@app/utils/svc.ts';
import { Outlet, useRouteData, useLocation, Link } from '@solidjs/router';
import { routeStrings } from '@app/routes/route.strings';
import { useCurrentPageTitle } from '@app/routes/hooks';

interface CardInteriorLayoutProps {
    title?: string;
    name?: () => string | undefined;
    backgroundColor?: string;
    onBack?: () => void;
}

export const CardInteriorLayoutOutlet = ({
    title,
    name
}: CardInteriorLayoutProps) => {
    const getName = name ?? (() => {
        return svc.card.cardInfo()?.name;
    });

    const pageTitle = title ?? useCurrentPageTitle();

    const location = useLocation();

    // Determine the background color based on the current route
    const backgroundColor = (location.pathname === routeStrings.medsPath || location.pathname === routeStrings.contactsPath || location.pathname === routeStrings.walletPath)
        ? '#FFFFFF'
        : '#EBEBEB';
    return (
        <>
            <Box sx={layoutHeaderStyles.layoutHeaderContainer}>
                <ActionMenu />
                <Link href={routeStrings.cardHomePath} replace={true}>
                    <Avatar sx={layoutBodyStyles.backAction}>
                        <BackArrowIcon fontSize='small' color="primary" />
                    </Avatar>
                </Link>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '140px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        height: '140px',
                        marginBottom: '10px',
                        marginLeft: '20px',
                    }}>
                        <Typography
                            sx={careCardInteriorIdentityStyles.careCardInteriorName}
                            variant='h3'
                            color='text.primary'
                        >
                            {getName()}
                        </Typography>
                        <Typography
                            sx={careCardInteriorIdentityStyles.careCardInteriorPageTitle}
                            variant='h1'
                            color='text.primary'
                        >
                            {pageTitle ?? useRouteData<any>()?.title ?? ''}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        height: '140px',
                        marginTop: '30px',
                        marginRight: '20px',
                    }}>
                        <CareCardInteriorPhoto imageUrl={() => svc.card.cardInfo()?.imageUrl} />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ ...layoutBodyStyles.layoutBodyContainer, backgroundColor: backgroundColor }}>
                <Outlet />
                {/* temporary fix for space needed to account for actionbar */}
                <Box sx={{ height: '150px' }}></Box>
            </Box>
        </>
    );
};

export default CardInteriorLayoutOutlet;