

export default function MedBottleIcon() {
    return (
<svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 7H17.5V20.2C17.5 21.8801 17.5 22.7203 17.173 23.362C16.8855 23.9265 16.4265 24.3855 15.862 24.673C15.2203 25 14.3802 25 12.7 25H7.3C5.61984 25 4.77976 25 4.13803 24.673C3.57354 24.3855 3.1146 23.9265 2.82699 23.362C2.5 22.7203 2.5 21.8801 2.5 20.2V7ZM1 1H19V7H1V1Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
}
