import { Component, Setter, createEffect, createSignal } from 'solid-js'
import { Show } from 'solid-js'
import { useRegisterSW } from 'virtual:pwa-register/solid'
import styles from './ReloadPrompt.module.css'
import { LogLevel, useLogger } from '@libs/logger/mod.ts'
import { useIsInPwa } from '@app/utils/hooks'
import { DeviceType, utils } from '../host'
import { appStorage } from '@app/utils/app.storage'
import { Runtime } from '@app/host/runtime'

const logger = useLogger('ReloadPrompt');

const ReloadPrompt: Component = () => {
    const isPWA = useIsInPwa();
    document.addEventListener('DOMContentLoaded', () => {
        if (isPWA()) {
            return;
        }

        if (utils.getDeviceType() === DeviceType.Desktop) {
            const pwaInstall = document.getElementsByTagName('pwa-install')[0];
            (pwaInstall as any)?.showDialog();
        }
    });

    const {
        needRefresh,
        setNeedRefresh,
        offlineReady,
        setOfflineReady,
        updateServiceWorker } = startServiceWorker();

    const close = () => {
        setOfflineReady(false)
        setNeedRefresh(false)
    }

    if (needRefresh() && pendingUpdateTime === null) {
        setNeedRefresh(false);
    }

    createEffect(() => {
        if (needRefresh() && pendingUpdateTime === null) {
            setNeedRefresh(false);
        }
    });

    createEffect(() => {
        if (offlineReady()) {
            close();
        }
    });

    return (
        <div class={styles.Container}>
            <Show when={offlineReady() || needRefresh()}>
                <div class={styles.Toast}>
                    <div class={styles.Message}>
                        <Show
                            fallback={<span>Update available, click on reload button to update.</span>}
                            when={offlineReady()}
                        >
                            <span>App ready to work offline</span>
                        </Show>
                    </div>
                    <Show when={needRefresh()}>
                        <button class={styles.ToastButton} onClick={() => {
                            if (pendingUpdateTime === null) {
                                return close();
                            }
                            appStorage.setItem('APP_BUILD_DATE_TIME', (lastUpdateTime = pendingUpdateTime));
                            pendingUpdateTime = null;
                            try {
                                updateServiceWorker(true);
                            } finally {
                                window.location.reload();
                            }
                        }}>Reload</button>
                    </Show>
                    <button class={styles.ToastButton} onClick={() => {
                        pendingUpdateTime = null;
                        close();
                    }}>Close</button>
                </div>
            </Show>
        </div>
    )
}

export default ReloadPrompt;

// TODO: refactotor to separate file

let pendingUpdateTime: string | null = null;
let lastUpdateTime = Runtime.currentVersionDt;

const startServiceWorker = (() => {
    let initialRegistrationStarted = false;
    let hasStarted = false;
    return () => {
        if (hasStarted) {
            return {
                needRefresh: () => false,
                setNeedRefresh: () => { },
                offlineReady: () => false,
                setOfflineReady: () => { },
                updateServiceWorker: () => { },
            };
        }

        hasStarted = true;
        if ("serviceWorker" in navigator) {
            const {
                needRefresh: [needRefresh, setNeedRefresh],
                offlineReady: [offlineReady, setOfflineReady],
                updateServiceWorker,
            } = useRegisterSW({
                immediate: true,
                onRegisteredSW(swUrl, r) {
                    if (!r || initialRegistrationStarted) {
                        return;
                    }

                    logger.emit(LogLevel.INFO, `Service Worker at: ${swUrl}`);
                    initialRegistrationStarted = true;
                    startUpdateChecker(setNeedRefresh);
                },
                onRegisterError(error) {
                    logger.error('SW registration error', error)
                    logger.emit('SW registration error');
                },
            })

            return {
                needRefresh,
                setNeedRefresh,
                offlineReady,
                setOfflineReady,
                updateServiceWorker,
            }
        }

        logger.emit(LogLevel.INFO, `Browser does not support service workers`);
        if (!Runtime.versionCheckDisabled) {
            const [needRefresh, setNeedRefresh] = createSignal(false);
            startUpdateChecker(setNeedRefresh);

            return {
                needRefresh,
                setNeedRefresh,
                offlineReady: () => false,
                setOfflineReady: () => { },
                updateServiceWorker: () => { },
            }
        }

        return {
            needRefresh: () => false,
            setNeedRefresh: () => { },
            offlineReady: () => false,
            setOfflineReady: () => { },
            updateServiceWorker: () => { },
        };
    };
})();

let updateCheckerStarted = false;
const startUpdateChecker = (() => {
    if (updateCheckerStarted) {
        // should never happen
        throw new Error('Update checker already started');
    }

    updateCheckerStarted = true;

    if (Runtime.versionCheckDisabled) {
        return () => { };
    }

    const updateRefreshInterval = Runtime.appRefreshCheckInterval * 1000;
    return (setNeedRefresh: Setter<boolean>) => {
        getVersionInfo().then((updateTime) => {
            if ((pendingUpdateTime = updateTime) !== null) {
                setNeedRefresh(true);
            }
        }).finally(() => {
            setInterval(() => {
                if (pendingUpdateTime) {
                    return;
                }

                getVersionInfo().then((updateTime) => {
                    if ((pendingUpdateTime = updateTime) !== null) {
                        setNeedRefresh(true);
                    }
                });
            }, updateRefreshInterval)
        });
    };
})();

const getVersionInfo = (() => {
    if (Runtime.versionCheckDisabled) {
        return async () => null;
    }

    const storedBuildTime = appStorage.getItem('APP_BUILD_DATE_TIME');
    if (!storedBuildTime || new Date(lastUpdateTime) > new Date(storedBuildTime)) {
        appStorage.setItem('APP_BUILD_DATE_TIME', lastUpdateTime);
    } else {
        lastUpdateTime = storedBuildTime;
    }

    logger.emit(LogLevel.INFO, `App build date/time: ${lastUpdateTime}, mode: ${Runtime.envType}`);

    return async () => {
        logger.emit('Checking for app version update')
        const updatedVersion = await Runtime.checkLatestVersionDt();
        return updatedVersion === lastUpdateTime ? null : updatedVersion;
    };
})();