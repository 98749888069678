import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@suid/material';
import { useBoundSource } from '@app/data/hooks';
import { useIsReadOnlyMode } from '@app/utils/hooks';
import { svc } from '@app/utils/svc';
import { createSignal } from 'solid-js';
import type { DosageForm } from '@app/services/meds-lookups';

export type Props = {
    name: string;
    gxs?: number;
};

export function DosageFormSelect(props: Props) {
    const source = useBoundSource();
    const isReadonly = useIsReadOnlyMode();
    const [dosageForms, setDosageForms] = createSignal<DosageForm[]>([]);

    svc.meds.getDosageForms().then(setDosageForms);

    const select = (
        <Box sx={{}}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>Dosage Form</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={props.name}
                    value={source.get(props.name) ?? ''}
                    label='Dosage Form'
                    variant='filled'
                    onChange={(ev) => source.set(props.name, ev.target.value)}
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    {dosageForms().map((form) => (
                        <MenuItem value={form.value}>
                            {form.caption}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

    if (!props.gxs) {
        return select;
    }

    return (
        <Grid item xs={props.gxs}>
            {select}
        </Grid>
    );
}
