import { createInterceptor } from './interceptor.ts';

interface SignaledObject {
    fromEntries<T = unknown>(entries: Iterable<readonly [PropertyKey, T]>): { [key: PropertyKey]: T };

    new <T extends Record<PropertyKey, unknown> = Record<PropertyKey, unknown>>(obj: T): T;
}

const SignaledObject = function <T extends Record<PropertyKey, unknown>>(obj: T): T {
    const proto = Object.getPrototypeOf(obj);
    const descriptors = Object.getOwnPropertyDescriptors(obj);

    const clone = Object.create(proto) as T;

    for (const prop in descriptors) {
        Object.defineProperty(clone, prop, descriptors[prop]);
    }

    return new Proxy(obj, {
        ...createInterceptor<T>(obj),
        getPrototypeOf() {
            return SignaledObject.prototype;
        },
    });
} as any as SignaledObject;

SignaledObject.fromEntries = function <T = unknown>(
    entries: Iterable<readonly [PropertyKey, T]>,
): { [key: PropertyKey]: T } {
    return new SignaledObject(Object.fromEntries(entries));
};

export function createObject<T extends Record<PropertyKey, unknown>>(object: T): T {
    if (isSignaledObject(object)) {
        return object;
    }
    return new SignaledObject(object);
}

export function isSignaledObject(object: any) {
    return object instanceof SignaledObject
}
