import Gleap from 'gleap';
import { Config } from "@libs/config";
import { ServiceFactory } from '@libs/services/service.ts';
import { GLEAP_SERVICE, GleapService, GleapUser } from './types';
import { Env } from '@libs/environment';
import { LogLevel, logger } from './logger';
import { utils } from '../host';

let proxy = {
    initialize: Gleap.initialize.bind(Gleap),
    setEnvironment: Gleap.setEnvironment.bind(Gleap),
    showFeedbackButton: Gleap.showFeedbackButton.bind(Gleap),
    clearIdentity: Gleap.clearIdentity.bind(Gleap),
    showSurvey: Gleap.showSurvey.bind(Gleap),
    open: Gleap.open.bind(Gleap),
    trackEvent: Gleap.trackEvent.bind(Gleap),
    startChecklist: Gleap.startChecklist.bind(Gleap),
    identify: Gleap.identify.bind(Gleap)
};

let status = 0;
const init = () => {
    if (status !== 0) {
        return;
    }

    // TODO: remove fallback gleap api key
    const apiKey = Config.GLEAP_API_KEY.else('xNbXJeKBe9vpkriMvrFqiaLKX9Lq9VrU');
    if (apiKey.length === 0) {
        status = -1;
        return;
    }

    status = 1;

    if (!utils.isCordovaApp()) {
        // Regardless if the gleap plugin is available, if we are in cordova,
        // then the init will be pointless here, so it is skipped.
        doInit(apiKey);
        return
    }

    document.addEventListener('deviceready', () => {
        const plugin = (window as any)?.cordova?.plugins?.GleapPlugin;
        if (plugin) {
            proxy = plugin;
            doInit(apiKey);
            if (currentUser) {
                logger.emit('Re-identifying user');
                identifyUser(currentUser);
            }
        } else {
            logger.emit(LogLevel.ERROR, 'Gleap plugin not found');
        }
    }, false);
};

const doInit = (apiKey: string) => {
    proxy.initialize(apiKey, true);
    proxy.showFeedbackButton(false);
    if (proxy.setEnvironment) { // not available in cordova plugin
        proxy.setEnvironment(Env.envType);
    }
}

const openWidget = () => {
    doAction(() => {
        proxy.open();
    });
};

const showSurvey = (surveyId: string) => {
    doAction(() => {
        proxy.showSurvey(surveyId, "survey_full");
    });
};

const trackEvent = (name: string, data?: any) => {
    doAction(() => {
        proxy.trackEvent(name, data);
    });
};

const startChecklist = (id: string) => {
    doAction(() => {
        proxy.startChecklist(id);
    });
};

let currentUser: { userId: string, name?: string, email?: string, phone?: string } | undefined;
let userIdentified = false;

const doAction = (action: () => void) => {
    if (status === 1 && userIdentified) {
        action();
    }
};

const clearUser = () => {
    try {
        doAction(() => {
            proxy.clearIdentity()
        });
    } finally {
        currentUser = undefined;
        userIdentified = false;
    }
};

const identifyUser = (user: GleapUser) => {
    if (!user?.userId) {
        clearUser();
        return;
    }

    // regardless if init is called, setting current user so we can resuse this
    // later when if we are in cordova and need to re-identify
    currentUser = {
        userId: user.userId,
        name: user.name ?? '',
        email: user.email ?? '',
        phone: user.phone ?? ''
    };

    if (status !== 1) {
        logger.emit('identifyUser with status !== 1');
        return;
    }

    proxy.identify(currentUser.userId, {
        email: currentUser.email,
        name: currentUser.name,
        phone: currentUser.phone
    });

    userIdentified = true;
};

const gleap = {
    init,
    clearUser,
    identifyUser,
    openWidget,
    showSurvey,
    trackEvent,
    startChecklist,
    isEnabled: () => status === 1,
}

export const gleapServiceFactory: ServiceFactory<GleapService> = Object.seal({
    name: GLEAP_SERVICE,
    init: init,
    create: () => gleap
});
