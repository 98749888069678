import { Resource, createSignal } from 'solid-js';
import {
    Box,
    Card,
    Avatar,
    Badge
} from '@suid/material';

import CameraIcon from '@suid/icons-material/CameraAltOutlined';
import ImgPlaceholder from '@suid/icons-material/AccountCircle';
import { careCardPhotoStyles } from '@app/assets/theme';
import { svc } from '@app/utils/svc';
import { CardInfo } from 'shared/card-service/types';
import { ImageCropDialog } from './ImageCrop';
import AlertDialog from './AlertDialog';
import { DataTooLargeError } from '@rdt-utils';
import { getCurrentTheme } from '@app/assets/theme';
import { ErrorLike } from '@rdt-utils';
import { logger } from '@libs/logger';

interface CareCardPhotoProps {
    cardInfo?: Resource<CardInfo>;
}

const CareCardPhoto = (props: CareCardPhotoProps) => {
    let fileInput: HTMLInputElement;

    const [overriddenImageUrl, setOverriddenImageUrl] = createSignal<string | undefined>(undefined);
    const [newImageFile, setNewImageFile] = createSignal<File | null>(null);

    const cardInfo = props.cardInfo ?? svc.card.cardInfo;
    const handleFileInput = (event: Event) => {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        if (target.files) {
            setNewImageFile(target.files[0] as any);
            try {
                if ((fileInput as any).value) {
                    (fileInput as any).value = '';
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleClick = () => {
        fileInput.click();
    };

    const careCardPhoto = () => {
        return careCardPhotoElement(overriddenImageUrl() ?? cardInfo()?.imageUrl);
    };

    const [alertMessage, setAlertMessage] = createSignal('');
    const updateImage = async (image?: Blob, e?: ErrorLike) => {
        if (e) {
            setAlertMessage('An error occurred while loading the profile image');
            setNewImageFile(null);
            logger.error("image upload error", e);
            logger.emit("image upload error: " + e.toString());
            return;
        }

        if (!image) {
            setAlertMessage('An error occurred while loading the profile image. No image provided without reported error.');
            setNewImageFile(null);
            return;
        }

        try {
            const imageUrl = await cardInfo()!.updateImage(image);
            setOverriddenImageUrl(imageUrl);
            setNewImageFile(null);
        } catch (err) {
            if (err instanceof DataTooLargeError) {
                setAlertMessage('Image too large for car card profile photo. Please upload a smaller image.');
            } else {
                setAlertMessage('An error occurred while updating the profile image');
            }
            logger.error("image upload error", err);
            logger.emit("image upload error: " + (err as ErrorLike).toString());
        }
    };

    return (
        <>
            <AlertDialog
                title="Message Upload Error"
                description={alertMessage}
                type="Ok"
                onClick={() => {
                    setAlertMessage('');
                }}
            />
            <input
                id='care-card-photo-input-id'
                aria-label='care-card-photo'
                multiple={false}
                disabled={newImageFile() !== null}
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{ "display": "none" }}
                onInput={handleFileInput}
                ref={el => (fileInput = el!)}
            />
            <Box sx={careCardPhotoStyles.careCardPhotoContainer}>
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<Avatar sx={{ backgroundColor: getCurrentTheme().iconSecondary }}><CameraIcon style={{ width: '20px', height: '20px' }} /></Avatar>}
                    overlap="circular"
                    sx={careCardPhotoStyles.editPhoto}
                    onClick={handleClick}
                >
                    <Card sx={careCardPhotoStyles.careCardPhotoFrame}>{careCardPhoto()}</Card>
                </Badge>
            </Box>
            {newImageFile() && (
                <ImageCropDialog
                    imageFile={newImageFile()!}
                    onChange={updateImage}
                    onClose={() => {
                        setNewImageFile(null);
                    }}
                />
            )}
        </>
    );
};

export default CareCardPhoto;

export const careCardPhotoElement = (imageUrl: string | undefined) => {
    if (imageUrl) {
        return (
            <img
                src={imageUrl}
                alt="Care Card"
                style={{
                    "width": "100%",
                    "height": "100%",
                    "object-fit": "cover",
                    "object-position": "center"
                }}
            />
        );
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ImgPlaceholder style={{
                    "width": "90%",
                    "height": "90%",
                    "object-fit": "cover",
                    "object-position": "center",
                    "color": "#FFFFFF",
                    "opacity": "0.5"
                }} />
            </Box>
        );
    }
};