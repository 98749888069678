import { AUTH_LOGOUT_CALLBACK_PATH, AUTH_LOGGED_OUT_PATH, AUTH_CALLBACK_PATH } from '@app/auth/constants';
import { APP_API_ORIGIN } from '@app/svc/constants';
import { logger } from '@libs/logger/mod.ts';
import { Config } from '@libs/config/index.ts';
import { Env } from '@libs/environment/index.ts';

export * from '@app/svc/constants.ts';

export const SYNC_INTERVAL_SECONDS = Config.SYNC_INTERVAL_SECONDS.else(5);
export const UPDATE_REFRESH_INTERVAL_SECONDS = Config.UPDATE_REFRESH_INTERVAL
    .else(!Env.isProd ? 20 /* 20s for testing purposes */ : 60 * 60 /* 1h */);

logger.info('Using api url from origin ' + APP_API_ORIGIN);

const shareResolve = 'shareResolve';
export const ROUTE_STRINGS = Object.seal({
    profilePath: '/profile',
    medicalInfoPath: '/medicalInfo',
    medsPath: '/meds',
    contactsPath: '/contacts',
    walletPath: '/wallet',
    documentsPath: '/documents',
    contactUsPath: '/contactus',
    loginPath: '/login',
    authnCallback: AUTH_CALLBACK_PATH,
    logoutPathCallback: AUTH_LOGOUT_CALLBACK_PATH,
    loggedoutPath: AUTH_LOGGED_OUT_PATH,
    cardSelectionPath: '/cardSelection',
    cardCreatePath: '/cardCreate',
    cardHomePath: '/cardHome',
    shareResolvePath: `/${shareResolve}`,
    shareResolveHash: `#/${shareResolve}`,
    sharedShowPath: '/sharedShow',
});
