//TODO: seperate concerns - dynamic colors vs static layout attributes
import { createTheme, Theme } from '@suid/material';
import { SxProps } from '@suid/system';

import TexturePrimary from './textures/TexturePrimary.tsx';
import TextureWhite from './textures/TextureWhite.tsx';
import { zIndexes } from '@app/assets/zIndexes.ts';
import { appStorage } from '@app/utils/app.storage.ts';

const texturePrimary = TexturePrimary;
const textureWhite = TextureWhite;

export function getCurrentTheme() {
    return careCardThemes[appStorage.getItem('currentThemeName') ?? 'calmOcean']; // default theme if the provided theme name doesn't exist
}

export function setCurrentTheme(themeName: string) {
    appStorage.setItem('currentThemeName', themeName);
}

export function getThemeOrDefault(themeName: string) {
    return careCardThemes[themeName] ?? getCurrentTheme();
}

//careCardThemes
export const careCardThemes: { [key: string]: any } = {
    calmOcean: {
        primary: 'hsl(187deg, 33%, 49%)',
        secondary: 'hsl(187deg, 32%, 7%)',
        iconPrimary: 'hsl(187deg, 33%, 80%)',
        iconSecondary: 'hsl(187deg, 32%, 7%)',
        textPrimary: 'hsl(187deg, 32%, 7%)',
        textSecondary: 'hsl(187deg, 32%, 7%)',
    },
    desertMoon: {
        primary: 'hsl(173deg, 33%, 49%)',
        secondary: 'hsl(173deg, 32%, 7%)',
        iconPrimary: 'hsl(173deg, 33%, 80%)',
        iconSecondary: 'hsl(173deg, 32%, 7%)',
        textPrimary: 'hsl(173deg, 32%, 7%)',
        textSecondary: 'hsl(173deg, 32%, 7%)',
    },
    rhythmicRadiance: {
        primary: 'hsl(187deg, 23%, 61%)',
        secondary: 'hsl(187deg, 32%, 6%)',
        iconPrimary: 'hsl(187deg, 33%, 80%)',
        iconSecondary: 'hsl(187deg, 32%, 6%)',
        textPrimary: 'hsl(187deg, 32%, 6%)',
        textSecondary: 'hsl(187deg, 32%, 6%)',
    },
    organicOdyssey: {
        primary: 'hsl(150deg, 23%, 61%)',
        secondary: 'hsl(150deg, 32%, 6%)',
        iconPrimary: 'hsl(150deg, 33%, 80%)',
        iconSecondary: 'hsl(150deg, 32%, 6%)',
        textPrimary: 'hsl(150deg, 32%, 6%)',
        textSecondary: 'hsl(150deg, 32%, 6%)',
    },
};

//Home and Interior Page Layout Styles
export const layoutHeaderStyles: Record<string, SxProps<Theme>> = {
    layoutHeaderContainer: {
        background: getCurrentTheme().primary,
        backgroundImage: 'url(' + textureWhite + ')',
        backgroundRepeat: 'repeat',
        backgroundSize: { xs: '100%' },
        inset: '0px',
        position: 'absolute',
    },
    layoutHeaderContainerInterior: {
        background: getCurrentTheme().primary,
        backgroundImage: 'url(' + textureWhite + ')',
        backgroundRepeat: 'repeat',
        backgroundSize: { xs: '100%' },
        inset: '0px',
        overflow: 'auto',
        position: 'fixed',
    },
    actionMenu: {
        top: '20px',
        position: 'fixed',
        right: '30px',
        cursor: 'pointer',
        '.cordova-app &': {
            top: '60px',
        },
    }
};
export const layoutBodyStyles: Record<string, SxProps<Theme>> = {
    layoutBodyContainer: {
        top: { xs: '230px' },
        borderTopLeftRadius: { xs: '0px' },
        borderTopRightRadius: { xs: '0px' },
        bottom: '0',
        right: '0',
        left: '0',
        overflow: 'auto',
        position: 'absolute',
        marginLeft: { xs: '0px' },
        marginRight: { xs: '0px' },
        backgroundImage: 'url(' + texturePrimary + ')',
        backgroundRepeat: 'repeat',
        backgroundColor: '#FFFFFF',
        backgroundSize: { xs: '100%' },
        marginTop: { xs: '0px' },
        '.cordova-app &': {
            top: '270px',
        },
    },
    featureListingContainer: {
        marginTop: { xs: '35px' },
        marginLeft: { xs: '15px' },
        marginRight: { xs: '10px' },
    },
    layoutBodyContainerInterior: {
        top: { xs: '35px', },
        borderTopLeftRadius: { xs: '30px' },
        borderTopRightRadius: { xs: '30px' },
        bottom: '0',
        right: '0',
        left: '0',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        marginLeft: { xs: '0px' },
        marginRight: { xs: '0px' },
        overflow: 'hidden',
        '.cordova-app &': {
            top: '105px',
        },
    },
    formHeaderContainerEditMode: {
        display: 'flex',
        marginTop: { xs: '30px' },
        marginLeft: { xs: '20px' },
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pageTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    backArrowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        paddingRight: '5px',
    },
    backAction: {
        bgcolor: '#182325',
        marginTop: '20px',
        marginLeft: '10px',
        '.cordova-app &': {
            marginTop: '60px',
        },
    },
    saveAction: {
        boxShadow: 'none',
        position: 'absolute',
        top: 20,
        right: 20,
        borderRadius: 1,
        paddingLeft: 2,
        paddingRight: 2,
        '.cordova-app &': {
            marginTop: '0px',
        },
    },
    bottomAction: {
        boxShadow: 'none',
        width: '88dvw',
        borderRadius: 1,
        paddingLeft: 2,
        paddingRight: 2,
        padding: 3,
        textTransform: 'none',
    },
    pageTitle: {
        paddingBottom: '5px',
    },
    actionSave: {
        marginRight: { xs: '25px' },
        paddingBottom: '5px',
    },
    formContainer: {
        overflow: 'auto',
        height: '90dvh',
        margin: '20px',
    },
    formBox: {
        width: '88dvw',
    },
    callToAction: {
        position: 'fixed',
        top: '200px',
        zIndex: zIndexes.appBar + 1,
        left: '5px',
        textTransform: 'none',
        '.cordova-app &': {
            top: '240px',
        },
    }
};
const careCardPhotoFrameZIndex = zIndexes.appBar - 1;

export const careCardPhotoStyles: Record<string, SxProps<Theme>> = {
    careCardPhotoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: { xs: 'center' },
        alignItems: { xs: 'center' },
        width: '100%',
        overflow: 'hidden',
        minHeight: { xs: '245px' },
        marginTop: { xs: '-25px' },
        '.cordova-app &': {
            marginTop: '-25px',
        },
    },
    careCardPhotoFrame: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 5,
        borderColor: '#EBEBEB',
        height: { xs: '230px', md: '230px' },
        width: { xs: '230px', md: '230px' },
        minHeight: { xs: '230px', md: '230px' },
        minWidth: { xs: '230px', md: '230px' },
        marginLeft: { xs: '0px', md: '0px' },
    },
    smallCardNext: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 3,
        borderColor: '#EBEBEB',
        height: { xs: '115px' },
        width: { xs: '115px' },
        minHeight: { xs: '115px' },
        minWidth: { xs: '115px' },
        marginLeft: { xs: '-10px' },
        zIndex: careCardPhotoFrameZIndex - 1,
        cursor: 'pointer',
        opacity: '.8',
    },
    smallCardBack: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 3,
        borderColor: '#EBEBEB',
        height: { xs: '115px' },
        width: { xs: '115px' },
        minHeight: { xs: '115px' },
        minWidth: { xs: '115px' },
        marginLeft: { xs: '0px' },
        marginRight: { xs: '-10px' },
        zIndex: careCardPhotoFrameZIndex - 1,
        cursor: 'pointer',
        opacity: '.8',
    },
    editPhoto: {
        zIndex: careCardPhotoFrameZIndex + 1,
        cursor: 'pointer',
    },
    link: {
        zIndex: careCardPhotoFrameZIndex + 1
    }
};
export const careCardIdentityStyles: Record<string, SxProps<Theme>> = {
    careCardIdentityContainer: {
        marginTop: { xs: '60px' },
        textAlign: 'center',
    },
    careCardName: {
        width: { xs: '100dvw' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '28px', },
        fontWeight: '600',
        lineHeight: '1.6',
    },
    careCardAge: {
        alignItems: { xs: 'center' },
        width: { xs: '100dvw' },
        fontSize: { xs: '18px' },
    },
};
export const featureCardStyles: Record<string, SxProps<Theme>> = {
    featureCard: {
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, .4)',
        padding: '8px 5px 8px 5px',
        ':hover': { bgcolor: '#FFFFFF' },
        cursor: 'pointer',
        border: '1px solid #DDDDDD',
    },
    featureCardText: {
        textAlign: 'left',
        fontSize: { xs: '15px' },
        fontWeight: '600',
        width: '80px',
        marginRight: '4px',
        letterSpacing: '-.4px',
    },
};
export const shareCareCardStyles: Record<string, SxProps<Theme>> = {
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: 'primary.main',
        color: '#FFFFFF',
        '.cordova-app &': {
            marginTop: '40px',
        }
    }
};
//Card Interior Layout Styles
export const careCardInteriorPhotoStyles: Record<string, SxProps<Theme>> = {
    careCardInteriorPhotoContainer: {
        justifyContent: { xs: 'center' },
        marginTop: { xs: '40px' },
        '.cordova-app &': {
            marginTop: '110px',
        },
    },
    careCardInteriorPhotoFrame: {
        borderRadius: 50,
        backgroundColor: getCurrentTheme().iconPrimary,
        border: 5,
        borderColor: '#EBEBEB',
        height: { xs: '140px' },
        width: { xs: '140px' },
        marginRight: { xs: '20px' },
        zIndex: careCardPhotoFrameZIndex - 1,
    },
};
export const careCardInteriorIdentityStyles: Record<string, SxProps<Theme>> = {
    careCardInteriorIdentityContainer: {
        marginTop: { xs: '60px' },
        textAlign: 'center',
    },
    careCardInteriorName: {
        width: { xs: '180px' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '18px' },
        fontWeight: '400',
        lineHeight: '1.3',
    },
    careCardInteriorPageTitle: {
        width: { xs: '180px' },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: { xs: '24px' },
        fontWeight: '500',
    }
};

export const documentStyles: Record<string, SxProps<Theme>> = {
    documentForm: {
        fontSize: { xs: '14px' },
    },
    documentFormLabel: {
        fontWeight: '500',
        fontSize: { xs: '12px' },
        opacity: '.6',
    },
    documentFormValue: {
        marginBottom: '10px',
        whiteSpace: 'pre-wrap',
    },
    documentFormValueAttention: {
        marginBottom: '10px',
        whiteSpace: 'pre-wrap',
        fontSize: { xs: '18px' },
        color: '#E41010'
    }
};

/**
 * Title: Compile and IDE slowness issues
 * Author: Randal
 * Date: 2023-10-17
 * Detail: The below is a workaround for a bug in the @suid/material package.
 * The bug is that the createTheme function at compilation time causes
 * a type check that takes 2-3 minutes. Additionally, it eventually just
 * recurses until thr depth limit fails and doesn't finish, so it is not
 * actually helping in anyway, only makign the DIE and compiles times
 * PAINFULLY slow.
 *
 * You can replace the below with the original createTheme and then run
 * 'tsc --generateTrace --extendedDiagnostics ts-trace' to see the check
 * time issue. Additionally, run 'npx analyze-trace ts-trace' to see
 * the breakdown.
 */
const doNotCheckCreateTheme = createTheme as any;
export const theme = doNotCheckCreateTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: getCurrentTheme().primary,
        },
        secondary: {
            main: getCurrentTheme().secondary,
        },
        text: {
            primary: getCurrentTheme().textPrimary,
            secondary: getCurrentTheme().textSecondary,
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
    components: {
        /* MuiCssBaseline: {
          styleOverrides: {
            html: {
              background: "#FF0000"
          },
        },
      }, */
        MuiTextField: {
            defaultProps: {
                InputProps: {
                    disableUnderline: true,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                disableUnderline: true,
            },
        },
    },
});
