import { createSignal, Show } from 'solid-js';

import { Contact } from '@app/entities/contacts';
import * as h from '@app/pages/helpers/commonHelpers.tsx';

import NotesIcon from '@app/assets/icons/NotesIcon';
import MobileIcon from '@app/assets/icons/MobileIcon';
import EmailIcon from '@app/assets/icons/EmailIcon';
import RelationshipIcon from '@app/assets/icons/RelationshipIcon';
import { filteredContacts } from './contact.funcs';
import { ContactCategorySelector } from './ContactCategorySelector';
import ListEditDetailsDrawer from '@app/components/ListEditDetailsDrawer';
import { EmergencyCareTeamAttribute } from './EmergencyCareTeamAttribute';

interface ContactsListProps {
    contacts: Contact[];
    onContactEditSelected: (contact: Contact) => void;
}

const ContactList = ({ contacts, onContactEditSelected }: ContactsListProps) => {
    const [selectedCategory, setSelectedCategory] = createSignal('All');
    return (
        <>
            <ContactCategorySelector
                contacts={contacts}
                categorySignal={[selectedCategory, setSelectedCategory]} />

            <ListEditDetailsDrawer
                title={(item) => item.name || 'Provide Contact Name'}
                onEditSelected={onContactEditSelected}
                listItems={() => listItems(filteredContacts(contacts, selectedCategory))}
                avatar={true}
            >{c => (
                <>
                    <h.Attribute name={c.relationShip || c.categoryType || c.category || ''} contentIcon={<RelationshipIcon />} />
                    {h.attributePhoneNumber(c.phone1, c.phone1_type)}
                    {h.attributePhoneNumber(c.phone2, c.phone2_type)}
                    {h.attributePhoneNumber(c.phone3, c.phone3_type)}
                    {h.attributePhoneNumber(c.phone4, c.phone4_type)}
                    <h.Attribute name={c.email} contentIcon={<EmailIcon />} />
                    {h.attributeAddress(
                        c.street1,
                        c.street2,
                        c.city,
                        c.state,
                        c.zipcode,
                        c.county
                    )}
                    <h.Attribute name={c.notes} contentIcon={<NotesIcon />} />

                    <Show when={c.isEmergencyContact}>
                        <EmergencyCareTeamAttribute item={c} />
                    </Show>
                </>
            )}
            </ListEditDetailsDrawer>
        </>
    );
};

export default ContactList;

const listItems = (contacts: Contact[]) => contacts.map(contact => ({
    id: contact._id,
    primaryText: contact.name || 'Provide Contact Name',
    secondaryText: contact.relationShip || contact.categoryType || contact.category || '',
    icon: MobileIcon,
    data: contact,
}));