import { createSignal } from 'solid-js';
import { Grid, Box, Button } from '@suid/material';

import { useBoundSource } from '@app/data/hooks';
import { TextFieldCommon } from '@app/components/TextFieldCommon';
import { InsuranceTypeSelect } from '@app/pages/wallet/components/InsuranceTypeSelect';
import { InsuranceFieldMapping } from './walletHelpers';

import AlertDialog from '@app/components/AlertDialog';
import { layoutBodyStyles } from '@app/assets/theme';

interface InsuranceFormProps {
    isAddCard?: boolean;
}

type InsuranceTypeKey = keyof typeof InsuranceFieldMapping;

const InsuranceForm = ({ isAddCard = false }: InsuranceFormProps) => {
    const source = useBoundSource();
    const [confirmAndDelete, setConfirmAndDelete] = createSignal(false);
    const [selectedInsuranceType, setSelectedInsuranceType] = createSignal<InsuranceTypeKey | ''>('');

    const handleInsuranceTypeChange = (type: string) => {
        const allFields = new Set(Object.values(InsuranceFieldMapping).flat().map(field => field.name));
        const currentTypeFields = new Set(InsuranceFieldMapping[type as InsuranceTypeKey]?.map(field => field.name));
        const fieldsToClear = [...allFields].filter(name => !currentTypeFields.has(name));
            fieldsToClear.forEach(fieldName => {
            source.set(fieldName, '');
        });
        setSelectedInsuranceType(type as InsuranceTypeKey);
    };

    return (
        <Box sx={layoutBodyStyles.formBox}>
            <Grid container spacing={{ xs: 1, md: 1 }}>
                <InsuranceTypeSelect name='insuranceType' gxs={12} onTypeChange={handleInsuranceTypeChange} />
                <TextFieldCommon name='insurerName' label='Name of Insurer' fullWidth={true} gxs={12} />
                <TextFieldCommon name='responsibleParty' label='Member Name' fullWidth={true} gxs={12} />
                <TextFieldCommon name='memberID' label='Member ID' fullWidth={true} gxs={12} />
                <TextFieldCommon name='groupNumber' label='Group Number' fullWidth={true} gxs={12} />
                <TextFieldCommon name='copay' label='Copay' fullWidth={true} gxs={12} multiline={true} />
                {selectedInsuranceType() && InsuranceFieldMapping[selectedInsuranceType() as InsuranceTypeKey]?.map((field) => (
                    <>
                        <TextFieldCommon name={field.name} label={field.label} fullWidth={true} gxs={12} multiline={field.multiline === true} />
                    </>
                ))}
                <TextFieldCommon name='contactInformation' label='Plan Contact Information' fullWidth={true} gxs={12} multiline={true} />
                <TextFieldCommon name='additionalNotes' label='Additional Notes' fullWidth={true} gxs={12} multiline={true}
                    placeholder='Provide any additional notes that will be helpful to capture insurance information'
                />
            </Grid>

            {!isAddCard && (
                <>
                    <Grid item xs={12} sx={{ marginTop: '20px' }}>
                        <Button
                            color="primary"
                            variant='text'
                            fullWidth
                            onClick={() => setConfirmAndDelete(true)}
                            sx={{
                                textTransform: 'none'
                            }}
                        >
                            Delete this insurance card
                        </Button>
                    </Grid>
                </>
            )}

            <AlertDialog
                id='insurance_card_confirmation_dialog'
                type={'YesNo' as const}
                open={confirmAndDelete}
                button1='Yes'
                button2='Cancel'
                button2ErrorButton={true}
                button2Variant={'outlined' as const}
                title='Delete Insurance Card'
                description='Are you sure you want to delete this card?'
                onClick={(e: any, index: number) => {
                    setConfirmAndDelete(false);
                    if (index !== 0) {
                        return;
                    }

                    // confirmed, mark as deleted and save
                    source.markAsDeleted();
                    source.save();
                }}
            />
        </Box>
    );
};

export default InsuranceForm;
