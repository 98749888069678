import { JSX } from 'solid-js';

import { BoundDataSource } from '../../types.ts';
import { BoundSourceInternal as BoundSource } from './_internal/bound.source.internal.tsx';
import { BoundSourceProps } from './bound.source.props.ts';

export { BoundSourceInternal as BoundSource } from './_internal/bound.source.internal.tsx';

export function createBoundSource<T extends BoundDataSource>
    (
        props: BoundSourceProps<T>
    ): JSX.Element

export function createBoundSource<T extends BoundDataSource,
    K1 extends string | number | undefined = undefined,
    K2 extends string | number | undefined = undefined,
    K3 extends string | number | undefined = undefined,
    K4 extends string | number | undefined = undefined,
    K5 extends string | number | undefined = undefined>
    (
        props: BoundSourceProps<T, K1, K2, K3, K4, K5>
    ): JSX.Element

export function createBoundSource(...args: any[]): JSX.Element {
    return BoundSource(args[0]);
}
