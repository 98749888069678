export abstract class BaseEnvVars {
  private source: { get: (key: string) => string | undefined } | undefined = undefined;

  public get(key: string): string | undefined {
    if (this.source === undefined) {
      this.source = this.getSource();
    }
    return this.source.get(key);
  }

  protected init(): { get: (key: string) => string | undefined } | null {
    return null;
  }

  private getSource(): { get: (key: string) => string | undefined } {
    let env: { get: (key: string) => string | undefined } | null = null;
    try {
      env = this.init();
      if (!env) {
        env = {
          get: () => {
            throw new Error('Env is not available');
          },
        };
      }
    } catch (error) {
      console.error('Error while retrieving env', error);
      env = {
        get: () => {
          throw new Error('Env is not available: ' + error);
        },
      };
    }

    return env;
  }
}
