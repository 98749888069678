

export default function RelationshipIcon() {
    return (
<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.899 19.6981H3.19978C2.61636 19.6981 2.05684 19.4663 1.6443 19.0538C1.23176 18.6413 1 18.0817 1 17.4983V3.19978C1 1.9899 1.9899 1 3.19978 1H7.52234C7.88466 1.00187 8.2409 1.09322 8.55942 1.26591C8.87795 1.4386 9.14888 1.6873 9.34815 1.9899L10.2501 3.30977C10.4493 3.61237 10.7203 3.86106 11.0388 4.03376C11.3573 4.20645 11.7136 4.29779 12.0759 4.29967H20.798C21.3814 4.29967 21.9409 4.53143 22.3535 4.94396C22.766 5.3565 22.9978 5.91603 22.9978 6.49944V8.14927" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.2168 12.7688C21.8421 12.3959 21.3651 12.1425 20.8463 12.0406C20.3274 11.9388 19.7901 11.9932 19.3021 12.1969C18.9722 12.3288 18.6752 12.5268 18.4222 12.7798L18.0483 13.1538L17.6633 12.7798C17.2897 12.405 16.8133 12.1495 16.2945 12.0458C15.7756 11.942 15.2376 11.9946 14.7486 12.1969C14.4186 12.3288 14.1327 12.5268 13.8797 12.7798C12.8348 13.8137 12.7798 15.5625 14.0997 16.8934L18.0483 20.798L22.0079 16.8934C23.3277 15.5625 23.2617 13.8137 22.2168 12.7798V12.7688Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
}
