import { type ContactsDetail } from '@app/entities/contacts.ts';
import { type InsuranceDetail } from '@app/entities/insurance.detail.ts';
import { type MedicalInfo } from '@app/entities/medical.info.ts';
import { type MedicationDetail } from '@app/entities/medication.detail.ts';
import { type Profile } from '@app/entities/profile.ts';
import { type Resource } from "@app/resources/mod.ts";
import { ServiceName } from '@libs/services/service.ts';

export interface CareCard {
    get isNew(): boolean;

    get profile(): Resource<Profile>;
    get medications(): Resource<MedicationDetail>;
    get medicalInfo(): Resource<MedicalInfo>;
    get contactsDetail(): Resource<ContactsDetail>;
    get insuranceDetails(): Resource<InsuranceDetail>;

    get cardInfo(): Resource<CardInfo>;
    get cardId(): string;
}

export type ImageDataUrl = string;

export type CardInfo = {
    readonly cardId: string;
    readonly firstName: string;
    readonly name: string;
    readonly age: string;
    readonly cardTheme: string;
    readonly imageUrl?: string;
    updateImage: (image: Blob) => Promise<ImageDataUrl>;
};

export interface CardService {
    ready(): Promise<void>;
    readonly currentCard: CareCard;
    delete(card: CareCard): Promise<void>;
    createNewCard(): CareCard;
}

export interface CardSelectorService {
    readonly currentView: Resource<CardInfo> | undefined;
    readonly currentViewIndex: number;
    get cards(): Resource<CardInfo>[];
    select: (cardId: string) => void;
}

export const CARD_SERVICE = ServiceName.create<CardService>('CARD_SERVICE');
export const CARD_SERVICE_SELECTOR = ServiceName.create<CardSelectorService>('CARD_SERVICE_SELECTOR');
