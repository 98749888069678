//TODO: implement photo management
import { careCardInteriorPhotoStyles } from '@app/assets/theme';

import { Box, Card, SvgIcon } from '@suid/material';

const CareCardInteriorPhoto = ({ imageUrl }: { imageUrl: () => string | undefined }) => {
    return (
        <>
            <Box sx={careCardInteriorPhotoStyles.careCardPhotoInteriorContainer}>
                <Card sx={careCardInteriorPhotoStyles.careCardInteriorPhotoFrame}>
                    {imageUrl() ? (
                        <img
                            src={imageUrl()}
                            alt="Care Card"
                            style={{
                                "width": "100%",
                                "height": "100%",
                                "object-fit": "cover",
                                "object-position": "center"
                            }}
                        />) :
                        (<SvgIcon
                            id='careCardPhoto'
                            sx={{
                                width: { xs: '120px', md: '122px' },
                                margin: '10px',
                                height: { xs: '120px', md: '122px' },
                            }}
                            width='120'
                            height='120'
                            viewBox='0 0 210 210'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M105 45C97.5833 45 90.3331 47.1994 84.1662 51.3199C77.9994 55.4405 73.1929 61.2972 70.3546 68.1494C67.5163 75.0016 66.7737 82.5416 68.2207 89.8159C69.6676 97.0902 73.2391 103.772 78.4836 109.017C83.7281 114.261 90.4099 117.833 97.6842 119.279C104.959 120.726 112.499 119.984 119.351 117.145C126.203 114.307 132.06 109.501 136.18 103.334C140.301 97.1671 142.5 89.9168 142.5 82.5C142.5 72.5544 138.549 63.0161 131.517 55.9835C124.484 48.9509 114.946 45 105 45Z'
                                fill='#FFFFFF'
                                fill-opacity='0.30'
                            />
                            <path
                                d='M105 0C84.233 0 63.9323 6.15814 46.6652 17.6957C29.398 29.2332 15.9399 45.632 7.99269 64.8182C0.0454951 84.0045 -2.03385 105.116 2.01759 125.484C6.06904 145.852 16.0693 164.562 30.7538 179.246C45.4383 193.931 64.1475 203.931 84.5155 207.982C104.884 212.034 125.996 209.955 145.182 202.007C164.368 194.06 180.767 180.602 192.304 163.335C203.842 146.068 210 125.767 210 105C209.969 77.1619 198.896 50.4729 179.212 30.7884C159.527 11.1039 132.838 0.0313642 105 0ZM164.944 171.943C164.795 162.105 160.786 152.72 153.782 145.809C146.778 138.899 137.339 135.017 127.5 135H82.5C72.6608 135.017 63.222 138.899 56.218 145.809C49.2139 152.72 45.2054 162.105 45.0563 171.943C31.4555 159.799 21.8643 143.81 17.5526 126.093C13.2408 108.377 14.412 89.7686 20.9111 72.7324C27.4101 55.6963 38.9304 41.036 53.9466 30.6927C68.9627 20.3495 86.7663 14.8112 105 14.8112C123.234 14.8112 141.037 20.3495 156.053 30.6927C171.07 41.036 182.59 55.6963 189.089 72.7324C195.588 89.7686 196.759 108.377 192.447 126.093C188.136 143.81 178.545 159.799 164.944 171.943Z'
                                fill='#FFFFFF'
                                fill-opacity='0.30'
                            />
                        </SvgIcon>
                        )}

                </Card>
            </Box>
        </>
    );
};
export default CareCardInteriorPhoto;
