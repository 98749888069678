import { ListEmptyState } from '@app/pages/helpers/commonHelpers';
import ContactList from '@app/pages/contacts/components/ContactList';
import ContactsIcon from '@suid/icons-material/ContactsRounded';
import { useNavigateToEdit } from '@app/routes/hooks';
import ActionBar from '@app/components/ActionBar';
import AddIcon from '@suid/icons-material/Add';
import { svc } from '@app/utils/svc';
import { createBoundSource, BoundSourceProps } from '@app/data/components/BoundSource';
import { ContactsDetail } from '@app/entities/contacts';

const boundPath = 'contacts';
const BoundSource = (props: BoundSourceProps<ContactsDetail, typeof boundPath>) => createBoundSource(props);

const ContactsView = () => {
    const editSection = useNavigateToEdit();
    const contact = svc.card.contactsDetail;

    return (
        <>
            <BoundSource source={contact} path={boundPath}>{c =>
                c && c.length > 0 ? (
                    <ContactList
                        contacts={c}
                        onContactEditSelected={m => editSection(m._id)}
                    />
                ) : (
                    <ListEmptyState
                        emptyStateHeader="No Contacts"
                        emptyStateText="Add emergency contacts, but also think about those folks nearby who can lend a hand, like neighbors or friends. Don't forget preferred hospitals, pharmacies, as well as doctors and specialists. Tap the plus to add a care contact."
                        emptyStateIcon={() => <ContactsIcon style={{ height: '80px', width: '80px', color: "#d3d3d3" }} />}
                    />
                )
            }
            </BoundSource>
            <ActionBar
                actionIcon={<AddIcon fontSize="large" sx={{ fontSize: "40px" }} />}
                actionClick={() => editSection("Add Contact")}
            />
        </>
    );
};

export default ContactsView;
