import {
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    DialogContentText,
    Typography
} from '@suid/material';
import BackArrowIcon from '@suid/icons-material/ArrowBackIosRounded';
import CloseIcon from '@suid/icons-material/CloseOutlined';
import { ifCordovaApp, isCordovaApp } from '../host';
import { Drawerx, DrawerxProps, SwipeDirection } from './Drawerx';
import { JSX } from 'solid-js';
import { Func } from '@libs/types';

export type DrawerCommonProps = Omit<DrawerxProps, 'onClose' | 'children' | 'title' | 'open'> & {
    onClose?: () => void,
    children?: JSX.Element | (() => JSX.Element)
    height?: string,
    closeOnSwipe?: SwipeDirection | false,
    title?: string | JSX.Element | (() => string | JSX.Element)
    options?: {
        disableContentDivider?: (expected: boolean) => boolean,
        showClose?: (expected: boolean) => boolean,
        showBackArrow?: (expected: boolean) => boolean
    }
};

const DrawerCommon = (props: DrawerCommonProps) => {
    const title = props.title;
    const children = Func.of(props.children);
    const height = props.height;

    const localProps: Omit<typeof props, 'height' | 'title' | 'children'> & { open: boolean } = {
        ...props,
        open: true
    };

    delete (localProps as any).height;
    delete (localProps as any).title;
    delete (localProps as any).children;

    const fullscreenHeight = ifCordovaApp('95vh', '98vh');
    const drawerHeight = height || fullscreenHeight;
    const isBackdropRequired = isCordovaApp() || drawerHeight !== fullscreenHeight; // if the drawer height is less than fullscreen or Cordova the backdrop is required

    const options = {
        ...props.options ?? {},
    };

    const disableContentDivider = !options.disableContentDivider ?
        () => false :
        () => options.disableContentDivider!(false);

    const showClose = !options.showClose ?
        () => drawerHeight != fullscreenHeight :
        () => options.showClose!(drawerHeight != fullscreenHeight);

    const showBackArrow = !options.showBackArrow ?
        () => drawerHeight === fullscreenHeight :
        () => options.showBackArrow!(drawerHeight === fullscreenHeight);

    /*
    const { onClose, closeOnSwipe } = localProps;
    const closeOnDirectionSwipe =
        closeOnSwipe === false ?
            undefined :
            closeOnSwipe === undefined ?
                SwipeDirection.DOWN :
                closeOnSwipe;
    */

    return (
        <>
            <Drawerx
                {...localProps}
                anchor="bottom"
                BackdropProps={{ invisible: !isBackdropRequired }}
                sx={{
                    height: drawerHeight,
                    maxHeight: fullscreenHeight,
                    overflowY: 'auto',
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        maxHeight: fullscreenHeight,
                        height: 'inherit'
                    }
                }}
                onSwipe={undefined}
                /*onSwipe={closeOnDirectionSwipe ? (direction => {
                    if (direction === closeOnDirectionSwipe) {
                        onClose?.();
                    }
                }) : undefined} */
            >
                <div onClick={e => e.stopPropagation()}>
                    <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', "align-items": 'center', "padding-top": '5px', "justify-content": 'space-between' }}>
                            <div style={{ display: 'flex', "align-items": 'center' }}>
                                {
                                    showBackArrow() && (
                                        <BackArrowIcon fontSize='small' style={{ color: '#182325', cursor: 'pointer' }} onClick={props.onClose} />
                                    )
                                }
                                <Typography variant='h6' color='text.primary' style={{ "padding-left": '5px', cursor: 'pointer' }} onClick={props.onClose}>
                                    {typeof title === 'function' ? title() : title}
                                </Typography>
                            </div>
                            <div style={{ "margin-left": 'auto' }}>
                                <Typography variant='subtitle1' style={{ cursor: 'pointer' }}>
                                    {/* SAVE BUTTON HERE */}
                                </Typography>
                                {
                                    showClose() && (
                                        <div style={{ display: 'flex', "justify-content": 'flex-end' }}>
                                            <CloseIcon fontSize='large' style={{ color: '#979797', cursor: 'pointer', padding: 0 }} onClick={props.onClose} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </DialogTitle>
                    {!disableContentDivider() && <Divider />}
                    <DialogContent sx={{ marginBottom: '50px' }}>
                        <DialogContentText>
                            {
                                children()
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* Optional actions */}
                    </DialogActions>
                </div>

            </Drawerx>
        </>
    );
};

export default DrawerCommon;
