import { IconProps } from ".";

export default function ContactsIcon({ themeColorPrimary, themeColorSecondary }: IconProps) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 25.9725C0 18.4172 0 14.6396 1.76035 12.2924C3.5207 9.94531 6.35394 9.94531 12.0204 9.94531C17.6869 9.94531 20.5201 9.94531 22.2805 12.2924C24.0408 14.6396 24.0408 18.4172 24.0408 25.9725C24.0408 33.5278 24.0408 37.3055 22.2805 39.6526C20.5201 41.9998 17.6869 41.9998 12.0204 41.9998C6.35394 41.9998 3.5207 41.9998 1.76035 39.6526C0 37.3055 0 33.5278 0 25.9725Z" fill={themeColorPrimary} />
      <path d="M14.0095 16.0747C15.7854 9.92732 16.6733 6.85365 18.6634 5.35943C20.6534 3.86521 22.9683 4.53398 27.5982 5.8715C32.2281 7.20903 34.5431 7.8778 35.4297 10.203C36.3163 12.5283 35.4284 15.602 33.6525 21.7493C31.8766 27.8966 30.9887 30.9703 28.9986 32.4645C27.0086 33.9587 24.6936 33.29 20.0637 31.9525C15.4338 30.6149 13.1189 29.9462 12.2323 27.6209C11.3456 25.2957 12.2336 22.222 14.0095 16.0747Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M35.64 6.05027C31.1504 4.1409 26.3839 2.90178 21.4856 2.37059C19.057 2.10722 16.6916 3.5707 15.7691 5.90745L14.8959 8.11942C12.8784 13.2301 11.3172 18.4942 10.2313 23.8471L9.76142 26.1639C9.26493 28.6114 10.4857 31.0072 12.7117 31.9539C17.2013 33.8632 21.9677 35.1023 26.8661 35.6336C29.2948 35.897 31.6602 34.4335 32.5826 32.0966L33.4559 29.8847C35.4733 24.774 37.0345 19.5099 38.1204 14.157L38.5903 11.8402C39.0868 9.39273 37.866 6.99695 35.64 6.05027ZM22.4432 13.3093C22.9747 11.5173 24.8275 10.4342 26.5817 10.8902C28.3358 11.3463 29.327 13.1687 28.7955 14.9607C28.264 16.7526 26.4112 17.8358 24.657 17.3798C22.9028 16.9237 21.9117 15.1012 22.4432 13.3093ZM16.1395 23.029C16.9367 20.3409 19.716 18.7165 22.3472 19.4005L25.5233 20.2262C28.1546 20.9102 29.6413 23.6437 28.844 26.3318C28.5783 27.2278 27.6519 27.7694 26.7748 27.5413L17.2464 25.0643C16.3693 24.8363 15.8737 23.9251 16.1395 23.029Z" fill={themeColorSecondary} />
    </svg>
  );
}




