import { pageStrings } from "@app/defs/page.strings";
import { useLocation, useNavigate, useParams } from "@solidjs/router";

export const useNavigateToEdit = (defaultSection?: string) => {
    const loc = useLocation();
    const nav = useNavigate();
    return (section?: string) => {
        if (section) {
            nav(loc.pathname + '/' + section)
        } else if (defaultSection) {
            nav(loc.pathname + '/' + defaultSection)
        } else {
            throw new Error('No section provided');
        }
    };
};

export const useNavigateToSelect = () => {
    const params = useParams();
    const path = useLocation().pathname.replace('/' + params.section, '');
    const nav = useNavigate();
    return [() => nav(path), params.section?.replaceAll('%20', ' ')] as [view: () => void, section: string];
};

export const useNavigateToView = () => {
    const params = useParams();
    const path = useLocation().pathname.replace('/' + params.section, '');
    const nav = useNavigate();
    return [() => nav(path), params.section?.replaceAll('%20', ' ')] as [view: () => void, section: string];
};

export const useCurrentPageTitle = (route?: string) => {
    const pageType = (route ?? useLocation().pathname)?.split('/')[1] ?? '';
    if (pageType === 'profile') {
        return pageStrings.profileInteriorCaption;
    }

    if (pageType === 'medicalInfo') {
        return pageStrings.medicalInfoInteriorCaption;
    }

    if (pageType === 'meds') {
        return pageStrings.medsInteriorCaption;
    }

    if (pageType === 'contacts') {
        return pageStrings.contactsInteriorCaption;
    }

    if (pageType === 'wallet') {
        return pageStrings.walletInteriorCaption;
    }

    if (pageType === 'documents') {
        return pageStrings.documentsInteriorCaption;
    }

    return '';
};