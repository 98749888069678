import { Link } from '@solidjs/router';
import { Badge, Card, Grid, Typography } from '@suid/material';
import { JSX } from 'solid-js';

import { Icon } from '@app/assets/icons';
import { featureCardStyles, getThemeOrDefault } from '@app/assets/theme';
import { useBoundSource } from '../data/hooks';

export type Props = {
    caption: string | JSX.Element;
    icon: Icon;
    path: string;
    status: number | undefined;
};

export const FeatureCard = (feature: Props) => {
    const subject = useBoundSource<{ themeName: string }>();
    const theme = getThemeOrDefault(subject.get('themeName'));
    const CardIcon = feature.icon;
    return (
        <Grid item xs={6} md={4} sx={{ ':hover': { bgcolor: '#FFFFFF' } }}>
            <Card sx={featureCardStyles.featureCard} elevation={0}>
                <Link href={feature.path} style='text-decoration:none'>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Badge
                                color='error'
                                badgeContent={feature.status !== undefined ? Number(feature.status) : null}
                                sx={{ left: 6, mr: 1 }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            >
                                <CardIcon
                                    themeColorPrimary={theme.iconPrimary}
                                    themeColorSecondary={theme.iconSecondary}
                                />
                            </Badge>
                        </Grid>
                        <Grid item>
                            <Typography sx={featureCardStyles.featureCardText} color='text.primary'>
                                {feature.caption}
                            </Typography>
                        </Grid>
                    </Grid>
                </Link>
            </Card>
        </Grid>
    );
};

export default FeatureCard;
