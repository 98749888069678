import { xs } from "@libs/db/mod.ts";
import { TypeInfo } from "@app/entities/entity.types.ts";

const contactDef = xs.define("Contact", TypeInfo.CONTACT.type, {
    name: xs.string().nonempty(),
}, {
    isEmergencyContact: xs.boolean(),
    category: xs.string(),
    relationShip: xs.string(),
    categoryType: xs.string(),
    street1: xs.string(250),
    street2: xs.string(250),
    city: xs.string(),
    state: xs.string(),
    zipcode: xs.zipcode(),
    county: xs.string(),
    email: xs.email(),
    notes: xs.string(1000),
    phone1: xs.phone(),
    phone1_type: xs.string(20),
    phone2: xs.phone(),
    phone2_type: xs.string(20),
    phone3: xs.phone(),
    phone3_type: xs.string(20),
    phone4: xs.phone(),
    phone4_type: xs.string(20)
});

const def = xs.define("ContactsDetail", TypeInfo.CONTACTS_DETAIL.type, {
    contacts: xs.array(contactDef.schemaDef).default([])
});

export const Contact = xs.EntityFactory<Contact>(contactDef, TypeInfo.CONTACT.version, migrateContact);

export const ContactsDetail = xs.EntityFactory<ContactsDetail>(def, TypeInfo.CONTACT.version);

export interface Contact extends xs.inferType<typeof contactDef.schemaDef> { __type: typeof TypeInfo.CONTACT.type }

export interface ContactsDetail extends xs.inferType<typeof def.schemaDef> {
    __type: typeof TypeInfo.CONTACTS_DETAIL.type,
    contacts: Contact[]
}

function migrateContact(entity: Contact, isNew: boolean) {
    migrateContactv1(entity, isNew);
}

function migrateContactv1(entity: Contact, isNew: boolean) {
    if (isNew || entity.__scid === TypeInfo.CONTACT.version.value) {
        return;
    }

    const legacy = entity as any;
    if (!legacy.firstName && !legacy.lastName) {
        return;
    }

    if (!entity.name) {
        entity.name = `${legacy.firstName} ${legacy.lastName}`;
    }

    delete legacy.firstName;
    delete legacy.lastName;
}
