import BloodTypeSelect from "@app/pages/profile/components/BloodTypeSelect";
import RelationshipSelect from "@app/components/RelationshipSelect";
import { JSX } from "solid-js";
import PhoneNumbersByIndex from "./components/ProfilePhoneNumbers";

export interface Field {
    name: string;
    label: string;
    fullWidth: boolean;
    autoComplete?: string;
    gxs: number;
    mode?: string;
    endAdornmentValue?: string;
    multiline?: boolean;
    placeholder?: string;
    shrink?: boolean;
    component?: (props: any) => JSX.Element;
}

export const basicInfoFields: Field[] = [
    { name: 'firstName', label: 'First Name', fullWidth: true, autoComplete: 'given-name', gxs: 12 },
    { name: 'lastName', label: 'Last Name', fullWidth: true, autoComplete: 'family-name', gxs: 12 },
    { name: 'dob', label: 'Date of Birth', fullWidth: true, gxs: 12, mode: 'date', shrink: true },
    { name: 'relationShip', label: 'Relationship to You', fullWidth: true, gxs: 12, component: RelationshipSelect },
];

export const personalDetailsFields: Field[] = [
    { name: 'heightFt', label: 'Height', fullWidth: true, gxs: 4, mode: 'number', endAdornmentValue: 'ft' },
    { name: 'heightIn', label: 'Height', fullWidth: true, gxs: 4, mode: 'number', endAdornmentValue: 'in' },
    { name: 'weight', label: 'Weight', fullWidth: true, gxs: 4, mode: 'number', endAdornmentValue: 'lbs' },
    { name: 'blood_type', label: 'Blood Type', fullWidth: true, gxs: 12, component: BloodTypeSelect }
];

export const contactInfoFields: Field[] = [
    { name: 'email', label: 'Email Address', fullWidth: true, gxs: 12 },
    { name: 'phone', label: 'Phone Numbers', fullWidth: true, gxs: 12, component: PhoneNumbersByIndex },
    { name: 'street1', label: 'Street Address', fullWidth: true, gxs: 12 },
    { name: 'street2', label: 'Street Address Line 2', fullWidth: true, gxs: 12 },
    { name: 'city', label: 'City', fullWidth: true, gxs: 5 },
    { name: 'state', label: 'State', fullWidth: true, gxs: 3 },
    { name: 'zipcode', label: 'Zip', fullWidth: true, autoComplete: 'postal-code', gxs: 4 },
    { name: 'county', label: 'County', fullWidth: true, gxs: 12 }
];

export const profileNotesFields: Field[] = [
    { name: 'notes', label: 'Additional Notes', fullWidth: true, multiline: true, gxs: 12 },
];
