import { Resource, createEffect, createSignal } from 'solid-js';
import {
    Box,
    Card,
    Link,
    Theme
} from '@suid/material';
import { routeStrings } from '@app/routes/route.strings';

import { careCardPhotoStyles } from '@app/assets/theme';
import { arrayUtils } from '@libs/utils/arrays';
import { svc } from '@app/utils/svc';
import { Lazy } from '@libs/utils/lazy';
import { CardInfo } from 'shared/card-service/types';
import { SxProps } from '@suid/system';
import { careCardPhotoElement } from '@app/components/CareCardPhoto';

interface TouchPosition {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
}

type CardSelectorProps = {
    onCardSlected: (cardId: string) => void,
    cards: () => Resource<CardInfo>[],
    onSelectorNav: (card: CardInfo) => void
}

const CareCardSelector = (props: CardSelectorProps) => {
    const cardImages = arrayUtils.indexNavigator(Lazy.of(props.cards), createSignal(svc.cardSelector.currentViewIndex));

    createEffect(() => {
        const card = cardImages.current();
        if (!card) {
            return;
        }

        props.onSelectorNav(card()!);
    });

    const moveCard = (direction: 'next' | 'previous') => {
        if (direction === 'next') {
            cardImages.moveNext();
        } else if (direction === 'previous') {
            cardImages.movePrevious();
        }
    };

    const getCardImage = (type: 'next' | 'previous' | 'current') => {
        if (type === 'next') {
            return cardImages.next()?.()?.imageUrl;
        } else if (type === 'previous') {
            return cardImages.previous()?.()?.imageUrl;
        }

        return cardImages.current()?.()?.imageUrl;
    };

    const [touchPosition, setTouchPosition] = createSignal<TouchPosition>({ startX: 0, startY: 0, endX: 0, endY: 0 });
    const [touchStartTime, setTouchStartTime] = createSignal<number>(0); // Correctly declare the signal

    const isTapGesture = () => {
        const duration = Date.now() - touchStartTime();
        const { startX, endX, startY, endY } = touchPosition();
        const distanceX = Math.abs(startX - endX);
        const distanceY = Math.abs(startY - endY);
        const maxDistance = 10;
        const maxDuration = 300;

        return duration < maxDuration && distanceX < maxDistance && distanceY < maxDistance;
    };

    const handleTouchStart = (event: TouchEvent) => {
        const touch = event.touches[0];
        setTouchPosition({ startX: touch.clientX, startY: touch.clientY, endX: touch.clientX, endY: touch.clientY });
        setTouchStartTime(Date.now());
    };

    const handleTouchMove = (event: TouchEvent) => {
        const touch = event.touches[0];
        setTouchPosition({ ...touchPosition(), endX: touch.clientX, endY: touch.clientY });
        // Removed event.preventDefault();
    };

    const handleTouchEnd = (event: TouchEvent) => {
        if (isTapGesture()) {
            event.stopPropagation();
        } else {
            const { startX, endX } = touchPosition();
            if (startX - endX > 50) {
                cardImages.moveNext();
            } else if (startX - endX < -50) {
                cardImages.movePrevious();
            }
        }
    };

    const Pagination = ({ isActive }: {
        isActive: boolean;
    }) => (
        <span
            style={{
                height: '10px',
                width: '10px',
                margin: '0 5px',
                "background-color": isActive ? '#DFD3A6' : '#FFFFFF',
                "border-radius": '50%',
                display: 'inline-block'
            }}
        />
    );

    const createPhotoCard = (type: 'current' | 'next' | 'previous', sx: SxProps<Theme>) => {
        const imageUrl = getCardImage(type);
        if (!imageUrl) {
            return null;
        }

        const onClick = type === 'current' ? () => { } : () => moveCard(type);
        return (
            <Card sx={sx} onClick={onClick}>
                {careCardPhotoElement(imageUrl)}
            </Card>
        );
    };
    const hasMinCards = (minCount: number) => {
        const allCards = props.cards();
        return allCards.length >= minCount;
    };
    return (
        <>
            <div style={{ width: '100%' }}>
                <Box sx={careCardPhotoStyles.careCardPhotoContainer} onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}>
                    {hasMinCards(2) && createPhotoCard('previous', careCardPhotoStyles.smallCardBack)}
                    <Link
                        href={`#${routeStrings.cardHomePath}`} sx={careCardPhotoStyles.link}
                        onClick={() => {
                            const currentCard = cardImages.current()?.();
                            if (currentCard) {
                                props.onCardSlected(currentCard.cardId);
                            }
                        }}
                    >
                        {createPhotoCard('current', careCardPhotoStyles.careCardPhotoFrame)}
                    </Link>
                    {hasMinCards(3) && createPhotoCard('next', careCardPhotoStyles.smallCardNext)}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px',
                        marginTop: '20px'
                    }}
                >
                    {cardImages.map(isActive => (
                        <Pagination isActive={isActive} />
                    ))}
                </Box>
            </div>
        </>
    );
};

export default CareCardSelector;

