import { Accessor, JSX, Resource } from "solid-js";

export type ActionViewType = 'emergency-personnel' | 'family-member-caregiver' | 'doctor-specialist' | 'financial-representative';
export const ActionViewType = Object.freeze({
    EMERGENCY_PERSONNEL: 'emergency-personnel' as ActionViewType,
    FAMILY_MEMBER_CAREGIVER: 'family-member-caregiver' as ActionViewType,
    DOCTOR_SPECIALIST: 'doctor-specialist' as ActionViewType,
    FINANCIAL_REPRESENTATIVE: 'financial-representative' as ActionViewType
});

export type ShareActionView = {
    type: ActionViewType;
    name: string;
    component: (sub?: Resource<SubjectView> | Accessor<SubjectView>) => JSX.Element;
    ttlMinutes: number;
}

export type SubjectView = ContactView & {
    __relId: string;
    viewType: ActionViewType;
    dob?: string;
    age?: string;
    heightFt?: string;
    heightIn?: string;
    weight?: string;
    bloodType?: string;
    medications?: string[];
    medicalConditions?: string[];
    surgeries?: string[];
    drugAllergies?: string[];
    otherAllergies?: string[];
    immunizations?: string[];
    emergencyContacts?: ContactView[];
    insuranceInfo?: InsuranceCardView[];
};

export type AddressView = {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    county?: string;
};

export type ContactView = {
    name: string;
    relationShip?: string;
    isEmergencyContact?: boolean;
    email?: string;
    notes?: string;
    address?: AddressView;
    phone1?: {
        num?: string;
        type?: string;
    }
    phone2?: {
        num?: string;
        type?: string;
    }
    phone3?: {
        num?: string;
        type?: string;
    }
    phone4?: {
        num?: string;
        type?: string;
    }
}

export type InsuranceCardView = {
    insuranceType?: string,
    responsibleParty?: string,
    insurerName?: string,
    memberID?: string,
    groupNumber?: string,
    copay?: string,
    deductible?: string,
    coIns?: string,
    planNumber?: string,
    additionalNotes?: string
    contactInformation?: string
    planType?: string
    payerID?: string
    network?: string
    effectiveDate?: string
    dependents?: string
    rxName?: string
    rxID?: string
    rxBIN?: string
    rxGRP?: string
    rxPCN?: string
    rxNetwork?: string
    rxFormulary?: string
    rxCopay?: string
}