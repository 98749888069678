import { DisconnectListener } from '@libs/utils/event.ts';
import { ServiceName } from '@libs/services/service.ts';
import { User } from "./user.ts";
import { IDENTITY_SERVICE, type AuthIdentityService } from '@app/identity/identity.ts';

export const AUTH_SERVICE = IDENTITY_SERVICE as ServiceName<AuthService>;

export interface AuthService extends AuthIdentityService {
    navigateUserExternalLogin: (type: 'sms' | 'email' | 'user_password') => void;
    handleExternalLoginCallback: (onComplete: (err: any) => void) => void;
    invokeExternalLogout: () => void
    logoutSilently: () => void;
    refreshToken(): Promise<string>;
    onLoginLogout: (listener: (isAuthenticated: boolean) => void, once?: boolean) => DisconnectListener;
    currentUser: () => User;
}
