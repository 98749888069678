import { globalSelf } from '@libs/utils/globalSelf.ts';
import { BaseEnvVars } from './baseEnvVars.ts';

declare const Deno: any;

export const CFG_PREFIX = 'APP_CFG_';

const gSelf = globalSelf as any;

class EnvVars extends BaseEnvVars {
    #getter: { get: (key: string) => string | undefined } | undefined;

    protected init(): { get: (key: string) => string | undefined } {
        if (this.#getter) {
            return this.#getter;
        }

        const getter = this.resolveLookup();
        if (!getter) {
            throw new Error('Unable to resolve environment lookup');
        }

        return {
            get: (key: string) => {
                if (key.startsWith(CFG_PREFIX)) {
                    return getter.get(key) ?? getter.get(key.substring(CFG_PREFIX.length));
                }

                return getter.get(CFG_PREFIX + key) ?? getter.get(key);
            },
        };
    }

    private resolveLookup() {
        try {
            return this.#getter = this.wrapGetter(Deno.env);
        } catch {
            const env = (import.meta as any).env;
            return this.#getter = this.wrapGetter({
                get: (key: string) => {
                    return env[key];
                },
            });
        }
    }

    private wrapGetter(getter: { get: (key: string) => string | undefined }) {
        if (!gSelf.__ENV_VARS_APP_CFG ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'string' ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'function' ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'number' ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'boolean' ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'symbol' ||
            typeof gSelf.__ENV_VARS_APP_CFG === 'bigint') {

            return getter;
        }

        return {
            get: (key: string) => {
                return getter.get(key) ?? gSelf.__ENV_VARS_APP_CFG[key];
            },
        };
    }
}

export const envVars = Object.seal(new EnvVars());
