import { For, Show, createSignal } from 'solid-js';
import { Avatar, Box, Grid, IconButton, InputAdornment, List, ListItem, TextField } from '@suid/material';

import { useIsReadOnlyMode } from '@app/utils/hooks.ts';
import { useBoundProperty } from '@app/data/bound.property';
import { usePageContext } from '@app/data/hooks';
import { ulid } from '@std/ulid';

import AddIcon from '@suid/icons-material/Add';
import RemoveIcon from '@suid/icons-material/Clear';

export type Props = {
    name: string;
    label: string;
    placeholder?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    alias?: string;
    autoComplete?: string;
    type?: string;
    mode?: string;
    endAdornmentValue?: string;
    shrink?: boolean;
    gxs?: number;
};

interface Value {
    id: string;
    name: string;
}

export const MedicalInfoListEditor = (
    { name, mode, label, shrink, placeholder, alias, fullWidth = false, multiline = false, autoComplete, gxs }: Props,
) => {

    mode = mode ?? 'text';
    const pageContext = usePageContext();
    const isReadonly = useIsReadOnlyMode();
    const source = useBoundProperty<Value[]>(alias ?? name, {
        validate: false
    });

    const initialValue: Value[] = source.value
    const [formValue, setFormValue] = createSignal("");
    const [values, setValues] = createSignal<Value[]>(initialValue);

    const removeValue = (index: number) => {
        if (isReadonly()) {
            return
        }
        setValues([...values().slice(0, index), ...values().slice(index + 1)]);
        source.value = values();
    };

    const onAddAction = () => {
        const id = ulid();
        const newValue: Value = { id: id, name: formValue() };
        if (newValue.name && !values().some(v => v.name === newValue.name)) {
            const updatedValues = [...values(), newValue];
            setValues(updatedValues); // Update the state with the new value
            source.value = updatedValues; // Update the source value with the entire list
            setFormValue(""); // Reset the form value
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents the default action of the enter key
            onAddAction();
        }
    };

    const textField = (
        <Box
            sx={{
                width: '100%',
                marginTop: '8px',
            }}
        >
            <TextField
                required={source.isRequired()}
                type={mode}
                error={pageContext.displayValidationErrors() && source.hasError()}
                helperText={pageContext.displayValidationErrors() && source.errorMessage}
                name={name}
                autoComplete={autoComplete}
                onChange={(e) => setFormValue(e.target.value)}
                onKeyDown={handleKeyDown}
                value={formValue()}
                multiline={multiline}
                variant='filled'
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '10px',
                        border: '1px solid #E0E0E0',
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" >
                            <IconButton onClick={onAddAction} >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    readOnly: isReadonly()
                }}
                InputLabelProps={{
                    shrink: shrink,
                }}
            />
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Show
                    when={values().length > 0}
                >
                    <For each={values()}>
                        {
                            (item, index) =>
                                <ListItem
                                    divider
                                    sx={{ paddingLeft: 0, paddingRight: 0 }}

                                >
                                    <ListItem
                                       sx={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div>
                                            {item.name}
                                        </div>
                                        <Avatar
                                            variant='circular'
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                backgroundColor: '#EA7D7D',
                                                color: '#FFFFFF',
                                                cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                                e.stopImmediatePropagation();
                                                removeValue(index())
                                            }}
                                        >
                                            <RemoveIcon fontSize='small' />
                                        </Avatar>
                                    </ListItem>
                                </ListItem>
                        }
                    </For>
                </Show>
            </List>
        </Box>
    );

    if (!gxs) {
        return textField;
    }

    return (
        <Grid item xs={gxs}>
            {textField}
        </Grid>
    );
};

export default MedicalInfoListEditor;
