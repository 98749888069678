import { EntityCore, Identifier } from "@libs/db/mod.ts";
import { Validator } from "@libs/validators";
import { Accessor, Signal, createSignal } from "solid-js";
import { createMutableArray, createMutableEntity } from "./_internal/mutable.factory";
import { SignalRef, mutableSymbol } from "./_internal/common";

export type Entity<TYPE extends number = number, T = object> = EntityCore<TYPE, T> & {
    [mutableSymbol]?: MutableEntity<EntityCore<TYPE, T>>;
};

export type MutableEntity<T extends EntityCore> = {
    source: T;
    unwrap: () => T;
    hasChanges: Accessor<boolean>;
    isValid: Accessor<boolean>;
    validator: (name: string) => Validator;
    isRequired: (name: string) => boolean;
    save: () => Promise<T>;
    untrack: () => T;
    reset: () => void;
};

export type SaveLifecyleHandler = {
    onBeforeSave: () => void;
    onAfterSave?: never;
} | {
    onBeforeSave: () => void;
    onAfterSave?: never;
} | {
    onBeforeSave: () => void;
    onAfterSave: (error: any) => void;
}

export type OwningArrayInfo<P extends EntityCore = EntityCore, K extends keyof P = any> = {
    parent: P, propName: K, id: Identifier
}

export const MutableEntity = {
    track: <T extends EntityCore, P extends EntityCore = EntityCore, K extends keyof P = any>(
        source: T | OwningArrayInfo<P, K>,
        changeSignal?: Signal<boolean>,
        isValidSignal?: Signal<boolean>,
        onSave?: (() => void) | SaveLifecyleHandler
    ): MutableEntity<T> => {

        if (source === undefined || source === null) {
            throw new Error('Cannot create mutable entity from undefined or null');
        }

        const signal = changeSignal ?? createSignal<boolean>(false)
        const validSignal = isValidSignal ?? createSignal<boolean>(true)

        if ('parent' in source) {
            const { parent, propName, id } = source as OwningArrayInfo<P, K>;
            return createMutableArray(parent, propName, id, SignalRef.from(signal), SignalRef.from(validSignal), onSave);
        }

        return createMutableEntity(source, SignalRef.from(signal), SignalRef.from(validSignal), onSave);
    },
    untrack: <T extends EntityCore>(entity: T): T => {
        if (!entity) {
            return entity;
        }

        const tracker = (entity as any)[mutableSymbol];
        if (tracker === undefined) {
            return entity;
        }

        return (tracker as any).untrack();
    },
    as<T extends EntityCore = EntityCore>(obj: any): MutableEntity<T> | undefined {
        return obj[mutableSymbol];
    }
}