import { JSX, Match, Switch, createResource } from 'solid-js';

import { InferType } from '@libs/types/index.ts';
import { objects, strings } from '@rdt-utils';
import { CircularIndeterminate } from '@app/components/CircularIndeterminate';
import { BoundDataSource, BoundSourceContext } from '@app/data/types';
import { BoundSourceProps } from '../bound.source.props';

export function BoundSourceInternal<T extends BoundDataSource = BoundDataSource,
    K1 extends string | number | undefined = undefined,
    K2 extends string | number | undefined = undefined,
    K3 extends string | number | undefined = undefined,
    K4 extends string | number | undefined = undefined,
    K5 extends string | number | undefined = undefined>
    (
        props: BoundSourceProps<T, K1, K2, K3, K4, K5>
    ) {

    const resource = 'state' in props.source ? createResource(props.source, s => {
        return resolve(s, props.path);
    })[0] : createResource(props.source, s => {
        if (s.error) {
            throw s.error;
        }

        if (!s.value) {
            throw new Error('No value for bound source');
        }

        return resolve(s.value, props.path);
    })[0];

    const children = (): JSX.Element => {
        const c = props.children;
        if (typeof c !== 'function') {
            return c === undefined ? <></> : c;
        }

        const r = resource()!;
        return (c as any)(r.value, r.parent);
    }

    const errorComponent = props.errorComponent ?? (() => null);

    const fallback = props.fallback === null ? undefined : (props.fallback ?? <CircularIndeterminate />);
    return (
        <BoundSourceContext.Provider value={resource as any}>
            <Switch fallback={fallback}>
                <Match when={resource() && resource.state === "ready"}>
                    {children()}
                </Match>
                <Match when={resource.state === "errored"}>
                    {errorComponent()}
                </Match>
            </Switch>
        </BoundSourceContext.Provider>
    );
}

type ParentValueTuple<T extends BoundDataSource = BoundDataSource, K1 extends string | number | undefined = undefined,
    K2 extends string | number | undefined = undefined,
    K3 extends string | number | undefined = undefined,
    K4 extends string | number | undefined = undefined,
    K5 extends string | number | undefined = undefined> = { value: InferType<T, K1, K2, K3, K4, K5>, parent: InferType<T, K1, K2, K3, K4> | undefined };

const resolve = <T extends BoundDataSource = BoundDataSource, K1 extends string | number | undefined = undefined,
    K2 extends string | number | undefined = undefined,
    K3 extends string | number | undefined = undefined,
    K4 extends string | number | undefined = undefined,
    K5 extends string | number | undefined = undefined>
    (
        value: any,
        path: string | undefined
    ): ParentValueTuple<T, K1, K2, K3, K4, K5> => {

    if (strings.isNilOrEmpty(path)) {
        return { value, parent: undefined };
    }

    const parts = (path as string).split('.');
    if (parts.length === 0) {
        return { value, parent: undefined };
    }

    return parts.reduce<{ value: any, parent: any }>((obj, prop, i) => {
        if (objects.isNil(obj.value)) {
            return obj;
        }

        obj.parent = obj.value;
        obj.value = obj.parent[prop === '$' + i ? i : prop]

        return obj;
    }, { value: value, parent: undefined });
}

