import { Grid, Box } from '@suid/material';

import MedicalInfoListEditor from '@app/pages/medical-info/components/MedicalInfoListEditor';

import { layoutBodyStyles } from '@app/assets/theme';

interface MedicalInfoFormProps {
    type: 'MedicalConditions' | 'DrugAllergies' | 'OtherAllergies' | 'Surgeries' | 'Immunizations';
}

const MedicalInfoForm = (props: MedicalInfoFormProps) => {
    const getFieldLabel = () => {
        switch (props.type) {
            case 'MedicalConditions': return 'Medical Conditions';
            case 'DrugAllergies': return 'Drug Allergies';
            case 'OtherAllergies': return 'Other Allergies';
            case 'Surgeries': return 'Surgeries';
            case 'Immunizations': return 'Immunizations';
            default: return '';
        }
    };

    const mapTypeToFieldName = (type: string) => {
        switch (type) {
            case 'MedicalConditions': return 'medicalConditions';
            case 'DrugAllergies': return 'drugAllergies';
            case 'OtherAllergies': return 'otherAllergies';
            case 'Surgeries': return 'surgeries';
            case 'Immunizations': return 'immunizations';
            default: return '';
        }
    };

    return (
        <Box sx={layoutBodyStyles.formBox}>
            <Grid container spacing={{ xs: 1, md: 1 }}>
                <MedicalInfoListEditor name={mapTypeToFieldName(props.type)} label={`Add ${getFieldLabel()}`} fullWidth={true} gxs={12} />
            </Grid>
        </Box>
    );
};

export default MedicalInfoForm;
