import { ServiceName } from "@libs/services/service.ts";

export type UserIdentity = {
    id: string;
    email?: string;
    phone?: string;
};

export type AuthIdentityService = {
    isAuthenticated: () => boolean;
    getToken: () => Promise<string>;
    currentUser: () => UserIdentity;
}

export const IDENTITY_SERVICE = ServiceName.create<AuthIdentityService>('authIdentityService');