import * as h from '@app/pages/helpers/commonHelpers.tsx';

import MedicationIcon from '@app/assets/icons/MedicationIcon';
import CalendarIcon from '@app/assets/icons/CalendarIcon';
import NotesIcon from '@app/assets/icons/NotesIcon';
import MedBottleIcon from '@app/assets/icons/MedBottleIcon';
import ClipboardIcon from '@app/assets/icons/ClipboardIcon';
import MedicalConditionIcon from '@app/assets/icons/MedicalConditionIcon';
import { Medication } from '@app/entities/medication.detail';
import { findFrequency, formatDate, getFrequencyLabel } from './medHelpers';
import ListEditDetailsDrawer from '@app/components/ListEditDetailsDrawer';

interface MedicationListProps {
    medications: Medication[];
    onMedicationEditSelected: (medication: Medication) => void;
}

const MedicationList = ({ medications, onMedicationEditSelected }: MedicationListProps) => {
    return (
        <>
            <ListEditDetailsDrawer
                title={(item) => item.name || 'Medication Details'}
                onEditSelected={onMedicationEditSelected}
                listItems={() => listItems(medications)}
            >{m => (
                <>
                    <h.Attribute name={m.dosage} contentIcon={<MedBottleIcon />} />
                    <h.Attribute name={findFrequency(m.frequency ?? '') ?? ''} contentIcon={<ClipboardIcon />} />
                    <h.Attribute name={m.reason} contentIcon={<MedicalConditionIcon />} />
                    <h.Attribute name={getStartDate(m)} contentIcon={<CalendarIcon />} />
                    <h.Attribute name={m.notes} contentIcon={<NotesIcon />} />
                </>
            )}
            </ListEditDetailsDrawer>
        </>
    );
};

export default MedicationList;

const getStartDate = (med: Medication) => {
    if (!med?.startDate) {
        return '';
    }

    return `Started ${formatDate(med.startDate)}`;
};

const listItems = (medications: Medication[]) => medications.map(med => {
    const frequencyLabel = getFrequencyLabel(med.frequency || '');
    const secondaryText = frequencyLabel ? `${med.dosage}, ${frequencyLabel}` : med.dosage;

    return {
        id: med._id,
        primaryText: med.name,
        secondaryText: secondaryText?.trim() ?? '',
        icon: MedicationIcon,
        data: med,
    };
});
