import { Drawer } from "@suid/material";
import { DrawerTypeMap } from "@suid/material/Drawer";
import { JSX } from "solid-js";

export enum SwipeDirection {
    UP,
    DOWN,
    LEFT,
    RIGHT
}

let drawersCreatedCount = 0;
export type DrawerxProps = DrawerTypeMap<{ onSwipe?: (direction: SwipeDirection) => void, swipeThreshhold?: number }>['props'];

export interface DrawerProps extends DrawerxProps { }
export const Drawerx = (props: DrawerProps): JSX.Element => {
    const onSwipe = props.onSwipe;
    if (!onSwipe) {
        return <>
            <Drawer {...props}>
            </Drawer>
        </>;
    }

    let startY = 0;
    let startX = 0;

    const start = props.onTouchStart as any;
    props.onTouchStart = (event: TouchEvent) => {
        startY = event.touches[0].clientY;
        startX = event.touches[0].clientX;
        start?.(event);
    };

    let drawer: HTMLElement | null = null;
    const end = props.onTouchEnd as any;
    const swipeThreshhold = props.swipeThreshhold ?? 100;
    props.onTouchEnd = (event: TouchEvent) => {
        const endY = event.changedTouches[0].clientY;
        const diffY = endY - startY;

        if (!drawer) {
            drawer = document.getElementById(localProps.id!);
        }

        if (diffY > swipeThreshhold) {
            return onSwipe(SwipeDirection.DOWN);
        }

        if (diffY < -swipeThreshhold) {
            return onSwipe(SwipeDirection.UP);
        }

        const endX = event.changedTouches[0].clientX;
        const diffX = endX - startX;
        if (diffX > swipeThreshhold) {
            return onSwipe(SwipeDirection.RIGHT);
        }

        if (diffX < -swipeThreshhold) {
            return onSwipe(SwipeDirection.LEFT);
        }

        end?.(event);
    };

    drawersCreatedCount++;
    const localProps = {
        ...props,
        id: !props.id ? 'drawerx_comp_' + drawersCreatedCount.toString() + '_id' : props.id
    }

    return <>
        <Drawer
            {...props}
            id={!props.id ? 'drawerx_comp_' + drawersCreatedCount.toString() + '_id' : props.id}
        >
        </Drawer>
    </>;
};