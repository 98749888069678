

export default function MedicalConditionIcon() {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 6.86667C1 4.81314 1 3.78637 1.39964 3.00204C1.75118 2.3121 2.3121 1.75118 3.00204 1.39964C3.78637 1 4.81314 1 6.86667 1H17.1333C19.1869 1 20.2136 1 20.998 1.39964C21.6879 1.75118 22.2488 2.3121 22.6003 3.00204C23 3.78637 23 4.81314 23 6.86667V17.1333C23 19.1869 23 20.2136 22.6003 20.998C22.2488 21.6879 21.6879 22.2488 20.998 22.6003C20.2136 23 19.1869 23 17.1333 23H6.86667C4.81314 23 3.78637 23 3.00204 22.6003C2.3121 22.2488 1.75118 21.6879 1.39964 20.998C1 20.2136 1 19.1869 1 17.1333V6.86667Z" stroke="#333333" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.2408 6.25549C14.2408 5.91323 14.2408 5.74211 14.1741 5.61138C14.1156 5.49639 14.0221 5.4029 13.9071 5.34432C13.7764 5.27771 13.6053 5.27771 13.263 5.27771H10.7371C10.3948 5.27771 10.2237 5.27771 10.093 5.34432C9.97801 5.4029 9.88451 5.49639 9.82597 5.61138C9.75935 5.74211 9.75935 5.91323 9.75935 6.25549V8.78142C9.75935 9.12366 9.75935 9.2948 9.69274 9.42552C9.63408 9.5405 9.54062 9.63395 9.42564 9.69262C9.29492 9.75923 9.12379 9.75923 8.78154 9.75923H6.25561C5.91335 9.75923 5.74223 9.75923 5.6115 9.82584C5.49651 9.88439 5.40302 9.97789 5.34444 10.0929C5.27783 10.2236 5.27783 10.3947 5.27783 10.737V13.2629C5.27783 13.6052 5.27783 13.7763 5.34444 13.907C5.40302 14.022 5.49651 14.1155 5.6115 14.174C5.74223 14.2406 5.91335 14.2406 6.25561 14.2406H8.78154C9.12379 14.2406 9.29492 14.2406 9.42564 14.3072C9.54062 14.3659 9.63408 14.4594 9.69274 14.5743C9.75935 14.7051 9.75935 14.8762 9.75935 15.2184V17.7444C9.75935 18.0866 9.75935 18.2577 9.82597 18.3885C9.88451 18.5035 9.97801 18.597 10.093 18.6555C10.2237 18.7222 10.3948 18.7222 10.7371 18.7222H13.263C13.6053 18.7222 13.7764 18.7222 13.9071 18.6555C14.0221 18.597 14.1156 18.5035 14.1741 18.3885C14.2408 18.2577 14.2408 18.0866 14.2408 17.7444V15.2184C14.2408 14.8762 14.2408 14.7051 14.3074 14.5743C14.366 14.4594 14.4595 14.3659 14.5744 14.3072C14.7052 14.2406 14.8763 14.2406 15.2185 14.2406H17.7445C18.0867 14.2406 18.2578 14.2406 18.3886 14.174C18.5036 14.1155 18.5971 14.022 18.6557 13.907C18.7223 13.7763 18.7223 13.6052 18.7223 13.2629V10.737C18.7223 10.3947 18.7223 10.2236 18.6557 10.0929C18.5971 9.97789 18.5036 9.88439 18.3886 9.82584C18.2578 9.75923 18.0867 9.75923 17.7445 9.75923H15.2185C14.8763 9.75923 14.7052 9.75923 14.5744 9.69262C14.4595 9.63395 14.366 9.5405 14.3074 9.42552C14.2408 9.2948 14.2408 9.12366 14.2408 8.78142V6.25549Z" stroke="#333333" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
}
