import { IconProps } from ".";

const lightColor = "#FFFFFF";
const darkColor = "#333333";

export default function EditIcon({ mode }: IconProps) {
  const strokeColor = mode === 'light' ? lightColor : darkColor;

  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.33469 3.44141H3.11135C2.63977 3.44141 2.1875 3.62874 1.85404 3.9622C1.52059 4.29566 1.33325 4.74792 1.33325 5.2195V17.6662C1.33325 18.1378 1.52059 18.59 1.85404 18.9235C2.1875 19.2569 2.63977 19.4443 3.11135 19.4443H15.558C16.0296 19.4443 16.4819 19.2569 16.8153 18.9235C17.1488 18.59 17.3361 18.1378 17.3361 17.6662V11.4428" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.0027 2.10805C16.3564 1.75436 16.8361 1.55566 17.3363 1.55566C17.8365 1.55566 18.3162 1.75436 18.6699 2.10805C19.0236 2.46173 19.2223 2.94143 19.2223 3.44162C19.2223 3.94181 19.0236 4.42151 18.6699 4.77519L10.2239 13.2212L6.66772 14.1102L7.55677 10.554L16.0027 2.10805Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}