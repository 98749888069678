import { Env } from "@libs/environment/mod.ts";
import { debugConsole } from "./debug.console.ts";

const styleDefaults = [
    'border-radius:6px; font-weight: bold; padding-bottom: 2px; padding-top: 2px; padding-left: 8px; padding-right: 8px'
]

const styles = [
    // Debug
    [styleDefaults, 'color: #FFFFFF; background: #939292;'].join(';'),
    // Info
    [styleDefaults, 'color: #FFFFFF; background: #148FF9;'].join(';'),
    // Log
    [styleDefaults, 'color: #FFFFFF; background: #148FF9;'].join(';'),
    // Warn
    [styleDefaults, 'color: #FFFFFF; background: #148FF9;'].join(';'),
    // Error
    [styleDefaults, 'color: #FFFFFF; background: #148FF9;'].join(';'),
]

export enum LogLevel {
    DEBUG = 0,
    INFO = 1,
    LOG = 2,
    WARN = 3,
    ERROR = 4,
}

const GROUP_NAME = '%cvault' + (Env.isServiceWorker ? '-sw' : Env.isBrowser ? '-wn' : '-sv');
export const logger: Logger = {
    debug: (...args: any[]) => {
        console.debug(...[GROUP_NAME, styles[0], ...args]);
    },
    info: (...args: any[]) => {
        console.info(...[GROUP_NAME, styles[1], ...args]);
    },
    log(...args: any[]) {
        console.log(...[GROUP_NAME, styles[2], ...args]);
    },
    warn: (...args: any[]) => {
        console.warn(...[GROUP_NAME, styles[3], ...args]);
    },
    error: (...args: any[]) => {
        console.error(...[GROUP_NAME, styles[4], ...args]);
    },
    write: (level: LogLevel, ...args: any[]) => {
        logmap[level](...args);
    },
    emit: debugConsole.isEnabled ?
        (...args: any[]) => {
            if (args.length === 1) {
                debugConsole.emit(args[0]);
                return;
            }

            const level = args[0];
            logger.write(level, ...args.slice(1));

            debugConsole.emit(args[1]);
        } :
        (...args: any[]) => {
            if (args.length > 1) {
                const level = args[0];
                logger.write(level, ...args.slice(1));
            }
        },
};

export interface Logger {
    debug(...args: any[]): void;
    info(...args: any[]): void;
    log(...args: any[]): void;
    warn(...args: any[]): void;
    error(...args: any[]): void;
    emit(value: string): void;
    emit(level: LogLevel, value: string): void;
    write(level: LogLevel, ...args: any[]): void;
}

const logmap = [
    logger.debug,
    logger.info,
    logger.log,
    logger.warn,
    logger.error,
];

export const useLogger = (name: string): Logger => {
    const namedLogger = {
        debug: (...args: any[]) => {
            logger.debug(...[`[${name}]`, ...args]);
        },
        info: (...args: any[]) => {
            logger.info(...[`[${name}]`, ...args]);
        },
        warn: (...args: any[]) => {
            logger.warn(...[`[${name}]`, ...args]);
        },
        error: (...args: any[]) => {
            logger.error(...[`[${name}]`, ...args]);
        },
        write: (level: LogLevel, ...args: any[]) => {
            logmap[level](...[`[${name}]`, ...args]);
        },
        log(...args: any[]) {
            logger.log(...[`[${name}]`, ...args]);
        },
        emit: (...args: any[]) => {
            (logger as any).emit(...args);
        }
    }

    return namedLogger;
}
