import ActionBar from '@app/components/ActionBar';
import { ListEmptyState } from '@app/pages/helpers/commonHelpers';
import DocumentsIcon from '@suid/icons-material/Folder';

export default function DocumentsView() {
    return (
        <>
                                    <ListEmptyState
                                emptyStateHeader="Coming Soon"
                                emptyStateText="Soon you'll be able to store critical documents that you need at the time of an emergency. Documents could inlude a Living Will, a Healthcare Power of Attorney and a Do Not Resuscitate (DNR) Order."
                                emptyStateIcon={() => <DocumentsIcon style={{ height: '80px', width: '80px', color: "#d3d3d3" }} />}
                            />
            <ActionBar showAction={false} />
        </>
    );
}
