export default function RxInsuranceIcon() {
    return (
        <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M37.96 7.30005H6.04C4.70331 7.30005 3.42137 7.83105 2.47618 8.77623C1.531 9.72141 1 11.0034 1 12.34V31.66C1 32.9967 1.531 34.2787 2.47618 35.2239C3.42137 36.169 4.70331 36.7 6.04 36.7H37.96C39.2967 36.7 40.5786 36.169 41.5238 35.2239C42.469 34.2787 43 32.9967 43 31.66V12.34C43 11.0034 42.469 9.72141 41.5238 8.77623C40.5786 7.83105 39.2967 7.30005 37.96 7.30005Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M28.3 7.3V2.68C28.3 2.23444 28.123 1.80712 27.8079 1.49206C27.4928 1.177 27.0655 1 26.62 1H17.38C16.9344 1 16.5071 1.177 16.192 1.49206C15.877 1.80712 15.7 2.23444 15.7 2.68V7.3" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M21.2579 14.8389L18.9567 8.18658L9.17394 11.5706C8.52338 11.7956 8.18105 12.5001 8.40608 13.1506L16.257 35.8467C16.4821 36.4973 17.1865 36.8396 17.837 36.6146L34.2721 30.9294C34.9227 30.7044 35.265 29.9999 35.04 29.3494L29.4902 13.3056L22.8379 15.6067C22.1922 15.8301 21.4812 15.4845 21.2579 14.8389ZM27.6468 22.4584L28.3914 22.8203C28.8028 23.0202 28.9742 23.5157 28.7743 23.9272L27.8176 25.8956L29.7966 26.8574C30.2081 27.0573 30.3795 27.5528 30.1795 27.9643L29.8177 28.7089C29.6177 29.1204 29.1222 29.2918 28.7107 29.0918L26.7316 28.1296L25.7699 30.1085C25.5699 30.52 25.0744 30.6914 24.6629 30.4914L23.9184 30.1296C23.5069 29.9296 23.3355 29.4341 23.5355 29.0227L24.4972 27.0437L20.5921 25.1463L19.6754 25.4634L20.4876 27.8113C20.6372 28.2437 20.4081 28.715 19.9757 28.8646L19.1931 29.1353C18.7607 29.2849 18.2893 29.0558 18.1397 28.6234L15.4325 20.7972C15.2829 20.3648 15.512 19.8934 15.9444 19.7438L19.8575 18.3902C21.5866 17.7921 23.4728 18.7087 24.0709 20.4378C24.4807 21.6225 24.167 22.8719 23.3804 23.7393L25.5832 24.8098L26.5399 22.8413C26.7398 22.4299 27.2353 22.2585 27.6468 22.4584ZM21.2111 22.3034L18.8633 23.1155L18.3218 21.5503L20.6697 20.7381C21.1011 20.5889 21.5738 20.8186 21.723 21.25C21.8723 21.6814 21.6426 22.1541 21.2111 22.3034ZM28.8455 11.442L28.9488 11.7403L22.6878 13.9061L20.522 7.64513L20.8204 7.54192C21.1334 7.43363 21.4741 7.4527 21.7703 7.59667L28.2172 10.7338C28.5134 10.8777 28.7389 11.1338 28.8455 11.442Z" fill="#333333" />
            </g>
        </svg>
    );
}
