import { Grid, Box, Button } from '@suid/material';
import { TextFieldCommon } from '@app/components/TextFieldCommon';
import { FrequencySelect } from '@app/pages/medications/components/FrequencySelect';
import { DosageFormSelect } from '@app/pages/medications/components/DosageFormSelect';

import { layoutBodyStyles } from '@app/assets/theme';
import { useBoundSource } from '@app/data/hooks';
import AlertDialog from '@app/components/AlertDialog';
import { createSignal } from 'solid-js';
import type { BoundDataSource } from '@app/data/types';
import SelectSearchCommon from '@app/components/SelectSearchCommon';
import { svc } from '@app/utils/svc';
import type { Medication } from '@app/services/meds-lookups';

interface MedicationFormProps {
    isAddMedication?: boolean;
}

const MedicationForm = ({ isAddMedication = false }: MedicationFormProps) => {
    const source = useBoundSource();
    return (
        <Box sx={layoutBodyStyles.formBox}>
            {isAddMedication ? showMedicationSearch(source) : showMedicationForm(source, false)}
        </Box>
    );
};

export default MedicationForm;

function showMedicationSearch(source: BoundDataSource) {
    const [searchMeds, setSearchMeds] = createSignal(true);
    return (
        <>
            {searchMeds() ?
                (
                    <SelectSearchCommon<Medication>
                        selector={value => {
                            return svc.meds.find(value, 10).then(data => {
                                return data.map(m => {
                                    return {
                                        id: m.id + "",
                                        primaryText: m.brand,
                                        secondaryText: m.substance,
                                        data: m
                                    };
                                });
                            });
                        }}
                        placeholder='Type Medication Name'
                        instructions='Provide the medication brand name or substance'
                        onSelected={(item) => {
                            source.set('name', item.brand);
                            const dosageForms = item.dosageForms();
                            if (dosageForms.length === 1) {
                                source.set('dosageForm', dosageForms[0].value);
                            }
                            source.set('notes', item.substance);
                            setSearchMeds(false);
                        }}
                    />
                ) : (
                    showMedicationForm(source, true)
                )
            }
        </>
    );
}

function showMedicationForm(source: BoundDataSource, isAddMedication: boolean) {
    const [confirmAndDelete, setConfirmAndDelete] = createSignal(false);
    return (
        <>
            <Grid container spacing={{ xs: 1, md: 1 }}>
                <TextFieldCommon name='name' label='Medication Name' fullWidth={true} gxs={12} />
                <TextFieldCommon name='dosage' label='Dosage' fullWidth={true} gxs={12} />
                <DosageFormSelect name='dosageForm' gxs={12} />
                <FrequencySelect name='frequency' gxs={12} />

                {/* {frequency() === 'Other' && ( //TODO: need to clear this value when frequency changes
                    <TextFieldCommon name='otherFrequency' label='Other Frequency' multiline={true} fullWidth={true} gxs={12} />
                )}

                {frequency() === 'Hourly' && ( //TODO: need to clear this value when frequency changes
                    <TextFieldCommon name='hourlyFrequency' label='Hourly Frequency' fullWidth={true} gxs={12} />
                )} */}

                <TextFieldCommon name='reason' label='Reason for Medication' multiline={true} fullWidth={true} gxs={12} />
                <TextFieldCommon name='notes' label='Notes' multiline={true} fullWidth={true} gxs={12} />
                <TextFieldCommon name='startDate' label='Start Date' fullWidth={true} gxs={12} mode='date' shrink={true} />
                <TextFieldCommon name='endDate' label='End Date' fullWidth={true} gxs={12} mode='date' shrink={true} />

                {!isAddMedication && (
                    <>
                        <Grid item xs={12} sx={{ marginTop: '20px' }}>
                            <Button
                                color="primary"
                                variant='text'
                                fullWidth
                                onClick={() => setConfirmAndDelete(true)}
                                sx={{
                                    textTransform: 'none'
                                }}
                            >
                                Delete this medication record
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            {!isAddMedication && (
                <AlertDialog
                    id='medication_form_confirmation_dialog'
                    type={'YesNo' as const}
                    open={confirmAndDelete}
                    button1='Yes'
                    button2='Cancel'
                    button2ErrorButton={true}
                    button2Variant={'outlined' as const}
                    title='Delete Medication'
                    description='Are you sure you want to delete this medication?'
                    onClick={(e: any, index: number) => {
                        setConfirmAndDelete(false);
                        if (index !== 0) {
                            return;
                        }

                        // confirmed, mark as deleted and save
                        source.save({ deleteEntity: true });
                    }}
                />)
            }
        </>
    );
}
