import { IconProps } from ".";

export default function CareGuideIcon({themeColorPrimary, themeColorSecondary}: IconProps) {
  return (
<svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.58331 45.5V30.3334C7.58331 19.6089 7.58331 14.2467 10.915 10.915C14.2466 7.58337 19.6089 7.58337 30.3333 7.58337H60.6666C71.3911 7.58337 76.7533 7.58337 80.085 10.915C83.4166 14.2467 83.4166 19.6089 83.4166 30.3334V45.5C83.4166 56.2245 83.4166 61.5867 80.085 64.9184C76.916 68.0874 71.9098 68.2421 62.1993 68.2496C62.7397 66.2411 62.2976 64.0267 60.8731 62.3551C59.9879 61.3164 59.453 60.025 59.3445 58.6647C59.0911 55.4902 56.5704 52.9695 53.3959 52.7161C52.0356 52.6076 50.7442 52.0727 49.7055 51.1875C47.2817 49.122 43.7168 49.122 41.293 51.1875C40.2544 52.0727 38.963 52.6076 37.6026 52.7161C34.4282 52.9695 31.9074 55.4902 31.6541 58.6647C31.5455 60.025 31.0106 61.3164 30.1255 62.3551C28.7009 64.0267 28.2589 66.2411 28.7993 68.2496C19.0897 68.2421 14.0838 68.0872 10.915 64.9184C7.58331 61.5867 7.58331 56.2245 7.58331 45.5Z" fill={themeColorPrimary} stroke="black"/>
<path d="M31.2812 22.75C31.2812 21.1794 32.5544 19.9062 34.125 19.9062H56.875C58.4455 19.9062 59.7187 21.1794 59.7187 22.75C59.7187 24.3206 58.4455 25.5938 56.875 25.5938H34.125C32.5544 25.5938 31.2812 24.3206 31.2812 22.75Z" fill={themeColorSecondary}/>
<path d="M26.5416 33.1771C24.9711 33.1771 23.6979 34.4503 23.6979 36.0208C23.6979 37.5914 24.9711 38.8646 26.5416 38.8646H64.4583C66.0289 38.8646 67.302 37.5914 67.302 36.0208C67.302 34.4503 66.0289 33.1771 64.4583 33.1771H26.5416Z" fill={themeColorSecondary}/>
<path d="M28 67.5H63L71 44H24L28 67.5Z" fill={themeColorPrimary}/>
<path d="M28 64.08C28 70.5874 36.1725 77.5051 41.1847 81.1357C42.9004 82.3786 43.7583 83 45 83C46.2417 83 47.0996 82.3786 48.8154 81.1358C53.8275 77.5051 62 70.5875 62 64.08C62 54.1396 52.6497 50.4283 45 58.1072C37.3503 50.4283 28 54.1396 28 64.08Z" fill="#F99B78"/>
<path d="M41.1847 81.1357L42.0646 79.921L42.0646 79.921L41.1847 81.1357ZM45 58.1072L43.9373 59.1658C44.2188 59.4484 44.6012 59.6072 45 59.6072C45.3988 59.6072 45.7812 59.4484 46.0627 59.1658L45 58.1072ZM48.8154 81.1358L49.6953 82.3505L49.6953 82.3505L48.8154 81.1358ZM45 83L45 81.5L45 81.5L45 83ZM42.0646 79.921C39.5979 78.1342 36.3994 75.57 33.8328 72.6889C31.2169 69.7526 29.5 66.755 29.5 64.08H26.5C26.5 67.9124 28.8693 71.6274 31.5927 74.6844C34.3654 77.7967 37.7592 80.5066 40.3047 82.3505L42.0646 79.921ZM29.5 64.08C29.5 59.5872 31.5758 56.8399 34.1488 55.8943C36.7462 54.9397 40.4029 55.6179 43.9373 59.1658L46.0627 57.0485C41.9474 52.9176 37.1041 51.612 33.1139 53.0784C29.0993 54.5539 26.5 58.6324 26.5 64.08H29.5ZM49.6953 82.3505C52.2408 80.5067 55.6346 77.7968 58.4073 74.6845C61.1307 71.6274 63.5 67.9125 63.5 64.08H60.5C60.5 66.755 58.783 69.7527 56.1672 72.6889C53.6006 75.57 50.4021 78.1342 47.9354 79.921L49.6953 82.3505ZM63.5 64.08C63.5 58.6324 60.9007 54.5538 56.8861 53.0784C52.8959 51.612 48.0526 52.9176 43.9373 57.0485L46.0627 59.1658C49.5971 55.6179 53.2538 54.9397 55.8512 55.8943C58.4242 56.8399 60.5 59.5872 60.5 64.08H63.5ZM40.3047 82.3505C41.8987 83.5052 43.1749 84.5 45 84.5L45 81.5C44.3417 81.5 43.9021 81.252 42.0646 79.921L40.3047 82.3505ZM47.9354 79.921C46.0979 81.252 45.6583 81.5 45 81.5L45 84.5C46.8251 84.5 48.1013 83.5052 49.6953 82.3505L47.9354 79.921Z" fill={themeColorSecondary}/>
</svg>

  );
}
