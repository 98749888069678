import { Accessor, createSignal } from "solid-js"
import { useBoundSource } from "./hooks"

export type BoundProperty<T> = {
    value: T
    hasError: Accessor<boolean>
    isRequired: Accessor<boolean>
    errorMessage: string | undefined
}

export const useBoundProperty = <T = string>(name: string, options?: {
    validate?: boolean
}): BoundProperty<T> => {
    const source = useBoundSource<any>();
    if (options?.validate === undefined || options?.validate === true) {
        const validator = source.validator(name);
        const isRequired = source.isRequired(name);
        const validation = validator(source.get(name));
        const [hasError, setHasError] = createSignal<boolean>(!validation.isValid);
        const prop = {
            hasError,
            isRequired: () => isRequired,
            errorMessage: validation.isValid ? undefined : (validation.message === '' ? undefined : validation.message)
        };

        Object.defineProperty(prop, 'value', {
            get: () => source.get(name),
            set: (value: any) => {
                const v = validator(value as any);
                source.set(name, value);
                prop.errorMessage = !v.isValid ? (v.message === '' ? undefined : v.message) : undefined;
                setHasError(!v.isValid);
            }
        });

        return prop as BoundProperty<T>;
    } else {
        const prop = {
            hasError: () => false,
            errorMessage: undefined,
            isRequired: () => false
        };

        Object.defineProperty(prop, 'value', {
            get: () => source.get(name),
            set: (value: any) => source.set(name, value)
        });

        return prop as BoundProperty<T>;
    }
}
