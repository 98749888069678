import { Convert, DefaultValue } from "../utils/convert.ts";
import { InvalidArgumentError, objects } from "@rdt-utils";
import { Behavior } from "../utils/behavior.ts";

export const configHelper = {
    asNum: (key: string, value: any, dv: DefaultValue<any>) => {
        return as(key, value, dv, Convert.toNum);
    },
    asDt: (key: string, value: any, dv: DefaultValue<any>) => {
        return as(key, value, dv, Convert.toDt);
    },
    asBool: (key: string, value: any, dv: DefaultValue<any>) => {
        return as(key, value, dv, Convert.toBool);
    },
    asStr: (key: string, value: any, dv: DefaultValue<any>) => {
        return as(key, value, dv, Convert.toStr);
    },
    asArray: (key: string, value: any, dv: DefaultValue<any>, d = ',') => {
        try {
            return Convert.toArray(value, dv, d);
        } catch (e) {
            throw new InvalidArgumentError({
                name: key,
                message: `Invalid value ${value} for ${key}`,
            });
        }
    },
    else: <T extends number | string | string[] | Date | boolean>(
        key: string,
        value: any,
        elseValue: T,
    ): T => {
        if (objects.isNil(elseValue)) {
            throw new Error('elseValue cannot be null or undefined');
        }

        if (objects.isNil(value)) {
            return elseValue;
        }

        try {
            if (objects.isNum(elseValue)) {
                return Convert.toNum(value, Behavior.FailIfInvalid) as T;
            }

            if (objects.isDate(elseValue)) {
                return Convert.toDt(value, Behavior.FailIfInvalid) as T;
            }

            if (objects.isBool(elseValue)) {
                return Convert.toBool(value, Behavior.FailIfInvalid) as T;
            }

            if (objects.isStr(elseValue)) {
                return Convert.toStr(value, Behavior.FailIfInvalid) as T;
            }

            if (Array.isArray(elseValue)) {
                return Convert.toArray(value, Behavior.FailIfInvalid) as T;
            }
        } catch (e) {
            throw new InvalidArgumentError({
                name: key,
                message: `Invalid value ${value} for ${key}`,
            });
        }

        throw new Error(`Invalid elseValue type ${typeof elseValue}`);
    },
};

function as(
    key: string,
    value: any,
    dv: DefaultValue<any>,
    fn: (value: any, dv: DefaultValue<any>) => any,
) {
    try {
        return fn(value, dv);
    } catch (e) {
        throw new InvalidArgumentError({
            name: key,
            message: `Invalid value ${value} for ${key}`,
        });
    }
}
