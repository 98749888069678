import { svc } from '@app/utils/svc';
import { CircularIndeterminate } from '@app/components/CircularIndeterminate';
import { Match, Switch, createSignal } from 'solid-js';
import Loggedout from './Loggedout.tsx';
import { useNavigate } from '@solidjs/router';
import { routeStrings } from '@app/routes/route.strings.ts';
import { useIsAuthenticated } from '@app/utils/hooks.ts';

export const AuthnCallback = () => {
    const isAuthenticated = useIsAuthenticated();

    const [
        awaitTokenAuthentication,
        setAwaitTokenAuthentication
    ] = createSignal(!isAuthenticated());

    svc.auth.handleExternalLoginCallback(
        () => {
            setAwaitTokenAuthentication(false);
        }
    );

    const doNavigate = () => {
        return <>
            {isAuthenticated() ? <NavHome /> : <Loggedout />}
        </>;
    }

    return <>
        <Switch fallback={<CircularIndeterminate />}>
            <Match when={!awaitTokenAuthentication()}>
                {doNavigate()}
            </Match>
        </Switch>
    </>;
};

export const NavHome = () => {
    return <>
        {useNavigate()(routeStrings.cardSelectionPath, { replace: true })}
    </>;
};

export default AuthnCallback;