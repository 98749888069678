import { createEffect, createSignal } from 'solid-js';
import { useLocation, useNavigate } from '@solidjs/router';
import {
    Box,
    Typography
} from '@suid/material';

import { layoutHeaderStyles, layoutBodyStyles } from '@app/assets/theme';
import { routeStrings } from '@app/routes/route.strings.ts';
import DrawerCommon from '@app/components/DrawerCommon';
import FormCommon from '@app/components/FormCommon.tsx';
import DataPage from '@app/data/components/DataPage.tsx';
import * as h from '@app/pages/profile/profileHelper.tsx';
import { svc } from '@app/utils/svc.ts';
import { Once } from '@rdt-utils';

export const CardCreate = () => {
    if (!useLocation<any>()?.state?.createCard) {
        // Means the user had a link to this page, so we should redirect
        // them to the card selection page versus creating another card
        useNavigate()(routeStrings.cardSelectionPath, {
            replace: true,
        });

        return null;
    }

    const [status, setStatus] = createSignal<string>();

    const card = svc.cardService.createNewCard();

    createEffect(() => {
        if (status() === 'success') {
            return useNavigate()(routeStrings.cardHomePath, { replace: true });
        }

        if (status() !== 'close') {
            return;
        }

        useNavigate()(routeStrings.cardSelectionPath, {
            replace: true,
            state: {
                removeCard: card.isNew ? card : undefined,
                notifyOnCancel: () => {
                    setStatus('');
                }
            }
        });
    });

    const dataPage = Once.of(() => {
        return <>
            <DataPage
                pageType="personal"
                source={card.profile}
                onSaveSuccess={() => setStatus('success')}
                saveAction={{
                    style: layoutBodyStyles.bottomAction,
                    label: 'Continue',
                    target: () => document.getElementById('createCardContinueButtonPortalId') as HTMLElement
                }}
            >
                <Typography variant='body1' sx={{ marginBottom: '10px', marginLeft: '5px' }}>
                Think of a Care Card as your family’s safety net. In an emergency, it provides immediate access to vital information, ensuring that you and your loved ones get the right care, right away.  Prepare your family for any situation.<br/><br/>Who are you creating this Care Card for?
                </Typography>
                <FormCommon fields={h.basicInfoFields} />
                <div id='createCardContinueButtonPortalId' ></div>
            </DataPage>
        </>;
    });

    return (
        <Box sx={layoutHeaderStyles.layoutHeaderContainer}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xs: '80px' },
            }}>
                <DrawerCommon
                    onClose={() => setStatus('close')}
                    title="Let's create a new Care Card"
                    options={{
                        disableContentDivider: () => true,
                        showBackArrow: () => false,
                        showClose: () => true
                    }}
                >
                    {dataPage()}
                </DrawerCommon>
            </Box>
        </Box>
    );
};

export default CardCreate;
