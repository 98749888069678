import { ServiceManager, ServiceFactory, ServiceName } from '@libs/services/service.ts';
import { type CardService, CARD_SERVICE, CARD_SERVICE_SELECTOR, type CardSelectorService } from './types.ts';

export const cardServiceFactory = createFactory(CARD_SERVICE);
export const cardSelectorFactory = createFactory(CARD_SERVICE_SELECTOR);

let factory: { init: () => void, postInit: (manager: ServiceManager) => Promise<void>, create: () => CardService } | null = null;
function createFactory<T extends CardService | CardSelectorService>(name: ServiceName<T>): ServiceFactory {
    return Object.seal({
        name,
        init: async () => {
            factory = (await import('./_internal/card.service.ts')).cardInitFactory;
            if (factory.init) {
                await factory.init();
            }
        },
        create: () => factory!.create(),
        postInit: (manager: ServiceManager) => {
            return factory!.postInit(manager);
        }
    });
}
