import { Version } from "@libs/db/mod-schema.ts";

const typeInfo = Object.freeze({
    PROFILE: Object.freeze({
        type: 1010,
        version: Version.v1
    }),
    PROFILE_IMAGE: Object.freeze({
        type: 1011,
        version: Version.v1
    }),
    MEDICATION_DETAIL: Object.freeze({
        type: 1020,
        version: Version.v1
    }),
    INSURANCE_DETAILS: Object.freeze({
        type: 1040,
        version: Version.v1
    }),
    MEDICAL_INFO: Object.freeze({
        type: 1050,
        version: Version.v1
    }),
    SUBJECT: Object.freeze({
        type: 1060,
        version: Version.v1,
        embeddedTypeOnly: true
    }),
    MEDICATION: Object.freeze({
        type: 1070,
        version: Version.v1
    }),
    CONTACTS_DETAIL: Object.freeze({
        type: 1080,
        version: Version.v1,
        embeddedTypeOnly: true
    }),
    CONTACT: Object.freeze({
        type: 1090,
        version: Version.v1
    }),
    INSURANCE: Object.freeze({
        type: 1100,
        version: Version.v1
    })
});

const allTypes = Object.getOwnPropertyNames(typeInfo).map(k => typeInfo[k as keyof typeof typeInfo].type);

type typeInfo = typeof typeInfo;

export interface TypeInfo extends typeInfo {
    getAllTypes: () => number[]
}

export const TypeInfo: TypeInfo = {
    ...typeInfo,
    getAllTypes: () => allTypes
}
