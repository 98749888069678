

export default function BloodTypeIcon() {
    return (
<svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.484 10.6641L10.1788 0.815775C10.1059 0.701889 10.0055 0.608177 9.88687 0.543267C9.76824 0.478357 9.63519 0.444336 9.49996 0.444336C9.36474 0.444336 9.23168 0.478357 9.11305 0.543267C8.99442 0.608177 8.89403 0.701889 8.82112 0.815775L2.51577 10.6642C1.42264 12.1471 0.835153 13.9421 0.839996 15.7844C0.839996 20.5594 4.72479 24.4443 9.49996 24.4443C14.2751 24.4443 18.1599 20.5595 18.1599 15.7844C18.1648 13.9421 17.5772 12.147 16.484 10.6641ZM9.49996 22.8322C5.61381 22.8322 2.45209 19.6706 2.45209 15.7844C2.44797 14.2777 2.93074 12.8101 3.82847 11.6001C3.83918 11.5856 3.84939 11.5707 3.8591 11.5555L9.49996 2.7453L15.1406 11.5556C15.1503 11.5708 15.1605 11.5857 15.1713 11.6001C16.0691 12.8101 16.5519 14.2777 16.5478 15.7844C16.5478 19.6706 13.3861 22.8322 9.49996 22.8322Z" fill="#333333"/>
</svg>


  );
}
