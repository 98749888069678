

export default function NotesIcon() {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.8571 1.2312H4.14286C2.4071 1.2312 1 2.28633 1 3.5879V20.0848C1 21.3864 2.4071 22.4415 4.14286 22.4415H19.8571C21.5929 22.4415 23 21.3864 23 20.0848V3.5879C23 2.28633 21.5929 1.2312 19.8571 1.2312Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.28564 5.94458H16.7142" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.28564 10.658H16.7142" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.28564 15.3713H13.5714" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
}
