import { ErrorBoundary, JSX, ParentProps } from 'solid-js';
import { Env } from '@libs/environment/mod.ts';
import { logger } from '@libs/logger/mod.ts';

const ErrorBoundaryX = (props: ParentProps<{ fallback?: JSX.Element | ((err: any, reset: () => void) => JSX.Element) }>) => {
    if (Env.isDev) {
        return props.children;
    }

    const fallback = props.fallback ?? ((err, reset) => <div onClick={reset}>Error: {logAndReturn(err)}</div>);

    return <ErrorBoundary fallback={fallback} children={props.children} />;
}

export default ErrorBoundaryX;

const logAndReturn = (err: any) => {
    logger.error(err);
    logger.emit(err.toString());
    return err.toString();
}