

export default function ClipboardIcon() {
    return (
<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66659 3.66667H3.99992C3.29267 3.66667 2.6144 3.94762 2.1143 4.44772C1.6142 4.94781 1.33325 5.62609 1.33325 6.33333V22.3333C1.33325 23.0406 1.6142 23.7189 2.1143 24.219C2.6144 24.719 3.29267 25 3.99992 25H19.9999C20.7072 25 21.3854 24.719 21.8855 24.219C22.3856 23.7189 22.6666 23.0406 22.6666 22.3333V6.33333C22.6666 5.62609 22.3856 4.94781 21.8855 4.44772C21.3854 3.94762 20.7072 3.66667 19.9999 3.66667H17.3333M6.66659 3.66667C6.66659 2.95942 6.94754 2.28115 7.44763 1.78105C7.94773 1.28095 8.62601 1 9.33325 1H14.6666C15.3738 1 16.0521 1.28095 16.5522 1.78105C17.0523 2.28115 17.3333 2.95942 17.3333 3.66667M6.66659 3.66667C6.66659 4.37391 6.94754 5.05219 7.44763 5.55229C7.94773 6.05238 8.62601 6.33333 9.33325 6.33333H14.6666C15.3738 6.33333 16.0521 6.05238 16.5522 5.55229C17.0523 5.05219 17.3333 4.37391 17.3333 3.66667M6.66659 11.6667H17.3333M6.66659 17H11.9999" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>






  );
}
