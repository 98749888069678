import AddressIcon from "@app/assets/icons/AddressIcon";
import PhoneIcon from "@app/assets/icons/PhoneIcon";
import { Typography, Divider } from "@suid/material";
import { JSX, JSXElement } from "solid-js";
import { documentStyles } from '@app/assets/theme.ts';

export const Attribute = (props: {
    name: JSX.Element | string,
    contentIcon?: JSX.Element
}): JSX.Element | null => {
    if (!props.name) {
        return null;
    }

    return (
        <div style={{ display: 'flex', "align-items": 'flex-start', "margin-bottom": '15px',"white-space": 'pre-wrap' }}>
            <div style={{ display: 'flex', "align-items": 'center', "margin-right": '20px', "margin-left": '5px' }}>
                {props.contentIcon}
            </div>
            {props.name}
        </div>
    );
};

export const AttributeWithLabel = (props: {
    label: string,
    value: JSX.Element | string,
    labelStyle?: any,
    valueStyle?: any,
    divider?: boolean
}): JSX.Element | null => {
    if (!props.value) {
        return null;
    }

    const DividerComponent = () => (
        <Divider sx={{ "marginBottom": '15px', "marginTop": '25px' }}>
            <Typography sx={props.labelStyle || documentStyles.documentFormLabel}>
                {props.label}
            </Typography>
        </Divider>
    );

    return (
        <Typography sx={documentStyles.documentForm}>
            {props.divider ? <DividerComponent /> : (
                <Typography sx={props.labelStyle || documentStyles.documentFormLabel}>{props.label}</Typography>
            )}
            <Typography sx={props.labelStyle || documentStyles.documentFormValue}>{props.value}</Typography>
        </Typography>
    );
};


export function attributePhoneNumber(phoneNumber: any, phoneType: any) {
    if (!phoneNumber) {
        return null;
    }

    const el = (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{phoneNumber}</span>
            <span style={{
                'font-size': '14px',
                'color': '#979797',
                'margin-left': '10px'
            }}>
                {phoneType}
            </span>
        </Typography>
    );

    return Attribute({ name: el, contentIcon: <PhoneIcon /> });
}

export function attributeAddress(
    street1: string | null | undefined,
    street2: string | null | undefined,
    city: string | null | undefined,
    state: string | null | undefined,
    zipcode: string | null | undefined,
    county: string | null | undefined,
    label?: string,
    labelStyle?: any,
    valueStyle?: any,
    showIcon: boolean = true
) {
    if (!(street1 || street2 || city || state || zipcode || county)) {
        return null;
    }

    const addressParts = [
        street1,
        street2,
        [city, state, zipcode].filter(part => part).join(', '),
        county ? `${county} County` : null
    ].filter(part => part);

    const addressData = (
        <div>
            {addressParts.map((part,) => (
                <div>{part}</div>
            ))}
        </div>
    );

    return (
        <div style={{ "margin-bottom": '15px' }}>
            {showIcon ? (
                <Attribute name={addressData} contentIcon={<AddressIcon />} />
            ) : (
                <>
                    <Typography sx={labelStyle || documentStyles.documentFormLabel}>{label}</Typography>
                    <div>
                        <Typography sx={valueStyle || documentStyles.documentFormValue}>
                            {addressData}
                        </Typography>
                    </div>
                </>
            )}
        </div>

    );
}

export function formatDate(dateString: string | null | undefined) {
    if (!dateString) {
        return '';
    }

    const date = new Date(dateString + 'T00:00');

    return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
    }).format(date);
}


type EmptyStateProps = {
    emptyStateText?: string | null | undefined;
    emptyStateHeader?: string;
    emptyStateIcon?: () => JSXElement;
};

export const SectionEmptyState = (props: EmptyStateProps) => (
    <div style={{ "padding": '20px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>{props.emptyStateHeader}</Typography>
        <Typography sx={{ fontWeight: 300, fontSize: '13px', paddingTop: '8px' }}>{props.emptyStateText}</Typography>
    </div>
);

export const MultiSelectEmptyState = (props: EmptyStateProps) => (
    <div style={{ "padding": '5px' }}>
        <Typography sx={{ fontWeight: 300, fontSize: '13px', paddingTop: '0px' }}>{props.emptyStateText}</Typography>
    </div>
);

export const ListEmptyState = (props: EmptyStateProps) => (
    <div style={{
        display: 'flex',
        "flex-direction": 'column',
        "justify-content": 'center',
        "align-items": 'center',
        height: '100vh',
        padding: '20px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        "transform": 'translate(-50%, -50%)',
        width: '400px',
        "max-width": '80%',
        "box-sizing": 'border-box'
    }}>
        {props.emptyStateIcon && (
            props.emptyStateIcon()
        )}
        <Typography sx={{ "fontWeight": 500, "fontSize": '22px', "textAlign": 'center', "verticalAlign": 'middle' }}>{props.emptyStateHeader}</Typography>
        <Typography sx={{ "fontWeight": 300, "fontSize": '13px', "paddingTop": '8px', "textAlign": 'center', "verticalAlign": 'middle' }}>{props.emptyStateText}</Typography>
    </div>
);


