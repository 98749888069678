import { getCurrentTheme } from "@app/assets/theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@suid/material";
import { JSX } from "solid-js";

export type Props = {
    id?: string;
    open?: (() => boolean) | boolean | undefined;
    onClose?: () => void;
    title: string | JSX.Element | (() => string | JSX.Element);
    description: string | JSX.Element | (() => string | JSX.Element);
    type: 'YesNo' | 'OkayCancel' | 'Ok' | 'YesNoCancel';
    button1?: string;
    button2?: string;
    button3?: string;
    onClick: (ev: MouseEvent | TouchEvent, index: number, name: string) => void;
    button2ErrorButton?: boolean;
    button2Variant?: 'text' | 'filled' | 'outlined';
}

export default function AlertDialog(props: Props) {
    const id = props.id || 'alert-dialog-';
    const button1 = props.button1 || (props.type.startsWith('YesNo') ? 'Yes' : 'Okay');
    const button2 = props.button2 || (props.type.startsWith('YesNo') ? 'No' : (props.type === 'Ok' ? undefined : 'Cancel'));
    const button3 = props.button3 || (props.type === 'YesNoCancel' ? 'Cancel' : undefined);
    const title = typeof props.title === 'function' ? props.title : () => props.title as JSX.Element;
    const description = typeof props.description === 'function' ? props.description : () => props.description as JSX.Element;
    const open = typeof props.open === 'function' ? props.open : () => props.open !== false;
    return (
        <div>
            <Dialog
                open={open() && !!description()}
                onClose={props.onClose}
                aria-labelledby={`${id}-title`}
                aria-describedby={`${id}-description`}
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
            >
                <DialogTitle id={`${id}-title`} sx={{
                    backgroundColor: getCurrentTheme().primary,
                    color: '#FFFFFF',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {title()}
                </DialogTitle>
                <DialogContent sx={{
                    marginTop: 2,
                }}>
                    <DialogContentText id={`${id}-description`}>
                        {description()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={(ev) => props.onClick(ev, 2, button3!)} sx={{
                        visibility: button3 ? 'visible' : 'hidden',
                        backgroundColor: '#FFFFFF',
                        color: '#333333',
                        alignSelf: 'flex-end',
                    }}>{button3 ?? ''}</Button>

                    <div style={{ display: 'flex' }}>
                        <Button onClick={(ev) => props.onClick(ev, 1, button2!)}
                            color={props.button2ErrorButton ? "error" : "secondary"}
                            variant={props.button2Variant ? "outlined" : "text"} sx={{
                                visibility: button2 ? 'visible' : 'hidden',
                            }}>{button2 ?? ''}</Button>
                        <Button onClick={(ev) => props.onClick(ev, 0, button1!)} sx={{
                            backgroundColor: getCurrentTheme().secondary,
                            color: '#FFFFFF',
                            marginLeft: '15px',
                        }}>{button1}</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

