import { xs } from "@libs/db/mod.ts";
import { TypeInfo } from "./entity.types.ts";

const medication = xs.define("Medication", TypeInfo.MEDICATION.type, {
    name: xs.string()
}, {
    dosage: xs.string().optional(),
    dosageUnit: xs.string(25).optional(),
    dosageForm: xs.string(50).optional(),
    reason: xs.string(250).optional(),
    notes: xs.string(1500).optional(),
    frequency: xs.string().optional(),
    startDate: xs.string().optional(),
    endDate: xs.string().optional()
});

const def = xs.define("MedicationDetail", TypeInfo.MEDICATION_DETAIL.type, {
    medications: xs.array(medication.schemaDef).default([])
});

export interface Medication extends xs.inferType<typeof medication.schemaDef> {
    __type: typeof TypeInfo.MEDICATION.type,
}

export const Medication = xs.EntityFactory<Medication>(medication, TypeInfo.MEDICATION.version);

export interface MedicationDetail extends xs.inferType<typeof def.schemaDef> {
    __type: typeof TypeInfo.MEDICATION_DETAIL.type,
    medications: Medication[]
}

export const MedicationDetail = xs.EntityFactory<MedicationDetail>(def, TypeInfo.MEDICATION_DETAIL.version);
