export default function VisionInsuranceIcon() {
    return (
        <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M37.96 7.30005H6.04C4.70331 7.30005 3.42137 7.83105 2.47618 8.77623C1.531 9.72141 1 11.0034 1 12.34V31.66C1 32.9967 1.531 34.2787 2.47618 35.2239C3.42137 36.169 4.70331 36.7 6.04 36.7H37.96C39.2967 36.7 40.5786 36.169 41.5238 35.2239C42.469 34.2787 43 32.9967 43 31.66V12.34C43 11.0034 42.469 9.72141 41.5238 8.77623C40.5786 7.83105 39.2967 7.30005 37.96 7.30005Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M28.3 7.3V2.68C28.3 2.23444 28.123 1.80712 27.8079 1.49206C27.4928 1.177 27.0655 1 26.62 1H17.38C16.9344 1 16.5071 1.177 16.192 1.49206C15.877 1.80712 15.7 2.23444 15.7 2.68V7.3" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M19.6666 20C19.6666 19.2928 19.9476 18.6145 20.4477 18.1144C20.9478 17.6143 21.626 17.3334 22.3333 17.3334C23.0405 17.3334 23.7188 17.6143 24.2189 18.1144C24.719 18.6145 25 19.2928 25 20" stroke="#333333" stroke-width="2.70692" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M38.3334 17.3334H39.6667" stroke="#333333" stroke-width="2.70692" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.33333 17.3334H5" stroke="#333333" stroke-width="2.70692" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.33337 17.3333C6.33337 25.3333 7.66671 28 13 28C18.3334 28 19.6667 25.3333 19.6667 17.3333C19.6667 17.3333 18.3334 16 13 16C7.66671 16 6.33337 17.3333 6.33337 17.3333Z" stroke="#333333" stroke-width="2.70692" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M38.3333 17.3333C38.3333 25.3333 37 28 31.6667 28C26.3333 28 25 25.3333 25 17.3333C25 17.3333 26.3333 16 31.6667 16C37 16 38.3333 17.3333 38.3333 17.3333Z" stroke="#333333" stroke-width="2.70692" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>
    );
}
