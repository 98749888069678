import { JSX, createSignal } from "solid-js";
import DetailDrawer from "./DetailDrawer";
import ListCommon, { ListCommonItem } from "./ListCommon";

export type ListEditDetailsDrawerProps<T = any> = {
    listItems: () => ListCommonItem<T>[],
    onEditSelected: (item: T) => void,
    title: (item: T) => string | JSX.Element,
    children: ((source: T) => JSX.Element),
    avatar?: boolean
}

export default function ListEditDetailsDrawer<T = any>(props: ListEditDetailsDrawerProps<T>) {
    const [selected, setSelected] = createSignal<T | null>(null);
    return (
        <>
            <ListCommon<T>
                items={props.listItems}
                onItemClick={setSelected}
                renderIcon={(Icon) => <Icon />}
                avatar={props.avatar}
            />

            <DetailDrawer
                title={props.title}
                height='auto'
                onClose={() => setSelected(null)}
                item={selected}
                button={{ onClick: props.onEditSelected }}>{c =>
                    <>
                        {props.children(c)}
                    </>
                }
            </DetailDrawer>
        </>
    );
}

