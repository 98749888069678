import { Secret } from "@rdt-utils";
import { LOCAL_DB, type LocalDbClient } from "@app/localdb/local_db";
import type { ServiceName } from "@libs/services/service";

export interface VaultDb extends LocalDbClient {
    deleteLocalData(): Promise<void>;
    isConnected(): boolean;
}

export const databaseName = "vaultDb";
export const defaultDataStore = "care_data";

export type DbStoreContext = {
    storeName: string;
    db: IDBDatabase;
    secret: Secret
}

export const VAULT_DB = LOCAL_DB as unknown as ServiceName<VaultDb>;
