import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@suid/material';
import { useBoundSource } from "@app/data/hooks";
import { useIsReadOnlyMode } from '@app/utils/hooks';

export type Props = {
    name: string;
    gxs?: number;
};

export default function BloodTypeSelect(props: Props) {
    const source = useBoundSource();
    const isReadonly = useIsReadOnlyMode();

    const select = (
        <Box sx={{}}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>Blood Type</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={props.name}
                    value={source.get(props.name) ?? ''}
                    label='Blood Type'
                    variant='filled'
                    onChange={(ev) => source.set(props.name, ev.target.value)}
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    <MenuItem value={'A+'}>A+</MenuItem>
                    <MenuItem value={'A-'}>A-</MenuItem>
                    <MenuItem value={'B+'}>B+</MenuItem>
                    <MenuItem value={'B-'}>B-</MenuItem>
                    <MenuItem value={'O+'}>O+</MenuItem>
                    <MenuItem value={'O-'}>O-</MenuItem>
                    <MenuItem value={'AB+'}>AB+</MenuItem>
                    <MenuItem value={'AB-'}>AB-</MenuItem>
                    <MenuItem value={'Unknown'}>Unknown</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    if (!props.gxs) {
        return select;
    }

    return (
        <Grid item xs={props.gxs}>
            {select}
        </Grid>
    );
}
