import { AUTH0_CLIENT_CONFIG, AUTH0_LOGOUT_ARGS } from './constants.ts';
import { useLogger } from '@libs/logger/mod.ts';
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import { authTokenCache } from './auth.token.ts';
import { AppOfflineError } from '@libs/utils/app.offline.error.ts';

let client: Promise<Auth0Client> | null = null;
let unavailable = false;
let unavailableReason = window.navigator.onLine ? '' : 'Browser is offline';
let errorCount = 0;

const logger = useLogger('AUTH0');

const createClient = () => {
    if (errorCount >= 5) {
        return client ?? (client = Promise.reject(unavailableReason));
    }

    return client = new Promise<Auth0Client>((resolve, reject) => {
        createAuth0Client({
            ...AUTH0_CLIENT_CONFIG,
            cache: authTokenCache
            // if not usign cache, then use --> cacheLocation: 'localstorage',
        }).then(client => {
            errorCount = 0;
            unavailable = false;
            unavailableReason = '';
            resolve(client);
        }).catch((err) => {
            errorCount++;
            unavailable = true;
            if (errorCount >= 5) {
                logError(unavailableReason = 'Too many sequential errors while attempting to create auth client');
            } else {
                logError(unavailableReason = 'Auth client creation resulted in an error', err);
            }
            reject(new Error(unavailableReason));
        });
    });
};

const logError = (msg: string, err?: Error) => {
    logger.error(msg, err);
    logger.emit(msg);
}

(() => {
    const setAsOffline = () => {
        logger.debug('Moving auth client to offline mode');
        unavailable = true;
        unavailableReason = 'Browser is offline';
        client = null;
    }

    const setAsOnline = () => {
        logger.debug('Moving auth client to online mode');
        unavailable = false;
        unavailableReason = '';
        if (client === null) {
            createClient();
        }
    }

    if (window.navigator.onLine) {
        setAsOnline();
    } else {
        setAsOffline();
    }

    window.addEventListener('offline', setAsOffline);
    window.addEventListener('online', setAsOnline);
})();

const _invoke = async <T>(callable: (client: Auth0Client) => Promise<T>, onOffline: () => Error): Promise<T> => {
    if (!window.navigator.onLine) {
        throw onOffline();
    }
    try {
        return callable(await _current());
    } catch (err) {
        throw (window.navigator.onLine ? err : onOffline());
    }
};

const _current = () => client ?? createClient();

export const authClient = Object.seal({
    isUnavailable: () => unavailable,
    unavailableReason: () => unavailableReason,
    getTokenSilently: async () => {
        const client = await _current();
        return client.getTokenSilently({
            cacheMode: 'off'
        });
    },
    loginWithRedirect: async (type: 'sms' | 'email' | 'user_password' = 'sms'): Promise<void> => {
        await _invoke(
            async (client) => {
                logger.info('Initiating login process');
                await client.loginWithRedirect({
                    authorizationParams: {
                        ...AUTH0_CLIENT_CONFIG.authorizationParams,
                        login_hint: type
                    }
                });
            },
            () => {
                return new AppOfflineError('Cannot navigate to external login in offline mode');
            });
    },
    handleRedirectCallback: async (onComplete: () => void): Promise<void> => {
        await _invoke(
            async (client) => {
                logger.info('Processing login callback');
                await client.handleRedirectCallback();
                window.history.replaceState({}, document.title, "/");
                if (!(await client.isAuthenticated())) {
                    return new Error('Fatal error handling external login callback');
                }
                onComplete();
            },
            () => {
                return new AppOfflineError('Cannot handle external login callback in offline mode');
            });
    },
    logout: async (): Promise<void> => {
        await _invoke(
            async (client) => {
                logger.debug('Logging out user');
                await client.logout(AUTH0_LOGOUT_ARGS);
            },
            () => {
                return new AppOfflineError('Cannot fully logout in offline mode');
            }
        )
    },
});
