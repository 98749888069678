import { Lazy } from "@libs/utils/lazy.ts";
import { Service, ServiceFactory, ServiceManager, ServiceName } from "@libs/services/service.ts";
import { Task } from "@libs/async/task/task.ts";
import { logger } from "@libs/logger/mod.ts";

export type Host = ServiceManager;

export const builder: HostBuilder = {
    register(factory: ServiceFactory): HostBuilder {
        return registry.register(factory);
    },
    start(): Promise<Host> {
        return registry.loadServices();
    }
}

export const host = {
    onStart(): Promise<Host> {
        return hostReady;
    },
    get<T extends Service>(name: ServiceName<T>): T {
        return instance.get(name);
    },
    getUnsafe<T extends Service>(name: ServiceName<T>): T | undefined | null {
        return instance.get(name);
    },
    hasStarted(): boolean {
        return hasStarted;
    },
}

const instance: Host = {
    get<T extends Service>(name: ServiceName<T>): T {
        if (!registrationClosed) {
            throw new Error(`Service registration is still in process`);
        }

        const service = resolved.get(name);
        if (service) {
            return service as T;
        }

        if (loading.has(name)) {
            return Lazy.of(() => {
                if (loading.has(name)) {
                    throw new Error(`Service '${name.toString()}' is loading. This is a circular dependency, An attempt was already made to use a proxy. Please check your service.`);
                }
                return this.get(name);
            }) as T;
        }

        const factory = services.get(name);
        if (!factory) {
            throw new Error(`Service '${name.toString()}' is not registered`);
        }

        logger.info(`Resolving service '${name.toString()}'`);
        loading.add(name);

        try {
            const resolvedService = factory.create(this) as T;
            resolved.set(name, resolvedService);
            services.delete(name);
            return resolvedService;
        } finally {
            loading.delete(name);
        }
    }
}

type HostBuilder = {
    register: <T extends Service = Service>(factory: ServiceFactory<T>) => HostBuilder;
    start(): Promise<Host>;
};

const loading: Set<ServiceName> = new Set();
const services: Map<ServiceName, ServiceFactory> = new Map();
const resolved: Map<ServiceName, Service> = new Map();
const initServices = new Array<Task<void>>();
const postInitServices = new Array<ServiceFactory>();
const hostReady = Task.create<Host>();
let hasStarted = false;

let registrationClosed = false;
const registry = {
    register(factory: ServiceFactory): HostBuilder {
        if (registrationClosed) {
            throw new Error(`The registration phase for services has been closed. Please register services earlier in the application lifecycle.`);
        }

        if (services.has(factory.name)) {
            throw new Error(`Service '${factory.name.toString()}' is already registered`);
        }

        if (factory.init) {
            initServices.push(Task.lazy(factory.init));
        }

        if (factory.postInit) {
            postInitServices.push(factory);
        }

        services.set(factory.name, factory);

        return builder;
    },
    loadServices(): Promise<Host> {
        if (registrationClosed) {
            return hostReady;
        }

        registrationClosed = true;

        const initComplete = Task.create();
        Promise.all(initServices).catch(e => {
            logger.error("Error initializing services", e);
            hostReady.reject(e);
            initComplete.reject(e);
        }).then(() => {
            initComplete.resolve();
        });

        initComplete.then(() => {
            const postInit = new Array<Task<void>>();
            for (const factory of postInitServices) {
                postInit.push(Task.lazy(() => factory.postInit?.(instance)));
            }
            Promise.all(postInit).catch(e => {
                logger.error("Error during post initialization of services", e);
                hostReady.reject(e);
            }).then(() => {
                logger.debug("Post initialization of services complete");
                hasStarted = true;
                hostReady.resolve(instance);
            });
        });

        return hostReady;
    }
}
