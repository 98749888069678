import { IconProps } from ".";

export default function PersonProfileIcon({themeColorPrimary, themeColorSecondary}: IconProps) {
  return (
    <svg width="42" height="42" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 24.5C0.75 13.3041 0.75 7.70621 4.22811 4.22811C7.70621 0.75 13.3041 0.75 24.5 0.75C35.6959 0.75 41.2938 0.75 44.7719 4.22811C48.25 7.70621 48.25 13.3041 48.25 24.5C48.25 35.6959 48.25 41.2938 44.7719 44.7719C41.2938 48.25 35.6959 48.25 24.5 48.25C13.3041 48.25 7.70621 48.25 4.22811 44.7719C0.75 41.2938 0.75 35.6959 0.75 24.5Z" fill={themeColorPrimary} />
      <path d="M27.2188 17.4167C27.2188 16.5019 27.9603 15.7604 28.875 15.7604H39.9167C40.8314 15.7604 41.5729 16.5019 41.5729 17.4167C41.5729 18.3314 40.8314 19.0729 39.9167 19.0729H28.875C27.9603 19.0729 27.2188 18.3314 27.2188 17.4167Z" fill={themeColorSecondary} />
      <path d="M29.4271 24.0417C29.4271 23.1269 30.1686 22.3854 31.0833 22.3854H39.9167C40.8314 22.3854 41.5729 23.1269 41.5729 24.0417C41.5729 24.9564 40.8314 25.6979 39.9167 25.6979H31.0833C30.1686 25.6979 29.4271 24.9564 29.4271 24.0417Z" fill={themeColorSecondary} />
      <path d="M31.6354 30.6667C31.6354 29.7519 32.3769 29.0104 33.2917 29.0104H39.9167C40.8314 29.0104 41.5729 29.7519 41.5729 30.6667C41.5729 31.5814 40.8314 32.3229 39.9167 32.3229H33.2917C32.3769 32.3229 31.6354 31.5814 31.6354 30.6667Z" fill={themeColorSecondary} />
      <path d="M17.8333 21.8333C20.2726 21.8333 22.25 19.8559 22.25 17.4167C22.25 14.9774 20.2726 13 17.8333 13C15.3941 13 13.4167 14.9774 13.4167 17.4167C13.4167 19.8559 15.3941 21.8333 17.8333 21.8333Z" fill={themeColorSecondary} />
      <path d="M17.8333 35.0833C26.6667 35.0833 26.6667 33.1059 26.6667 30.6667C26.6667 28.2274 22.7118 26.25 17.8333 26.25C12.9548 26.25 9 28.2274 9 30.6667C9 33.1059 9 35.0833 17.8333 35.0833Z" fill={themeColorSecondary} />
    </svg>
  );
}
