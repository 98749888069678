import { For, createSignal } from "solid-js";
import { Grid, Box, Button } from '@suid/material';
import { TextFieldCommon } from '@app/components/TextFieldCommon';


import { layoutBodyStyles } from '@app/assets/theme';
import { useBoundSource } from '@app/data/hooks';
import { Field } from '@app/pages/profile/profileHelper';
import AlertDialog from "@app/components/AlertDialog";
import { createMemo } from "solid-js";
import { getCategoryFormFields } from "./contact.category.fields";

interface ContactFormProps {
    isAddContact?: boolean;
}

const ContactForm = ({ isAddContact = false }: ContactFormProps) => {
    const source = useBoundSource();

    const [confirmAndDelete, setConfirmAndDelete] = createSignal(false);
    const [selectedCategory, setSelectedCategory] = createSignal(source.get('category') ?? '');

    const contactInfoFields = createMemo(() => {
        const category = selectedCategory();
        return getCategoryFormFields(category, (category: string) => {
            setSelectedCategory(category);
            source.set('relationShip', '');
            source.set('categoryType', '');
            source.set('isEmergencyContact', false);
        });
    });

    return (
        <Box sx={layoutBodyStyles.formBox}>
            <Grid container spacing={{ xs: 1, md: 1 }}>
                <For each={contactInfoFields()}>{(field: Field) => {
                    const FieldComponent = field.component || TextFieldCommon;
                    return (
                        <FieldComponent
                            name={field.name}
                            label={field.label}
                            fullWidth={field.fullWidth}
                            autoComplete={field.autoComplete}
                            gxs={field.gxs}
                            mode={field.mode}
                            endAdornmentValue={field.endAdornmentValue}
                            multiline={field.multiline}
                            shrink={field.shrink}
                            placeholder={field.placeholder}
                        />
                    );
                }}</For>

                {!isAddContact && (
                    <>
                        <Grid item xs={12} sx={{ marginTop: '20px' }}>
                            <Button
                                color="primary"
                                variant='text'
                                fullWidth
                                onClick={() => setConfirmAndDelete(true)}
                                sx={{
                                    textTransform: 'none'
                                }}
                            >
                                Delete this contact record
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            <AlertDialog
                id='contact_form_confirmation_dialog'
                type={'YesNo' as const}
                open={confirmAndDelete}
                button1='Yes'
                button2='Cancel'
                button2ErrorButton={true}
                button2Variant={'outlined' as const}
                title='Delete Contact'
                description='Are you sure you want to delete this contact?'
                onClick={(e: any, index: number) => {
                    setConfirmAndDelete(false);
                    if (index !== 0) {
                        return;
                    }

                    // confirmed, mark as deleted and save
                    source.markAsDeleted();
                    source.save();
                }}
            />
        </Box>
    );
};

export default ContactForm;
