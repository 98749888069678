export default function DentalInsuranceIcon() {
    return (
        <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M37.96 7.30005H6.04C4.70331 7.30005 3.42137 7.83105 2.47618 8.77623C1.531 9.72141 1 11.0034 1 12.34V31.66C1 32.9967 1.531 34.2787 2.47618 35.2239C3.42137 36.169 4.70331 36.7 6.04 36.7H37.96C39.2967 36.7 40.5786 36.169 41.5238 35.2239C42.469 34.2787 43 32.9967 43 31.66V12.34C43 11.0034 42.469 9.72141 41.5238 8.77623C40.5786 7.83105 39.2967 7.30005 37.96 7.30005Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M28.3 7.3V2.68C28.3 2.23444 28.123 1.80712 27.8079 1.49206C27.4928 1.177 27.0655 1 26.62 1H17.38C16.9344 1 16.5071 1.177 16.192 1.49206C15.877 1.80712 15.7 2.23444 15.7 2.68V7.3" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M31.5015 15.2813C31.0179 13.2951 29.4322 11.6769 27.4601 11.1648C26.0462 10.7976 24.6929 11.3888 23.5447 12.2335C23.402 12.3384 23.239 12.3973 23.0835 12.4742L24.3274 13.274C24.6533 13.4835 24.7482 13.9184 24.5383 14.2452C24.3424 14.5504 23.9115 14.6782 23.5671 14.456L19.1524 11.6176C18.2431 11.1411 17.247 10.8973 16.2178 11.1648C14.2457 11.6774 12.66 13.2951 12.1764 15.2813C11.7315 17.1094 12.158 18.8308 13.1208 20.1318C14.1468 21.5185 14.5562 23.1476 14.7148 24.847C14.908 26.9184 15.1685 28.9828 15.6345 31.0103L15.9771 32.5015C16.1172 33.1108 16.6575 33.5425 17.2803 33.5425C17.894 33.5425 18.4289 33.1231 18.5778 32.5253L20.0919 26.4454C20.2922 25.6407 21.0125 25.0767 21.8383 25.0767C22.6641 25.0767 23.3844 25.6411 23.5847 26.4454L25.0987 32.5253C25.2476 33.1231 25.7826 33.5425 26.3962 33.5425C27.0191 33.5425 27.5593 33.1108 27.6994 32.5015L28.0421 31.0103C28.5081 28.9833 28.7681 26.9184 28.9618 24.847C29.1204 23.1471 29.5297 21.5185 30.5558 20.1318C31.5199 18.8312 31.9464 17.1099 31.5015 15.2813Z" fill="#333333" />
            </g>
        </svg>
    );
}
