import { Behavior } from './behavior.ts';
import { ArgumentNilError, InvalidArgumentError, objects } from '@rdt-utils';

export type DefaultValue<T extends number | string | string[] | Date | boolean> = T | Behavior;

const __dt = new Date(0);
export const Convert = {
    toArray(
        value: any,
        defaultValue: DefaultValue<string[]> = Behavior.FailIfInvalid,
        delimiter?: string,
    ): string[] {
        if (objects.isNil(value)) {
            return handleNiL(defaultValue, []);
        }

        if (objects.isStr(value)) {
            return value.split(delimiter ?? ',').map((s) => s.trim()).filter((s) => s.length > 0);
        }

        return handleInvalidNiL(value, defaultValue, []);
    },
    toNum(value: any, defaultValue: DefaultValue<number> = Behavior.FailIfInvalid): number {
        if (objects.isNil(value)) {
            return handleNiL(defaultValue, 0);
        }

        const parseResult = parseInt(value, 10);
        if (!Number.isNaN(parseResult)) {
            return parseResult;
        }

        return handleInvalidNiL(value, defaultValue, 0);
    },
    toDt(value: any, defaultValue: DefaultValue<Date> = Behavior.FailIfInvalid): Date {
        if (objects.isNil(value)) {
            return handleNiL(defaultValue, __dt);
        }

        const dt = new Date(value);
        if (dt.toString() !== 'Invalid Date') {
            return dt;
        }

        return handleInvalidNiL(value, defaultValue, __dt);
    },
    toBool(value: any, defaultValue: DefaultValue<boolean> = Behavior.FailIfInvalid): boolean {
        if (objects.isNil(value)) {
            return handleNiL(defaultValue, false);
        }

        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'string') {
            value = value.trim().toLowerCase();
            if (value === 'true' || value === 'yes') {
                return true;
            }
            if (value === 'false' || value === 'no') {
                return false;
            }
        }

        return handleInvalidNiL(value, defaultValue, false);
    },
    toStr(value: any, defaultValue: DefaultValue<string> = Behavior.FailIfInvalid): string {
        if (objects.isNil(value)) {
            return handleNiL(defaultValue, '');
        }

        if (typeof value === 'string') {
            return value;
        }

        return handleInvalidNiL(value, defaultValue, '');
    },
    toObject<T>(json: string, defaultValue: undefined | T): T | undefined {
        if (objects.isNil(json)) {
            return handleNiL(defaultValue, null) ?? undefined;
        }

        try {
            return JSON.parse(json) ?? undefined;
        } catch (error) {
            return handleInvalidNiL(json, defaultValue, null) ?? undefined;
        }
    },
};

function handleNiL<T>(behaviorOrDefault: undefined | T | Behavior, typeDefault: T): T {
    if (!(behaviorOrDefault instanceof Behavior)) {
        return objects.isNil(behaviorOrDefault) ? typeDefault : behaviorOrDefault;
    }

    if (behaviorOrDefault === Behavior.FailIfNil || behaviorOrDefault === Behavior.FailIfEither) {
        throw new ArgumentNilError('value cannot be nil');
    }

    return typeDefault;
}

function handleInvalidNiL<T>(
    actual: any,
    behaviorOrDefault: undefined | T | Behavior,
    typeDefault: T,
): T {
    if (!(behaviorOrDefault instanceof Behavior)) {
        return objects.isNil(behaviorOrDefault) ? typeDefault : behaviorOrDefault;
    }

    if (behaviorOrDefault === Behavior.FailIfInvalid || behaviorOrDefault === Behavior.FailIfEither) {
        throw new InvalidArgumentError(`value ${actual} is invalid`);
    }

    return typeDefault;
}
