import { createSignal, JSXElement } from 'solid-js';
import {
    AppBar,
    Toolbar,
    Paper,
    Fab,
    Box,
    IconButton
} from '@suid/material';

import MoreIcon from '@suid/icons-material/MoreVertOutlined';
import CloseIcon from '@suid/icons-material/Close';

type ActionOption = { icon: JSXElement, onClick: () => void };

interface ActionBarProps {
    showAction?: boolean;
    actionIcon?: JSXElement;
    actionClick?: () => void;
    showMoreActions?: boolean;
    moreActionsOptions?: ActionOption | ActionOption[];
}

const ActionBar = ({
    actionIcon,
    actionClick,
    showAction,
    showMoreActions,
    moreActionsOptions = []
}: ActionBarProps) => {

    showAction = showAction !== false && actionIcon !== null;
    actionClick = actionClick ?? (() => { });

    moreActionsOptions = Array.isArray(moreActionsOptions) ? moreActionsOptions : [moreActionsOptions];
    showMoreActions = showMoreActions !== false && moreActionsOptions && moreActionsOptions.length! > 0;

    const [isSpeedDialOpen, setIsSpeedDialOpen] = createSignal(false);
    const toggleSpeedDial = () => {
        setIsSpeedDialOpen(!isSpeedDialOpen());
    };

    const handleMoreActionSelect = (action: ActionOption) => {
        action.onClick();
        toggleSpeedDial();
    };

    return (
        <div>
            <Paper
                sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                elevation={3}
            >
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0, paddingBottom: "20px" }}
                >
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        {showAction && (
                            <Fab
                                size="large"
                                sx={{
                                    position: "absolute",
                                    top: -40,
                                    left: 0,
                                    right: 0,
                                    margin: "0 auto",
                                    border: `5px solid #FFFFFF`,
                                    width: "80px",
                                    height: "80px",
                                    boxShadow: "none",
                                    backgroundColor: "#EC8C76",
                                    color: "#FFFFFF",
                                    '&:hover, &:focus, &:active': {
                                        backgroundColor: "#EC8C76",
                                        boxShadow: "none"
                                    }
                                }}
                                onClick={actionClick}
                            >
                                {actionIcon}
                            </Fab>
                        )}
                        {!isSpeedDialOpen() && showMoreActions && (
                            <IconButton
                                color="inherit"
                                onClick={toggleSpeedDial}
                            >
                                <MoreIcon fontSize="large" sx={{ fontSize: "45px" }} />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
            </Paper>
            {isSpeedDialOpen() && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                        paddingBottom: "100px",
                        zIndex: 9999, //Needed to overlap the carecard photo - TODO: integrate into theme and zindex definitions, find the rouge zindex thats prioritizing the carecard photo
                    }}
                >
                    {moreActionsOptions.map((action) => (
                        <Fab
                            color="secondary"
                            size="large"
                            sx={{
                                mb: 2,
                                width: "80px",
                                height: "80px",
                            }}
                            onClick={() => handleMoreActionSelect(action)}
                        >
                            {action.icon}
                        </Fab>
                    ))}
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: "26px",
                            bottom: "20px"
                        }}
                        color="inherit"
                        onClick={toggleSpeedDial}
                    >
                        <CloseIcon
                            fontSize="large"
                            color="inherit"
                            sx={{ fontSize: "45px", color: "#FFFFFF" }}
                        />
                    </IconButton>
                </Box>
            )}
        </div>
    );
};

export default ActionBar;