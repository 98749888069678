import EmergencyContactIcon from "@app/assets/icons/EmergencyContactIcon";
import AlertDialog from "@app/components/AlertDialog";
import { Contact } from "@app/entities/contacts";
import { svc } from "@app/utils/svc";
import { Stack, IconButton } from "@suid/material";
import { createSignal } from "solid-js";

export const EmergencyCareTeamAttribute = (props: { item: Contact }) => {
    const [showDialog, setShowDialog] = createSignal(false);

    const handleInfoClick = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <div>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%', height: '100%', marginTop: '30px' }}
            >
                <IconButton onClick={handleInfoClick}>
                    <EmergencyContactIcon />
                </IconButton>
            </Stack>

            <AlertDialog
                open={showDialog}
                onClose={handleClose}
                title="Emergency Care Team"
                description={`${props.item.name} is a member of ${svc.card.cardInfo()?.firstName || 'Subject'}'s emergency care team. They will be contacted in case of an emergency and appear on the Care Card you share with others.`}
                type="Ok"
                onClick={() => {
                    handleClose();
                }}
            />
        </div>
    );
};