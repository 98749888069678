import { shareCareCardStyles } from "@app/assets/theme";
import { Ecc, QrCode } from "@app/utils/qrcodegen";
import CloseIcon from '@suid/icons-material/Cancel';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@suid/material";
import { Signal, createEffect } from "solid-js";

export type Props = {
    open: Signal<boolean>;
    url: () => string;
    id?: string;
    onClose?: () => void;
}

export default function QrCodeDialog(props: Props) {
    const id = props.id || 'qr-code-dialog-';

    createEffect(() => {
        if (props.open[0]()) {
            setTimeout(() => {
                const errCorLvl: Ecc = Ecc.LOW;  // Error correction level
                const qr: QrCode = QrCode.encodeText(props.url(), errCorLvl);  // Make the QR Code symbol
                drawCanvas(qr, `${id}-canvas`);
            }, 0);
        }
    });

    return (
        <div>
            <Dialog
                open={props.open[0]()}
                aria-labelledby={`${id}-title`}
                sx={{ '& .MuiDialog-paper': { width: '100%', height: '100%' } }}
                maxWidth="xs"
            >
                <DialogTitle sx={shareCareCardStyles.dialogTitle}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            props.open[1](false);
                            props?.onClose?.();
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    marginTop: 2,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <canvas id={`${id}-canvas`} style={{
                        "align-items": "center"
                    }}>
                    </canvas>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export function drawCanvas(qr: QrCode, id: string): void {
    const scale = 6;
    const border = 2;
    const lightColor = "#FFFFFF"
    const darkColor = "#000000";
    const canvas: HTMLCanvasElement = document.getElementById(id) as HTMLCanvasElement;
    const width: number = (qr.size + border * 2) * scale;
    canvas.width = width;
    canvas.height = width;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    for (let y = -border; y < qr.size + border; y++) {
        for (let x = -border; x < qr.size + border; x++) {
            ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
            ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale);
        }
    }
}
