import { AsyncSignal } from '@app/utils/async.signal';
import { CircularIndeterminate } from '../components/CircularIndeterminate';
import { JSX } from 'solid-js';

type Props = { init: Promise<void>, fallback?: () => JSX.Element, children: () => JSX.Element }
export const Pending = (props: Props) => {
    const awaiting = AsyncSignal.onPending(props.init);
    const fallback = props.fallback ?? (() => <CircularIndeterminate />)

    return (<>
        {!awaiting() && props.children()}
        {awaiting() && fallback()}
    </>);
}