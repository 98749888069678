
import { Signal, createEffect, createSignal, onMount } from 'solid-js';
import {
    Drawer,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Backdrop,
    CircularProgress,
    Paper,
    Divider,
    Chip
} from '@suid/material';

import QrCodeDialog from '../../components/QrCodeDialog';
import { logger } from '@libs/logger/mod.ts';
import { svc } from '@app/utils/svc';
import { ShareActionView, SubjectView } from './types';
import { ShareCareCardAction } from './ShareCareCardAction';
import { subjectViewService } from './subjectViewService';

import CloseIcon from '@suid/icons-material/CloseOutlined';
import { utils } from '@app/host/utils';

interface ShareCareCardProps {
    open: () => boolean;
    onClose: () => void;
    shareAction: () => ShareActionView;
}

// SHARE CARE CARD COMPONENT
// Preview the care card content to be shared and select your share method
export function ShareCareCard({ open, onClose, shareAction }: ShareCareCardProps) {
    const [scroll] = createSignal('careCardContent');

    const showQrCode = createSignal(false);

    const [generatingUrl, setGeneratingUrl] = createSignal(true);

    const [urlFieldValue, setUrlFieldValue] = createSignal('');

    const [resolvedAction, setResolvedAction] = createSignal<ShareActionView>() as Signal<ShareActionView>;
    const [resolvedSubject, setResolvedSubject] = createSignal<SubjectView>() as Signal<SubjectView>;

    const isCordova = utils.isCordovaApp();
    const fullscreenHeight = isCordova ? '85vh' : '98vh';
    const drawerHeight = fullscreenHeight;
    const isBackdropRequired = isCordova || drawerHeight !== fullscreenHeight; // if the drawer height is less than fullscreen or Cordova the backdrop is required


    createEffect(() => {
        const action = shareAction();
        if (!action) {
            return;
        }

        const subject = subjectViewService.getSubjectView(action.type);
        const value = subject();
        if (!value) {
            return;
        }

        setResolvedSubject(value);
        setResolvedAction(action)

        setGeneratingUrl(true);
        svc.share.share(value.__relId, value, action.ttlMinutes)
            .then(url => setUrlFieldValue(url.toString()))
            .catch(e => logger.emit('share failure: ' + e))
            .finally(() => setGeneratingUrl(false));
    });

    const showQRCode = () => {
        showQrCode[1](true);
    };

    let careCardContent: any;

    const handleClose = () => {
        onClose();
    };

    onMount(() => {
        if (open()) {
            careCardContent?.focus();
        }
    });

    return (
        <>
            <QrCodeDialog open={showQrCode} url={urlFieldValue} />
            <Drawer
                open={open()}
                onClose={handleClose}
                anchor="bottom"
                BackdropProps={{ invisible: !isBackdropRequired }}
                sx={{
                    height: drawerHeight,
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        height: 'inherit'
                    }
                }}
            >
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', "align-items": 'center', "padding-top": '5px', "justify-content": 'space-between' }}>
                        <div style={{ display: 'flex', "align-items": 'center' }}>
                            <Typography variant='h6' color='text.primary' style={{ "padding-left": '5px', cursor: 'pointer' }} onClick={handleClose}>
                                Share with {resolvedAction()?.name ?? ''}
                            </Typography>
                        </div>
                        <div style={{ "margin-left": 'auto' }}>
                            <div style={{ display: 'flex', "justify-content": 'flex-end' }}>
                                <CloseIcon fontSize='large' style={{ color: '#979797', cursor: 'pointer', padding: 0 }} onClick={handleClose} />
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent dividers={scroll() === 'careCardContent'} sx={{ backgroundColor: "#EFEFEF", }}>
                    <Divider><Typography><Chip variant="filled" label="Care Card Preview" sx={{}} /></Typography></Divider>
                    <Paper elevation={3} sx={{ backgroundColor: "#FFFFFF", margin: 1, padding: 3 }}>
                        {resolvedAction() && <DialogContentText
                            ref={careCardContent}
                            tabIndex={-1}>
                            {resolvedAction().component(resolvedSubject)}
                        </DialogContentText>}
                    </Paper>
                </DialogContent>
                <ShareCareCardAction shareUrl={urlFieldValue} showQRCode={showQRCode} />
            </Drawer>
            <Backdrop
                sx={{ color: '#fff' }}
                open={generatingUrl()}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
        </>
    );
}