export const InsuranceTypeList = [
    { value: 'Medical', label: 'Medical' },
    { value: 'Vision', label: 'Vision' },
    { value: 'Dental', label: 'Dental' },
    { value: 'RxPlan', label: 'Prescription Plan' },
    { value: 'Other', label: 'Other' },
];

const commonAdditionalFields = [
    { name: 'deductible', label: 'Deductible', multiline: true },
    { name: 'coIns', label: 'Coinsurance', multiline: true },
    { name: 'planNumber', label: 'Plan Number', multiline: false },
    { name: 'planType', label: 'Plan Type', multiline: false },
    { name: 'network', label: 'Network', multiline: false },
    { name: 'payerID', label: 'Payer ID', multiline: false },
    { name: 'effectiveDate', label: 'Effective Date', multiline: false },
    { name: 'dependents', label: 'Dependents', multiline: true },
];

const rxFields = [
    { name: 'rxName', label: 'Rx Plan Name' },
    { name: 'rxID', label: 'Rx ID', multiline: false },
    { name: 'rxBIN', label: 'Rx BIN', multiline: false },
    { name: 'rxGRP', label: 'Rx GRP', multiline: false },
    { name: 'rxPCN', label: 'Rx PCN', multiline: false },
    { name: 'rxNetwork', label: 'Rx Network', multiline: false },
    { name: 'rxFormulary', label: 'Rx Formulary', multiline: false },
    { name: 'rxCopay', label: 'Rx Copay', multiline: true },
];

export const InsuranceFieldMapping = {
    Medical: [
        ...commonAdditionalFields,
        ...rxFields,
    ],
    Vision: [
        { name: 'planType', label: 'Plan Type', multiline: false },
        { name: 'network', label: 'Network', multiline: false },
        { name: 'effectiveDate', label: 'Effective Date', multiline: false },
    ],
    Dental: [
        ...commonAdditionalFields,
    ],
    RxPlan: [
        ...rxFields,
    ],
    Other: [
        ...commonAdditionalFields,
    ],
};