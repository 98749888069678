import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Divider } from '@suid/material';

import { useBoundSource } from '../data/hooks';
import { useIsReadOnlyMode } from '@app/utils/hooks';
import { svc } from '@app/utils/svc.ts';

export type Props = {
    name: string;
    label?: string;
    gxs?: number;
    inContacts?: boolean;
    value?: string;
    onInput?: (event: any) => void;
};

export default function RelationshipSelect(props: Props) {
    const source = useBoundSource();
    const isReadonly = useIsReadOnlyMode();
    const inContacts = props.inContacts === true;
    const label = inContacts
        ? `Relationship to ${svc.card.cardInfo()?.firstName || 'Subject'}`
        : 'Relationship to You';


    const select = (
        <Box sx={{ background: '#FFFFFF' }}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>{label}</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={props.name}
                    value={source.get(props.name) ?? ''}
                    label={label}
                    variant='filled'
                    onChange={(ev) => source.set(props.name, ev.target.value)}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    {/* Family relationship types */}
                    {!inContacts && (<MenuItem value={'Self'}>Self </MenuItem>)}
                    {/* Immediate relationship types */}
                    <MenuItem value={'Spouse'}>Spouse</MenuItem>
                    <MenuItem value={'Father'}>Father</MenuItem>
                    <MenuItem value={'Mother'}>Mother</MenuItem>
                    <MenuItem value={'Son'}>Son</MenuItem>
                    <MenuItem value={'Daughter'}>Daughter</MenuItem>
                    <Divider />
                    {/* Extended family relationship types */}
                    <MenuItem value={'Brother'}>Brother</MenuItem>
                    <MenuItem value={'Sister'}>Sister</MenuItem>
                    <MenuItem value={'Grandmother'}>Grandmother</MenuItem>
                    <MenuItem value={'Grandfather'}>Grandfather</MenuItem>
                    <MenuItem value={'Niece'}>Niece</MenuItem>
                    <MenuItem value={'Nephew'}>Nephew</MenuItem>
                    <MenuItem value={'Uncle'}>Uncle</MenuItem>
                    <MenuItem value={'Aunt'}>Aunt</MenuItem>
                    <MenuItem value={'Cousin'}>Cousin</MenuItem>
                    <Divider />
                    {/* Romantic relationship types */}
                    <MenuItem value={'Partner'}>Partner</MenuItem>
                    <MenuItem value={'Significant Other'}>Significant Other</MenuItem>
                    {/* Friend relationship types */}
                    <MenuItem value={'Friend'}>Friend</MenuItem>
                    <MenuItem value={'Neighbor'}>Neighbor</MenuItem>
                    <Divider />
                    <MenuItem value={'Father-In-Law'}>Father-In-Law</MenuItem>
                    <MenuItem value={'Mother-In-Law'}>Mother-In-Law</MenuItem>
                    <MenuItem value={'Sister-In-Law'}>Sister-In-Law</MenuItem>
                    <MenuItem value={'Brother-In-Law'}>Brother-In-Law</MenuItem>
                    <MenuItem value={'Daughter-In-Law'}>Daughter-In-Law</MenuItem>
                    <MenuItem value={'Son-In-Law'}>Son-In-Law</MenuItem>
                    <MenuItem value={'Grandfather-In-Law'}>Grandfather-In-Law</MenuItem>
                    <MenuItem value={'Grandmother-In-Law'}>Grandmother-In-Law</MenuItem>
                    {/* Catchall */}
                    <Divider />
                    <MenuItem value={'Other'}>Relationship Not Listed</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    if (!props.gxs) {
        return select;
    }

    return (
        <Grid item xs={props.gxs}>
            {select}
        </Grid>
    );
}
