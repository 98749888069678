

export default function MedicationIcon() {
    return (
<svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path d="M7.18077 0.723877C3.21466 0.725233 0.0013564 3.93989 0 7.90464V26.0953C0 30.0614 3.21466 33.276 7.18077 33.276C11.1469 33.276 14.3615 30.0614 14.3615 26.0953V7.90464C14.3507 3.94396 11.1414 0.734728 7.18077 0.723877ZM12.7976 16.218H1.56393V7.90464C1.56393 4.80256 4.07869 2.28645 7.18212 2.28645C10.2856 2.28645 12.8003 4.80121 12.8003 7.90464L12.7976 16.218Z" fill="#333333"/>
<path d="M30.1622 20.5042C28.738 18.8372 26.6342 17.7874 24.2849 17.7874C20.0231 17.7874 16.5684 21.2421 16.5684 25.5039C16.5684 26.9051 16.9414 28.2194 17.5952 29.352L17.5748 29.314L30.1622 20.5042Z" fill="#333333"/>
<path d="M18.4061 30.5023C19.8303 32.1693 21.9341 33.2192 24.2834 33.2192C28.5452 33.2192 31.9999 29.7644 31.9999 25.5026C31.9999 24.1015 31.6269 22.7871 30.9731 21.6545L30.9935 21.6925L18.4061 30.5023Z" fill="#333333"/>
<path d="M24.9048 1.81038H24.9116C27.6678 1.81038 29.9031 4.04572 29.9031 6.80192C29.9031 7.98877 29.4894 9.07931 28.7977 9.9352L28.8044 9.9257L23.3124 2.07081C23.7871 1.90669 24.3324 1.81038 24.9007 1.80903L24.9048 1.81038ZM24.9048 0.957208C24.9021 0.957208 24.8994 0.957208 24.8967 0.957208C23.8414 0.957208 22.8526 1.23934 21.9994 1.73171L22.0279 1.71679L28.6959 11.2523C29.9601 10.1739 30.7563 8.5788 30.7563 6.79785C30.7563 3.56963 28.1398 0.951782 24.9102 0.951782C24.9061 0.951782 24.9007 0.951782 24.8967 0.951782H24.898L24.9048 0.957208Z" fill="#333333"/>
<path d="M21.0227 3.6795L26.5148 11.5344C26.0373 11.7012 25.4866 11.7989 24.9129 11.7989C22.1567 11.7989 19.9213 9.56355 19.9213 6.80735C19.9213 5.61915 20.3364 4.52725 21.0295 3.67001L21.0227 3.6795ZM21.1313 2.34888C19.863 3.42721 19.0627 5.02369 19.0627 6.80735C19.0627 10.0329 21.6779 12.648 24.9034 12.648C25.9654 12.648 26.961 12.3645 27.8183 11.8694L27.7898 11.8843L21.1313 2.34888Z" fill="#333333"/>
</g>
</svg>
  );
}
