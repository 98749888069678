

export default function HeightIcon() {
    return (
<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.3576 1.731H10.7776C10.607 1.731 10.4434 1.66322 10.3227 1.54258C10.2021 1.42193 10.1343 1.25829 10.1343 1.08767C10.1343 0.917047 10.2021 0.753412 10.3227 0.632764C10.4434 0.512115 10.607 0.444336 10.7776 0.444336H22.3576C22.5282 0.444336 22.6919 0.512115 22.8125 0.632764C22.9332 0.753412 23.001 0.917047 23.001 1.08767C23.001 1.25829 22.9332 1.42193 22.8125 1.54258C22.6919 1.66322 22.5282 1.731 22.3576 1.731Z" fill="#333333"/>
<path d="M22.3576 8.96733H10.7776C10.607 8.96733 10.4434 8.89955 10.3227 8.7789C10.2021 8.65826 10.1343 8.49462 10.1343 8.324C10.1343 8.15338 10.2021 7.98974 10.3227 7.86909C10.4434 7.74844 10.607 7.68066 10.7776 7.68066H22.3576C22.5282 7.68066 22.6919 7.74844 22.8125 7.86909C22.9332 7.98974 23.001 8.15338 23.001 8.324C23.001 8.49462 22.9332 8.65826 22.8125 8.7789C22.6919 8.89955 22.5282 8.96733 22.3576 8.96733Z" fill="#333333"/>
<path d="M22.3576 16.2037H10.7776C10.607 16.2037 10.4434 16.1359 10.3227 16.0152C10.2021 15.8946 10.1343 15.7309 10.1343 15.5603C10.1343 15.3897 10.2021 15.2261 10.3227 15.1054C10.4434 14.9848 10.607 14.917 10.7776 14.917H22.3576C22.5282 14.917 22.6919 14.9848 22.8125 15.1054C22.9332 15.2261 23.001 15.3897 23.001 15.5603C23.001 15.7309 22.9332 15.8946 22.8125 16.0152C22.6919 16.1359 22.5282 16.2037 22.3576 16.2037Z" fill="#333333"/>
<path d="M22.3599 23.4444H10.7799C10.6093 23.4444 10.4457 23.3766 10.325 23.256C10.2044 23.1353 10.1366 22.9717 10.1366 22.801C10.1366 22.6304 10.2044 22.4668 10.325 22.3461C10.4457 22.2255 10.6093 22.1577 10.7799 22.1577H22.3599C22.5306 22.1577 22.6942 22.2255 22.8148 22.3461C22.9355 22.4668 23.0033 22.6304 23.0033 22.801C23.0033 22.9717 22.9355 23.1353 22.8148 23.256C22.6942 23.3766 22.5306 23.4444 22.3599 23.4444Z" fill="#333333"/>
<path d="M0.187001 20.694C0.065859 20.5728 -0.00219798 20.4085 -0.00219798 20.2372C-0.00219798 20.0659 0.065859 19.9016 0.187001 19.7804C0.308143 19.6593 0.472447 19.5912 0.643768 19.5912C0.815089 19.5912 0.979393 19.6593 1.10054 19.7804L2.56734 21.2472V2.64846L1.10054 4.11526C0.977647 4.23306 0.813999 4.29883 0.643768 4.29883C0.473538 4.29883 0.309889 4.23306 0.187001 4.11526C0.126702 4.05546 0.0788423 3.9843 0.0461811 3.90591C0.0135199 3.82751 -0.0032959 3.74342 -0.0032959 3.65849C-0.0032959 3.57357 0.0135199 3.48948 0.0461811 3.41108C0.0788423 3.33269 0.126702 3.26153 0.187001 3.20173L2.76034 0.628392C2.87755 0.514293 3.03423 0.449775 3.1978 0.448259C3.37336 0.447056 3.54308 0.511273 3.67387 0.628392L6.24721 3.20173C6.36186 3.32621 6.42551 3.48926 6.42551 3.65849C6.42551 3.82773 6.36186 3.99078 6.24721 4.11526C6.1874 4.17556 6.11625 4.22342 6.03785 4.25608C5.95945 4.28874 5.87537 4.30556 5.79044 4.30556C5.70551 4.30556 5.62142 4.28874 5.54303 4.25608C5.46463 4.22342 5.39348 4.17556 5.33367 4.11526L3.854 2.63559V21.2537L5.33367 19.774C5.39366 19.714 5.46487 19.6664 5.54324 19.634C5.62161 19.6015 5.70561 19.5848 5.79044 19.5848C5.87527 19.5848 5.95927 19.6015 6.03764 19.634C6.11601 19.6664 6.18722 19.714 6.24721 19.774C6.30719 19.834 6.35477 19.9052 6.38723 19.9836C6.4197 20.0619 6.4364 20.1459 6.4364 20.2308C6.4364 20.3156 6.4197 20.3996 6.38723 20.478C6.35477 20.5563 6.30719 20.6276 6.24721 20.6875L3.67387 23.2609C3.55098 23.3787 3.38733 23.4444 3.2171 23.4444C3.04687 23.4444 2.88322 23.3787 2.76034 23.2609L0.187001 20.694Z" fill="#333333"/>
</svg>
  );
}
