
// TODO: remove spacing

export type HomeLayoutProps<
    T extends { name: string; age: string } = { name: string; age: string }>
    = ParentProps & {
        subject?: Resource<T>
    };

export const HomeLayout = (props: HomeLayoutProps) => {
    const [open, setOpen] = createSignal(false);
    const [selectedValue, setSelectedValue] = createSignal<ShareActionView>();
    const [shareCareCardOpen, setShareCareCardOpen] = createSignal(false);
    const subject = props.subject ?? useBoundSourceReadonly<{ name: string; age: string }>();

    const handleClose = (action: ShareActionView | null) => {
        setOpen(false);
        if (action && shareActions.some(a => a.type === action.type)) {
            setSelectedValue(action);
            setShareCareCardOpen(true);
        }
    };

    const [confirmAndDelete, setConfirmAndDelete] = createSignal(false);
    const [isDeleted, setIsDeleted] = createSignal(-1);
    createEffect(() => {
        if (isDeleted() < 0) {
            return;
        }

        if (isDeleted() === 0) {
            svc.cardService
                .delete(svc.cardService.currentCard)
                .then(() => setIsDeleted(1));
        } else if (isDeleted() === 1) {
            useNavigate()(routeStrings.cardSelectionPath, { replace: true });
        }
    });

    return (
        <>
            <Box sx={layoutHeaderStyles.layoutHeaderContainer}>
                <ActionMenu />

                <Link href={`#${routeStrings.cardSelectionPath}`}>
                    <Avatar sx={layoutBodyStyles.backAction}>
                        <BackArrowIcon fontSize='small' color="primary" />
                    </Avatar>
                </Link>
                <CallToAction />
                <CareCardPhoto />
            </Box>

            <Box sx={layoutBodyStyles.layoutBodyContainer}>
                <CareCardIdentity subject={subject} editMode={true} />
                <Box sx={layoutBodyStyles.featureListingContainer}>
                    <Grid container spacing={{ xs: 3, md: 3 }}>
                        {props.children}
                        {/* temporary fix for space needed to account for actionbar */}
                        <Box sx={{ height: '220px' }}></Box>
                    </Grid>
                </Box>

                <ActionBar
                    actionIcon={<ShareIcon fontSize="large" sx={{ fontSize: "40px" }} />}
                    actionClick={() => setOpen(true)}
                    moreActionsOptions={{
                        icon: <DeleteIcon fontSize="large" />,
                        onClick: () => setConfirmAndDelete(true)
                    }}
                />
            </Box>
            <ShareChoiceDialog open={open} onClose={handleClose} />
            {selectedValue() && (
                <>
                    <ShareCareCard
                        open={shareCareCardOpen}
                        onClose={() => setShareCareCardOpen(false)}
                        shareAction={selectedValue as () => ShareActionView}
                    />
                </>
            )}
            {confirmAndDelete() && <AlertDialog
                id='delete_care_card_confirmation_dialog'
                type='YesNo'
                open={() => true}
                button1='Yes'
                button2='Cancel'
                button2ErrorButton={true}
                button2Variant={'outlined' as const}
                title='Delete Care Card'
                description='Are you sure you want to delete this Care Card?'
                onClick={(_ev, _idx, name) => {
                    if (name?.toLowerCase() === 'yes') {
                        setIsDeleted(0);
                    } else {
                        setConfirmAndDelete(false);
                    }
                }}
            />}
        </>
    );
};

export default HomeLayout;
import { Box, Grid, Link, Avatar, } from '@suid/material';
import { createEffect, createSignal, ParentProps, Resource } from 'solid-js';

import ActionMenu from '@app/components/ActionMenu';
import CallToAction from '@app/components/CallToAction';
import ActionBar from '@app/components/ActionBar';
import BackArrowIcon from '@suid/icons-material/ArrowBackIosNewOutlined';
import { routeStrings } from '@app/routes/route.strings';

import CareCardIdentity from '@app/components/CareCardIdentity';
import CareCardPhoto from '@app/components/CareCardPhoto';
import { layoutBodyStyles, layoutHeaderStyles } from '@app/assets/theme.ts';
import { useBoundSourceReadonly } from '@app/data/hooks';

import ShareIcon from '@suid/icons-material/IosShareOutlined';
import DeleteIcon from '@suid/icons-material/DeleteForeverOutlined';

import { ShareCareCard } from '../share/ShareCareCard';
import { shareActions } from '../share/ShareActionView';
import { ShareActionView } from '../share/types';
import { ShareChoiceDialog } from '../share/ShareChoiceDialog';

import AlertDialog from '@app/components/AlertDialog';
import { svc } from '@app/utils/svc';
import { useNavigate } from '@solidjs/router';
