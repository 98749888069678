import { AUTH0_CLIENT_CONFIG } from "@app/auth/service/constants";
import { APP_API_ORIGIN, APP_ORIGIN, SYNC_INTERVAL_SECONDS, AUTH0_ALLOWED_AUTH_TYPES, UPDATE_REFRESH_INTERVAL_SECONDS } from "@app/defs/constants";
import { useLogger, LogLevel } from "@libs/logger/mod.ts";
import { Config } from "@libs/config";
import { Env } from "@libs/environment";
import appInfo from "../app.info";
import { svc } from "@app/utils/svc";

const logger = useLogger('RUNTIME');

export type RuntimeInfo = {
    apiOrigin: string;
    appOrigin: string;
    dataSyncInterval: number;
    appRefreshCheckInterval: number;
    envType: string;
    currentVersionDt: string;
    appStartedDt: Date;
    versionCheckDisabled: boolean;
    auth0: {
        allowedAuthTypes: readonly string[];
        domain: string;
    },
    checkLatestVersionDt: () => Promise<string>;
    userInfo: () => {
        mobile?: string;
        email?: string;
    };
}

const versionCheckDisabled = Config.APP_CFG_DISABLE_APP_INFO_CHECK.else(false) === true;
const currentVersion = versionCheckDisabled ? 'N/A' : appInfo.APP_BUILD_DATE_TIME;
const noCheckPromise = Promise.resolve<string>(currentVersion);
export const Runtime: RuntimeInfo = Object.freeze({
    apiOrigin: APP_API_ORIGIN,
    appOrigin: APP_ORIGIN,
    appStartedDt: new Date(),
    appRefreshCheckInterval: UPDATE_REFRESH_INTERVAL_SECONDS,
    dataSyncInterval: SYNC_INTERVAL_SECONDS,
    versionCheckDisabled,
    envType: Env.envType,
    currentVersionDt: currentVersion,
    auth0: {
        allowedAuthTypes: AUTH0_ALLOWED_AUTH_TYPES,
        domain: AUTH0_CLIENT_CONFIG.domain,
    },
    checkLatestVersionDt: versionCheckDisabled ?
        () => noCheckPromise :
        async () => {
            const response = await fetch('/.app-info');
            if (!response.ok) {
                if (response.status !== 404 || Env.envType !== 'dev') {
                    logger.emit(LogLevel.ERROR, `Error checking app info: ${response.status} ${response.statusText}`);
                }
                return Runtime.currentVersionDt;
            }

            const data = await response.json();
            if (!data.APP_BUILD_DATE_TIME || data.APP_BUILD_DATE_TIME === 'DEV_MODE') {
                return Runtime.currentVersionDt;
            }

            return data.APP_BUILD_DATE_TIME as string;
        },
    userInfo: () => {
        if (!svc.auth.isAuthenticated()) {
            return {};
        }

        const user = svc.auth.currentUser();
        return {
            email: user.email,
            mobile: user.phone,
        };
    }
});