import { AsyncSignal } from "@app/utils/async.signal";
import { DevUtil } from "@app/utils/dev.utils";
import { logger } from "@libs/logger/mod.ts";
import { createSignal, untrack } from "solid-js";
import { Trackable } from "../trackable";
import { EntityCore } from "@libs/db";
import { PageContext } from "../types";
import { useBeforeLeave } from "@solidjs/router";
import { svc } from "@app/utils/svc";
import { Task } from "@libs/async/task";

export const saveButtonHandler = <T extends EntityCore = EntityCore>(
    ctx: PageContext,
    trackable: Trackable<T>,
    disableSavePrompt = false,
    onSaveSuccess?: () => void
) => {
    const entity = trackable.source;
    const isNew = untrack(() => entity.updatedAt === entity.createdAt);

    ctx.setDisplayValidationErrors(!isNew);
    svc.tracker.onView(ctx.type, { isNew, createdAt: entity.createdAt });

    const saveChanges = () => {
        if (!ctx.displayValidationErrors()) {
            ctx.setDisplayValidationErrors(true);
        }

        if (!trackable.isValid()) {
            return Promise.resolve(false);
        }

        if (!trackable.hasChanges()) {
            return Promise.resolve(false);
        }

        const task = Task.create<boolean>();
        AsyncSignal
            .bindFalse(trackable.save(), () => { })
            .then(v => {
                svc.tracker.onSave(ctx.type, {
                    id: v._id,
                    updatedAt: v.updatedAt
                });
                task.resolve(true);
                if (onSaveSuccess) {
                    onSaveSuccess();
                }
            })
            .catch((err) => {
                logger.error('error saving data', err);
                task.resolve(false);
            });

        return task;
    }

    return {
        onClick: (e: Event) => {
            if (e.defaultPrevented) {
                return;
            }

            saveChanges().then(success => {
                if (!success) {
                    e.preventDefault();
                }
            });
        },
        confirmationDialogProps: () => confirmationDialogProps(ctx, saveChanges, disableSavePrompt, trackable)
    };
};

const confirmationDialogProps = (ctx: PageContext, saveChanges: () => Promise<boolean>, disableSavePrompt: boolean, trackable: Trackable) => {
    if (disableSavePrompt) {
        return {
            type: 'YesNoCancel' as const,
            open: () => false,
            title: '',
            description: '',
            onClick: () => { }
        }
    }

    const resetAndUntrack = () => {
        DevUtil.reset();
        trackable.untrack();
    };

    const [open, setOpen] = createSignal(false);
    let notifyOnNavCancelled = () => { };

    let retryClose = () => { };
    useBeforeLeave(e => {
        if (e.defaultPrevented || !trackable.hasChanges()) {
            resetAndUntrack();
            return;
        }

        const notifyOnCancel = (e.options as any)?.state?.notifyOnCancel;
        if (notifyOnCancel) {
            notifyOnNavCancelled = () => {
                notifyOnCancel();
                notifyOnNavCancelled = () => { };
            }
        }

        // preventDefault to block immediately and prompt user async
        e.preventDefault();
        setTimeout(() => {
            retryClose = () => e.retry(true);
            setOpen(true);
        }, 100);
    });

    return {
        type: 'YesNoCancel' as const,
        button1: 'Save',
        button2: 'Discard',
        button2ErrorButton: true,
        button2Variant: 'outlined' as const,
        open,
        title: 'Review Changes',
        description: 'You have unsaved changes. Do you want to save them?',
        onClick: (e: any, index: number) => {
            setOpen(false);

            const promptData = {
                hasChanges: trackable.hasChanges(),
                isValid: trackable.isValid(),
                discardChosen: index === 1
            };

            svc.tracker.onPrompt(ctx.type, promptData);
            if (index === 2) { // cancel
                notifyOnNavCancelled();
                return;
            }

            if (index === 0) { // save
                saveChanges().then(success => {
                    if (success) {
                        resetAndUntrack();
                        retryClose();
                    }
                });
            } else { // discard
                resetAndUntrack();
                retryClose();
            }
        }
    };
};