import AddIcon from '@suid/icons-material/Add';
import RemoveIcon from '@suid/icons-material/Clear';

import { Grid, Avatar, Button } from '@suid/material';
import PhoneTypeSelect from '../../../components/PhoneTypeSelect';
import TextFieldCommon from '../../../components/TextFieldCommon';
import { useBoundPhoneNumbersSource } from './phones.numbers';
import { For } from 'solid-js';

export type Props = {
    numberBaseName?: string;
    typeBaseName?: string;
    max?: number
}

type PhoneItem = {
    index: number;
    name: string;
    type: string;
    isEditable: () => boolean,
    makeEditable: () => void,
    remove: () => void
}

export default function PhoneNumbersByIndex(props: Props) {
    const { items, maxEditableReached } = useBoundPhoneNumbersSource(props.max, props.numberBaseName);

    const addItem = () => {
        return (
            <Grid item xs={12}>
                <Button
                    size='small'
                    color='primary'
                    type='button'
                    onClick={() => {
                        items.find(item => !item.isEditable())?.makeEditable();
                    }}
                >
                    <AddIcon fontSize='small' sx={{ mr: 1 }} />Add another phone number
                </Button>
            </Grid>
        );
    }

    const removeItem = (item: PhoneItem) => {
        return (
            <Grid item xs={1}>
                <Avatar
                    variant='circular'
                    sx={{
                        width: 25,
                        height: 25,
                        backgroundColor: '#EA7D7D',
                        color: '#FFFFFF',
                        top: 20,
                        cursor: 'pointer',
                    }}
                    onClick={() => item.remove()}
                >
                    <RemoveIcon fontSize='small' />
                </Avatar>
            </Grid>
        );
    }

    return (
        <>
            <For each={items}>{(item) =>
                <>
                    {item.isEditable() && (
                        <>
                            <TextFieldCommon
                                mode='tel'
                                name={item.name}
                                label='Phone'
                                fullWidth={true}
                                gxs={6}
                            />
                            <PhoneTypeSelect name={item.type} phoneIndex={item.index} gxs={5} />
                            {item.index > 1 && removeItem(item)}
                        </>
                    )}
                </>}
            </For>
            {!maxEditableReached() && addItem()}
        </>
    );
}
