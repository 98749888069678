import { CUSTOMER_IO_SERVICE, GLEAP_SERVICE, SW_SERVICE } from "./types"
import { AUTH_SERVICE } from "@app/auth/auth.service"
import { VAULT_DB } from "@app/db/mod.ts"
import { CARD_SERVICE, CARD_SERVICE_SELECTOR } from "@app/card-service/types.ts"
import { Service, ServiceName } from "@libs/services/service.ts"
import { EVENT_TRACKER } from "@app/services/event-tracker/event.tracker"
import { shareService } from "@app/services/share-service/share.service"
import { utils } from "../host"
import { type Host, host } from "@libs/services/host"
import { MED_LOOKUP_SERVICE } from "@app/services/meds-lookups"

let local: Host = {
    get<T extends Service>(name: ServiceName<T>): T {
        return host.getUnsafe(name)!;
    }
}

host.onStart().then((host) => local = host);

class Svc {
    get tracker() {
        return local.get(EVENT_TRACKER)
    }

    get meds() {
        return local.get(MED_LOOKUP_SERVICE)
    }

    get gleap() {
        return local.get(GLEAP_SERVICE)
    }

    get cio() {
        return local.get(CUSTOMER_IO_SERVICE)
    }

    get sw() {
        return local.get(SW_SERVICE)
    }

    get auth() {
        return local.get(AUTH_SERVICE)
    }

    get db() {
        return local.get(VAULT_DB)
    }

    get card() {
        return this.cardService.currentCard
    }

    get cardService() {
        return local.get(CARD_SERVICE)
    }

    get cardSelector() {
        return local.get(CARD_SERVICE_SELECTOR)
    }

    get share() {
        return shareService;
    }

    isCordovaApp() {
        return utils.isCordovaApp();
    }
}

export const svc = Object.seal(new Svc());