export default function TexturePrimary() {
  return (
      <svg width="418" height="616" viewBox="0 0 418 616" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.05" d="M265.337 292.044C225.005 187.397 181.335 -1.63256 329.307 79.4209C477.279 160.474 348.315 254.942 265.337 292.044Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M21.1655 13.6306C138.362 60.4661 327.994 170.159 148.956 234.246C-30.0814 298.332 -10.8366 113.872 21.1655 13.6306Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M63.1865 73.1272C215.293 3.87691 494.186 -76.9564 392.905 153.713C291.624 384.383 130.892 196.101 63.1865 73.1272Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M154.153 53.258C226.017 20.3042 357.686 -18.3739 309.448 90.5444C261.211 199.463 185.819 111.069 154.153 53.258Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M28.432 177.084C131.059 181.848 306.163 215.636 185.556 312.677C64.9502 409.717 30.5542 262.715 28.432 177.084Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M354.634 485.026C266.219 416.031 131.536 276.389 300.124 269.784C468.711 263.178 406.709 410.526 354.634 485.026Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M2.03207 371.866C126.626 351.747 345.672 348.272 225.101 495.324C104.53 642.376 26.1504 474.291 2.03207 371.866Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M89.9708 450.7C184.79 313.072 382.379 100.296 414.176 350.206C445.974 600.116 211.288 521.331 89.9708 450.7Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M136.46 337.443C181.138 272.218 274.157 171.321 288.816 289.538C303.474 407.754 193.353 370.732 136.46 337.443Z" fill="#ABABAB"/>
        <path opacity="0.05" d="M92.3406 508.3C182.801 459.596 350.343 398.502 296.831 543.758C243.319 689.013 138.208 580.642 92.3406 508.3Z" fill="#ABABAB"/>
     </svg>
  );
}
