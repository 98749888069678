import { createEffect, createSignal } from 'solid-js';
import { useNavigate, useLocation } from '@solidjs/router';
import {
    Box,
    Typography
} from '@suid/material';

import AddIcon from '@suid/icons-material/Add';
import { layoutHeaderStyles } from '@app/assets/theme';
import ActionBar from '@app/components/ActionBar';
import { svc } from '@app/utils/svc.ts';
import CareCardSelector from '@app/pages/card/components/CardSelector.tsx';
import { routeStrings } from '@app/routes/route.strings.ts';
import ActionMenu from '@app/components/ActionMenu';

export const CardSelection = () => {
    rerouteIfNoCards();

    checkForAndDeleteAbandonedCard();

    const selector = svc.cardSelector;
    const [onCardAction, setOnCardAction] = createSignal<'create' | 'selected'>();

    createEffect(() => {
        const action = onCardAction();
        if (!action) {
            return;
        }

        if (action === 'selected') {
            return useNavigate()(routeStrings.cardHomePath, { replace: true });
        }

        return navToCreateCard();
    });

    const [viewing, setViewing] = createSignal(selector.currentView?.());

    return (
        <>
            <Box sx={layoutHeaderStyles.layoutHeaderContainer}>
                <ActionMenu />
                <Box sx={{
                    marginTop: { xs: '110px' }, //45px for tiny phones
                    textAlign: 'center',
                }}>
                    <Typography
                        gutterBottom
                        variant='h1'
                        color='text.primary'
                        sx={{
                            width: { xs: '100dvw' },
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize: { xs: '40px' },
                            fontWeight: '400',
                            lineHeight: '1.6',
                            color: '#ffffff'
                        }}
                    >
                        {viewing()?.name ?? ''}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant='h3'
                        color='text.primary'
                        sx={{
                            alignItems: { xs: 'center' },
                            width: { xs: '100dvw' },
                            fontSize: { xs: '22px' },
                            color: '#ffffff',
                            fontWeight: '300',
                            minHeight: '25px'
                        }}
                    >
                        {viewing()?.age ?? ''}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: { xs: '60px' },
                }}>
                    {selector.cards.length === 0 ? null : (
                        <CareCardSelector
                            onSelectorNav={setViewing}
                            cards={() => selector.cards}
                            onCardSlected={cardId => {
                                selector.select(cardId);
                                setOnCardAction('selected');
                            }}
                        />)
                    }

                    <ActionBar
                        actionIcon={<AddIcon fontSize="large" sx={{ fontSize: "40px" }} />}
                        actionClick={() => setOnCardAction('create')}
                    />
                </Box>
            </Box>
        </>
    );
};

export default CardSelection;

const checkForAndDeleteAbandonedCard = () => {
    const card = useLocation<any>()?.state?.removeCard;
    if (card) {
        svc.cardService.delete(card);
    }
}

const rerouteIfNoCards = () => {
    if (svc.cardSelector.cards.length == 0) {
        navToCreateCard();
    }
}

const navToCreateCard = () => {
    useNavigate()(
        routeStrings.cardCreatePath,
        {
            replace: true,
            state: {
                createCard: true
            }
        }
    )
};
