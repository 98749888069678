import { JSX, createSignal } from 'solid-js';
import {
    Fab
} from '@suid/material';
import DrawerCommon, { DrawerCommonProps } from './DrawerCommon';
import EditIcon from '@app/assets/icons/EditIcon';
import { NonNil } from '@libs/types';

export type DetailDrawerProps<T = any> = Omit<DrawerCommonProps, 'children' | 'open' | 'title'> & {
    item: () => T,
    button: {
        icon?: () => JSX.Element
        onClick: (item: NonNil<T>) => void
    } | false,
    children?: JSX.Element | ((source: NonNil<T>) => JSX.Element),
    title?: string | JSX.Element | ((itee: NonNil<T>) => string | JSX.Element)
};

export default function DetailDrawer<T = any>
    (
        props: DetailDrawerProps<T>
    ) {

    const { button, children, item } = props;

    const localProps: typeof props & { title?: string | JSX.Element | (() => string | JSX.Element) } = {
        ...props,
        height: props.height ?? 'auto',
        title: () => {
            if (typeof props.title !== 'function') {
                return props.title;
            }
            return props.title(item() as NonNil<T>);
        }
    };

    delete (localProps as any).button;
    delete (localProps as any).item;
    delete localProps.children;

    const [open, setOpen] = createSignal(true);

    return (
        <>
            {open() && item() && (
                <DrawerCommon
                    {...localProps}
                >
                    {getChildren(item, children)}
                    {getButton(item as () => NonNil<T>, () => setOpen(false), button)}
                </DrawerCommon>
            )}
        </>
    );
}

const getChildren = <T,>(item: () => T, children?: JSX.Element | ((source: NonNil<T>) => JSX.Element)): JSX.Element => {
    if (typeof children !== 'function') {
        return children === undefined ? <></> : children;
    }

    return children(item() as NonNil<T>);
}

const getButton = <T,>(item: () => NonNil<T>, close: () => void, button: {
    icon?: () => JSX.Element
    onClick: (item: NonNil<T>) => void
} | false) => {
    if (button === false) {
        return <></>;
    }

    return <Fab
        color="secondary"
        size='large'
        onClick={() => {
            try {
                if (button) {
                    button.onClick(item());
                }
            } finally {
                close();
            }
        }}
        sx={{
            position: 'fixed',
            bottom: 30,
            right: 20,
            margin: 0,
            textTransform: 'none',
        }}
    >
        {button?.icon?.() ?? <EditIcon mode="light"/>}
    </Fab>
}