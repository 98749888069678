
export const pageStrings = {
    profileCaption: 'Personal Profile',
    medicalInfoCaption: 'Medical Information',
    medsCaption: 'Current Medications',
    contactsCaption: 'Care Contacts',
    walletCaption: 'Health Insurance',
    documentsCaption: 'Emergency Documents',
    contactUsCaption: 'Contact Us',
    loginCaption: 'Login',
    profileInteriorCaption: 'Profile',
    medicalInfoInteriorCaption: 'Medical Info',
    medsInteriorCaption: 'Medications',
    contactsInteriorCaption: 'Contacts',
    walletInteriorCaption: 'Insurance',
    documentsInteriorCaption: 'Documents',
    careCardCaption: 'Care Card (View)',
};

export const pageTypeStrings = {
    profile: 'Personal_Profile',
    medicalInfo: 'Medical_Information',
    meds: 'Current_Medications',
    contacts: 'Contacts',
    wallet: 'Insurance',
    documents: 'Documents'
};