
import { createSignal } from 'solid-js';
import {
    Button,
    DialogActions,
    IconButton,
    InputAdornment,
    TextField
} from '@suid/material';

import ContentCopyIcon from '@suid/icons-material/ContentCopy';
import { logger } from '@libs/logger/mod.ts';

interface ShareCareCardActionProps {
    shareUrl: () => string;
    showQRCode: () => void;
}

// SHARE CARE CARD COMPONENT
// Preview the care card content to be shared and select your share method
export function ShareCareCardAction({ shareUrl, showQRCode }: ShareCareCardActionProps) {
    const canShare = navigator.canShare && navigator.canShare({ title: 'Care Card', text: 'I’m sharing a Care Card from my Vital Vault with you.', url: "https://vault.waywiser.com?id=wret2&key=2wqs2w#/share" });

    const shareData = () => {
        navigator
            .share({ title: 'Care Card', text: 'I’m sharing a Care Card from my Vital Vault with you.', url: shareUrl() })
            .then(() => logger.emit('share success'))
            .catch(e => logger.emit('share failure: ' + e));
    };

    // Signal for the text field value
    const [showCopied, setShowCopied] = createSignal(false);

    // Function to handle the copy action
    const handleCopy = async () => {
        try {
            const url = shareUrl();
            if (!url) {
                return;
            }

            await navigator.clipboard.writeText(url);
            setShowCopied(true); // Set the "copied" message to show

            // Hide the "copied" message after 2 seconds
            setTimeout(() => {
                setShowCopied(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    return (
        <DialogActions sx={{ padding: 0, flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', width: '100%', overflow: 'hidden' }}>
            <div style={{ "margin-top": '20px', width: '95%' }}>
                <TextField
                    value={shareUrl()}
                    //onChange={(e) => setTextFieldValue(e.currentTarget.value)}
                    fullWidth
                    label="Your CareCard Link"
                    variant="filled"
                    sx={{
                        '& .MuiFilledInput-root': {
                            '&.Mui-disabled': {
                                backgroundColor: '#F5F5F5', // Background color for the disabled input
                            },
                        },
                        '& .MuiInputBase-root': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                        '& .MuiInputBase-input': {
                            '&.Mui-disabled': {
                                color: '#333333', // Text color for the disabled input
                                '-webkit-text-fill-color': '#333333' as any, // Override for Chrome and Safari
                            }
                        }
                    }}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        disabled: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleCopy}>
                                    <ContentCopyIcon />
                                </IconButton>
                                {showCopied() && <span style={{ "margin-left": '10px' }}>Copied</span>}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div style={{ display: 'flex', "justify-content": 'stretch', width: '100%', "margin-top": '10px', padding: '0 8px' }}>
                {canShare && (<Button disabled={!shareUrl()} variant="contained" color="secondary" sx={{ flexGrow: 1, margin: '10px', textTransform: 'none', padding: '10px', borderRadius: '8px' }}
                    onClick={shareData}>
                    Share</Button>)}
                <Button disabled={!shareUrl()} variant="contained" color="secondary" sx={{ flexGrow: 1, margin: '10px', textTransform: 'none', padding: '10px', borderRadius: '8px' }}
                    onClick={showQRCode}>
                    QR Code</Button>
            </div>
            <div style={{ "padding-bottom": '50px' }}></div>
        </DialogActions>
    );
}