import { z } from 'zod';

import { Result } from '../../utils/result.ts';

import { EntityData } from './entity.data.ts';
import { EntitySchema } from './entity.schema.ts';
import { EntityTypeId } from './entity.type.id.ts';
import { EntityCore, EntityType, isCoreProperty } from './entity.core.ts';
import { ulid } from '@std/ulid/mod.ts';
import { Version } from '../../utils/version.ts';
import { Env } from '@libs/environment/mod.ts';
import { StorageRule } from './entity.storage.ts';

export class EntityFactory<T extends EntityCore> {
    private constructor(
        private readonly type: Pick<T, '__type'>,
        private readonly partialSchema: z.ZodType,
        private readonly schema: EntitySchema,
        private readonly schemaVersion: number,
        private readonly onEntityInit?: (entity: T & { __scid: number }, isNew: boolean) => void,
        private readonly storage?: StorageRule<T>
    ) {
    }

    public static create<T extends EntityCore>(
        schema: EntitySchema,
        schemaVersion?: Version,
        onEntityInit?: (entity: T & { __scid: number }, isNew: boolean) => void,
        storage?: StorageRule<T>
    ): EntityFactory<T> {
        const type = schema.schemaDef.shape.__type.value ?? -1;
        if (type < 1000 || type > 9999) {
            throw logAndReturnError(
                'Error creating entity factory from schema. Type must be a number between 1000 and 9999.',
            );
        }

        if (type !== schema.typeId.type) {
            throw logAndReturnError(`Schema type ${schema.typeId.type} does not match schema __type ${type}`);
        }

        if (factoryRegistry.has(type)) {
            throw logAndReturnError(
                `Entity factory for type ${type}, name ${schema.typeId.name} has already been defined`,
            );
        }

        const registeredSchema = EntitySchema.getByType(type);
        if (!registeredSchema) {
            throw logAndReturnError(
                `The entity schema being used has not been previously defined. In order to create ` +
                "an entity factory, you need to first use the 'define' function of the entity schema.",
            );
        }

        if (schema !== registeredSchema) {
            throw logAndReturnError(
                `An entity schema for type ${type} has been previously defined and is not the same ` +
                "instance as the current schema being used to create an entity factory.",
            );
        }

        if ((schema.schemaDef.shape as any).__scid !== undefined) {
            throw logAndReturnError(
                "The entity schema cannot have a schema definition for '__scid'. This is a reserved property."
            );
        }

        return factoryRegistry.setAndGet(
            type,
            new EntityFactory<T>(
                type as any,
                schema.schemaDef.partial(),
                schema,
                schemaVersion?.value ?? 0,
                onEntityInit ?? (() => { }),
                storage
            ),
        );
    }

    public static getByType<
        T extends EntityCore = EntityCore,
    >(type: EntityType<T>): EntityFactory<T> | undefined {
        return factoryRegistry.get(type);
    }

    public static getByName<T extends EntityCore = EntityCore>(
        name: string,
    ): EntityFactory<T> | undefined {
        return factoryRegistry.getByName(name);
    }

    public get STORAGE_RULE(): StorageRule<T> | undefined {
        return this.storage;
    }

    public get TYPE(): Pick<T, '__type'> & number {
        return this.type as any;
    }

    public get NAME(): string {
        return this.schema.typeId.name;
    }

    public tryParse(obj: Exclude<object, string>): Result<T> {
        try {
            return { success: true, value: this.load(obj) };
        } catch (error) {
            return { success: false, error };
        }
    }

    public parseJson(json: string): T {
        return this.load(JSON.parse(json));
    }

    public tryParseJson(json: string): Result<T> {
        try {
            return { success: true, value: this.parseJson(json) };
        } catch (error) {
            return { success: false, error };
        }
    }

    public load(obj: Exclude<object, string>): T {
        const anyObj = obj as any;
        if (anyObj.__type === undefined) {
            throw logAndReturnError(
                `Invalid object. Missing __type property.`, obj
            );
        }

        if (this.type !== anyObj.__type) {
            throw logAndReturnError(
                `Invalid type: ${anyObj.__type}. Expected: ${this.type}.`, obj
            );
        }

        if (anyObj.createdAt === undefined) {
            throw logAndReturnError(
                `Invalid object. Missing createdAt property.`, obj
            );
        }

        if (anyObj.updatedAt === undefined) {
            throw logAndReturnError(
                `Invalid object. Missing updatedAt property.`, obj
            );
        }

        if (anyObj._id === undefined) {
            throw logAndReturnError(
                `Invalid object. Missing _id property.`, obj
            );
        }

        const merged = {
            ...obj,
            ...this.setDefaultsIfUndefined(obj),
        };

        if (merged['__scid'] === undefined || merged['__scid'] === null) {
            merged['__scid'] = 0; // likely legacy data
        }

        const onInit = this.onEntityInit;
        if (onInit) {
            onInit(merged, false);

            if (merged.__type !== this.type) {
                throw logAndReturnError(
                    `Invalid type: ${merged.__type}. Expected: ${this.type}`,
                    merged
                );
            }
        }

        const version = this.schemaVersion;
        if (merged['__scid'] !== version) {
            if (typeof merged['__scid'] !== 'number') {
                throw logAndReturnError(
                    `Invalid schema version. Expected number. Got: ${merged['__scid']}`
                );
            }

            if (merged['__scid'] > version) {
                throw logAndReturnError(
                    `Invalid schema version. Expected <= ${version}. Got: ${merged['__scid']}`
                );
            }

            merged['__scid'] = version;
        }

        return merged;
    }

    public copyFrom(values: object, props?: string[]): T {
        const copy: any = {};
        const keys = props ??
            Object.getOwnPropertyNames(this.schema.schemaDef.shape);

        for (const key of keys.filter(v => !isCoreProperty(v))) {
            copy[key] = (values as any)[key];
        }

        return this.create(copy);
    }

    public create(values?: EntityData<T>): T {
        const anyObj = values as any;
        const type = anyObj?.__type;
        if (type !== undefined && type !== this.type) {
            throw logAndReturnError(
                `Invalid type: ${type}. Expected: ${this.type}.`,
                values
            );
        }

        if (
            anyObj?.createdAt !== undefined ||
            anyObj?.updatedAt !== undefined ||
            anyObj?._id !== undefined ||
            anyObj?.__scid !== undefined
        ) {
            throw logAndReturnError(
                `Invalid values. Cannot set createdAt, updatedAt, __scid, or _id.`,
                values
            );
        }

        const dt = Date.now();

        const entity = this.partialSchema.parse({
            ...(values ?? {}),
            ...this.setDefaultsIfUndefined(values),
            __type: this.type,
            _id: ulid(),
            createdAt: dt,
            updatedAt: dt,
            __scid: this.schemaVersion
        });

        const onInit = this.onEntityInit;
        if (onInit) {
            onInit(entity, true);
        }

        return entity;
    }

    public getSchemaPropertyNames(): string[] {
        return Object.getOwnPropertyNames(this.schema.schemaDef.shape)
            .filter(v => !isCoreProperty(v));
    }

    private setDefaultsIfUndefined(values?: any): any {
        const defaults: any = {};
        for (const key of this.getUndefinedDataProperties(values)) {
            const p = this.schema.schemaDef.shape[key];
            if (p instanceof z.ZodDefault) {
                defaults[key] = p.parse(undefined);
            }

            if (
                p instanceof z.ZodOptional &&
                p._def.innerType instanceof z.ZodDefault
            ) {
                defaults[key] = p._def.innerType.parse(undefined);
            }

            if (p instanceof z.ZodLiteral) {
                defaults[key] = p.parse(p.value);
            }

            if (
                p instanceof z.ZodOptional &&
                p._def.innerType instanceof z.ZodLiteral
            ) {
                defaults[key] = p._def.innerType.parse(p._def.innerType.value);
            }
        }

        return defaults;
    }

    private getUndefinedDataProperties(values?: any): string[] {
        return Object.getOwnPropertyNames(this.schema.schemaDef.shape)
            .filter(key => {
                if (!values) {
                    return !isCoreProperty(key);
                }

                return !isCoreProperty(key) && values[key] === undefined;
            });
    }
}

function logAndReturnError(message: string, obj?: any): Error {
    if (Env.envType === 'dev') {
        console.error(message, obj);
    }
    return new Error(message);
}

const factoryRegistry = (() => {
    const registry = new Array<EntityFactory<any>>(9000);
    return {
        has: (type: number): boolean => {
            return registry[type - 1000] !== undefined;
        },
        get: <T extends EntityCore>(
            type: number
        ): EntityFactory<T> | undefined => {
            return registry[type - 1000];
        },
        getByName: <T extends EntityCore>(
            name: string
        ): EntityFactory<T> | undefined => {
            const typeId = EntityTypeId.lookupByName(name);
            return typeId === undefined ? undefined :
                registry[typeId.type - 1000];
        },
        setAndGet: <T extends EntityCore>(
            type: number,
            factory: EntityFactory<T>,
        ): EntityFactory<T> => {
            return registry[type - 1000] = factory;
        },
    };
})();
