export type ValidationResult = {
    isValid: false;
    message: string;
} | {
    isValid: true;
    message: undefined;
}

const valid_result: ValidationResult = Object.freeze({
    isValid: true,
    message: undefined
});

export const ValidationResult = Object.freeze({
    valid: (): ValidationResult => valid_result,
    invalid: (message: string): ValidationResult => ({ isValid: false, message })
});

export type Validate = <T extends number | string = string>(value: T) => ValidationResult;

export type Validator = Validate & { message: () => string };

export type RegexValidator = Validator & { regex: RegExp };
