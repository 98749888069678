import type { EntityCore } from "@libs/db/schema/entity.core.ts";
import type { Identifier } from "@libs/db/schema/identifier.ts";
import { ServiceName } from "@libs/services/service.ts";
import { DisconnectListener } from "@libs/utils/event.ts";

export const LOCAL_DB = ServiceName.create<LocalDbClient>('LOCAL_DB');

export type OnChangeEventData = {
    type: 'before' | 'after'
    action: 'upsert',
    data: EntityCore
} | {
    type: 'before' | 'after'
    action: 'delete',
    id: Identifier
};

export interface LocalDbClient {
    deleteLocal(...ids: Identifier[]): Promise<void>;
    upsert(data: EntityCore | Promise<EntityCore>): Promise<void>;
    fetch<T extends EntityCore>(
        id: Identifier, onError?: (e: any) => T | undefined
    ): Promise<T | null>;
    fetchByType<T extends EntityCore>(
        type: number, rel?: string, onError?: (e: any) => T | undefined
    ): Promise<T[]>;
    fetchAll<T extends EntityCore>(
        rel?: string, onError?: (e: any) => T | undefined
    ): Promise<T[]>;
    delete(...ids: Identifier[]): Promise<void>;
    deleteAll(): Promise<void>;
    onChange(
        handler: (e: OnChangeEventData) => void | Promise<void>
    ): DisconnectListener;
}