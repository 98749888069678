import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@suid/material';
import { useIsReadOnlyMode } from '@app/utils/hooks';
import { useBoundSource } from '@app/data/hooks';
import { For } from 'solid-js';
import { FrequencyList } from './medHelpers';

export type Props = {
    name: string;
    gxs?: number;
};

export function FrequencySelect(props: Props) {
    const source = useBoundSource();
    const isReadonly = useIsReadOnlyMode();

    const select = (
        <Box sx={{}}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>Frequency</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={props.name}
                    value={source.get(props.name) ?? ''}
                    onChange={(ev) => source.set(props.name, ev.target.value)}
                    label='Frequency'
                    variant='filled'
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    <For each={FrequencyList}>
                        {(item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        )}
                    </For>
                </Select>
            </FormControl>
        </Box>
    );

    if (!props.gxs) {
        return select;
    }

    return (
        <Grid item xs={props.gxs}>
            {select}
        </Grid>
    );
}
