import { Once } from "@rdt-utils";

export type LazyObject = {
    [key: string | symbol | number]: any;
};

export const Lazy = {
    of<T extends LazyObject>(fn: () => T): T {
        if (fn === undefined || fn === null || typeof fn !== 'function') {
            throw new Error(`'fn' must be a valid function`);
        }

        fn = Once.of(fn);
        return new Proxy<T>({} as any, {
            get: (_, p) => Reflect.get(fn(), p),

            has(_, p) {
                return Reflect.has(fn(), p);
            },

            getOwnPropertyDescriptor(_: any, p: string | symbol) {
                return Reflect.getOwnPropertyDescriptor(fn(), p);
            },

            ownKeys() {
                return Reflect.ownKeys(fn());
            },

            set(_, p, value) {
                return Reflect.set(fn(), p, value);
            },

            defineProperty(_, p, attributes) {
                return Reflect.defineProperty(fn(), p, attributes);
            },

            deleteProperty(_, p) {
                return Reflect.deleteProperty(fn(), p);
            },

            getPrototypeOf() {
                return Reflect.getPrototypeOf(fn());
            },

            isExtensible() {
                return Reflect.isExtensible(fn());
            },

            preventExtensions() {
                return Reflect.preventExtensions(fn());
            }
        }) as T;
    }
};
