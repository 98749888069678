import { Result } from '@libs/utils/result.ts';
import { Task, TaskState } from './task.ts';
import _, { __task_symbol } from './__utils.ts';

export const createLazyTask = <T>(fn: () => T | Promise<T>): Task<T> => {
    return new LazyTask<T>(fn);
}

class LazyTask<T> implements Task<T> {
    #taskFn: (() => Task<T>) & { cancel: () => boolean, resolve(value: T): boolean, reject(e: any): boolean };

    constructor(value: () => T | Promise<T>) {
        let task: Task<T> | undefined;
        this.#taskFn = (() => {
            const x: any = () => {
                if (task) {
                    return task;
                }
                return task = _.invoke(value);
            };

            x.cancel = () => {
                if (task) {
                    return task.cancel();
                }

                task = Task.cancelled();
                return true;
            };

            x.resolve = (value: T) => {
                if (task) {
                    return task.resolve(value);
                }

                task = Task.fulfilled(value);
                return true;
            };

            x.reject = (e: any) => {
                if (task) {
                    return task.reject(e);
                }

                task = Task.rejected(e);
                return true;
            };

            return x;
        })() as (() => Task<T>) & { cancel: () => boolean, cancelAfter: (ms: number) => Promise<boolean>, resolve(value: T): boolean, reject(e: any): boolean };
    }

    readonly [Symbol.toStringTag]: string = 'Task';

    get delegate(): Task<T> {
        return this.#taskFn();
    }

    get [__task_symbol](): true {
        return true;
    }

    public bind(promise: Promise<T>): void {
        this.delegate.bind(promise);
    }

    public getResult(): Result<T> | undefined {
        return this.delegate.getResult();
    }

    public then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2> {
        try {
            return this.delegate.then(onfulfilled, onrejected);
        } catch (err) {
            console.error(err);
            console.error(this.delegate);
            throw err;
        }
    }

    public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
        return this.delegate.catch(onrejected);
    }

    public finally(onfinally?: (() => void) | undefined | null): Promise<T> {
        return this.delegate.finally(onfinally);
    }

    public resolve(value: T): boolean {
        return this.delegate.resolve(value);
    }

    public reject(reason: unknown): boolean {
        return this.delegate.reject(reason);
    }

    public cancel(): boolean {
        return this.delegate.cancel();
    }

    public cancelAfter(ms: number): Promise<boolean> {
        return this.delegate.cancelAfter(ms);
    }

    public isDone(): boolean {
        return this.delegate.isDone();
    }

    public isCancelled(): boolean {
        return this.delegate.isCancelled();
    }

    public isFulfilled(): boolean {
        return this.delegate.isFulfilled();
    }

    public isRejected(): boolean {
        return this.delegate.isRejected();
    }

    public status(): TaskState {
        return this.delegate.status();
    }

    public withTimeout(timeoutMs: number): Task<T> {
        return this.delegate.withTimeout(timeoutMs);
    }
}