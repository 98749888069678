import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@suid/material';

import { useBoundSource } from '../data/hooks';
import { useIsReadOnlyMode } from '@app/utils/hooks';
import { logger } from '@libs/logger';

interface PhoneTypeSelectProps {
    phoneIndex: number;
    name: string;
    gxs?: number;
}

export default function PhoneTypeSelect({ phoneIndex, name, gxs }: PhoneTypeSelectProps) {
    const propName = `phone${phoneIndex}_type`;
    const source = useBoundSource<{ [key: string]: string }>();
    const isReadonly = useIsReadOnlyMode();

    const select = (
        <Box sx={{}}>
            <FormControl fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel variant='filled'>Type</InputLabel>
                <Select
                    inputProps={{
                        readOnly: isReadonly()
                    }}
                    name={name}
                    value={source.get(propName) ?? ''}
                    label='Type'
                    variant='filled'
                    onChange={(ev) => {
                        logger.debug(`PhoneTypeSelect: onChange: ev.target.value=${ev.target.value}`);
                        source.set(propName, ev.target.value);
                    }}
                    sx={{
                        '& .MuiSelect-filled': {
                            borderRadius: '10px',
                            border: '1px solid #E0E0E0',
                        },
                    }}
                >
                    <MenuItem value={'mobile'}>Mobile</MenuItem>
                    <MenuItem value={'home'}>Home</MenuItem>
                    <MenuItem value={'front desk'}>Front Desk</MenuItem>
                    <MenuItem value={'main'}>Main</MenuItem>
                    <MenuItem value={'work'}>Work</MenuItem>
                    <MenuItem value={'room'}>Room</MenuItem>
                    <MenuItem value={'emergency'}>Emergency</MenuItem>
                    <MenuItem value={'administration'}>Administration</MenuItem>
                    <MenuItem value={'school'}>School</MenuItem>
                    <MenuItem value={'fax'}>Fax</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    if (!gxs) {
        return select;
    }

    return (
        <Grid item xs={gxs}>
            {select}
        </Grid>
    );
}
