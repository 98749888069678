const formatter = new Intl.DateTimeFormat(
    !navigator.language || navigator.language.length <= 2 ? 'en-US' : navigator.language,
);

const TimeZone = formatter.resolvedOptions().timeZone;
const Locale = formatter.resolvedOptions().locale;

const DateFormatString = formatter
    .format(new Date(1970, 11, 10, 0, 0, 0, 0))
    .replace('1970', 'YYYY')
    .replace('70', 'YY')
    .replace('12', 'MM')
    .replace('10', 'DD');

export const dt = {
    TimeZone,
    Locale,
    DateFormatString,
    format: (date?: Date | number): string => {
        return formatter.format(date);
    },
    now: (): Date => {
        return new Date();
    },
    getAge: (date: number | string | Date): number => {
        const today = new Date();
        const dob = new Date(date);

        let age = today.getFullYear() - dob.getFullYear();
        const m = today.getMonth() - dob.getMonth();

        // UTC used in this case since the date is stored at midnight of the day
        // It then gets converted to the local time zone and the day of the month
        // is then incorrect
        if (m < 0 || (m === 0 && today.getDate() < dob.getUTCDate())) {
            age--;
        }

        return age;
    },
    getLongAge: (date: number | string | Date): string => {
        const age = dt.getAge(date);
        if (age === 0) {
            return '< 1 year old';
        }

        return `${age} year${age > 1 ? 's' : ''} old`;
    },
};
