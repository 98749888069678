import { MutableEntity } from "@app/data/mutable";
import { Config } from "@libs/config";
import { EntityCore } from "@libs/db";
import { Env } from '@libs/environment';
import { globalSelf } from '@libs/utils/globalSelf.ts';

export interface DevUtil {
    set(key: string, value: any): void;
    get<T = any>(key: string): T | undefined;
    call<T = any>(key: string): T | undefined;
    setTrackable<T extends MutableEntity<any>>(trackable?: T): T;
    trackable<T extends EntityCore = EntityCore>(): MutableEntity<T> | undefined;
    setBoundSource<T>(boundSource?: T): T;
    boundSource<T = any>(): T | undefined;
    reset(): void;
    isEnabled: boolean;
}

const getDevUtil = (): DevUtil => {
    if (Env.envType === 'prod') {
        return {
            isEnabled: Config.DEV_UTILS_ENABLED.else(false),
            set: () => { },
            get: () => undefined,
            call: () => undefined,
            setTrackable: v => v as any,
            trackable: () => undefined,
            setBoundSource: v => v as any,
            boundSource: () => undefined,
            reset: () => { }
        };
    }

    let data: Record<string, any> = {};
    return {
        isEnabled: true,
        set: (key: string, value: any) => {
            data[key] = value;
        },
        get: (key: string) => {
            return data[key];
        },
        call: (key: string) => {
            return data[key]();
        },
        setTrackable: <T extends MutableEntity<any>>(trackable?: T): T => {
            data['trackable'] = trackable;
            return trackable as any;
        },
        trackable: <T extends EntityCore = EntityCore>(): MutableEntity<T> | undefined => {
            return data['trackable'];
        },
        setBoundSource: <T>(boundSource?: T): T => {
            data['boundSource'] = boundSource;
            return boundSource as any;
        },
        boundSource: <T = any>(): T | undefined => {
            return data['boundSource'];
        },
        reset: () => {
            data = {};
        }
    };
}

const gSelf = globalSelf as any;
export const DevUtil: DevUtil = gSelf.__vault = !gSelf.__vault ? getDevUtil() : {
    ...gSelf.__vault,
    ...getDevUtil()
};