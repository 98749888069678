import { Accessor, createSignal, Setter } from "solid-js";

interface AsyncSignalFactory {
    create<T>(promise: T | PromiseLike<T>): AsyncSignal<T | undefined>;
    create<T>(promise: T | PromiseLike<T>, initialValue: T): AsyncSignal<T>;

    onDone(promise: PromiseLike<unknown> | (() => PromiseLike<unknown>)): Accessor<boolean>;
    onDone(promise: PromiseLike<unknown> | (() => PromiseLike<unknown>), onError: (err: any) => void): Accessor<boolean>;

    onPending(promise: PromiseLike<unknown> | (() => PromiseLike<unknown>)): Accessor<boolean>;
    onPending(promise: PromiseLike<unknown> | (() => PromiseLike<unknown>), onError: (err: any) => void): Accessor<boolean>;

    bind<T>(promise: Promise<T>, setter: Setter<boolean>): Promise<T>;
    bindFalse<T>(promise: Promise<T>, setter: Setter<boolean>): Promise<T>;
}

export type AsyncSignal<T> = { resolved: () => T, rejected: (value: Error) => void };

export const AsyncSignal: AsyncSignalFactory = {
    create: <T>(...args: any[]): AsyncSignal<T> => {
        if (args.length !== 1 && args.length !== 2) {
            throw new Error(`Invalid number of arguments: ${args.length}`);
        }

        const [resolved, setResolved] = args.length === 1 ? createSignal<T>() : createSignal<T>(args[1]);
        const [rejected, setRejected] = createSignal<Error>();

        Promise.resolve(args[0]).then(setResolved).catch(err => {
            setRejected(err instanceof Error ? err : new Error(err));
        });

        return { resolved: resolved as any, rejected };
    },
    onDone: (...args: any[]): Accessor<boolean> => {
        return handle(false, ...args);
    },
    onPending: (...args: any[]): Accessor<boolean> => {
        return handle(true, ...args);
    },
    bind: <T>(promise: Promise<T>, setter: Setter<boolean>) => {
        promise.then(() => setter(true), () => setter(true));
        return promise;
    },
    bindFalse: <T>(promise: Promise<T>, setter: Setter<boolean>) => {
        promise.then(() => setter(false), () => setter(false));
        return promise;
    }
}

const handle = (initValue: boolean, ...args: any[]): Accessor<boolean> => {
    if (args.length !== 1 && args.length !== 2) {
        throw new Error(`Invalid number of arguments: ${args.length}`);
    }

    const [bool, setBool] = createSignal(initValue);

    const promise: Promise<any> = typeof args[0] === 'function' ? args[0]() : args[0];
    const onError = args.length === 2 ? args[1] : () => { };
    promise.then(() => setBool(!initValue)).catch(err => {
        setBool(!initValue);
        onError(err);
    });

    return bool;
}