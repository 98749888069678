import { useBoundSource } from "@app/data/hooks";
import { createSignal } from "solid-js";

export type PhoneItem = {
    index: number;
    name: string;
    type: string;
    isEditable: () => boolean,
    makeEditable: () => void,
    remove: () => void
}

export const useBoundPhoneNumbersSource = (max_numbers?: number, numberBaseName?: string) => {
    const source = useBoundSource<{ [key: string]: string }>();
    numberBaseName = numberBaseName ?? 'phone';
    const max = max_numbers ?? 4;
    const [maxEditableReached, setMaxEditable] = createSignal(false);
    const items = new Array<PhoneItem>();
    let editableCount = 0;
    for (let i = 1; i <= max; i++) {
        const [isEditable, setIsEditable] = createSignal(false);
        const item = {
            index: i,
            name: `${numberBaseName}${i}`,
            type: `phone${i}_type`,
            isEditable: isEditable,
            makeEditable: () => {
                setIsEditable(true);
                editableCount++;
                if (editableCount >= max) {
                    setMaxEditable(true);
                }
            },
            remove: () => {
                source.set(item.name, '');
                source.set(item.type, '');
                setIsEditable(false);
                editableCount--;
                setMaxEditable(false);
            }
        };
        items.push(item);
        setIsEditable(i === 1 || (source.get(item.name) && source.get(item.type)));
        if (item.isEditable()) {
            editableCount++;
        }
    }

    return { items, maxEditableReached };
}