import { useNavigateToEdit } from '@app/routes/hooks';
import ActionBar from '@app/components/ActionBar';
import AddIcon from '@suid/icons-material/Add';
import { BoundSource } from '@app/data/components/BoundSource';
import { svc } from '@app/utils/svc';
import { ListEmptyState } from '@app/pages/helpers/commonHelpers';
import CardList from '@app/pages/wallet/components/CardList';
import InsuranceIcon from '@suid/icons-material/CardMembership';


export default function WalletView() {
    const editSection = useNavigateToEdit();
    const insuranceDetails = svc.card.insuranceDetails;

    return (
        <>
            <BoundSource source={insuranceDetails}>
                {() => {
                    // Perform the check inside a function to ensure we are working with the most current state
                    const cards = insuranceDetails()?.insuranceCards;
                    if (cards && cards.length > 0) {
                        return (
                            <CardList insuranceCards={() => insuranceDetails()!.insuranceCards} onInsuranceCardEditSelected={m => editSection(m._id)} />
                        );
                    } else {
                        return (
                            <ListEmptyState
                                emptyStateHeader="No Insurance Cards"
                                emptyStateText="Keep everything in one place by adding health insurance and other plans here.  This includes medical, vision, dental and prescription discount plans. Just tap the plus sign to add a new card."
                                emptyStateIcon={() => <InsuranceIcon style={{ height: '80px', width: '80px', color: "#d3d3d3" }} />}
                            />
                        );
                    }
                }}
            </BoundSource>

            <ActionBar
                actionIcon={<AddIcon fontSize="large" sx={{ fontSize: "40px" }} />}
                actionClick={() => editSection("Add Insurance Card")}
            />
        </>
    );
}
