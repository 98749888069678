const API_PATH = `/api`;
const API_VERSION_V1 = 'v1';
const API_VERSION_V2 = 'v2';

function getApiUrls(apiVersion: string) {
    return Object.freeze({
        apiRootUrlPath: `${API_PATH}/${apiVersion}`,
        dataUrlPath: `${API_PATH}/${apiVersion}/data`,
        relsUrlPath: `${API_PATH}/${apiVersion}/rels`,
        wsUpdatesUrlPath: `${API_PATH}/${apiVersion}/ws-updates`,
        shareUrlPath: `${API_PATH}/${apiVersion}/share`,
        medsUrlPath: `${API_PATH}/${apiVersion}/med`,
    });
}

export const API_PATH_TEMPLATES = Object.freeze({
    apiRootUrlPath: `${API_PATH}` + '/${VERSION}',
    dataUrlPath: `${API_PATH}` + '/${VERSION}/data',
    relsUrlPath: `${API_PATH}` + '/${VERSION}/rels',
    wsUpdatesUrlPath: `${API_PATH}` + '/${VERSION}/ws-updates',
    shareUrlPath: `${API_PATH}` + '/${VERSION}/share',
    medsUrlPath: `${API_PATH}` + '/${VERSION}/med',
});

export const API_V1 = Object.freeze({
    version: API_VERSION_V1,
    ...getApiUrls(API_VERSION_V1)
});

export const API_V2 = Object.freeze({
    version: API_VERSION_V2,
    ...getApiUrls(API_VERSION_V2)
});