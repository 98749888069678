import { Signaller } from './signaller.ts';

const OBJECT_KEYS = Symbol('objectKeys');

type REQUIRED_METHODS =
    | 'defineProperty'
    | 'deleteProperty'
    | 'get'
    | 'getOwnPropertyDescriptor'
    | 'has'
    | 'ownKeys'
    | 'set';

const arrayProps: Array<string | symbol> = ['length', 'values', 'keys', 'entries'];

export type ObjectProxyHandler<T extends object> = Required<
    Pick<ProxyHandler<T>, REQUIRED_METHODS>
>;

export function createInterceptor<T extends object>(obj: T): ObjectProxyHandler<T> {
    const metaSignaller = Signaller.of();
    const valueSignaller = Signaller.of();

    const getter = Array.isArray(obj)
        ? (target: T, p: string | symbol) => {
            if (arrayProps.includes(p)) {
                metaSignaller.track(OBJECT_KEYS);
            } else if (p === 'resignalAll') {
                return valueSignaller.resignalAll;
            } else {
                valueSignaller.track(p);
            }

            return Reflect.get(target, p);
        }
        : (target: T, p: string | symbol) => {
            if (p === 'resignalAll') {
                return valueSignaller.resignalAll;
            }
            valueSignaller.track(p);
            return Reflect.get(target, p);
        };

    return {
        get: getter,
        has(target, p) {
            metaSignaller.track(p);
            return Reflect.has(target, p);
        },

        getOwnPropertyDescriptor(target: T, p: string | symbol) {
            metaSignaller.track(p);
            return Reflect.getOwnPropertyDescriptor(target, p);
        },

        ownKeys(target) {
            metaSignaller.track(OBJECT_KEYS);
            return Reflect.ownKeys(target);
        },

        set(target, p, value) {
            const hasKey = Reflect.has(target, p);
            const prevValue = Reflect.get(target, p);
            const result = Reflect.set(target, p, value);

            if (!hasKey) {
                metaSignaller.dirty(OBJECT_KEYS);
                metaSignaller.dirty(p);
            }

            if (value !== prevValue) {
                valueSignaller.dirty(p);
            }

            return result;
        },

        defineProperty(target, p, attributes) {
            const hasKey = Reflect.has(target, p);
            const result = Reflect.defineProperty(target, p, attributes);
            const value = Reflect.get(target, p);

            if (!hasKey) {
                metaSignaller.dirty(OBJECT_KEYS);
                metaSignaller.dirty(p);
            }

            if (value !== undefined) {
                valueSignaller.dirty(p);
            }

            return result;
        },

        deleteProperty(target, p) {
            const hasKey = Reflect.has(target, p);
            const currentValue = Reflect.get(target, p);
            const result = Reflect.deleteProperty(target, p);

            if (hasKey) {
                metaSignaller.dirty(OBJECT_KEYS);
                metaSignaller.dirty(p);
            }

            if (currentValue !== undefined) {
                valueSignaller.dirty(p);
            }

            return result;
        },
    };
}