import { logger } from "@libs/logger/mod.ts";
import { EntityCore, Identifier } from "@libs/db";
import { ChangeProxyHandler, SignalRef, mutableSymbol } from "./common.ts";
import { createChangeHandler } from "./change.handler.ts";
import { svc } from "@app/utils/svc.ts";
import { getPropertyValidationHandler } from "./property.validation.handler.ts";
import { MutableEntity, SaveLifecyleHandler } from "../mutable.ts";
import { batch } from "solid-js";
import { EntityFactory } from "@libs/db/schema/entity.factory.ts";
import { logEmitAndReturnError } from "@libs/logger/log.util.ts";

export const createMutableEntity = <T extends EntityCore>(entity: T, changeSignal: SignalRef<boolean>,
    isValidSignal: SignalRef<boolean>, onSave?: (() => void) | SaveLifecyleHandler): MutableEntity<T> => {

    const handler = createChangeHandler(entity, changeSignal, isValidSignal);
    const source = new Proxy(entity, handler) as T;
    const _save = getSaveHandler(source, handler, () => svc.db.upsert(entity), changeSignal, onSave);

    const propInfo = getPropertyValidationHandler(entity.__type, isValidSignal);
    let saving = Promise.resolve<EntityCore>(entity);
    return (entity as any)[mutableSymbol] = {
        unwrap: () => entity,
        untrack: () => {
            const untracked = entity;

            changeSignal.detach();

            if (handler.originalValues) {
                const e = entity;
                const originalValues = handler.originalValues;
                batch(() => {
                    for (const [key, value] of originalValues) {
                        // reset any values if they were changed and not saved
                        Reflect.set(e, key, value);
                    }
                });
            }

            entity = undefined as any;
            handler.originalValues = undefined;

            delete (untracked as any)[mutableSymbol];

            return untracked;
        },
        source,
        isRequired: (path: string) => propInfo(path).isRequired(),
        validator: (path: string) => propInfo(path).validator,
        hasChanges: () => changeSignal.get() ?? false,
        isValid: () => !(isValidSignal.get() === false),
        save: () => {
            if (!entity) {
                throw logEmitAndReturnError('Cannot save untracked entity');
            }

            return saving = saving.then(_save, _save);
        },
        reset: () => {
            if (!entity) {
                throw logEmitAndReturnError('Cannot reset untracked entity');
            }

            if (handler.originalValues) {
                const e = entity;
                const originalValues = handler.originalValues;
                batch(() => {
                    for (const [key, value] of originalValues) {
                        // reset any values if they were changed and not saved
                        Reflect.set(e, key, value);
                    }
                });
            }

            handler.originalValues = undefined;
            changeSignal.set(false);
        }
    };
}

export const createMutableArray = <P extends EntityCore, K extends keyof P, T extends EntityCore>(
    parent: P, propName: K, id: Identifier, changeSignal: SignalRef<boolean>,
    isValidSignal: SignalRef<boolean>, onSave?: (() => void) | SaveLifecyleHandler): MutableEntity<T> => {

    const owner = getOwner<P, K, T>(parent, propName, id);
    if (MutableEntity.as(owner.entity)) {
        throw logEmitAndReturnError('Cannot create mutable array for already tracked entity');
    }

    const handler = createChangeHandler(owner.entity, changeSignal, isValidSignal);
    const source = new Proxy(owner.entity, handler);
    const _save = getSaveHandler(source, handler, () => owner.apply(), changeSignal, onSave);

    const propInfo = getPropertyValidationHandler(owner.entity.__type, isValidSignal);
    let saving = Promise.resolve<EntityCore>(parent);
    return (owner.entity as any)[mutableSymbol] = {
        unwrap: () => owner.entity,
        untrack: () => {
            const untracked = owner.entity;

            changeSignal.detach();

            if (handler.originalValues) {
                const e = owner.entity;
                const originalValues = handler.originalValues;
                batch(() => {
                    for (const [key, value] of originalValues) {
                        // reset any values if they were changed and not saved
                        Reflect.set(e, key, value);
                    }
                });
            }

            owner.entity = undefined as any;
            handler.originalValues = undefined;

            delete (untracked as any)[mutableSymbol];

            return untracked as T;
        },
        source,
        isRequired: (path: string) => propInfo(path).isRequired(),
        validator: (path: string) => propInfo(path).validator,
        hasChanges: () => changeSignal.get() ?? false,
        isValid: () => !(isValidSignal.get() === false),
        save: () => {
            if (!owner.entity) {
                throw logEmitAndReturnError('Cannot save untracked entity');
            }

            return saving = saving.then(_save, _save);
        },
        reset: () => {
            if (!owner.entity) {
                throw logEmitAndReturnError('Cannot reset untracked entity');
            }

            if (handler.originalValues) {
                const e = owner.entity;
                const originalValues = handler.originalValues;
                batch(() => {
                    for (const [key, value] of originalValues) {
                        // reset any values if they were changed and not saved
                        Reflect.set(e, key, value);
                    }
                });
            }

            handler.originalValues = undefined;
            changeSignal.set(false);
        }
    };
}

const getOwner = <P extends EntityCore, K extends keyof P, T extends EntityCore>(parent: P, propName: K, id: Identifier): { entity: T, apply: () => Promise<void> } => {
    const array = ((parent as any)[propName]) as T[];
    if (!array) {
        throw logEmitAndReturnError(`Cannot create mutable array for undefined property ${String(propName)}`);
    }

    const resolved = (array.find((e) => e._id === id.key) ?? EntityFactory.getByType(id.type)?.create()) as T;
    if (!resolved) {
        throw logEmitAndReturnError(`Cannot create mutable array for unknown type ${id.type}`);
    }

    return {
        entity: resolved as T,
        apply: () => {
            const updated = (resolved as any).__deleted ? array.filter((e) => e._id !== id.key) : !array.some((e) => e._id === id.key) ? [...array, resolved] : [...array];
            (resolved as any).updatedAt = Date.now();
            ((parent as any)[propName]) = updated;
            return svc.db.upsert(parent);
        }
    };
};

const getSaveHandler = <T extends EntityCore>(
    source: T,
    handler: ChangeProxyHandler<T>,
    doSave: () => Promise<void>,
    changeSignal: SignalRef<boolean>,
    onSave?: (() => void) | SaveLifecyleHandler,
) => {
    const saveHandler: Required<SaveLifecyleHandler> =
        typeof onSave === 'function' ?
            { onBeforeSave: () => { }, onAfterSave: onSave } :
            {
                onBeforeSave: () => onSave?.onBeforeSave?.(),
                onAfterSave: (error: any) => onSave?.onAfterSave?.(error),
            };

    return async () => {
        saveHandler.onBeforeSave();

        let error: any
        try {
            if (!handler.originalValues || handler.originalValues.size === 0) {
                return source;
            }

            try {
                await doSave()
                logger.debug('data saved');
                handler.originalValues = undefined;
            } catch (e: any) {
                throw (error = logEmitAndReturnError('An error occurred during save', {
                    cause: e
                }));
            }

            try {
                changeSignal.set(false);
            } catch (e) {
                logger.error('Error calling onChange', error = e);
            }

            return source;
        } finally {
            saveHandler.onAfterSave(error);
        }
    }
}