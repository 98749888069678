import { logger } from "@libs/logger/mod.ts";
import { ChangeProxyHandler, SignalRef, mutableSymbol } from "./common";
import { createObjectProxy } from "./object.proxy";

export const createChangeHandler = <T extends object>(entity: T, changeSignal: SignalRef<boolean>, isValidSignal: SignalRef<boolean>): ChangeProxyHandler<T> => {
    const mutable = (entity as any)[mutableSymbol];
    if (mutable !== undefined) {
        return mutable;
    }

    let proxied: Map<string | symbol, any> | undefined = undefined
    const proxy: ChangeProxyHandler<T> = {
        originalValues: undefined,
        get: (_, p) => {
            if (p === mutableSymbol) {
                return (entity as any)[mutableSymbol];
            }

            if (proxied && proxied.has(p)) {
                return proxied.get(p);
            }

            const value = Reflect.get(entity, p);
            if (Array.isArray(value)) {
                return value;
            }

            if (typeof value === 'object' && typeof value !== 'function') {
                const objectProxy = createObjectProxy(value as any, changeSignal, isValidSignal);
                (proxied ?? (proxied = new Map())).set(p, objectProxy);
                return objectProxy;
            }

            return value;
        },
        set(_, p, value) {
            if (p === mutableSymbol) {
                return false;
            }

            const prevValue = Reflect.get(entity, p);
            const result = Reflect.set(entity, p, value);
            if (!result || value === prevValue) {
                return result;
            }

            if (proxy.originalValues === undefined) {
                proxy.originalValues = new Map();
                proxy.originalValues.set(p, prevValue);
            } else if (!proxy.originalValues.has(p)) {
                proxy.originalValues.set(p, prevValue);
            } else if (value === proxy.originalValues.get(p)) {
                proxy.originalValues.delete(p);
            }

            changeSignal.set(proxy.originalValues.size > 0);

            return true;
        },
        deleteProperty(_, p) {
            if (p === mutableSymbol) {
                Reflect.deleteProperty(entity, p)
                return true;
            }

            const currentValue = Reflect.get(entity, p);
            const result = Reflect.deleteProperty(entity, p);
            if (!result || currentValue === undefined) {
                return result;
            }

            if (proxy.originalValues === undefined) {
                proxy.originalValues = new Map();
            }

            proxy.originalValues.set(p, undefined);
            changeSignal.set(true);
            logger.info("DELETE::onChange?.(true);");

            return true;
        },
    };

    return proxy;
};
