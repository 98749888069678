
import { pageTypeStrings } from '@app/defs/page.strings.ts';
import { svc } from '@app/utils/svc.ts';

import DrawerCommon from '@app/components/DrawerCommon.tsx';
import DataPage, { OwningArrayInfo } from '@app/data/components/DataPage.tsx';
import { useNavigateToView } from '@app/routes/hooks.ts';
import { createResource } from 'solid-js';
import ContactForm from './components/ContactForm';
import { ContactsDetail } from '@app/entities/contacts';
import { Once } from '@rdt-utils';

const ContactsEdit = () => {
    const [navToView, section] = useNavigateToView()
    const isAddContact = section === 'Add Contact';
    const title = isAddContact ? 'Add Contact' : 'Edit Contact';

    const ownerInfo = createResource(svc.card.contactsDetail, m => {
        return {
            parent: m,
            propName: 'contacts',
            id: { type: ContactsDetail.TYPE, key: isAddContact ? undefined : section }
        } as OwningArrayInfo<ContactsDetail, 'contacts'>
    })[0];

    const dataPage = Once.of(() => {
        return <>
            <DataPage source={ownerInfo} onSaveSuccess={navToView} pageType={pageTypeStrings.contacts}>
                <ContactForm isAddContact={isAddContact} />
            </DataPage>
        </>;
    });

    return (
        <DrawerCommon
            onClose={navToView}
            title={title}
            closeOnSwipe={false}
        >
            {dataPage()}
        </DrawerCommon>
    );
};

export default ContactsEdit;
