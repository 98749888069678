import {
    Chip
} from '@suid/material';


interface MedicalInformationItem {
    id: string;
    name: string;
}

interface MedicalInformationProps {
    medicalInformationItems: () => MedicalInformationItem[];
}

const MedicalInfoList = ({ medicalInformationItems }: MedicalInformationProps) => {
    return (
        <>

            {medicalInformationItems().map((medicalInformationItem) => (
                <Chip
                    label={medicalInformationItem.name}
                    variant="filled"
                    sx={{ margin: '3px', borderRadius: '8px', "&:hover": { backgroundColor: "none" } }}
                />
            ))}

        </>
    );
};

export default MedicalInfoList;
