import { Route, useNavigate } from '@solidjs/router';
import { Loggedout } from '@app/pages/Login/Loggedout';
import { useIsAuthenticated } from '@app/utils/hooks';
import { routeStrings } from './route.strings';

export const FallbackRoute = () => {
    const isAuthenticated = useIsAuthenticated();
    return (<>
        {!isAuthenticated() && (<Route path='*' component={Loggedout} />)}
        {isAuthenticated() && (<Route path='*' component={NavHome} />)}
    </>);
}

const NavHome = () => {
    return <>
        {useNavigate()(routeStrings.cardSelectionPath, { replace: true })}
    </>;
};