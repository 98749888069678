import { AuthenticationService } from './service/auth.service.ts';
import { AUTH_SERVICE, AuthService } from './auth.service.ts';
import { ServiceFactory } from '@libs/services/service.ts';

export const authServiceFactory: ServiceFactory<AuthService> = Object.seal({
    name: AUTH_SERVICE,
    create: () => AuthenticationService.instance,
    init: AuthenticationService.initAuth,
});

AuthenticationService.initAuth(); // no reason to wait for this