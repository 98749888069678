import { svc } from "@app/utils/svc";
import { ActionViewType, SubjectView } from "./types";
import { Resource, createResource } from "solid-js";
import { Profile } from "@app/entities/profile";
import { Contact } from "@app/entities/contacts";
export type SubjectViewService = {
    getSubjectView: (viewType: ActionViewType) => Resource<SubjectView>;
};

const isContact = (object: any): object is Contact => {
    return 'isEmergencyContact' in object;
}

const getDetailFromProfileOrContact = (p: Profile | Contact, named?: { name: string }) => {
    return {
        name: (named || p as any).name! ?? '',
        notes: p.notes,
        email: p.email,
        relationShip: p.relationShip,
        isEmergencyContact: isContact(p) ? p.isEmergencyContact : undefined,
        address: {
            street1: p.street1,
            street2: p.street2,
            city: p.city,
            state: p.state,
            zip: p.zipcode,
            county: p.county
        },
        phone1: {
            num: p.phone1,
            type: p.phone1_type
        },
        phone2: {
            num: p.phone2,
            type: p.phone2_type
        },
        phone3: {
            num: p.phone3,
            type: p.phone3_type
        },
        phone4: {
            num: p.phone4,
            type: p.phone4_type
        }
    }
}

export const subjectViewService: SubjectViewService = {
    getSubjectView: (viewType: ActionViewType) => {
        const contacts = svc.card.contactsDetail;
        const insurance = svc.card.insuranceDetails;
        const medical = svc.card.medicalInfo;
        const medications = svc.card.medications;
        const profile = svc.card.profile;
        const subject = svc.card.cardInfo;
        return createResource(() => [
            contacts(),
            insurance(),
            medical(),
            medications(),
            profile(),
            subject()
        ] as const, ([c, i, m, d, p, s]) => {
            if (!s || !p || !m || !d || !i || !c) {
                return;
            }
            const medicalInsuranceCards = i.insuranceCards?.filter(card => card.insuranceType === 'Medical') || [];

            const pruned = pruneType({
                __relId: p._id,
                viewType,
                dob: p.dob,
                age: s.age,
                heightFt: (p.heightFt ?? '').toString(),
                heightIn: (p.heightIn ?? '').toString(),
                weight: (p.weight ?? '').toString(),
                bloodType: p.blood_type,
                medications: d.medications.map(v => v.name),
                medicalConditions: m.medicalConditions.map(v => v.name),
                surgeries: m.surgeries.map(v => v.name),
                drugAllergies: m.drugAllergies.map(v => v.name),
                otherAllergies: m.otherAllergies.map(v => v.name),
                immunizations: m.immunizations.map(v => v.name),
                insuranceInfo: medicalInsuranceCards,
                ...getDetailFromProfileOrContact(p, s),
                emergencyContacts: !c.contacts ? undefined : c.contacts.map(i => getDetailFromProfileOrContact(i))
            });

            return deepReplaceUndefinedNullWithEmptyString(pruned);
        })[0] as Resource<SubjectView>;
    }
};

const pruneType = (subject: SubjectView): SubjectView => {
    if (subject.viewType === ActionViewType.FAMILY_MEMBER_CAREGIVER) {
        return subject;
    }
    if (subject.viewType === ActionViewType.FINANCIAL_REPRESENTATIVE) {
        return {
            __relId: subject.__relId,
            viewType: subject.viewType,
            name: subject.name,
            dob: subject.dob,
            email: subject.email,
            address: subject.address,
            phone1: subject.phone1,
            phone2: subject.phone2,
            phone3: subject.phone3,
            phone4: subject.phone4,
            insuranceInfo: subject.insuranceInfo
        };
    }
    if (subject.viewType === ActionViewType.EMERGENCY_PERSONNEL) {
        return {
            __relId: subject.__relId,
            viewType: subject.viewType,
            name: subject.name,
            dob: subject.dob,
            age: subject.age,
            heightFt: subject.heightFt,
            heightIn: subject.heightIn,
            weight: subject.weight,
            bloodType: subject.bloodType,
            medications: subject.medications,
            medicalConditions: subject.medicalConditions,
            surgeries: subject.surgeries,
            drugAllergies: subject.drugAllergies,
            otherAllergies: subject.otherAllergies,
            immunizations: subject.immunizations,
            emergencyContacts: subject.emergencyContacts
        };
    }
    if (subject.viewType === ActionViewType.DOCTOR_SPECIALIST) {
        return {
            __relId: subject.__relId,
            viewType: subject.viewType,
            name: subject.name,
            dob: subject.dob,
            age: subject.age,
            notes: subject.notes,
            heightFt: subject.heightFt,
            heightIn: subject.heightIn,
            weight: subject.weight,
            bloodType: subject.bloodType,
            medications: subject.medications,
            medicalConditions: subject.medicalConditions,
            surgeries: subject.surgeries,
            drugAllergies: subject.drugAllergies,
            otherAllergies: subject.otherAllergies,
            immunizations: subject.immunizations
        };
    }
    throw new Error(`Unknown viewType: ${subject.viewType}`);
}

function deepReplaceUndefinedNullWithEmptyString(obj: any) {
    if (obj === null || obj === undefined) {
        return '';
    }
    if (typeof obj === 'object') {
        for (const key in obj) {
            obj[key] = deepReplaceUndefinedNullWithEmptyString(obj[key]);
        }
    }
    return obj;
}