import { For } from 'solid-js';
import { AddressView, SubjectView } from './types';
import { Typography, Divider, Box, Card, CardHeader } from '@suid/material';
import { documentStyles } from '@app/assets/theme.ts';
import * as h from '@app/pages/helpers/commonHelpers';

type ViewProps = { subject: SubjectView };

const List = (props: { items?: string[] }) => {
    if (!props.items || props.items.length === 0) {
        return "None";
    }

    return (
        <For each={props.items}>
            {(value, index) => {
                const isLastItem = index() === props.items!.length - 1;
                return (
                    <>
                        {value}{!isLastItem && ", "}
                    </>
                );
            }}
        </For>
    );
};

export const BasicInfo = ({ subject }: ViewProps) => {
    return (
        <Card sx={{ backgroundColor: theme => theme.palette.primary.main, padding: 1 }}>
            <CardHeader title={subject.name} sx={{ color: '#FFFFFF', textAlign: 'center' }} />
        </Card>
    );
};

export const PersonalInfo = ({ subject }: ViewProps) => {
    let height = '';
    if (subject.heightFt && subject.heightIn) {
        height = `${subject.heightFt}' ${subject.heightIn}"`;
    } else if (subject.heightFt) {
        height = `${subject.heightFt}'`;
    } else if (subject.heightIn) {
        height = `${subject.heightIn}"`;
    }

    let weight = '';
    if (subject.weight) {
        weight = `${subject.weight} lbs`;
    }

    return (
        <Box sx={{ width: '100%', padding: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'flex-start', marginTop: '10px' }}>
                <Box sx={{ marginRight: 2 }}>
                    <h.AttributeWithLabel value={h.formatDate(subject.dob)} label={"Date of Birth"} />
                    <h.AttributeWithLabel value={height} label={"Height"} />
                    <h.AttributeWithLabel value={subject.bloodType} label={"Blood Type"} />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ marginRight: 3, marginLeft: 2 }} />
                <Box>
                    <h.AttributeWithLabel value={subject.age} label={"Age"} />
                    <h.AttributeWithLabel value={weight} label={"Weight"} />
                </Box>
            </Box>
        </Box>
    );
};

export const MedicalInfo = ({ subject }: ViewProps) => (
    <>
        <Box sx={{ backgroundColor: '#EBEBEB', padding: 1, marginTop: '20px', marginBottom: '10px' }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }}>Medical Information</Typography>
        </Box>
        <Box sx={{ width: '100%', padding: 1 }}>
            <Typography sx={documentStyles.documentFormLabel}>Medical Conditions</Typography>
            <Typography sx={documentStyles.documentFormValue}><List items={subject.medicalConditions} /></Typography>
        </Box>
        <Box sx={{ width: '100%', padding: 1 }}>
            <Typography sx={documentStyles.documentFormLabel}>Drug Allergies</Typography>
            <Typography sx={documentStyles.documentFormValueAttention}><List items={subject.drugAllergies} /></Typography>
        </Box>
        <Box sx={{ width: '100%', padding: 1 }}>
            <Typography sx={documentStyles.documentFormLabel}>Other Allergies</Typography>
            <Typography sx={documentStyles.documentFormValue}><List items={subject.otherAllergies} /></Typography>
        </Box>
    </>
);

export const MedicalInfoExtra = ({ subject }: ViewProps) => (
    <>
        <Box sx={{ width: '100%', padding: 1 }}>
            <Typography sx={documentStyles.documentFormLabel}>Surgeries</Typography>
            <Typography sx={documentStyles.documentFormValue}><List items={subject.surgeries} /></Typography>
        </Box>
        <Box sx={{ width: '100%', padding: 1 }}>
            <Typography sx={documentStyles.documentFormLabel}>Immunizations</Typography>
            <Typography sx={documentStyles.documentFormValue}><List items={subject.immunizations} /></Typography>
        </Box>
    </>
);

export const Medications = ({ subject }: ViewProps) => (
    <Box sx={{ width: '100%', padding: 1 }}>
        <Typography sx={documentStyles.documentFormLabel}>Medications</Typography>
        <Typography sx={documentStyles.documentFormValue}><List items={subject.medications} /></Typography>
    </Box>
);

export const Address = (props: { address?: AddressView }) => {
    const address = props.address;
    if (!address?.city && !address?.state && !address?.zip && !address?.county && !address?.street1 && !address?.street2) {
        return null;
    }

    return (
        <>
            {h.attributeAddress(
                address.street1,
                address.street2,
                address.city,
                address.state,
                address.zip,
                address.county,
                "Address",
                documentStyles.documentFormLabel,
                documentStyles.documentFormValue,
                false
            )}
        </>
    )
};

export const Phone = (props: { phone?: { num?: string, type?: string } }) => {
    const phone = props.phone;
    if (!phone?.num) {
        return null;
    }

    const label = phone.type ? `Phone (${phone.type})` : "Phone";

    return (
        <>
            <h.AttributeWithLabel value={phone.num} label={label} />
        </>
    )
};

export const EmergencyContacts = ({ subject }: ViewProps) => {
    if (!subject.emergencyContacts || subject.emergencyContacts.length === 0) {
        return null;
    }

    const filteredContacts = subject.emergencyContacts?.filter(contact =>
        contact.isEmergencyContact &&
        (contact.phone1?.num || contact.phone2?.num || contact.phone3?.num || contact.phone4?.num)
    );

    if (!filteredContacts || filteredContacts.length === 0) {
        return null;
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#EBEBEB', padding: 1, marginTop: '20px', marginBottom: '10px' }}>
                <Typography variant="h6" sx={{ textAlign: 'left' }}>Emergency Contacts</Typography>
            </Box>
            <For each={filteredContacts}>{(contact) => {
                const showDivider = contact.phone2?.num || contact.phone4?.num;

                return (
                    <Box sx={{ width: '100%', padding: 1 }}>
                        <Typography sx={documentStyles.documentFormValue}>
                            {contact.name}
                            {contact.relationShip && (
                                <span style="opacity: .6"> ({contact.relationShip})</span>
                            )}
                        </Typography>
                        <Box sx={{ width: '100%', padding: 0 }}>
                            <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'flex-start', marginTop: '10px' }}>
                                <Box sx={{ marginRight: 2 }}>
                                    <Phone phone={contact.phone1} />
                                    <Phone phone={contact.phone3} />
                                </Box>
                                {showDivider && <Divider orientation="vertical" flexItem sx={{ marginRight: 3, marginLeft: 2 }} />}
                                <Box>
                                    <Phone phone={contact.phone2} />
                                    <Phone phone={contact.phone4} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            }}</For>
        </>
    );
};

export const ProfileNotes = ({ subject }: ViewProps) => {
    if (!subject.notes) {
        return null;
    }

    return (
        <Box sx={{ width: '100%', padding: 1 }}>
            <h.AttributeWithLabel value={subject.notes ? subject.notes : null} label={"Additional Notes"} />
        </Box>
    );
};

export const ContactInfo = ({ subject }: ViewProps) => {
    const showDivider = subject.phone2?.num || subject.phone4?.num;

    return (
        <>
            <Box sx={{ backgroundColor: '#EBEBEB', padding: 1, marginTop: '20px', marginBottom: '10px' }}>
                <Typography variant="h6" sx={{ textAlign: 'left' }}>Contact Information</Typography>
            </Box>
            <Box sx={{ width: '100%', padding: 1 }}>
                <Address address={subject.address} />
                <Box sx={{ width: '100%', padding: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'flex-start', marginTop: '10px' }}>
                        <Box sx={{ marginRight: 2 }}>
                            <Phone phone={subject.phone1} />
                            <Phone phone={subject.phone3} />
                        </Box>
                        {showDivider && <Divider orientation="vertical" flexItem sx={{ marginRight: 3, marginLeft: 2 }} />}
                        <Box>
                            <Phone phone={subject.phone2} />
                            <Phone phone={subject.phone4} />
                        </Box>
                    </Box>
                </Box>
                <h.AttributeWithLabel value={subject.email} label={"Email"} />
            </Box>
        </>
    );
};


export const InsuranceInfo = ({ subject }: ViewProps) => {
    if (!subject.insuranceInfo || subject.insuranceInfo.length === 0) {
        return null;
    }

    return (<>
        <Box sx={{ backgroundColor: '#EBEBEB', padding: 1, marginTop: '20px', marginBottom: '10px' }}>
            <Typography variant="h5" sx={{ textAlign: 'left' }}>Medical Insurance</Typography>
        </Box>
        {subject.insuranceInfo.map((info) => (
            <Box sx={{ width: '100%', padding: 1 }}>
                <Divider><Typography variant='h5'>{info.insurerName}</Typography></Divider>
                <h.AttributeWithLabel label="Member ID" value={info.memberID} />
                <h.AttributeWithLabel label="Group Number" value={info.groupNumber} />
                <h.AttributeWithLabel label="Member Name" value={info.responsibleParty} />
                <h.AttributeWithLabel label="Plan Number" value={info.planNumber} />
                <h.AttributeWithLabel label="Plan Type" value={info.planType} />
                <h.AttributeWithLabel label="Network" value={info.network} />
                <h.AttributeWithLabel label="Payer ID" value={info.payerID} />
                <h.AttributeWithLabel label="Effective Date" value={info.effectiveDate} />
                <h.AttributeWithLabel label="Copayments" value={info.copay} divider={true} />
                <h.AttributeWithLabel label="Deductible" value={info.deductible} divider={true} />
                <h.AttributeWithLabel label="Coinsurance" value={info.coIns} divider={true} />
                <h.AttributeWithLabel label="Dependents" value={info.dependents} divider={true} />
                <h.AttributeWithLabel label="Contact Information" value={info.contactInformation} divider={true} />
                <h.AttributeWithLabel label="Rx Information" value={info.rxName} divider={true} />
                <h.AttributeWithLabel label="Rx ID" value={info.rxID} />
                <h.AttributeWithLabel label="Rx BIN" value={info.rxBIN} />
                <h.AttributeWithLabel label="Rx GRP" value={info.rxGRP} />
                <h.AttributeWithLabel label="Rx PCN" value={info.rxPCN} />
                <h.AttributeWithLabel label="Rx Network" value={info.rxNetwork} />
                <h.AttributeWithLabel label="Rx Formulary" value={info.rxFormulary} />
                <h.AttributeWithLabel label="Rx Copay" value={info.rxCopay} />
                <h.AttributeWithLabel label="Additional Notes" value={info.additionalNotes} divider={true} />
            </Box>
        ))}
    </>);
};