import { svc_util } from "@app/svc/svc_util.ts";
import { Errors } from "@rdt-utils";

export class HttpClient {
    #tokenFetcher: () => Promise<string>;
    constructor(tokenFetcher?: () => Promise<string>) {
        this.#tokenFetcher = tokenFetcher ?? (() => svc_util.auth.getToken());
        const self = this as any;
        self.fetch = this.fetch.bind(this);
        self.get = this.get.bind(this);
        self.post = this.post.bind(this);
        self.put = this.put.bind(this);
        self.delete = this.delete.bind(this);
        self.doFetch = this.doFetch.bind(this);
    }

    public async fetch(input: string | URL, init?: RequestInit): Promise<Response> {
        return this.doFetch(input, init?.method, init);
    }

    public async get(input: string | URL, init?: RequestInit): Promise<Response> {
        return this.doFetch(input, 'GET', init);
    }

    public async post(input: string | URL, init?: RequestInit): Promise<Response> {
        return this.doFetch(input, 'POST', init);
    }

    public async put(input: string | URL, init?: RequestInit): Promise<Response> {
        return this.doFetch(input, 'PUT', init);
    }

    public async delete(input: string | URL, init?: RequestInit): Promise<Response> {
        return this.doFetch(input, 'DELETE', init);
    }

    private async doFetch(input: string | URL, method?: string, requestInit?: RequestInit): Promise<Response> {
        const init = requestInit ?? {};

        init.method = method;

        const headers = (init.headers ?? (init.headers = {})) as any;
        headers.Authorization = `Bearer ${await this.#tokenFetcher()}`;

        const request = new Request(input, init);

        const response = await fetch(request);
        if (!response.ok) {
            throw Errors.getErrorForHttpCode(response.status, response.statusText);
        }

        return response;
    }
}