export default function MedicalInsuranceIcon() {
  return (
    <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path d="M37.96 7.30005H6.04C4.70331 7.30005 3.42137 7.83105 2.47618 8.77623C1.531 9.72141 1 11.0034 1 12.34V31.66C1 32.9967 1.531 34.2787 2.47618 35.2239C3.42137 36.169 4.70331 36.7 6.04 36.7H37.96C39.2967 36.7 40.5786 36.169 41.5238 35.2239C42.469 34.2787 43 32.9967 43 31.66V12.34C43 11.0034 42.469 9.72141 41.5238 8.77623C40.5786 7.83105 39.2967 7.30005 37.96 7.30005Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
        <path d="M28.3 7.3V2.68C28.3 2.23444 28.123 1.80712 27.8079 1.49206C27.4928 1.177 27.0655 1 26.62 1H17.38C16.9344 1 16.5071 1.177 16.192 1.49206C15.877 1.80712 15.7 2.23444 15.7 2.68V7.3" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
        <path d="M21.9375 22.5C25.3896 22.5 28.1875 19.7021 28.1875 16.25C28.1875 12.7979 25.3896 10 21.9375 10C18.4854 10 15.6875 12.7979 15.6875 16.25C15.6875 19.7021 18.4854 22.5 21.9375 22.5ZM16.0781 30.7031C16.0781 31.3525 16.6006 31.875 17.25 31.875C17.8994 31.875 18.4219 31.3525 18.4219 30.7031C18.4219 30.0537 17.8994 29.5312 17.25 29.5312C16.6006 29.5312 16.0781 30.0537 16.0781 30.7031ZM26.625 24.0918V26.4844C28.4072 26.8457 29.75 28.4277 29.75 30.3125V32.3486C29.75 32.7197 29.4863 33.042 29.1201 33.1152L27.5479 33.4277C27.3379 33.4717 27.1328 33.335 27.0889 33.1201L26.9375 32.3535C26.8936 32.1436 27.0303 31.9336 27.2451 31.8945L28.1875 31.7041V30.3125C28.1875 27.2461 23.5 27.1338 23.5 30.4053V31.709L24.4424 31.8994C24.6523 31.9434 24.7891 32.1484 24.75 32.3584L24.5986 33.125C24.5547 33.335 24.3496 33.4717 24.1396 33.4326L22.6162 33.2275C22.2305 33.1738 21.9424 32.8467 21.9424 32.4512V30.3125C21.9424 28.4277 23.2852 26.8506 25.0674 26.4844V24.2773C24.96 24.3115 24.8525 24.3311 24.7451 24.3701C23.8662 24.6777 22.9238 24.8486 21.9424 24.8486C20.9609 24.8486 20.0186 24.6777 19.1396 24.3701C18.7783 24.2432 18.4121 24.165 18.0361 24.1162V28.1006C19.1641 28.4375 19.9893 29.4727 19.9893 30.708C19.9893 32.2168 18.7637 33.4424 17.2549 33.4424C15.7461 33.4424 14.5205 32.2168 14.5205 30.708C14.5205 29.4727 15.3457 28.4375 16.4736 28.1006V24.1748C13.3682 24.6973 11 27.373 11 30.625V32.8125C11 34.0186 11.9814 35 13.1875 35H30.6875C31.8936 35 32.875 34.0186 32.875 32.8125V30.625C32.875 27.1094 30.1016 24.2627 26.625 24.0918Z" fill="#333333" />
      </g>
    </svg>
  );
}
