import { createSignal, Signal } from 'solid-js';

export type Signaller = {
    track(key: any): void;
    dirty(key: any): void;
    resignalAll(): void;
};

export const Signaller = {
    of: () => ofCache(new Map<unknown, Signal<any>>()),
    ofWeak: () => ofCache(new WeakMap<object, Signal<any>>()),
};

function ofCache(map: Map<unknown, Signal<any>> | WeakMap<object, Signal<any>>): Signaller {
    return {
        resignalAll: () => {
            if (map instanceof WeakMap) {
                return;
            }

            map.forEach((signal: Signal<any>) => {
                if (signal) {
                    signal[1]()
                }
            });
        },
        track: (key: any): void => {
            let signal = map.get(key);

            if (signal) {
                signal[0]();
                return;
            }

            signal = createSignal(undefined, { equals: false });
            map.set(key, signal);
            signal[0]();
        },
        dirty: (key: any): void => {
            const signal = map.get(key);
            if (signal) {
                signal[1]();
            }
        },
    };
}
