import { Result } from '../../utils/result.ts';
import { Deadline, Executor } from '@rdt-utils';
import _, { __task_symbol } from './__utils.ts';
import { createLazyTask } from './lazy.task.ts';

export type TaskState = 'pending' | 'cancelled' | 'fulfilled' | 'rejected';

export class TaskCancelError extends Error {
    public constructor(name?: string) {
        super(name ?? 'Task cancelled');
        this.name = 'TaskCancelError';
    }
}

export class TaskTimeoutError extends TaskCancelError {
    constructor() {
        super('Task timeout cancellation');
        this.name = 'TimeoutError';
    }
}

export interface Task<T> extends Promise<T> {
    [__task_symbol]: true;

    getResult(): Result<T> | undefined;
    then: Promise<T>['then'];
    catch: Promise<T>['catch'];
    finally: Promise<T>['finally'];

    // accept(onAccept: (value: T) => void): Task<T>;
    // map<R>(transform: (value: T) => R | PromiseLike<R>): Task<R>;
    // remediate<R>(transform: (error: any) => R | PromiseLike<R>): Task<T>;

    // onSuccess(cb: (value: T) => void): Task<T>;
    // onError(cb: (error: any) => void): Task<T>;
    // onSuccessOrError(cb: (value: T, error: any) => void): Task<T>;
    withTimeout(ms: number): Task<T>;

    resolve(value: T): boolean;
    reject(reason: unknown): boolean;
    bind(promise: Promise<T>): void;

    cancel(): boolean;
    cancelAfter(ms: number): Promise<boolean>;
    isDone(): boolean;
    isCancelled(): boolean;
    isFulfilled(): boolean;
    isRejected(): boolean;
    status(): TaskState;
}

export interface TaskFactory {
    lazy<T>(value: () => T | Promise<T>): Task<T>;
    create<T = void>(): Task<T>;
    isTask<T = void>(value: any): value is Task<T>;
    resolve<T = void>(promise: T | PromiseLike<T>): Task<T>;
    cancelled<T>(): Task<T>;
    rejected<T>(reason: unknown): Task<T>;
    fulfilled(): Task<void>;
    fulfilled<T>(value: T): Task<T>;
    exec<T = void>(callable: () => T | PromiseLike<T>): Task<T>;
    run<T = void>(callable: () => T | PromiseLike<T>, executor?: Executor, deadline?: Deadline): Task<T>;
    schedule<T = void>(callable: () => T | PromiseLike<T>, ms: number): Task<T>;
    delay(ms: number): Task<void>;
}

export const Task: TaskFactory = {
    lazy: <T>(value: () => T | Promise<T>): Task<T> => createLazyTask<T>(value),
    create: <T = void>(): Task<T> => new TaskImpl<T>(),
    isTask: <T = void>(value: any): value is Task<T> => _.isTask(value),
    resolve: <T = void>(promise: T | PromiseLike<T>): Task<T> => _.fulfillTask(promise),
    cancelled: _.cancelled,
    rejected: _.rejected,
    fulfilled: _.fulfilled,
    run: _.run,
    schedule: _.schedule,
    exec: _.exec,
    delay: ms => _.schedule(() => { }, ms)
};

class TaskImpl<T> implements Task<T> {
    #valueOrError: any;
    #resolve!: (value: T | PromiseLike<T>) => void;
    #reject!: (reason: any) => void;
    #status: TaskState = 'pending';
    readonly #delegate: Promise<T>;

    constructor() {
        this.#delegate = new Promise<T>((resolve, reject) => {
            this.#resolve = resolve;
            this.#reject = reject;
        });
    }

    readonly [Symbol.toStringTag]: string = 'Task';

    public bind(promise: Promise<T>): void {
        promise.then(this.#resolve).catch(this.#reject);
    }

    public getResult(): { success: true; value: T } | { success: false; error: any } | undefined {
        if (this.isFulfilled()) {
            return { success: true, value: this.#valueOrError };
        }

        if (this.isRejected() || this.isCancelled()) {
            return { success: false, error: this.#valueOrError };
        }

        return undefined;
    }

    public then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2> {
        return this.#delegate.then(onfulfilled, onrejected);
    }

    public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
        return this.#delegate.catch(onrejected);
    }

    public finally(onfinally?: (() => void) | undefined | null): Promise<T> {
        return this.#delegate.finally(onfinally);
    }

    public resolve(value: T): boolean {
        if (this.isDone()) {
            return false;
        }
        this.#status = 'fulfilled';
        this.#resolve(this.#valueOrError = value);
        return true;
    }

    public reject(reason: unknown): boolean {
        if (this.isDone()) {
            return false;
        }
        this.#status = 'rejected';
        this.#reject(this.#valueOrError = reason || 'reason undefined');
        return true;
    }

    public cancel(): boolean {
        if (this.isDone()) {
            return false;
        }
        this.#status = 'cancelled';
        this.#reject(this.#valueOrError = new TaskCancelError());
        return true;
    }

    public cancelAfter(ms: number): Promise<boolean> {
        if (this.isDone()) {
            return Promise.resolve(false);
        }
        return new Promise<boolean>((resolve) => {
            setTimeout(() => {
                resolve(this.cancel());
            }, ms);
        });
    }

    public isDone(): boolean {
        return this.#status !== 'pending';
    }

    public isCancelled(): boolean {
        return this.#status === 'cancelled';
    }

    public isFulfilled(): boolean {
        return this.#status === 'fulfilled';
    }

    public isRejected(): boolean {
        return this.#status === 'rejected';
    }

    public status(): TaskState {
        return this.#status;
    }

    public withTimeout(timeoutMs: number): Task<T> {
        if (timeoutMs <= 0 || this.isDone()) {
            return this;
        }

        const timerId = setTimeout(() => {
            if (!this.isDone()) {
                this.#status = 'cancelled';
                this.#reject(this.#valueOrError = new TaskTimeoutError());
            }
        }, timeoutMs);

        this.finally(() => {
            clearTimeout(timerId);
        });

        return this;
    }

    get [__task_symbol](): true {
        return true;
    }
}