import { JSX } from 'solid-js';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    IconButton,
    Avatar
} from '@suid/material';

import MoreInfo from '@suid/icons-material/NotesOutlined';
import { extractInitialsFromName } from '@app/utils/normalize';

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: '#757575',
            color: '#FFFFFF'
        },
        children: `${extractInitialsFromName(name)}`,
    };
}

export interface ListCommonItem<T> {
    id: string;
    primaryText: string;
    secondaryText: string;
    icon: any;
    data: T;
}

interface ListCommonProps<T> {
    items: () => ListCommonItem<T>[];
    onItemClick: (item: T) => void;
    renderIcon: (icon: any) => JSX.Element;
    avatar?: boolean;
}

const ListCommon = <T,>({ items, onItemClick, renderIcon, avatar = false }: ListCommonProps<T>) => {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true}>
            {items().map((item) => (
                <ListItem
                    onClick={() => onItemClick(item.data)}
                    divider
                    sx={{ width: '100%', display: 'flex', marginLeft: '0px', paddingLeft: '0px' }}
                >
                    <ListItemButton alignItems='flex-start' sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                        {avatar ? (
                            <Avatar {...stringAvatar(item.primaryText)} sx={{ marginRight: '10px', marginTop: '8px' }} />
                        ) : (
                            <Avatar sx={{ bgcolor: '#FFFFFF', marginRight: '10px', marginTop: '8px' }} variant="rounded">
                                {renderIcon(item.icon)}
                            </Avatar>
                        )}
                        <ListItemText
                            primary={item.primaryText}
                            secondary={item.secondaryText}
                            sx={{ '& .MuiListItemText-primary': { fontWeight: '500', fontSize: '16px' } }}
                        />
                        <IconButton edge="end" sx={{ flexGrow: 0, "&:hover": { backgroundColor: "transparent" } }}>
                            <MoreInfo />
                        </IconButton>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default ListCommon;
