//Icons
import RateReview from '@suid/icons-material/RateReview';

import {
    Fab
} from '@suid/material';

import { svc } from '@app/utils/svc';
import { Config } from '@libs/config';
import { layoutBodyStyles } from '@app/assets/theme';

const embeddedSurveyId = Config.GLEAP_SURVEY_ID.else('').trim();

const CallToAction = () => {
    if (embeddedSurveyId === '') {
        return null;
    }

    return (
        <Fab
            variant="extended" color="secondary"
            onClick={() => {
                svc.gleap.showSurvey(embeddedSurveyId)
            }}
            sx={layoutBodyStyles.callToAction}
            >

            <RateReview sx={{ mr: 1 }} />
            Give Us Feedback
        </Fab>
    );
};

export default CallToAction;