import { Loggedout } from './Loggedout.tsx';
import { svc } from '@app/utils/svc';

export const LogoutCallback = () => {
    const authService = svc.auth;
    if (authService.isAuthenticated()) {
        authService.logoutSilently();
    }

    return <Loggedout />;
}

export default LogoutCallback;