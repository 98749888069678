import { z } from 'zod';

import { dt } from '../../utils/dt.ts';
import { Validators } from '@libs/validators/mod.ts';

const ensureDateFormatted = (date: string): string => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
        throw new Error(`Invalid date: ${date}. Please use ${dt.DateFormatString}`);
    }
    return dt.format(d);
};

export const array = z.array;
export const object = z.object;
export const numberOptional = (message?: string) => {
    return z.coerce.number({
        invalid_type_error: message ?? 'Invalid number',
    }).optional();
};
export const number = (message?: string) => {
    return z.coerce.number({
        invalid_type_error: message ?? 'Invalid number',
    });
};
export const boolean = z.boolean;
export const zipcode = () => z.string().regex(Validators.zip.regex, Validators.zip.message());
export const phone = () => z.string().regex(Validators.phone.regex, Validators.phone.message());
export const email = (message?: string) => z.string().email(message ?? Validators.email.message());
export const uuid = (): z.ZodString => z.string().uuid();
export const literal = <T extends z.Primitive>(value: T): z.ZodLiteral<T> => z.literal(value);
export const string = (max = 150): z.ZodString => z.string().max(max);
export const ostring = (max = 150): z.ZodOptional<z.ZodString> => z.string().max(max).optional();
export const date = (): z.ZodDate => z.date();
export const tuple = z.tuple;
export const union = z.union;
export const record = z.record;

export const stringOrDefault = (
    defaultValue: string,
    max?: number,
): z.ZodOptional<z.ZodDefault<z.ZodString>> => {
    if (defaultValue === undefined || defaultValue === null) {
        throw new Error(`Default value cannot be undefined or null`);
    }

    if (max !== undefined && defaultValue.length > max) {
        throw new Error(`Default value length (${defaultValue.length}) exceeds max length (${max})`);
    }

    const actualMax = max !== undefined ? max : defaultValue !== undefined ? defaultValue.length : 150;

    return z.string().max(actualMax).default(defaultValue).optional();
};

export const stringYMD = (): z.ZodEffects<z.ZodString> => {
    return z.string().transform(ensureDateFormatted);
};

export type inferType<T extends z.ZodType> = z.infer<T>;
