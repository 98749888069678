import { Box, Typography } from '@suid/material';
import { Resource, Show } from 'solid-js';

import { careCardIdentityStyles } from '@app/assets/theme';

interface Props<T extends { name: string, age: string } = { name: string, age: string }> {
    subject: Resource<T>;
    editMode: boolean;
}

const CareCardIdentity = ({ subject, editMode }: Props) => {
    return (
        <>
            {subject() &&
                (<Box sx={careCardIdentityStyles.careCardIdentityContainer}>
                    <Typography
                        sx={careCardIdentityStyles.careCardName}
                        gutterBottom
                        variant='h1'
                        color='text.primary'
                    >
                        {subject()?.name ?? ''}
                    </Typography>
                    <Typography
                        sx={{
                            ...careCardIdentityStyles.careCardAge,
                            "minHeight": "20px",
                        }}
                        gutterBottom
                        variant='h3'
                        color='text.primary'
                    >
                        {subject()?.age ?? ''}
                        <Show when={!editMode}>
                            <p color='text.primary'>You cannot edit this card</p>
                        </Show>
                    </Typography>
                </Box>)
            }
        </>
    );
};

export default CareCardIdentity;
