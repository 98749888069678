import { Cordova } from "./cordova";

export const clearStorage = () => {
    const c = localStorage.getItem(Cordova.CORDOVA_HEIGHT_STORAGE_KEY);
    localStorage.clear();
    if (c) {
        localStorage.setItem(Cordova.CORDOVA_HEIGHT_STORAGE_KEY, c);
    }
    window.sessionStorage.clear();
};

export const appStorage = Object.freeze({
    getItem: localStorage.getItem.bind(localStorage),
    setItem: localStorage.setItem.bind(localStorage),
    removeItem: localStorage.removeItem.bind(localStorage),
    clear: () => clearStorage(),
});