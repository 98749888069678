import { ServiceName, ServiceFactory } from '@libs/services/service.ts';
import { svc } from '@app/utils/svc.ts';

const eventTracker = {
    onView(type: string, data?: any): void {
        queueMicrotask(() => {
            svc.gleap.trackEvent(`${type}_viewed`, data);
            svc.cio.track(`${type}_viewed`, data);
        });
    },
    onSave(type: string, data?: any): void {
        queueMicrotask(() => {
            svc.gleap.trackEvent(`${type}_saved`, data);
            svc.cio.track(`${type}_saved`, data);
        });
    },
    onPrompt(type: string, data?: any): void {
        queueMicrotask(() => {
            svc.gleap.trackEvent(`${type}_save_changes_prompt`, data);
            svc.cio.track(`${type}_save_changes_prompt`, data);
        });
    }
}

export type EventTracker = {
    onView(type: string, data?: any): void;
    onSave(type: string, data?: any): void;
    onPrompt(type: string, data?: any): void;
}

export const EVENT_TRACKER = ServiceName.create<EventTracker>('EVENT_TRACKER');
export const eventTrackerFactory: ServiceFactory<EventTracker> = Object.seal({
    name: EVENT_TRACKER,
    create: () => eventTracker,
});