import { Md5 } from '@libs/utils/md5';
import { LogLevel, useLogger } from '@libs/logger/mod.ts';
import { API_V2 } from '@app/dto/constants';
import { APP_API_ORIGIN, APP_ORIGIN, ROUTE_STRINGS } from '@app/defs/constants';
import { base64 } from '@rdt-utils';
import { HttpClient } from "@app/identity/http.client";
import { useLocation } from '@solidjs/router';
import { decryptOnce, encryptOnce } from "@rdt-utils";

const shareApiUrl = APP_API_ORIGIN + API_V2.shareUrlPath;

const logger = useLogger('SHARE_SERVICE');

export type LookupId = string;
export type ShareKeyInfo = {
    id: LookupId;
    key: string;
}

export interface ShareService {
    share<T extends object>(relId: string, data: T, ttlMinutes: number): Promise<URL>;
    getSharedData<T extends object>(shareKey: ShareKeyInfo): Promise<{ subject: T, expiry: number }>;
    getUrlInfo(): ShareKeyInfo;
}

const client = new HttpClient();

// TODO: export a service factory
export const shareService: ShareService = {
    getSharedData: async <T extends object>(shareKey: ShareKeyInfo): Promise<{ subject: T, expiry: number }> => {
        const url = new URL(shareApiUrl + '?id=' + shareKey.id);
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            logger.emit(LogLevel.DEBUG, `Failed to get data from ${url.toString()}`);
            throw new Error(response.statusText);
        }

        const data = await response.json();
        if (typeof data?.expiry === "number" && data.expiry < Date.now()) {
            throw new Error('Data has expired');
        }

        const dataBuf = base64.toArrayBuffer(data.data);
        const ivBuf = base64.toArrayBuffer(data.iv);

        const clearData = await decryptOnce<T>(shareKey.key, { data: dataBuf, iv: ivBuf });
        if (!clearData) {
            throw new Error('Failed to decrypt data');
        }

        return { subject: clearData, expiry: data.expiry };
    },
    share: async <T extends object>(relId: string, data: T, ttlMinutes: number): Promise<URL> => {
        const key = new Md5().update(crypto.getRandomValues(new Uint8Array(32))).toString();
        const dataBuf = await encryptOnce(key, data);

        const response = await client.post(shareApiUrl, {
            body: JSON.stringify({
                __relId: relId,
                data: base64.fromArrayBuffer(dataBuf.data),
                iv: base64.fromArrayBuffer(dataBuf.iv),
                ttlMinutes,
            })
        });

        if (!response.ok) {
            logger.emit(LogLevel.DEBUG, `Failed to share data`);
            throw new Error(response.statusText);
        }

        const lookupId = await response.text();
        if (!lookupId) {
            throw new Error('Failed to share data. No lookup key returned');
        }

        return new URL(`${APP_ORIGIN}${ROUTE_STRINGS.shareResolveHash}?id=${lookupId}&key=${key}`);
    },
    getUrlInfo: (): ShareKeyInfo => {
        const loc = useLocation();
        const id = loc.query.id;
        const key = loc.query.key;
        if (!id || !key) {
            throw new Error('Invalid share url');
        }

        return { id, key };
    }
};
