export default function Brand() {
  return (
    <svg width="241" height="64" viewBox="0 0 241 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M150.773 50.5759V53.4674C150.773 53.716 150.769 53.9886 150.76 54.2852C150.751 54.5817 150.742 54.8282 150.733 55.0244H150.773C150.947 54.5839 151.251 54.2197 151.684 53.9319C152.117 53.6441 152.657 53.5001 153.304 53.5001C154.251 53.5001 154.981 53.812 155.494 54.4356C156.012 55.0593 156.271 55.973 156.271 57.1767C156.271 57.9138 156.155 58.5614 155.923 59.1197C155.691 59.6736 155.345 60.1075 154.885 60.4215C154.429 60.7312 153.858 60.886 153.17 60.886C152.55 60.886 152.043 60.7595 151.65 60.5066C151.258 60.2493 150.972 59.9287 150.793 59.5449H150.74L150.612 60.7552H150.097V50.5759H150.773ZM153.231 54.0758C152.655 54.0758 152.186 54.1979 151.824 54.4422C151.463 54.682 151.197 55.0331 151.028 55.4954C150.858 55.9577 150.773 56.5225 150.773 57.1898V57.2945C150.773 58.2758 150.956 59.0237 151.322 59.5384C151.688 60.053 152.282 60.3103 153.104 60.3103C153.916 60.3103 154.53 60.0399 154.945 59.4991C155.365 58.954 155.574 58.1798 155.574 57.1767C155.574 56.1518 155.38 55.3799 154.992 54.8609C154.603 54.3375 154.016 54.0758 153.231 54.0758Z" fill="#F4F4F4" />
    <path d="M157.074 53.6441H157.791L159.559 58.158C159.666 58.4241 159.76 58.6705 159.84 58.8973C159.925 59.1197 159.999 59.3247 160.061 59.5122C160.124 59.6954 160.175 59.8633 160.215 60.0159H160.255C160.322 59.8153 160.412 59.558 160.523 59.244C160.635 58.9256 160.762 58.5723 160.905 58.1842L162.653 53.6441H163.376L160.054 62.0897C159.894 62.4997 159.717 62.8464 159.525 63.1299C159.333 63.4178 159.108 63.6336 158.849 63.7776C158.59 63.9258 158.278 64 157.912 64C157.746 64 157.597 63.9869 157.463 63.9607C157.329 63.9389 157.199 63.9084 157.074 63.8692V63.3C157.191 63.3349 157.309 63.3632 157.429 63.3851C157.554 63.4112 157.691 63.4243 157.838 63.4243C158.092 63.4243 158.311 63.372 158.494 63.2673C158.682 63.167 158.849 63.01 158.996 62.7963C159.144 62.5826 159.282 62.31 159.412 61.9785L159.907 60.7159L157.074 53.6441Z" fill="#F4F4F4" />
    <path d="M31.832 2.19623L19.9458 36.3547H11.8623L0 2.19623H7.48566L14.0625 22.5231C14.1741 22.8657 14.3575 23.5122 14.6126 24.4623C14.8677 25.3969 15.1228 26.3937 15.3779 27.4529C15.6489 28.4965 15.8323 29.361 15.928 30.0463C16.0236 29.361 16.191 28.4965 16.4302 27.4529C16.6853 26.4093 16.9324 25.4202 17.1716 24.4857C17.4267 23.5355 17.61 22.8813 17.7216 22.5231L24.3463 2.19623H31.832Z" fill="#F4F4F4" />
    <path d="M42.9768 10.2335V36.3547H35.6824V10.2335H42.9768ZM39.3416 0C40.4258 0 41.3585 0.249218 42.1397 0.747654C42.921 1.23051 43.3116 2.14172 43.3116 3.48126C43.3116 4.80523 42.921 5.72423 42.1397 6.23824C41.3585 6.73667 40.4258 6.98589 39.3416 6.98589C38.2414 6.98589 37.3008 6.73667 36.5195 6.23824C35.7542 5.72423 35.3715 4.80523 35.3715 3.48126C35.3715 2.14172 35.7542 1.23051 36.5195 0.747654C37.3008 0.249218 38.2414 0 39.3416 0Z" fill="#F4F4F4" />
    <path d="M61.9181 31.1445C62.7153 31.1445 63.4886 31.0666 64.238 30.9108C65.0033 30.7551 65.7606 30.5604 66.51 30.3267V35.6304C65.7287 35.9731 64.7561 36.2534 63.5922 36.4715C62.4443 36.7051 61.1847 36.822 59.8135 36.822C58.2191 36.822 56.7842 36.5727 55.5087 36.0743C54.2491 35.5603 53.2526 34.6802 52.5192 33.4341C51.8017 32.1725 51.443 30.4202 51.443 28.1772V15.5839H47.9513V12.5699L51.9691 10.1868L54.0737 4.67284H58.7373V10.2335H66.223V15.5839H58.7373V28.1772C58.7373 29.1741 59.0243 29.9217 59.5983 30.4202C60.1882 30.903 60.9615 31.1445 61.9181 31.1445Z" fill="#F4F4F4" />
    <path d="M82.8923 9.69614C86.4797 9.69614 89.23 10.4594 91.1433 11.9858C93.0566 13.5123 94.0132 15.8331 94.0132 18.9484V36.3547H88.9191L87.5081 32.8033H87.3168C86.5514 33.7379 85.7702 34.5011 84.973 35.093C84.1758 35.6849 83.259 36.121 82.2227 36.4014C81.1863 36.6818 79.9268 36.822 78.444 36.822C76.8655 36.822 75.4465 36.526 74.187 35.9341C72.9433 35.3422 71.9628 34.4388 71.2453 33.2239C70.5278 31.9934 70.1691 30.4357 70.1691 28.551C70.1691 25.7785 71.1656 23.738 73.1586 22.4296C75.1516 21.1056 78.1411 20.3736 82.127 20.2334L86.7667 20.0932V18.9484C86.7667 17.5777 86.4 16.573 85.6666 15.9344C84.9331 15.2958 83.9127 14.9764 82.6053 14.9764C81.3139 14.9764 80.0463 15.1556 78.8027 15.5138C77.5591 15.8721 76.3155 16.3238 75.0718 16.8689L72.6563 12.0559C74.0754 11.3238 75.6618 10.7475 77.4156 10.327C79.1854 9.90641 81.0109 9.69614 82.8923 9.69614ZM86.7667 24.252L83.9446 24.3455C81.5849 24.4078 79.9427 24.8206 79.018 25.5838C78.1092 26.347 77.6548 27.3517 77.6548 28.5978C77.6548 29.6881 77.9816 30.4669 78.6353 30.9342C79.289 31.3859 80.142 31.6117 81.1943 31.6117C82.7568 31.6117 84.0722 31.16 85.1404 30.2566C86.2246 29.3532 86.7667 28.0682 86.7667 26.4015V24.252Z" fill="#F4F4F4" />
    <path d="M108.841 36.3547H101.547V0H108.841V36.3547Z" fill="#F4F4F4" />
    <path d="M144.523 2.19623L132.637 36.3547H124.554L112.691 2.19623H120.177L126.754 22.5231C126.866 22.8657 127.049 23.5122 127.304 24.4623C127.559 25.3969 127.814 26.3937 128.069 27.4529C128.34 28.4965 128.524 29.361 128.619 30.0463C128.715 29.361 128.883 28.4965 129.122 27.4529C129.377 26.4093 129.624 25.4202 129.863 24.4857C130.118 23.5355 130.302 22.8813 130.413 22.5231L137.038 2.19623H144.523Z" fill="#F4F4F4" />
    <path d="M159.327 9.69614C162.915 9.69614 165.665 10.4594 167.578 11.9858C169.492 13.5123 170.448 15.8331 170.448 18.9484V36.3547H165.354L163.943 32.8033H163.752C162.987 33.7379 162.205 34.5011 161.408 35.093C160.611 35.6849 159.694 36.121 158.658 36.4014C157.621 36.6818 156.362 36.822 154.879 36.822C153.301 36.822 151.882 36.526 150.622 35.9341C149.378 35.3422 148.398 34.4388 147.68 33.2239C146.963 31.9934 146.604 30.4357 146.604 28.551C146.604 25.7785 147.601 23.738 149.594 22.4296C151.587 21.1056 154.576 20.3736 158.562 20.2334L163.202 20.0932V18.9484C163.202 17.5777 162.835 16.573 162.102 15.9344C161.368 15.2958 160.348 14.9764 159.04 14.9764C157.749 14.9764 156.481 15.1556 155.238 15.5138C153.994 15.8721 152.751 16.3238 151.507 16.8689L149.091 12.0559C150.51 11.3238 152.097 10.7475 153.851 10.327C155.62 9.90641 157.446 9.69614 159.327 9.69614ZM163.202 24.252L160.38 24.3455C158.02 24.4078 156.378 24.8206 155.453 25.5838C154.544 26.347 154.09 27.3517 154.09 28.5978C154.09 29.6881 154.417 30.4669 155.07 30.9342C155.724 31.3859 156.577 31.6117 157.629 31.6117C159.192 31.6117 160.507 31.16 161.575 30.2566C162.66 29.3532 163.202 28.0682 163.202 26.4015V24.252Z" fill="#F4F4F4" />
    <path d="M202.519 10.2335V36.3547H196.923L195.943 33.0136H195.56C194.986 33.9014 194.268 34.6257 193.407 35.1865C192.546 35.7472 191.598 36.16 190.561 36.4248C189.525 36.6896 188.449 36.822 187.333 36.822C185.42 36.822 183.753 36.4949 182.334 35.8407C180.915 35.1709 179.807 34.1351 179.01 32.7332C178.229 31.3314 177.838 29.509 177.838 27.266V10.2335H185.133V25.4903C185.133 27.3595 185.475 28.7691 186.161 29.7192C186.847 30.6694 187.939 31.1445 189.437 31.1445C190.92 31.1445 192.084 30.8174 192.929 30.1632C193.774 29.4934 194.364 28.5199 194.699 27.2426C195.05 25.9498 195.225 24.3766 195.225 22.5231V10.2335H202.519Z" fill="#F4F4F4" />
    <path d="M217.467 36.3547H210.173V0H217.467V36.3547Z" fill="#F4F4F4" />
    <path d="M236.408 31.1445C237.205 31.1445 237.979 31.0666 238.728 30.9108C239.493 30.7551 240.251 30.5604 241 30.3267V35.6304C240.219 35.9731 239.246 36.2534 238.082 36.4715C236.934 36.7051 235.675 36.822 234.304 36.822C232.709 36.822 231.274 36.5727 229.999 36.0743C228.739 35.5603 227.743 34.6802 227.009 33.4341C226.292 32.1725 225.933 30.4202 225.933 28.1772V15.5839H222.441V12.5699L226.459 10.1868L228.564 4.67284H233.227V10.2335H240.713V15.5839H233.227V28.1772C233.227 29.1741 233.514 29.9217 234.088 30.4202C234.678 30.903 235.452 31.1445 236.408 31.1445Z" fill="#F4F4F4" />
      <path d="M165.904 44.5086H168.396L169.548 56.8653H169.595L170.818 44.5086H173.639L174.861 56.8653H174.908L176.06 44.5086H178.294L176.625 60.5861H173.404L172.228 49.7453H172.181L171.006 60.5861H167.573L165.904 44.5086Z" fill="#F4F4F4" />
      <path d="M181.279 44.5086H184.782L187.462 60.5861H184.876L184.406 57.3936V57.4395H181.467L180.997 60.5861H178.599L181.279 44.5086ZM184.1 55.2576L182.948 47.3107H182.901L181.773 55.2576H184.1Z" fill="#F4F4F4" />
      <path d="M190.012 53.7417L186.885 44.5086H189.636L191.399 50.4113H191.446L193.21 44.5086H195.725L192.598 53.7417V60.5861H190.012V53.7417Z" fill="#F4F4F4" />
      <path d="M196.279 44.5086H198.771L199.923 56.8653H199.97L201.193 44.5086H204.014L205.236 56.8653H205.283L206.435 44.5086H208.669L207 60.5861H203.779L202.603 49.7453H202.556L201.381 60.5861H197.948L196.279 44.5086Z" fill="#F4F4F4" />
      <path d="M209.977 44.5086H212.563V60.5861H209.977V44.5086Z" fill="#F4F4F4" />
      <path d="M217.862 60.8158C216.608 60.8158 215.66 60.4713 215.017 59.7822C214.375 59.0779 214.053 58.075 214.053 56.7734V55.8547H216.498V56.9572C216.498 57.9984 216.945 58.519 217.838 58.519C218.277 58.519 218.606 58.3965 218.826 58.1515C219.061 57.8912 219.178 57.4778 219.178 56.9113C219.178 56.2375 219.022 55.648 218.708 55.1427C218.395 54.6221 217.815 54.002 216.969 53.2823C215.903 52.3636 215.158 51.5368 214.735 50.8018C214.312 50.0515 214.1 49.2093 214.1 48.2753C214.1 47.0044 214.429 46.0245 215.088 45.3354C215.746 44.6311 216.702 44.2789 217.956 44.2789C219.194 44.2789 220.127 44.6311 220.754 45.3354C221.396 46.0245 221.718 47.0197 221.718 48.3212V48.9873H219.273V48.1605C219.273 47.6092 219.163 47.2111 218.943 46.9661C218.724 46.7058 218.403 46.5757 217.979 46.5757C217.117 46.5757 216.686 47.0886 216.686 48.1145C216.686 48.6964 216.843 49.24 217.157 49.7453C217.486 50.2506 218.074 50.863 218.92 51.5827C220.001 52.5014 220.746 53.3359 221.153 54.0862C221.561 54.8365 221.765 55.7169 221.765 56.7275C221.765 58.0443 221.428 59.0549 220.754 59.7593C220.095 60.4636 219.131 60.8158 217.862 60.8158Z" fill="#F4F4F4" />
      <path d="M223.247 44.5086H230.3V46.8054H225.834V51.0544H229.384V53.3512H225.834V58.2893H230.3V60.5861H223.247V44.5086Z" fill="#F4F4F4" />
      <path d="M231.788 44.5086H235.62C236.953 44.5086 237.924 44.8148 238.536 45.4273C239.147 46.0245 239.453 46.9508 239.453 48.2064V49.194C239.453 50.863 238.888 51.9196 237.76 52.3636V52.4095C238.387 52.5933 238.826 52.9684 239.076 53.535C239.343 54.1015 239.476 54.8595 239.476 55.8088V58.6338C239.476 59.0932 239.492 59.4683 239.523 59.7593C239.554 60.0349 239.633 60.3105 239.758 60.5861H237.125C237.031 60.3258 236.968 60.0808 236.937 59.8511C236.906 59.6215 236.89 59.208 236.89 58.6109V55.671C236.89 54.936 236.765 54.4231 236.514 54.1321C236.279 53.8412 235.863 53.6957 235.268 53.6957H234.374V60.5861H231.788V44.5086ZM235.315 51.3989C235.832 51.3989 236.216 51.2688 236.467 51.0085C236.733 50.7482 236.866 50.3118 236.866 49.6993V48.4591C236.866 47.8772 236.757 47.4561 236.537 47.1958C236.334 46.9355 236.004 46.8054 235.55 46.8054H234.374V51.3989H235.315Z" fill="#F4F4F4" />
    </svg>
  );
}

