import { User } from "../user.ts";
import { AuthToken, AuthSource } from "./types.ts";

export class AuthUser implements User {
    #authSource: AuthSource

    constructor(authSource: AuthSource) {
        this.#authSource = authSource;
    }

    public hasRole(name: string): boolean {
        return this.getAuthToken().hasRole(name);
    }

    public get id(): string {
        return this.getAuthToken().id;
    }

    public get email(): string | undefined {
        const current = this.getAuthToken();
        if (!current.sub.startsWith("email|") || current.name === undefined || current.name.length === 0) {
            return undefined;
        }

        return current.name;
    }

    public get phone(): string | undefined {
        const current = this.getAuthToken();
        if (!current.sub.startsWith("sms|") || current.name === undefined || current.name.length === 0) {
            return undefined;
        }

        return current.name;
    }

    public isAuthenticated(): boolean {
        return this.#authSource.isAuthenticated();
    }

    private getAuthToken(): AuthToken {
        const current = this.#authSource.getAuthToken();
        if (!current.isValid()) {
            throw new Error("User is not authenticated");
        }
        return current;
    }
}