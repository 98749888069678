export default function OtherInsuranceIcon() {
    return (
        <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M37.96 7.30005H6.04C4.70331 7.30005 3.42137 7.83105 2.47618 8.77623C1.531 9.72141 1 11.0034 1 12.34V31.66C1 32.9967 1.531 34.2787 2.47618 35.2239C3.42137 36.169 4.70331 36.7 6.04 36.7H37.96C39.2967 36.7 40.5786 36.169 41.5238 35.2239C42.469 34.2787 43 32.9967 43 31.66V12.34C43 11.0034 42.469 9.72141 41.5238 8.77623C40.5786 7.83105 39.2967 7.30005 37.96 7.30005Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path d="M28.3 7.3V2.68C28.3 2.23444 28.123 1.80712 27.8079 1.49206C27.4928 1.177 27.0655 1 26.62 1H17.38C16.9344 1 16.5071 1.177 16.192 1.49206C15.877 1.80712 15.7 2.23444 15.7 2.68V7.3" stroke="#333333" stroke-width="2" stroke-miterlimit="10" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9695 12L30.939 15.3636V20.0407C30.939 24.1447 29.3245 27.1296 27.416 29.0839C26.5698 29.9572 25.5889 30.689 24.5109 31.2515C23.601 31.718 22.691 32 21.9695 32C21.248 32 20.3381 31.718 19.4281 31.2525C18.4933 30.7732 17.4718 30.0556 16.523 29.0839C14.6135 27.1296 13 24.1447 13 20.0407V15.3636L21.9695 12ZM25.6639 20.7453C25.8455 20.5573 25.9459 20.3056 25.9437 20.0442C25.9414 19.7829 25.8366 19.533 25.6518 19.3482C25.467 19.1634 25.2171 19.0586 24.9558 19.0563C24.6944 19.0541 24.4427 19.1545 24.2547 19.3361L20.9729 22.6179L19.6843 21.3293C19.4963 21.1477 19.2446 21.0473 18.9833 21.0496C18.7219 21.0518 18.472 21.1566 18.2872 21.3414C18.1024 21.5262 17.9976 21.7762 17.9953 22.0375C17.9931 22.2988 18.0935 22.5505 18.2751 22.7385L20.2683 24.7317C20.4552 24.9185 20.7086 25.0235 20.9729 25.0235C21.2372 25.0235 21.4906 24.9185 21.6775 24.7317L25.6639 20.7453Z" fill="#333333" />
            </g>
        </svg>
    );
}
