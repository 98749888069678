import { Signal, createSignal } from "solid-js";

export const mutableSymbol = Symbol('mutable');

export type ChangeProxyHandler<T extends object> = ProxyHandler<T> & {
    originalValues?: Map<string | symbol, any>
};

export type SignalRef<T> = {
    get: () => T | undefined;
    set: (value: T) => void;
    detach: () => void;
};

export const SignalRef = {
    create: <T>(value?: T) => {
        return SignalRef.from(!value ? createSignal(value) : createSignal<T>())
    },
    from: <T>(signal: Signal<T>) => {
        return {
            get: () => {
                return signal ? signal[0]() : undefined;
            },
            set: (newValue: T) => {
                if (signal) {
                    signal[1](newValue as any);
                }
            },
            detach: () => {
                signal = undefined as any;
            }
        }
    }
}