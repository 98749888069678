import { Contact } from "@app/entities/contacts";

export const contactCategories = Object.freeze(['Family', 'Friends', 'Providers', 'Facilities', 'Others', 'Emergency Care Team']);

export const getCategoryPlural = (relationship: string, category: string) => {
    const categoryResult = getCategory(relationship || '', category || '');
    switch (categoryResult) {
        case 'Family': return 'Family';
        case 'Friend': return 'Friends';
        case 'Provider': return 'Providers';
        case 'Facility': return 'Facilities';
        default: return 'Other';
    }
};

export function getCategory(relationship: string, category: string) {
    if (relationship === 'Friend' || relationship === 'Neighbor') {
        return 'Friend';
    } else if (category === 'Doctor+Specialist' || category === 'Service') {
        return 'Provider';
    } else if (category === 'Facility') {
        return 'Facility';
    } else if (category === 'Other') {
        return 'Other';
    } else {
        return 'Family';
    }
}

export const getCategoriesWithData = (contacts: Contact[]) => {
    const c = contacts;
    if (!c) {
        return [];
    }

    const counts = contactCategories.reduce((acc: Record<string, number>, category) => {
        acc[category] = 0;
        return acc;
    }, {});

    contacts.forEach(contact => {
        const category = getCategoryPlural(contact.relationShip || '', contact.category || '');
        if (counts[category] !== undefined) {
            counts[category]++;
        }

        if (contact.isEmergencyContact) {
            counts['Emergency Care Team'] = (counts['Emergency Care Team'] || 0) + 1;
        }
    });

    return Object.keys(counts).filter(category => counts[category] > 0);
};

export const filteredContacts = (contacts: Contact[], selectedCategory: () => string) => {
    return contacts.filter(contact => {
        if (selectedCategory() === 'All') {
            return true;
        }
        if (selectedCategory() === 'Emergency Care Team') {
            return contact.isEmergencyContact;
        }
        return getCategoryPlural(contact.relationShip || '', contact.category || '') === selectedCategory() ||
            (selectedCategory() === 'Others' && !contactCategories.includes(getCategoryPlural(contact.relationShip || '', contact.category || '')));
    });
};

export const getCategoryTypeLabel = (category: string) => {
    switch (category) {
        case 'Doctor+Specialist':
            return 'Type of Doctor or Specialist';
        case 'Facility':
            return 'Type of Facility';
        case 'Service':
            return 'Type of Service';
        case 'Other':
            return 'Other';
        default:
            return 'Type';
    }
};