import { Contact } from "@app/entities/contacts";
import { Stack, Chip } from "@suid/material";
import { Signal, createMemo, Show } from "solid-js";
import { getCategoriesWithData } from "./contact.funcs";

export const ContactCategorySelector = (props: { categorySignal: Signal<string>, contacts: Contact[] }) => {
    const categoriesWithData = createMemo(() => getCategoriesWithData(props.contacts));
    const hasMultipleCategories = () => categoriesWithData().length > 1;
    const [selectedCategory, setSelectedCategory] = props.categorySignal;
    return <>
        <Show when={hasMultipleCategories()}>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    flexWrap: 'wrap',
                    rowGap: '8px',
                    marginLeft: '10px',
                }}
            >
                <Chip
                    label={"All"}
                    variant={selectedCategory() === 'All' ? 'filled' : 'outlined'}
                    onClick={() => setSelectedCategory('All')}
                    sx={{
                        border: '1px solid #E0E0E0',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        borderRadius: '10px',
                        marginLeft: '10px',
                    }}
                />
                {categoriesWithData().map(category => (
                    <Chip
                        label={category}
                        variant={selectedCategory() === category ? 'filled' : 'outlined'}
                        onClick={() => setSelectedCategory(category)}
                        size="medium"
                        sx={{
                            border: '1px solid #E0E0E0',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            borderRadius: '10px',
                        }}
                    />
                ))}
            </Stack>
        </Show>
    </>
}