import { Accessor, Resource, createContext, createSignal } from "solid-js";

export type BoundDataSource<T = any> = {
    [K in keyof T]: T[K] extends string ? string
    : T[K] extends number ? number
    : T[K] extends boolean ? boolean
    : T[K] extends object ? BoundDataSource<T[K]>
    : T[K];
};

export type BoundResource<T = BoundDataSource> = Resource<{ value: T, parent?: BoundResource | undefined }>;

const notInializedError = new Error('BoundSource has not been initialized');
const NOT_INITIALIZED = (() => {
    throw new Error('BoundSource has not been initialized');
}) as any as {
    state: 'errored';
    loading: false;
    error: any;
    latest: never;
    (): never;
};

(NOT_INITIALIZED as any).state = 'errored';
(NOT_INITIALIZED as any).loading = false;
(NOT_INITIALIZED as any).error = notInializedError;

export const BoundSourceContext = createContext<BoundResource>(NOT_INITIALIZED);

export type PageContextInfo = {
    displayValidationErrors: Accessor<boolean>;
    type: string;
};

export type PageContext = PageContextInfo & {
    setDisplayValidationErrors: (value: boolean) => void;
};

export const PageContext = {
    create: (type: string) => {
        const [displayValidationErrors, setDisplayValidationErrors] = createSignal(false);
        return {
            type,
            displayValidationErrors,
            setDisplayValidationErrors
        };
    }
};

export const DataPageContext = createContext<PageContextInfo>({
    type: 'unknown',
    displayValidationErrors: () => false
});