import { Env } from "@libs/environment/mod.ts";
import { logger } from "./mod.ts";

export function logAndReturnError(message: string, obj?: any, consoleLogDevOnly = true): Error {
    return __logAndReturnError(message, obj, false, consoleLogDevOnly);
}

export function logEmitAndReturnError(message: string, obj?: any, consoleLogDevOnly = true): Error {
    return __logAndReturnError(message, obj, true, consoleLogDevOnly);
}

function __logAndReturnError(message: string, obj: any, emit: boolean, consoleLogDevOnly: boolean): Error {
    if (!consoleLogDevOnly || Env.isDev) {
        console.error(message, obj);
    }

    if (emit) {
        logger.emit(message);
    }

    return new Error(message);
}