import { createSignal } from 'solid-js';
import { svc } from '@app/utils/svc.ts';

import { ListEmptyState } from '@app/pages/helpers/commonHelpers';
import MedicationList from '@app/pages/medications/components/MedicationList.tsx';
import ActionBar from '@app/components/ActionBar.tsx';
import DrawerCommon from '@app/components/DrawerCommon';
import { useNavigateToEdit } from '@app/routes/hooks.ts';

import MedicationInteractions from '@app/pages/medications/components/MedicationInteractions';

import AddIcon from '@suid/icons-material/Add';
import AlertIcon from '@suid/icons-material/AssignmentLateOutlined';
import MedsIcon from '@suid/icons-material/Medication';
import { FEATURES } from '@app/utils/features';
import { BoundSource } from '@app/data/components/BoundSource';


const MedsView = () => {
    const editSection = useNavigateToEdit();
    const medicationDetail = svc.card.medications;

    type ActionType = 'Interactions' | null;

    const [currentAction, setCurrentAction] = createSignal<ActionType>(null);

    const action = (type: any) => {
        setCurrentAction(type);
    };

    const actionTitles = {
        'Interactions': 'Potential Drug Interactions',
    };

    return (
        <>
            <BoundSource source={medicationDetail}>{meds =>
                meds.medications && meds.medications.length > 0 ? (
                    <MedicationList
                        medications={meds.medications}
                        onMedicationEditSelected={m => editSection(m._id)}
                    />
                ) : (
                    <ListEmptyState
                        emptyStateHeader="No Medications"
                        emptyStateText="Let's ensure we've got all of the medications, supplements, and over-the-counter meds currently being taken listed here. Simply tap the plus sign to add items to the list."
                        emptyStateIcon={() => <MedsIcon style={{ height: '80px', width: '80px', color: "#d3d3d3" }} />}
                    />
                )
            }</BoundSource>
            <ActionBar
                actionIcon={<AddIcon fontSize="large" sx={{ fontSize: "40px" }} />}
                actionClick={() => editSection("Add Medication")}
                showMoreActions={FEATURES.hasMedPreviewAccess()}
                moreActionsOptions={{
                    icon: <AlertIcon fontSize="large" />,
                    onClick: () => action('Interactions')
                }}
            />
            {currentAction() && (
                <DrawerCommon
                    onClose={() => setCurrentAction(null)}
                    title={actionTitles[currentAction() as keyof typeof actionTitles] || 'Default Title'}
                    closeOnSwipe={false}
                >
                    {currentAction() === 'Interactions' && <MedicationInteractions />}
                </DrawerCommon>
            )}
        </>
    );
};

export default MedsView;
