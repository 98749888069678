// deno-lint-ignore-file no-var
/* eslint-disable no-var */
import { envVars } from './envVars.ts';
import { globalSelf } from '../utils/globalSelf.ts';

declare var window: any;
declare var self: any;

export type VarFunc = (name: string) => string | undefined;

export type Env = {
    readonly [key: string]: string | undefined;
} & {
    readonly envType: 'dev' | 'staging' | 'prod';
    readonly isBrowser: boolean;
    readonly isServiceWorker: boolean;
    readonly global: any;
    readonly isDev: boolean;
    readonly isStage: boolean;
    readonly isProd: boolean;
    update: (configure: (func: VarFunc) => VarFunc) => void;
};

let envType: 'dev' | 'staging' | 'prod' | null = null;
export const Env = (() => {
    const isServiceWorker = typeof self !== 'undefined' &&
        self.constructor?.name === 'ServiceWorkerGlobalScope';
    const isBrowser = isServiceWorker || typeof window !== 'undefined';
    const env = {
        get: (key: string) => envVars.get(key),
    };

    return new Proxy(env, {
        get: (_: any, key: string) => {
            if (key === 'isBrowser') {
                return isBrowser;
            }

            if (key === 'isServiceWorker') {
                return isServiceWorker;
            }

            if (key === 'global') {
                return globalSelf;
            }

            const getEnvType = () => {
                if (envType !== null) {
                    return envType;
                }
                let type = (env.get('APP_CFG_DEPLOY_ENV') ?? env.get('DEPLOY_ENV') ?? 'prod').toLowerCase();
                type = type === 'development' ? 'dev' : type;
                type = type === 'production' ? 'prod' : type;
                type = type === 'test' ? 'staging' : type;
                type = type === 'qa' ? 'staging' : type;
                type = type === 'testing' ? 'staging' : type;
                type = type === 'stage' ? 'staging' : type;
                if (type === 'dev' || type === 'staging' || type === 'prod') {
                    envType = type;
                    return type;
                }

                console.error(`'APP_CFG_DEPLOY_ENV' or 'DEPLOY_ENV' must be set to 'dev', 'staging', or 'prod'`);
                throw new Error(`'APP_CFG_DEPLOY_ENV' or 'DEPLOY_ENV' must be set to 'dev', 'staging', or 'prod'`);
            };

            if (key === 'isDev') {
                return getEnvType() === 'dev';
            }

            if (key === 'isStage') {
                return getEnvType() === 'staging';
            }

            if (key === 'isProd') {
                return getEnvType() === 'prod';
            }

            if (key === 'envType') {
                return getEnvType();
            }

            if (key !== 'update') {
                return env.get(key);
            }

            return (configure: (current: VarFunc) => VarFunc) => {
                const updated = configure(env.get);
                if (updated === undefined || updated === null || typeof updated !== 'function') {
                    throw new Error('EnvSource must be a valid object');
                }
                env.get = updated;
            };
        },
    }) as Env;
})();
